<template>
	<div
		ref="select"
		class="select search select--two"
		:class="{ active: isOpen, chosen: selected , 'select--error': error }"
	>
		<s-display
			:onboarding="onboarding"
			:error="error"
			:placeholder="placeholder"
			:label="label"
			:async="async_"
			:value="selected"
			:autosearch="autosearch"
			:dynamic="dynamic"
			@inputDisplay="inputDisplay"
			@clickButton="clickButton"
			@clickDisplay="clickDisplay"
			@clickArrow="clickArrow"
		/>
		<s-dropdown
			:is-open="isOpen"
			:on-page="onPage"
			:options="filterData"
			:selected="selected"
			:async="async_"
			:autosearch="autosearch"
			:multi="multi"
			:comb="comb"
			:single="single"
			@selectOption="selectOption"
			@deselectOption="deselectOption"
			@scrollToBottom="scrollToBottom"
		/>
	</div>
</template>

<script>

import Display from './Display'
import Dropdown from './Dropdown'
import Formater from './formater'
import Rec from '@/api/recruiter'

import { mapActions } from 'vuex'

export default {
    components: {
        's-display': Display,
        's-dropdown': Dropdown
    },
    props: {
        simple: { // Простой список
            type: Boolean,
            default: false
        },
        onboarding: { //onboarding
            type: Boolean,
            default: false
        },
        multi: { // Список со множественным выбором
            type: Boolean,
            default: false
        },
        comb: { // Список с комбинированным выбором (множественный + одиночный)
            type: Boolean,
            default: false
        },
        single: { // Required - параметр для comb режима, список одиночных вариантов
            type: [String, Number],
            default: undefined
        },
        async: { // Асинхронная подгрузка вариантов (для больших селекторов)
            type: Boolean,
            default: false
        },
        onPage: { // Кол-во вариантов в видимой части dropdown-а
            type: Number,
            default: 10
        },
        dynamic: { // Растягивание dropdown-а по количеству вариантов (на всю высоту сразу)
            type: Boolean,
            default: false
        },
        error: { // Текст ошибки при наличии
            type: String,
            default: ''
        },
        placeholder: { //
            type: String,
            default: ''
        },
        label: { // Лейбл селектора, которое будет отображаться после выбора варианта
            type: String,
            default: ''
        },
        value: { // Значение выбранного варианта
            type: [String, Number],
            default: undefined
        },
        /* ==== Autosearch ==== */
        as: {
            type: Boolean,
            default: false
        },
        asCompany: { // Autosearch by companies
            type: Boolean,
            default: false
        },
        asReCompany: { // Autosearch by recruiter companies
            type: Boolean,
            default: false
        },
        asUniversity: { // Autosearch by universities
            type: Boolean,
            default: false
        },
        /* ==================== */
        options: undefined // Список вариантов для dropdown-а
    },
    data: () => ({
        isOpen: false,
        // только для acync вариантов селектора
        initialData: new Array(),
        initialPage: 1,
        //
        selected: '',
        search: '',
        formater: new Formater()
    }),
    computed: {
        filterData() {
            if (this.autosearch) {
                if (this.as) {
                    if (this.search) {
                        return this.formater.setOptions(this.searchOptions(this.search)).getFormated()
                    } else {
                        this.formater.setOptions(this.options).getFormated()
                    }
                } else if (this.asCompany) {
                    return this.formater.setOptions(this.initialData).getFormated('company')
                } else if (this.asReCompany) {
                    return this.formater.setOptions(this.initialData).getFormated('reCompany')
                } else if (this.asUniversity) {
                    return this.formater.setOptions(this.initialData).getFormated('university')
                }
            } else {
                return this.formater.setOptions(this.options).getFormated()
            }

            return this.formater.getFormated()
        },
        autosearch() {
            return this.as || this.asCompany || this.asReCompany || this.asUniversity
        },
        async_() {
            return this.async || this.asCompany || this.asReCompany || this.asUniversity
        }
    },
    watch: {
        value(newV) {
            this.selected = newV
        },
    },
    created() {
        if (this.value) {
            this.selected = this.value
        }
    },
    mounted() {
        document.addEventListener('click', event => {
            if (this.$refs.select && this.$refs.select.contains(event.target)) return
            else this.isOpen = false
        })
    },
    methods: {
        ...mapActions('cmn', [
            'getGlobalCities',
            'getVacancyCities',
            'getAllEducations',
            'getAllCompanies'
        ]),
        clickDisplay() {
            if (!this.autosearch) {
                this.isOpen = !this.isOpen
            }
        },
        inputDisplay(val) {
            this.search = val
            this.initialPage = 1
            if (this.asCompany) {
                this.getAllCompanies({ company_name: this.search, page: this.initialPage }).then(response => {
                    this.initialData = response.results
                    if (this.initialData.length) this.isOpen = !!this.initialData.length
                })
            } else if (this.asReCompany) {
                Rec.searchCompany({ name: this.search }).then(response => {
                    this.initialData = response.data
                    if (this.initialData.length) this.isOpen = !!this.initialData.length
                })
            } else if (this.asUniversity) {
                this.getAllEducations({ search: this.search.toUpperCase(), page: this.initialPage }).then(response => {
                    this.initialData = response.results
                    if (this.initialData.length) this.isOpen = !!this.initialData.length
                })
            }
        },
        scrollToBottom() {
            if (this.asCompany) {
                this.getAllCompanies({ company_name: this.search, page: ++this.initialPage }).then(response => {
                    this.initialData = this.initialData.concat(response.results)
                })
            } else if (this.asUniversity) {
                this.getAllEducations({ search: this.search.toUpperCase(), page: ++this.initialPage }).then(response => {
                    this.initialData = this.initialData.concat(response.results)
                })
            }
        },
        clickButton() {
            if (!this.autosearch) {
                this.isOpen = !this.isOpen
            }
        },
        clickArrow() {
            this.isOpen = !this.isOpen

            if (this.isOpen) {
                if (this.asCompany) {
                    this.getAllCompanies({ company_name: this.search, page: this.initialPage }).then(response => {
                        this.initialData = response.results
                        if (this.initialData.length) this.isOpen = !!this.initialData.length
                    })
                }

                if (this.asReCompany) {
                    Rec.searchCompany({ name: this.search }).then(response => {
                        this.initialData = response.data
                        if (this.initialData.length) this.isOpen = !!this.initialData.length
                    })
                }

                if (this.asUniversity) {
                    this.getAllEducations({ search: this.search.toUpperCase(), page: this.initialPage }).then(response => {
                        if (this.initialData.length) {
                            this.isOpen = !!this.initialData.length
                            if (this.initialPage > 1) {
                                this.initialData = this.initialData.concat(response.results)
                            }
                        } else {
                            this.initialData = response.results
                        }
                    })
                }
            }
        },
        selectOption(val) {
            if (this.multi) {
                if (this.selected) this.selected += ', ' + val
                else this.selected = val
                this.$emit('selected', this.selected.split(', '))
            } else if (this.comb)  {
                if (val === this.single) this.isOpen = false
                if (val === this.single || !this.selected || ( this.single === this.selected && this.single !== val)) this.selected = val
                else this.selected += ', ' + val
                this.$emit('selected', this.selected.split(', '))
            } else {
                this.selected = val
                this.isOpen = false
                this.$emit('selected', this.selected)
            }
        },
        deselectOption(val) {
            let splitSelected = this.selected.split(', ')

            if (splitSelected.includes(val)) {
                this.selected = splitSelected.filter(item => item !== val).join(', ')
            }
        },
        searchOptions(search) {
            let reg = new RegExp(`^${search}`)
            let result = this.options.filter(x => reg.test(x))

            if (result.length) {
                this.isOpen = true
            }

            return result
        }
    }
}
</script>
