<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<div class="modal-title sm">
				Изменить вопросы к вакансии
			</div>
		</div>
		<div>
			<button
				class="modal__button-added"
				@click="addedQuestion"
			>
				<img
					:src="'/img/icons/icon-add.png' | prodSrcImage"
					alt="Изменить вопросы к вакансии"
				>
				Добавить вопрос
			</button>
			<form>
				<div
					v-for="(question, key) in $v.questions.$each.$iter"
					:key="key"
					class="question-field__group"
				>
					<BaseInput
						v-model="question.text.$model"
						:label="`Вопрос № ${(Number(key) + 1)}`"
					>
						<span
							v-if="question.text.$error"
							class="help-block form-error"
						>
							Это обязательное поле
						</span>
					</BaseInput>
					<BaseInput
						v-model="question.priority.$model"
						class="priority"
						label="приоритет"
						type="number"
						min="1"
						:max="String(questions.length)"
						not-label
					>
						<span
							v-if="question.priority.$error"
							class="help-block form-error"
						>
							Это обязательное поле
						</span>
					</BaseInput>
					<div
						class="delete-button"
						@click="deleteQuestion(key)"
					/>
				</div>
				<BaseButton
					v-if="questions.length || isEdit"
					orange
					type="button"
					@clickButton="save"
				>
					Сохранить
				</BaseButton>
			</form>
		</div>
	</div>
</template>

<script>
import BaseInput from '../../../BaseComponents/BaseInput';
import BaseButton from '../../../BaseComponents/BaseButton';
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
export default {
	components: {
		BaseInput,
		BaseButton
	},
	data() {
		return {
			numberQuestion: 0,
			questions: [],
			isEdit: false,
		};
	},
	validations: {
		questions: {
			$each: {
				text: { required },
				priority: { required },
				$trackBy: 'id'
			}
		}
	},
	computed: {
		...mapGetters('modal', {
				questionsVacancy: 'getQuestions'
		}),
	},
	mounted() {
		this.questions = this.questionsVacancy.map(el => {
			return {
				...el
			};
		});
		this.numberQuestion = this.questions.length;
	},
	methods: {
		...mapActions('modal', ['setQuestions']),
		addedQuestion() {
			this.numberQuestion++;
			this.questions = [
				...this.questions,
				{
					text: '',
					priority: Number(this.numberQuestion),
					id: this.numberQuestion
				}
			];
		},
		deleteQuestion(index) {
			this.isEdit = true
			this.numberQuestion--;
			this.questions.splice(index, 1);
		},
		save() {
			this.$v.questions.$touch();
			if (!this.$v.$invalid) {
				this.setQuestions(this.questions);
				this.modalClose();
			}
		}
	}
};
</script>

<style lang="less">
.modal__button-added {
	display: flex;
	align-content: center;
	margin-bottom: 20px;
	img {
		margin-right: 10px;
	}
}
.question-field__group {
	position: relative;
	display: flex;
	align-items: center;
	@media @tablet-end {
		padding: 0 50px 0px 0px;
	}
	.field-wrap {
		&:first-child {
			width: 100%;
		}
		&:last-child {
			margin-left: 20px;
			input {
				text-align: center;
			}
		}
	}
}
.priority {
	margin-left: 20px;
}
.delete-button {
	background: url(../../../../assets/img/icons/icon-modal-close.svg) center
		center no-repeat;
	position: absolute;
	top: 10px;
	right: -25px;
	width: 16px;
	height: 16px;
	cursor: pointer;
	@media @tablet-end {
		right: 13px;
	}
}
.priority {
	input {
		text-align: center;
	}
}
</style>
