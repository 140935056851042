<template>
	<section
		id="numbers"
		v-wow
		class="section-simple stats wow fadeInUp"
		data-wow-delay="0.2s"
	>
		<div class="stats__list-wrap">
			<div class="content">
				<div class="stats__list">
					<div
						v-wow
						class="stat wow fadeIn"
						data-wow-delay="0.2s"
					>
						<div class="stat__title">
							Надежность <br>и постоянство
						</div>
						<div class="stat__value">
							<number
								ref="number1"
								:from="0"
								:to="12"
								:duration="3"
								animation-paused
								easing="Power1.easeOut"
							/>
							лет
						</div>
						<div
							v-wow
							class="stat__subtitle wow fadeInUp"
							data-wow-delay="0.2s"
						>
							Работы
						</div>
					</div>
					<div
						v-wow
						class="stat wow fadeIn"
						data-wow-delay="0.4s"
					>
						<div class="stat__title">
							Частная <br>инициатива
						</div>
						<div class="stat__value">
							<number
								ref="number2"
								:from="0"
								:to="1"
								:duration="1"
								animation-paused
								easing="Power1.easeOut"
							/>
							млрд
						</div>
						<div
							v-wow
							class="stat__subtitle wow fadeInUp"
							data-wow-delay="0.4s"
						>
							инвестиций
						</div>
					</div>
					<div
						v-wow
						class="stat wow fadeIn"
						data-wow-duration="0.6s"
					>
						<div class="stat__title">
							Проект крупных <br>работодателей
						</div>
						<div class="stat__value">
							<number
								ref="number3"
								:from="0"
								:to="500"
								:duration="3"
								animation-paused
								easing="Power1.easeOut"
							/>
						</div>
						<div
							v-wow
							class="stat__subtitle wow fadeInUp"
							data-wow-delay="0.6s"
						>
							стажировок от ведущих компаний страны
						</div>
					</div>
					<div
						v-wow
						class="stat wow fadeIn"
						data-wow-delay="0.8s"
					>
						<div class="stat__title">
							Фокус на устойчивое <br>развитие
						</div>
						<div class="stat__value">
							<number
								ref="number4"
								:from="0"
								:to="80"
								:duration="3"
								animation-paused
								easing="Power1.easeOut"
							/>+
						</div>
						<div
							v-wow
							class="stat__subtitle wow fadeInUp"
							data-wow-delay="0.8s"
						>
							курсов
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'ExpertStats',
    created () {
        if (this.$route.name === 'Expert') window.addEventListener('scroll', this.getClass)
    },
    destroyed () {
        window.removeEventListener('scroll', this.getClass)
    },
    methods: {
        getClass () {
            const el = document.getElementById('numbers')
            const rect = el.getBoundingClientRect()
            const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
            let startPlay = !(
                Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < 50 ||
                Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < 50
            )
            if (startPlay) {
                this.$refs.number1.play()
                this.$refs.number2.play()
                this.$refs.number3.play()
                this.$refs.number4.play()
            }
        },
    }
};
</script>
