<template>
	<div class="modal-content">
		<div class="subscribed">
			<img
				:src="'/img/landing/role/pic__subscribed--x2.png' | prodSrcImage"
				alt="Иконка"
				class="subscribed__pic"
			>
			<div class="title-block">
				{{ payload }} <br>
				Мы отправили гайд на указанную почту
			</div>
			<div
				class="button"
				@click="modalClose"
			>
				Отлично
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'LandingScs',
    computed: {
        payload() {
            return this.$store.state.modal.payload;
        },

    }
}
</script>

<style scoped>

</style>
