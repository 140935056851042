const state = {
    activeName: String,
    isActive: false,
    payload: null,
    questions: [],
    specialisations: [],
    activities: [],
    activeFail: null,
    activeSuccess: null,
    activeData: null
}
const actions  = {
    setQuestions({commit},params) {
        commit('setQuestions', params)
    },
    setSpecialisations({commit},params) {
        commit('setSpecialisations', params)
    },
    setActivities({commit},params) {
        commit('setActivities', params)
    },
}
const mutations = {
    open(state, { name, payload = null }) {
        state.activeName = name
        state.isActive = true;
        document.body.style.overflow = 'hidden'
        if (/fail/i.test(name)) {
            state.activeFail = name;
            state.activeName = 'stdFail'
        } else if (/success/i.test(name)) {
            state.activeSuccess = name;
            state.activeName = 'stdSuccess'
        } else {
            state.activeFail = null;
            state.activeSuccess = null;
        }
        state.payload = payload
    },
    setQuestions(state,payload){
        state.questions = payload
    },
    setSpecialisations(state, payload){
        state.specialisations = payload
    },
    setActivities(state, payload){
        state.activities = payload
    },
    close(state, { changeTo = null, payload = null }) {
        if (changeTo && !changeTo.type) {
            if (/fail/i.test(changeTo)) {
                state.activeFail = changeTo;
                state.activeName = 'stdFail'
            } else if (/success/i.test(changeTo)) {
                state.activeSuccess = changeTo;
                state.activeName = 'stdSuccess'
            } else if (state.activeName === 'CancelModal') {
                state.activeFail = null;
                state.activeSuccess = null;
                state.activeName = 'ApplyToContest'
            } else {
                state.activeFail = null;
                state.activeSuccess = null;
                state.activeName = changeTo
            }
            state.payload = payload
            return;
        } else {
            state.activeName = null;
            state.isActive = false;
            state.payload = null
            document.body.style.overflow = 'auto'
        }
    },
    setActiveData(state, data) {
        state.activeData = data
    },
    resetActiveData(state) {
        state.activeData = null
    }
}

const getters = {
    currentActive: state => state.activeName,
    isActive: state => state.isActive,
    getPayload: state => state.payload,
    getQuestions: state => state.questions,
    getSpecialisations: state => state.specialisations,
    getActivities: state => state.activities,
    currentFail: state => state.activeFail,
    currentSuccess: state => state.activeSuccess,
    getActiveData: state => state.activeData
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
