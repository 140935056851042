import {HTTP, getToken} from './default'

export default {
    getUsersFilters() {
        return HTTP({
            url: 'recruiter/search/filters/',
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    sendTurbo(id) {
        return HTTP({
            url: `/user/users/${id}/seen/`,
            method: 'PUT',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    getUsersData({ page = 1, city = '', education = '', filters = [], employment_type = [], birthday_after = '', birthday_before = '', month_worked_max = '', month_worked_min = '', search = '', last_login_after = '', seen = false, turbo = false } = new Object()) {
        return HTTP({
            url: 'recruiter/users/',
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            params: {
                page,
                seen,
                turbo,
                city: city.length ? city.join() : '',
                education: education.length ? education.join() : '',
                employment_type: employment_type.length ? employment_type.join() : '',
                filters: filters.length ? filters.join() : '',
                birthday_after: birthday_after ? birthday_after : '',
                birthday_before: birthday_before ? birthday_before : '',
                month_worked_max: month_worked_max ? month_worked_max : '',
                month_worked_min: month_worked_min ? month_worked_min : '',
                search: search ? search : '',
                last_login_after: last_login_after ? last_login_after : ''
            }
        })
    },
}
