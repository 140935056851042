<template>
	<section class="section wow fadeIn">
		<div class="content">
			<div class="system-response">
				<img
					:src="'/img/racoon/pic__racoon-3.png' | prodSrcImage"
					alt="Енот"
					class="system-response__pic"
				>
				<div class="subtitle-section">
					Вы успешно подписались
				</div>
				<div class="text">
					Спасибо, что подписались на нашу рассылку. <br>
					Удачи в карьере и саморазвитии!
				</div>
				<router-link
					:to="isLogged ? '/platform' : '/'"
					class="button orange"
				>
					Перейти на главную
					<img
						:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
						alt="Иконка"
						class="button__icon-right"
					>
				</router-link>
			</div>
		</div>
	</section>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'Subscribed',
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    }
}
</script>
