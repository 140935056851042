<template>
	<div class="sections">
		<section
			v-wow
			class="section-simple about wow fadeIn"
			data-wow-delay="0.2s"
		>
			<div class="content">
				<h1 class="page-title">
					О нас
				</h1>
				<div class="about__grid">
					<div class="about__text">
						<p><span>Всероссийская программа «Лифт в будущее»</span>, оператором которой выступает Благотворительный фонд «Система», стартовала в 2011 как масштабная инициатива по поддержке современного инженерного образования в России</p>
						<p>В 2020 году была создана открытая образовательная платформа «Лифт в будущее», которая реализует концепцию «ПРЕ HR-hub» с бесплатным доступом к обучающим программам и профориентационным тестам, библиотеке профессий, бирже вакансий от крупных российских работодателей, а также к стажировкам, консультациям наставников из числа ведущих отраслевых экспертов из лидирующих компаний страны. Команда «Лифта» – увлеченные и творческие люди, открытые новым инициативам и создающие флагманские проекты в области профориентации и старта карьеры в России</p>
					</div>
					<img
						:src="'/img/landing/role/pic__about-1--lg.png' | prodSrcImage"
						alt="Картинка"
						class="about__pic"
					>
				</div>
			</div>
		</section>

		<section
			v-wow
			class="section-simple get wow fadeInUp"
			data-wow-delay="0.2s"
		>
			<div class="content">
				<div class="get__list">
					<div class="get__point">
						Программа помогает молодым специалистам раскрыть свой потенциал
					</div>
					<div class="get__point">
						Получить доступ к экспертному сообществу
					</div>
					<div class="get__point">
						Предложить решение реальных задач рынка
					</div>
				</div>
			</div>
		</section>

		<section
			v-wow
			class="section-simple helper wow fadeInUp"
			data-wow-delay="0.2s"
		>
			<div class="content">
				<div class="helper__grid">
					<img
						:src="'/img/landing/role/pic__about-2.png' | prodSrcImage"
						alt="Картинка"
						class="helper__pic"
					>
					<div class="about__text">
						В качестве героя - помощника платформы “Лифт в будущее” был выбран енот Тоша, потому что еноты очень умные. По некоторым тестам они превосходят в интеллекте обезьян и помнят выученные навыки не меньше трех лет без повторений. А еще — у них живой, исследовательский характер.
					</div>
				</div>
			</div>
		</section>

		<section
			v-wow
			class="section-simple brands wow fadeInUp"
			data-wow-delay="0.2s"
		>
			<div class="content">
				<div class="brands__list">
					<div class="brand">
						<div class="brand__title">
							Главный партнер
						</div>
						<img
							:src="'/img/brands/brand2.svg' | prodSrcImage"
							alt="МТС"
							class="brand__logo"
						>
					</div>
					<div class="brand">
						<img
							:src="'/img/brands/brand__segezha--black.svg' | prodSrcImage"
							alt="Segezha Group"
							class="brand__logo"
						>
					</div>
					<div class="brand">
						<img
							:src="'/img/brands/brand__etalon--black.svg' | prodSrcImage"
							alt="Эталон"
							class="brand__logo"
						>
					</div>
					<div class="brand">
						<img
							:src="'/img/brands/brand__step--black.svg' | prodSrcImage"
							alt="Степь Агрохолдинг"
							class="brand__logo"
						>
					</div>
					<div class="brand">
						<img
							:src="'/img/brands/brand__binnopharm--black.svg' | prodSrcImage"
							alt="Binnopharm Group"
							class="brand__logo"
						>
					</div>
					<div class="brand">
						<img
							:src="'/img/brands/brand__medsi--black.svg' | prodSrcImage"
							alt="Медси"
							class="brand__logo"
						>
					</div>
					<div class="brand">
						<img
							:src="'/img/brands/brand__besk--black.svg' | prodSrcImage"
							alt="АО БЭСК"
							class="brand__logo"
						>
					</div>
				</div>
			</div>
		</section>

		<section
			v-wow
			class="section-simple downloads wow fadeInUp"
			data-wow-delay="0.2s"
		>
			<div class="content">
				<div class="downloads__list">
					<a
						:href="'/media/logos.zip' | prodSrcImage"
						class="button orange"
					>
						Скачать логотипы
						<img
							:src="'/img/icons/icon__download-cloud--white.svg' | prodSrcImage"
							alt="Иконка"
							class="button__icon-right"
						>
					</a>
					<a
						:href="'/media/banners.zip' | prodSrcImage"
						class="button orange"
					>
						Скачать баннеры
						<img
							:src="'/img/icons/icon__download-cloud--white.svg' | prodSrcImage"
							alt="Иконка"
							class="button__icon-right"
						>
					</a>
					<a
						class="button orange copy"
						@click="copyToClipBoard('https://lift-bf.ru?utm_source=ref&utm_medium=main&utm_campaign=partners')"
					>
						{{ isCopied ? 'Скопировано' : 'Скопировать ссылку' }}
						<img
							:src="'/img/icons/icon__copy--white.svg' | prodSrcImage"
							alt="Иконка"
							class="button__icon-right"
						>
					</a>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
    name: 'Contacts',
    data: () => ({
        isCopied: false
    }),
    methods: {
        copyToClipBoard(textToCopy){
            navigator.clipboard.writeText(textToCopy);
            this.isCopied = true
        }
    }
}
</script>

<style scoped>
.brand img {
    width: 140px;
    height: 100px;
}
</style>
