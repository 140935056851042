<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<h3 class="modal-title">
				Введите промокод
			</h3>
		</div>
		<form
			class="form"
			novalidate
			@submit.prevent="send"
		>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.promoCode.$model"
						type="text"
						class="field"
						placeholder="Промокод"
					>
					<div class="label">Промокод</div>
					<span
						v-if="$v.promoCode.$error"
						class="help-block form-error"
					>Это обязательное поле</span>
				</label>
			</div>
			<BaseErrorMessageForm
				v-if="responseError.isShow"
				:text="responseError.text"
			/>
			<button
				v-if="!isSended"
				class="button orange lg"
			>
				Сохранить изменения
			</button>
			<div
				v-else
				class="button orange lg button-preloader"
			>
				<div class="button-preloader-icons">
					<div class="button-preloader-icon" />
					<div class="button-preloader-icon" />
					<div class="button-preloader-icon" />
				</div>
			</div>
		</form>
	</div>
</template>

<script>

import {mapGetters, mapState} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import User from '@/api/user';
import BaseErrorMessageForm from '@/components/BaseComponents/BaseErrorMessageForm.vue';

export default {
	components: {
		BaseErrorMessageForm,
	},
	data: () => ({
		isSended: false,
		promoCode: '',
		responseError: {
			isShow: false,
			text: '',
		}
	}),
    validations: {
        promoCode: { required }
    },
    computed: {
        ...mapState('user', [
            'user'
        ]),
        ...mapGetters('user', {
            userID: 'userID'
        })
    },
    methods: {
        send() {
            this.$v.promoCode.$touch();
						this.responseError.isShow = false;
						
            if (this.$v.promoCode.$invalid) {
                console.log('Error in modal Reg');
            } else if (!this.isSended || !this.$v.promoCode.$invalid) {
                this.isSended = true
                User.sendPromoCode(
                    {'promo-code': this.promoCode},
                    this.userID
                ).then(() => this.modalOpen('promoSuccess'))
                .catch(error => {
									this.responseError.isShow = true;
									this.responseError.text = error.response.data;
                })
                .finally(() => this.isSended = false)
            }
        }
    }
}
</script>
