<template>
	<section
		v-if="extraInfo"
		class="section section-downloads"
	>
		<div class="content content--xs">
			<div class="downloads">
				<a
					v-for="(item, index) in extraInfo.download_buttons"
					:key="index"
					:href="item.download_link"
					class="button"
					target="_blank"
					download
				>
					{{ item.button_text }}
					<img
						:src="'/img/icons/icon__download-cloud--white.svg' | prodSrcImage"
						alt="Иконка"
						class="button__icon-right"
					>
				</a>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Downloads',
    props: {
        extraInfo: {
            type: Object,
            default: null
        }
    }
}
</script>
