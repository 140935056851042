<template>
	<footer
		v-if="!$route.meta.isMaintenance && !$route.meta.isLandingUr && !$route.meta.isOnboarding"
		class="footer-role"
	>
		<div
			v-if="!$route.meta.isLiftNoRefusal"
			class="footer-role__contacts"
		>
			<div class="content">
				<div class="footer-role__grid">
					<div class="footer-role__start">
						<router-link
							to="/"
							class="logo"
						/>
						<div class="socials">
							<a
								href="https://vk.com/lift2future"
								target="_blank"
								class="social"
							>
								<img
									:src="'/img/icons/icon__social-vk.svg' | prodSrcImage"
									alt="ВК"
									class="social__icon"
								>
							</a>
							<a
								href="https://t.me/liftinternship"
								target="_blank"
								class="social"
							>
								<img
									:src="'/img/icons/icon__social-telegram.svg' | prodSrcImage"
									alt="Telegram"
									class="social__icon"
								>
							</a>
							<a
								href="https://bf.sistema.ru/"
								target="_blank"
								class="social"
							>
								<img
									:src="'/img/logos/logo__sistema--black.png' | prodSrcImage"
									alt="БФ Система"
									height="24px"
									class="social__icon"
								>
							</a>
						</div>
					</div>
					<div class="footer-role__end">
						<div class="contacts">
							<div class="contact">
								<div class="contact__title">
									Контакты
								</div>
								<div class="contact__value">
									<a href="tel: +74957374419">+ 7 (495) 737-44-19</a>
								</div>
								<div class="contact__value">
									<a href="mailto: info@mail.lift-bf.ru">info@mail.lift-bf.ru</a>
								</div>
							</div>
							<div class="contact">
								<div class="contact__title">
									Адрес
								</div>
								<div class="contact__value">
									127006, Москва, ул. Малая Дмитровка, 18, строение 1
								</div>
							</div>
						</div>
						<div
							v-if="!isLogged"
							class="navigation-footer"
						>
							<router-link
								to="/school"
								class="navigation-footer__link"
								:class="{'none': $route.name === 'School'}"
							>
								Школьникам
							</router-link>
							<router-link
								to="/student"
								class="navigation-footer__link"
								:class="{'none': $route.name === 'Student'}"
							>
								Студентам
							</router-link>
							<router-link
								to="/"
								class="navigation-footer__link"
								:class="{'none': $route.name === 'Expert'}"
							>
								Специалистам
							</router-link>
							<router-link
								to="/bez-otkaza"
								class="navigation-footer__link"
							>
								Лифт без отказа
							</router-link>
						</div>
						<div
							v-else
							class="navigation-footer"
						>
							<router-link
								to="/bez-otkaza"
								class="navigation-footer__link"
							>
								Лифт без отказа
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-role__copyright">
			<div
				class="content"
				:style="$route.meta.isLiftNoRefusal ? 'padding: 0' : ''"
			>
				<div class="footer-role__grid">
					<div class="footer-role__start">
						<div class="copyright">
							© Благотворительный фонд «Система», 2024 г.
						</div>
					</div>
					<div class="footer-role__end">
						<div class="footer-role__links">
							<router-link
								to="/privacy-policy"
								class="footer-role__link"
							>
								Политика конфиденциальности
							</router-link>
							<router-link
								to="/agreement"
								class="footer-role__link"
							>
								Пользовательское соглашение
							</router-link>
							<a
								href="https://ea5ec095-4ab2-4f77-811e-49e733f2acc8.selcdn.net/contest_service/user_files/374944/da9fd646-7a93-4850-81a3-30ed2b5d7279/Положение_о_стипендиальной_программе_2023_23_06_23.pdf"
								target="_blank"
								class="footer-role__link"
							>Положение о стипендиальной программе</a>
						</div>
						<div
							class="footer-role__links"
							style="margin-top: 10px"
						>
							<a
								href="https://ea5ec095-4ab2-4f77-811e-49e733f2acc8.selcdn.net/static_video/%D0%A3%D1%81%D1%82%D0%B0%D0%B2%20%D0%91%D0%A4%20%D0%A1%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B0%202022.pdf"
								target="_blank"
								class="footer-role__link"
							>
								Устав Фонда
							</a>
							<a
								href="https://ea5ec095-4ab2-4f77-811e-49e733f2acc8.selcdn.net/static_video/%D0%A1%D0%B2_%D0%B2%D0%BE_%D0%BE_%D0%B3%D0%BE%D1%81_%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D0%B8_%D0%9E%D0%93%D0%A0%D0%9D_%D0%9C%D0%B8%D0%BD%D1%8E%D1%81%D1%82,_%D1%81_%D0%BD%D0%BE%D0%B2%D1%8B%D0%BC_%D0%B0%D0%B4%D1%80%D0%B5%D1%81%D0%BE%D0%BC.pdf"
								target="_blank"
								class="footer-role__link"
							>
								Свидетельство ОГРН
							</a>
							<a
								:href="'/media/requisites.pdf' | prodSrcImage"
								target="_blank"
								class="footer-role__link"
							>Реквизиты Фонда</a>
							<a
								href="https://bf.sistema.ru/about/documents/"
								target="_blank"
								class="footer-role__link"
							>Годовые отчеты Фонда</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'Footer2',
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    }
}
</script>

<style lang="less" scoped>
.none {
    display: none
}
</style>
