import User from '@/api/user';
import UserJob from '@/api/userJob';
import UserEd from '@/api/userEducation';
import Cnt from '@/api/contest';

const state = {
    user: null,
    watchUser: null,
    token: localStorage.getItem('userToken')
        ? localStorage.getItem('userToken')
        : false,
    jwtToken: localStorage.getItem('jwtToken')
        ? localStorage.getItem('jwtToken')
        : false,
    id: localStorage.getItem('userID') ? localStorage.getItem('userID') : false,
    isVerification: localStorage.getItem('userIsActive') === 'true' ? true : false,
    socialLogin: false,
    responses: undefined,
    usersCount: null,
    pageName: 'Профиль',
    educationList: [],
    favoriteVacancies: [],
    certificates: [],
    programCertificates: [],
    coursesInProgress: [],
    coursesCompleted: [],
    programs: [],
    transitionAfterReg: false,
    prevRoute:null,
    isShowUserCreate: false,
    user_role: localStorage.getItem('userRole') ? localStorage.getItem('userRole') : null,
    backOfficeRole: null
};

const actions = {
    login({ commit }, { email, pass, referrer_page = '/', pathName = '/' }) {
        return new Promise((resolve, reject) => {
            User.login(
                {
                    email,
                    pass,
                    referrer_page
                },
                pathName
            )
                .then(response => response.data)
                .then(result => {

                    localStorage.setItem('userToken', result.auth_token);
                    localStorage.setItem('userID', result.id);
                    localStorage.setItem('userRole', result.user_role);
                    localStorage.setItem('jwtToken', result.jwt_token);
                    localStorage.setItem(
                        'userIsActive',
                        result.is_verification
                    );

                    commit('setUserVerification', result.is_verification);
                    commit('authSuccess', {
                        token: result.auth_token,
                        id: result.id,
                        is_verification: result.is_verification
                    });
                    resolve(result);
                })
                .catch(error => {
                    if (new RegExp(/4\d\d/, 'g').test(error.response.status) && error.response.data) {
                        reject(error.response.data);
                    } else {
                        reject(error.response)
                    }

                });
        });
    },
    loginMobile({ commit }, {code, pathName}) {
        return new Promise((resolve, reject) => {
            User.loginMobile({code}, pathName)
                .then(response => response.data)
                .then(result => {
                    localStorage.setItem('userToken', result.auth_token);
                    localStorage.setItem('userID', result.id);
                    localStorage.setItem('userIsActive', result.is_verification);
                    localStorage.setItem('userRole', result.user_role);
                    commit('setUserVerification', result.is_verification)
                    commit('authSuccess', { token: result.auth_token, id: result.id, is_verification: result.is_verification });
                    resolve(result)
                })
                .catch(error => {
                    if (new RegExp(/4\d\d/, 'g').test(error.response.status) && error.response.data) reject(error.response.data.error_text)
                })
        })
    },
	loginEvent({ commit }, { data, slug }) {
		return new Promise((resolve, reject) => {
			let registerFunction;

            switch (slug) {
                case 'mentorsapplication-2':
                    registerFunction = Cnt.registerToEventMentorsApplication({ data, slug });
                    break;
                default:
                    registerFunction = Cnt.registerToEvent({ data, slug });
            }

            registerFunction
				.then(response => response.data)
				.then((result) => {
                    if (!localStorage.getItem('userToken')) {
                        localStorage.setItem('userToken', result.auth_token);
                        localStorage.setItem('userID', result.id);
                        localStorage.setItem('userIsActive', result.is_verification);
                        localStorage.setItem('userRole', result.user_role);
                        commit('setUserVerification', result.is_verification);
                        commit('authSuccess', {
                            token: result.auth_token,
                            id: result.id,
                            is_verification: result.is_verification
                        });
                    }

					resolve(result);
				})
				.catch(error => {
					if (new RegExp(/4\d\d/, 'g').test(error.response.status) && error.response.data) {
                        const data = error.response.data
                        if (data.error_text) {
                            reject(data.error_text)
                        } else if (Array.isArray(data)) {
                            reject(data[0])
                        }
                        reject()
                    }
				});
		});
	},
    loginExternal({}, net) {
        User.loginExternal(net);
    },
    regin({ commit }, { data, path = '/' }) {
        return new Promise((resolve, reject) => {
            User.register(data, path)
                .then(response => response.data)
                .then(result => {
                    localStorage.setItem('userToken', result.auth_token);
                    localStorage.setItem('userID', result.id);
                    localStorage.setItem('userIsActive', false);
                    localStorage.setItem('userRole', result.user_role);
                    localStorage.setItem('currentPage', data.referrer_page);
                    commit('authSuccess', {
                        token: result.auth_token,
                        id: result.id,
                        is_verification: false
                    });
                    resolve(result);
                })
                .catch(error => {
                    if (new RegExp(/4\d\d/, 'g').test(error.response.status) && error.response.data) {
                        reject(error.response.data);
                    } else {
                        reject(error.response)
                    }

                });
        });
    },
    reginEvent({ commit }, { data, path = '/' }) {
        return new Promise((resolve, reject) => {
            User.registerEvent(data, path)
                .then(response => {
                    let result = response.data;
                    if (response.status === 201) {
                        localStorage.setItem('userToken', result.auth_token);
                        localStorage.setItem('userID', result.id);
                        localStorage.setItem('userIsActive', false);
                        localStorage.setItem('userRole', result.user_role);
                        commit('authSuccess', {
                            token: result.auth_token,
                            id: result.id,
                            is_verification: false
                        });
                    }
                    resolve(result);
                })
                .catch(error => {
                    if (
                        new RegExp(/4\d\d/, 'g').test(error.response.status) &&
                        error.response.data
                    )
                        reject(error.response.data.error_text);
                });
        });
    },
    authExternal({ commit }, upid) {
        return new Promise((resolve, reject) => {
            User.authExternal(upid)
                .then(response => response.data)
                .then(result => {
                    localStorage.setItem('userToken', result.auth_token);
                    localStorage.setItem('userID', result.id);
                    localStorage.setItem('userIsActive', false);
                    localStorage.setItem('userRole', result.user_role);
                    commit('authSuccess', {
                        token: result.auth_token,
                        id: result.id,
                        is_verification: false
                    });
                    resolve(result);
                })
                .catch(error => {
                    if (
                        new RegExp(/4\d\d/, 'g').test(error.response.status) &&
                        error.response.data
                    )
                        reject(error.response.data.error_text);
                });
        });
    },
    detail({ commit, state }) {
        return new Promise((resolve, reject) => {
            User.detail(state.id)
                .then(response => response.data)
                .then(result => {
                    commit('setUser', result);
                    resolve(result);
                })
                .catch(error => {
                    if (
                        error.response.data.error_text === 'Недопустимый токен.'
                    ) {
                        localStorage.removeItem('userToken');
                        localStorage.removeItem('jwtToken');
                        localStorage.removeItem('userID');
                        sessionStorage.removeItem('manual_close');
                        window.location.reload();
                    }
                    reject();
                });
        });
    },
    getBackOfficeRole({ commit }) {
        return new Promise((resolve) => {
            User.getRole()
                .then(response => response.data)
                .then(result => {
                    commit('setBackOfficeRole', result.backoffice_role);
                    resolve(result);
                })
        });
    },
    detailWatchUser({ commit }, id) {
        return new Promise((resolve) => {
            User.detail(id)
                .then(response => response.data)
                .then(result => {
                    commit('setWatchUser', result);
                    resolve(result);
                })
        });
    },
    change({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            User.change({ data, id: state.id })
                .then(response => response.data)
                .then(result => {
                    commit('setUser', result);
                    resolve(result);
                })
                .catch(error => {
                    if (
                        new RegExp(/4\d\d/, 'g').test(error.response.status) &&
                        error.response.data
                    )
                        reject({
                            field: error.response.data.error_field,
                            text: error.response.data.error_text
                        });
                });
        });
    },
    changeOnboarding({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            User.changeOnboarding({ data, id: state.id })
                .then(response => response.data)
                .then(result => {
                    commit('setUser', result);
                    resolve(result);
                })
                .catch(error => {
                    if (
                        new RegExp(/4\d\d/, 'g').test(error.response.status) &&
                        error.response.data
                    )
                        reject({
                            field: error.response.data.error_field,
                            text: error.response.data.error_text
                        });
                });
        });
    },
    getStudyPlace({ commit }) {
        return new Promise((resolve, reject) => {
            UserEd.getStudyPlace(state.id)
                .then(response => response.data)
                .then(result => {
                    commit('setStudyPlace', result);
                    resolve(result);
                })
                .catch(error => {
                    if (
                        new RegExp(/4\d\d/, 'g').test(error.response.status) &&
                        error.response.data
                    )
                        reject({
                            field: error.response.data.error_field,
                            text: error.response.data.error_text
                        });
                });
        });
    },
    getStudyPlaceAnotherUser({ commit }, id) {
        return new Promise((resolve, reject) => {
            UserEd.getStudyPlace(id)
                .then(response => response.data)
                .then(result => {
                    commit('setStudyPlace', result);
                    resolve(result);
                })
                .catch(error => {
                    if (
                        new RegExp(/4\d\d/, 'g').test(error.response.status) &&
                        error.response.data
                    )
                        reject({
                            field: error.response.data.error_field,
                            text: error.response.data.error_text
                        });
                });
        });
    },
    getStudyPlaceResume({}, id) {
        return new Promise((resolve, reject) => {
            UserEd.getStudyPlaceResume(id)
                .then(response => response.data)
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    if (
                        new RegExp(/4\d\d/, 'g').test(error.response.status) &&
                        error.response.data
                    )
                        reject({
                            field: error.response.data.error_field,
                            text: error.response.data.error_text
                        });
                });
        });
    },
    getWorkPlace() {
        return new Promise((resolve, reject) => {
            UserJob.getWorkPlace(state.id)
                .then(response => response.data)
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    if (
                        new RegExp(/4\d\d/, 'g').test(error.response.status) &&
                        error.response.data
                    )
                        reject({
                            field: error.response.data.error_field,
                            text: error.response.data.error_text
                        });
                });
        });
    },
    getWorkPlaceAnotherUser(id) {
        return new Promise((resolve, reject) => {
            UserJob.getWorkPlace(id)
                .then(response => response.data)
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    if (
                        new RegExp(/4\d\d/, 'g').test(error.response.status) &&
                        error.response.data
                    )
                        reject({
                            field: error.response.data.error_field,
                            text: error.response.data.error_text
                        });
                });
        });
    },
    getWorkPlaceResume({}, id) {
        return new Promise((resolve, reject) => {
            UserJob.getWorkPlaceResume(id)
                .then(response => response.data)
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    if (
                        new RegExp(/4\d\d/, 'g').test(error.response.status) &&
                        error.response.data
                    )
                        reject({
                            field: error.response.data.error_field,
                            text: error.response.data.error_text
                        });
                });
        });
    },
    newpass(_, pass) {
        return new Promise((resolve, reject) => {
            User.newpass(pass)
                .then(resolve)
                .catch(error => reject(error.new_password));
        });
    },
    career({ commit }, id) {
        let stopFlag = false;
        return new Promise(resolve => {
            let timer = setTimeout(function pooling() {
                User.career(id)
                    .then(response => response.data)
                    .then(result => {
                        if (!stopFlag) {
                            stopFlag = true;
                            resolve(
                                `https://intalant.ru/lift/lift/index?user_id=${id}`
                            );
                        }
                        if (result && result.close_iframe) {
                            commit('clearCareerGuidanceTimer');
                            User.career(id, true);
                            this.modalClose()
                        } else
                            commit(
                                'setCareerGuidanceTimer',
                                setTimeout(pooling, 2000)
                            );
                    });
            }, 0);
            commit('setCareerGuidanceTimer', timer);
        });
    },
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            User.logout()
                .then(() => {
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('jwtToken');
                    localStorage.removeItem('userRole');
                    localStorage.removeItem('userID');
                    localStorage.removeItem('userIsActive');
                })
                .catch(reject)
                .finally(() => {
                    commit('clearCareerGuidanceTimer');
                    commit('logout');
                    resolve();
                });
        });
    },
    delete({ commit, state }) {
        return new Promise((resolve, reject) => {
            User.delete(state.id)
                .then(() => {
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('jwtToken');
                    localStorage.removeItem('userID');
                    sessionStorage.removeItem('manual_close');
                    commit('logout');
                    resolve();
                })
                .catch(() => reject());
        });
    },
    async getAllResponses({ commit }, order) {
        try {
            let response = await User.getAllResponses(order);
            if (response.status === 200) {
                commit('setAllResponses', response.data);
                return response.data;
            } else {
                throw new Error('alert');
            }
        } catch (error) {
            throw new Error('alert');
        }
    },
    getUsersCount({ commit }) {
        return new Promise(resolve => {
            User.getUsersCount()
                .then(response => response.data)
                .then(result => {
                    commit('setUsersCount', result);
                    resolve(result);
                });
        });
    },
    changePageName({ commit }, payload) {
        commit('setChangeName', payload);
    },
    async getFavoriteVacancies({ commit, state }) {
        return new Promise(resolve => {
            User.getFavoriteVacancies(state.id)
                .then(response => response.data)
                .then(result => {
                    commit('setFavoritesVacany', result)
                    resolve(result);
                });
        });
    },
    async addFavoriteVacancy({dispatch},id) {
        return new Promise(resolve => {
            User.addFavoriteVacancy(id)
                .then(response => response.data)
                .then(result => {
                    dispatch('getFavoriteVacancies')
                    resolve(result);
                });
        });
    },
    async getCertificates({ commit }, {page, uid}) {
        return new Promise(resolve => {
            User.getCertificates({page, uid})
                .then(response => response.data)
                .then(result => {
                    commit('setCertificates', result)
                    resolve(result);
                });
        });
    },
    async getProgramCertificates({ commit }, {page, uid}) {
        return new Promise(resolve => {
            User.getProgramCertificates({page, uid})
                .then(response => response.data)
                .then(result => {
                    commit('setProgramCertificates', result)
                    resolve(result);
                });
        });
    },
    async getCoursesInProgress({ commit }, {page, finished, id}) {
        return new Promise(resolve => {
            User.getCoursesInProgress({id, page, finished})
                .then(response => response.data)
                .then(result => {
                    commit('setCoursesInProgress', result)
                    resolve(result);
                });
        });
    },
    async getCompletedCourses({ commit }, {page, finished, id}) {
        return new Promise(resolve => {
            User.getCompletedCourses({id, page, finished})
                .then(response => response.data)
                .then(result => {
                    commit('setCompletedCourses', result)
                    resolve(result);
                });
        });
    },
    async getPrograms({ commit }, {page,status}) {
        return new Promise(resolve => {
            User.getPrograms({page,status})
                .then(response => response.data)
                .then(result => {
                    commit('setPrograms', result)
                    resolve(result);
                });
        });
    },
};

const mutations = {
    setPrevRoute(state,prevRoute) {
        state.prevRoute = prevRoute
    },
    authSuccess(state, { token, id, is_verification }) {
        state.token = token;
        state.id = id;
        state.isVerification = is_verification;
    },
    logout(state) {
        state.token = false;
        state.id = false;
        state.isVerification = false;
        state.user = null;
    },
    activate(state) {
        localStorage.setItem('userIsActive', true);
        state.isVerification = true;
    },
    deactivate(state) {
        localStorage.setItem('userIsActive', false);
        state.isVerification = false;
    },
    setUser(state, user) {
        state.user = user;
    },
    setWatchUser(state, user) {
        state.watchUser = user;
    },
    setStudyPlace(state, education) {
        state.educationList = education;
    },
    setUserVerification(state, value) {
        state.isVerification = value;
    },
    setSocialLogin(state) {
        state.socialLogin = true;
    },
    setAllResponses(state, responses) {
        state.responses = responses;
    },
    setCareerGuidanceTimer(state, timer) {
        state.user.career_guidance_timer = timer;
    },
    clearCareerGuidanceTimer(state) {
        if (state.user.career_guidance_timer) {
            clearTimeout(state.user.career_guidance_timer);
        }
    },
    setUsersCount(state, usersCount) {
        state.usersCount = usersCount;
    },
    setChangeName(state, pageName) {
        state.pageName = pageName;
    },
    setFavoritesVacany(state, vacancies) {
        state.favoriteVacancies = vacancies
    },
    setCertificates(state, certificates) {
        state.certificates = certificates
    },
    setProgramCertificates(state, certificates) {
        state.programCertificates = certificates
    },
    setCoursesInProgress(state, courses) {
        state.coursesInProgress = courses
    },
    setCompletedCourses(state, courses) {
        state.coursesCompleted = courses
    },
    setPrograms(state, programs) {
        state.programs = programs
    },
    setTransitionAfterReg(state,status) {
        state.transitionAfterReg = status
    },
    setShowUserCreate(state, payload) {
        state.isShowUserCreate = payload;
    },
    setUserRole(state, role) {
        state.user_role = role;
    },
    setBackOfficeRole(state, role) {
        state.backOfficeRole = role;
    }
};

const getters = {
    prevRoute: state => state.prevRoute,
    transitionAfterReg: state => state.transitionAfterReg,
    isRecruiter: state => state.user && state.user.is_recruiter,
    isLoggedIn: state => !!state.token,
    isJWTToken: state => !!state.jwtToken,
    isOnboarding: state => state.user && state.user.is_onbording,
    userID: state => +state.id,
    userInfo: state => state.user,
    watchUserInfo: state => state.watchUser,
    userToken: state => state.token,
    getStudyPlace: state => state.educationList,
    userIsCreateResume: state =>
        !!(
            state.user.first_name &&
            state.user.last_name &&
            state.user.birthday &&
            state.user.email &&
            state.user.phone &&
            state.user.city &&
            state.user.city.name
        ),
    userIsVerification: state => {
        let base = !!(
            state.user.first_name &&
            state.user.last_name &&
            state.user.middle_name &&
            state.user.birthday &&
            state.user.email &&
            state.user.city &&
            state.user.city.name
        );

        let temp = false;

        // if (localStorage.getItem('socialLogin') && localStorage.getItem('socialLogin') == 'true') {
        //     temp = true
        // } else {
        if (state.educationList.length) {
            state.educationList.forEach(item => {
                if (
                    item.institution &&
                    item.institution.name &&
                    item.specialization &&
                    item.graduation_year
                )
                    temp = true;
            });
        }
        // }
        return base && temp;
    },
    usersCount: state => state.usersCount,
    pageName: state => state.user && state.user.is_recruiter ? state.pageName : 'Статистика',
    favoriteVacancies: state => state.favoriteVacancies,
    certificates: state => state.certificates,
    programCertificates: state => state.programCertificates,
    programs: state => state.programs,
    isShowUserCreate: state => state.isShowUserCreate,
    getBackOfficeRole: state => state.backOfficeRole
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
