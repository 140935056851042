<template>
	<section
		v-if="faqInfo"
		class="section section-questions"
	>
		<div class="content">
			<div class="questions">
				<h2 class="title-section">
					{{ faqInfo.faq_title }}
				</h2>
				<div
					v-if="faqInfo.questions_and_answers"
					class="questions__list"
				>
					<div
						v-for="(item, index) in faqInfo.questions_and_answers"
						:key="index"
						class="question"
						:class="{'question--active': isActive === index}"
						@click="toggleItem(index)"
					>
						<div class="question__toggle">
							<div class="question__index">
								{{ index + 1 }}
							</div>
							<div
								class="question__title"
							>
								{{ item.question }}
							</div>
						</div>
						<transition name="fade">
							<div
								class="question__content"
								:style="isActive === index? 'display: block' : 'display: none'"
							>
								<div class="description">
									{{ item.answer }}
								</div>
							</div>
						</transition>
					</div>
				</div>
				<div
					v-if="faqInfo.faq_button_text"
					class="button-wrap"
				>
					<ConstructorButton
						:btn-name="faqInfo.faq_button_text"
						:btn-link="faqInfo.faq_button_link"
						:btn-color="faqInfo.faq_button_color"
						:actual-tests="actualTests"
						:is-apply-contest="isApplyContest"
						:btn-variations="btnVariations"
						:is-active="isActiveBtn"
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ConstructorButton from '@/components/ConstructorComponents/ConstructorButton';

export default {
    name: 'Questions',
    components: {
        ConstructorButton
    },
    props: {
        faqInfo: {
            type: Object,
            default: null
        },
        actualTests: {
            type: Array,
            default: null
        },
        isApplyContest: {
            type: Boolean,
            default: false
        },
        isActiveBtn: {
            type: Boolean,
            default: false
        },
        btnVariations: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        isActive: null
    }),
    methods: {
        toggleItem(index) {
            this.isActive = this.isActive === index ? null : index;
        }
    }
}
</script>
