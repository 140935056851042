<template>
	<div
		class="modal"
		@keydown.esc.stop.prevent="modalClose()"
	>
		<div class="modal-container">
			<div class="modal-inner">
				<div
					class="modal-body"
				>
					<div
						class="modal-close"
						data-modal-close
						@click.stop="modalClose"
					/>
					<div class="modal-content">
						<div class="center">
							<br>
							<div class="modal-title-wrap">
								<div class="modal-title sm">
									{{ text }}
								</div>
							</div>
							<div class="buttons">
								<button
									class="button-hollow"
									@click="getResponse()"
								>
									Да
								</button>
								<button
									class="button-hollow"
									@click="closeModal"
								>
									Нет
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
    props: {
        text: {
            type: String,
            default: null
        }
    },
    methods: {
        close () {
            window.location.reload()
        },
        getResponse() {
            if (this.text.includes('черновик')) this.$emit('saveToDraft', true);
            else this.$emit('sendApplication', true);
        },
        closeModal() {
            if (this.text.includes('черновик')) this.modalClose()
            else this.$emit('saveToDraft', true);
        }
    }
}
</script>
