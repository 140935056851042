<template>
	<section
		class="section-simple mentions fadeInUp"
	>
		<div class="content">
			<h2 class="section-title">
				Отзывы о программе
			</h2>
			<div
				id="contacts"
				ref="contacts"
				class="mentions__list"
			>
				<div
					v-for="(item, index) in $route.name === 'Student' ? studentReviews : schoolReviews"
					:key="index"
					class="mention"
				>
					<div class="mention__text">
						{{ item.review }}
					</div>
					<div class="mention__person">
						<div
							class="mention__avatar"
							:style=" item.avatar | bgrImageCover"
						/>
						<div class="mention__content">
							<div class="mention__name">
								{{ item.name }}
							</div>
							<div class="mention__position">
								{{ item.who }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Reviews',
    data: () => ({
        studentReviews: [
            {
                avatar: '/img/avatars/avatar-1.jpg',
                review: '«Работа сейчас не просто источник денег, она должна приносить удовлетворение, давать разнообразный опыт»',
                name: 'Татьяна Баранова',
                who: 'студентка 3-го курса РЭУ им. Г. В. Плеханова, Ставрополь'
            },
            {
                avatar: '/img/avatars/avatar-2.jpg',
                review: '«Я учусь на факультете кибербезопасности и уверен, что с легкостью найду работу, поэтому хочу выбирать вдумчиво, чтобы работа отвечала моим ценностям. Изучаю разные возможности на платформе»',
                name: 'Григорий Гулюк',
                who: 'студент 2-го курса НИУ ВШЭ, Москва'
            },
            {
                avatar: '/img/avatars/avatar-3.jpg',
                review: '«Я уверен, что успех формирует на 30% упорство, а на 70% - удача. Мне очень повезло в нужный момент жизни встретить людей из программы “Лифт в будущее”, которые помогли мне найти свой путь»',
                name: 'Марк Поморцев',
                who: 'студент 4-го курса, НГМУ, Новосибирск'
            },
            {
                avatar: '/img/avatars/avatar-4.jpg',
                review: '«Я понимаю, что без опыта работы найти хорошее место сложно: даже на джуниорские позиции ты конкурируешь с массой других людей, поэтому я решила украсить резюме разными крутыми скилами. Прохожу короткие курсы...»',
                name: 'Александра Морозова',
                who: 'ученица 10-го класса, г. Омск'
            }
        ],
        schoolReviews: [
            {
                avatar: '/img/avatars/school-avatar-5.jpg',
                review: '«Я убедился в правильности выбора, посмотрел на работу инженера-конструктора и представил себя на его месте - теперь я знаю, что еще мне стоит изучить и какой вуз выбрать, чтобы достичь моей цели»',
                name: 'Борис Петрусевич',
                who: 'ученик 8-го класса, г. Санкт-Петербург'
            },
            {
                avatar: '/img/avatars/school-avatar-2.jpg',
                review: '«Мы сходили на мастер-класс в отель Космос и я увидела, какая большая работа стоит за комфортом постояльцев: как учитываются пожелания клиентов, как развивается сервис, каких знаменитых гостей можно встретить в гостинице»',
                name: 'Маша Мильшина',
                who: 'ученица 10-го класса, г. Ижевск'
            },
            {
                avatar: '/img/avatars/school-avatar-3.jpg',
                review: '«Вместе со студентом-наставником мы с друзьями разработали проект умного освещения пешеходных переходов. Наш проект оказался лучшим в своей номинации, и я получил огромное удовольствие от работы в крутой команде»',
                name: 'Арсений Неупокоев',
                who: 'ученик 10-го класса, г. Омск'
            },
            {
                avatar: '/img/avatars/school-avatar-6.jpg',
                review: '«На сайте «Лифт в будущее» очень удобно собраны материалы по разным отраслям и профессиям. Раньше у меня уходили часы на поиск нужной информации в интернете, а тут я закрыла себе головную боль на целый учебный год. Формат занятий не похож на школьные уроки и им заходит на ура»',
                name: 'Добровольская Ирина',
                who: 'завуч школы № 82, г. Новосибирск'
            }
        ]
    })
};
</script>

<style scoped>

</style>
