import Events from '@/api/events'

const state = {
    banner: null,
    informers: null,
    about: null,
    skillsMain: null,
    skillsInfo: null,
    skillsShow: false,
    audienceMain: null,
    audienceInfo: null,
    audienceShow: false,
    descriptionBlock: null,
    stepsMain: null,
    stepsInfo: null,
    brandsMain: null,
    brandsInfo: null,
    prizesMain: null,
    prizesInfo: null,
    videoMain: null,
    videoInfo: null,
    photoInfo: null,
    leaders: null,
    speakers: null,
    faq: null,
    materials: null,
    organizer: null,
    extra: null
}

const actions = {
    getFirstThreeBlocks({ commit }, slug) {
        return new Promise(resolve => {
            Events.getFirstThreeBlocks(slug)
                .then(response => response.data)
                .then(result => {
                    if (result.main_banner_block) {
                        commit('setBanner', result.main_banner_block)
                        commit('setInformers', result.main_banner_block.key_points)
                    }
                    if (result.event_description_block) commit('setAbout', result.event_description_block)
                    if (result.if_you_are_block) {
                        commit('setSkillsMain', result.if_you_are_block)
                        commit('setSkillsInfo', result.if_you_are_block.key_points)
                        commit('setSkillsShow', result.if_you_are_block.show_if_you_are_block)
                    }
                    resolve(result)
                })
        })
    },
    getSecondFourBlocks({ commit }, slug) {
        return new Promise(resolve => {
            Events.getSecondFourBlocks(slug)
                .then(response => response.data)
                .then(result => {
                    if (result.why_to_participate_block) {
                        commit('setAudMain', result.why_to_participate_block)
                        commit('setAudInfo', result.why_to_participate_block.key_points)
                        commit('setAudShow', result.why_to_participate_block.show_why_to_participate_block)
                    }
                    if (result.how_things_work_block) commit('setDesc', result.how_things_work_block)
                    if (result.event_stages_block) {
                        commit('setStepsMain', result.event_stages_block)
                        commit('setStepsInfo', result.event_stages_block.event_stages)
                    }
                    if (result.info_partners_block) {
                        commit('setBrandsMain', result.info_partners_block)
                        commit('setBrandsInfo', result.info_partners_block.info_partners)
                    }
                    resolve(result)
                })
        })
    },
    getThirdFourBlocks({ commit }, slug) {
        return new Promise(resolve => {
            Events.getThirdFourBlocks(slug)
                .then(response => response.data)
                .then(result => {
                    if (result.prizes_block) {
                        commit('setPrizesMain', result.prizes_block)
                        commit('setPrizesInfo', result.prizes_block.prizes)
                    }
                    if (result.event_video_block) {
                        commit('setVideoMain', result.event_video_block)
                        commit('setVideoInfo', result.event_video_block.event_videos)
                    }
                    if (result.photo_slider_block) commit('setPhotoInfo', result.photo_slider_block.event_photos)
                    if (result.tasks_from_leaders_block) commit('setLeaders', result.tasks_from_leaders_block)
                    resolve(result)
                })
        })
    },
    getRemainingBlocks({ commit }, slug) {
        return new Promise(resolve => {
            Events.getRemainingBlocks(slug)
                .then(response => response.data)
                .then(result => {
                    if (result.speakers_block) commit('setSpeakers', result.speakers_block)
                    if (result.faq_block) commit('setFAQ', result.faq_block)
                    if (result.file_upload_block) commit('setMaterials', result.file_upload_block)
                    if (result.event_organizer_block) commit('setOrganizer', result.event_organizer_block)
                    if (result.extra_materials_block) commit('setExtra', result.extra_materials_block)
                    resolve(result)
                })
        })
    },
}

const mutations = {
    setBanner(state, banner) {
        state.banner = banner
    },
    setInformers(state, informers) {
        state.informers = informers
    },
    setAbout(state, about) {
        state.about = about
    },
    setSkillsMain(state, skillsMain) {
        state.skillsMain = skillsMain
    },
    setSkillsInfo(state, skillsInfo) {
        state.skillsInfo = skillsInfo
    },
    setSkillsShow(state, skillsShow) {
        state.skillsShow = skillsShow
    },
    setAudMain(state, audienceMain) {
        state.audienceMain = audienceMain
    },
    setAudInfo(state, audienceInfo) {
        state.audienceInfo = audienceInfo
    },
    setAudShow(state, audienceShow) {
        state.audienceShow = audienceShow
    },
    setDesc(state, descriptionBlock) {
        state.descriptionBlock = descriptionBlock
    },
    setStepsMain(state, stepsMain) {
        state.stepsMain = stepsMain
    },
    setStepsInfo(state, stepsInfo) {
        state.stepsInfo = stepsInfo
    },
    setBrandsMain(state, brandsMain) {
        state.brandsMain = brandsMain
    },
    setBrandsInfo(state, brandsInfo) {
        state.brandsInfo = brandsInfo
    },
    setPrizesMain(state, prizesMain) {
        state.prizesMain = prizesMain
    },
    setPrizesInfo(state, prizesInfo) {
        state.prizesInfo = prizesInfo
    },
    setVideoMain(state, videoMain) {
        state.videoMain = videoMain
    },
    setVideoInfo(state, videoInfo) {
        state.videoInfo = videoInfo
    },
    setPhotoInfo(state, photoInfo) {
        state.photoInfo = photoInfo
    },
    setLeaders(state, leaders) {
        state.leaders = leaders
    },
    setSpeakers(state, speakers) {
        state.speakers = speakers
    },
    setFAQ(state, faq) {
        state.faq = faq
    },
    setMaterials(state, materials) {
        state.materials = materials
    },
    setOrganizer(state, organizer) {
        state.organizer = organizer
    },
    setExtra(state, extra) {
        state.extra = extra
    },
}

const getters = {
    getBanner: state => state.banner,
    getInformers: state => state.informers,
    getAboutInfo: state => state.about,
    getSkillsMain: state => state.skillsMain,
    getSkillsInfo: state => state.skillsInfo,
    isSkillsShow: state => state.skillsShow,
    getAudienceMain: state => state.audienceMain,
    getAudienceInfo: state => state.audienceInfo,
    isAudienceShow: state => state.audienceShow,
    getDescriptionBlock: state => state.descriptionBlock,
    getStepsMain: state => state.stepsMain,
    getStepsInfo: state => state.stepsInfo,
    getBrandsMain: state => state.brandsMain,
    getBrandsInfo: state => state.brandsInfo,
    getPrizesMain: state => state.prizesMain,
    getPrizesInfo: state => state.prizesInfo,
    getVideoMain: state => state.videoMain,
    getVideoInfo: state => state.videoInfo,
    getPhotoInfo: state => state.photoInfo,
    getLeaders: state => state.leaders,
    getSpeakers: state => state.speakers,
    getFAQ: state => state.faq,
    getMaterials: state => state.materials,
    getOrganizer: state => state.organizer,
    getExtra: state => state.extra,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
