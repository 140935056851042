<template>
	<div
		class="preloader-page"
	>
		<div class="preloader-page__pretitle">
			Благотворительный фонд «Система»
		</div>
		<div class="preloader-page__content">
			<img
				:src="'/img/logo-string.svg' | prodSrcImage"
				alt="Лифт в будущее"
				class="preloader-page__logo"
			>
			<div class="preloader-page__title">
				Знания о профессиях и карьере
			</div>
		</div>
		<div class="preloader-page__loading">
			ЗАГРУЗКА — <span class="preloader-page__value">
				<number
					ref="numberLoad"
					:from="0"
					:to="100"
					:duration="1"
					animation-paused
					easing="Power1.easeOut"
					@complete="completed()"
				/>
			</span>%
		</div>
	</div>
</template>

<script>
export default {
    name: 'Preloader',
    props: {
        isLoading: {
            type: Boolean,
            default: true
        }
    },
    mounted () {
        this.$refs.numberLoad.play()
    },
    methods: {
        completed () {
            this.$emit('isExpertLoaded')
        }
    }
};
</script>
