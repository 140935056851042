<template>
	<section
		v-if="brandsMain"
		class="section section-brands"
	>
		<div class="content">
			<h2 class="title-section">
				{{ brandsMain.info_partners_title }}
			</h2>
			<div class="brands">
				<VueSlickCarousel
					class="brands__slider"
					v-bind="sliderSettings"
				>
					<div
						v-for="(item, index) in brandsInfo"
						:key="index"
					>
						<div class="brand">
							<img
								:src="item.image"
								alt="МТС"
								class="brand__logo"
							>
						</div>
					</div>
				</VueSlickCarousel>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'ConstructorBrands',
    props: {
        brandsMain: {
            type: Object,
            default: null
        },
        brandsInfo: {
            type: Array,
            default: null
        }
    },
    data: () => ({
        sliderSettings: {
            infinite: false,
            easing: 'swing',
            arrows: true,
            dots: false,
            autoplay: false,
            autoplaySpeed: 3000,
            pauseOnFocus: false,
            pauseOnHover: false,
            speed: 600,
            draggable: false,
            slidesToShow: 7,
            slidesToScroll: 1,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    })
}
</script>
