import Re from '@/api/recruiter';

const state = {
	keys: [],
	isReverse:false,
	count:0,
};

const actions = {
	getKeys({ commit, state },payload) {
		return new Promise(resolve => {
			Re.getKeys(payload.pageNumber,payload.pageSize)
				.then(response => response.data)
				.then(result => {
					commit('setKeys', result.results)
					commit('setCount',result.count)
					if(state.isReverse) {
						commit('filterKeys')
					}
					resolve(result);
				});
		});
	},
  filterKeys({ commit },isReverse) {
    commit('filterKeys')
    commit('setIsReverse',isReverse)
  },
	createKey({ dispatch }, payload) {
		Re.createKey().then(() => {
			dispatch('getKeys',payload);
		});
	},
	remove({ dispatch }, payload) {
		Re.removeKey(payload.id).then(() => {
			dispatch('getKeys',payload);
		});
	},
};

const mutations = {
	setKeys(state, keys) {
		state.keys = keys;
	},
	filterKeys(state) {
		state.keys = state.keys.reverse();
	},
	setIsReverse(state,isReverse) {
		state.isReverse = isReverse
	},
	setCount(state,count) {
		state.count = count
	},
};

const getters = {
	getKeys: state => state.keys,
	getCount: state => state.count,
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
