<template>
	<section
		class="section-simple major fadeIn"
		@click="pauseSlider"
	>
		<VueSlickCarousel
			ref="mainCarousel"
			class="major-slider"
			:class="{'active-button': isStopped}"
			v-bind="mainSliderSettings"
			@swipe="pauseSlider"
		>
			<div>
				<div
					class="major-slider__slide"
				>
					<img
						:src="'/img/landing/role/pic__major-1.png' | prodSrcImage"
						alt="Фото"
						class="major-slider__pic"
					>
					<img
						:src="'/img/landing/role/pic__major-1--mobile.png' | prodSrcImage"
						alt="Фото"
						class="major-slider__pic major-slider__pic--planshet"
					>
					<img
						:src="'/img/landing/role/pic__major-1--mobile.png' | prodSrcImage"
						alt="Фото"
						class="major-slider__pic major-slider__pic--mobile"
					>
					<div class="content">
						<h2 class="section-title">
							«Лифт в будущее» — бесплатная программа обучения, профориентации и карьерного сопровождения
							<span class="section-span">Проект Благотворительного фонда «Система»</span>
						</h2>
					</div>
				</div>
			</div>
			<div>
				<div
					class="major-slider__slide"
				>
					<img
						:src="'/img/landing/role/pic__major-2.jpg' | prodSrcImage"
						alt="Фото"
						class="major-slider__pic"
					>
					<img
						:src="'/img/landing/role/pic__major-2--mobile.jpg' | prodSrcImage"
						alt="Фото"
						class="major-slider__pic major-slider__pic--mobile"
					>
					<div class="content">
						<h2 class="section-title">
							Проект вошел в число лучших в национальной премии «Наш вклад» АНО «Национальные приоритеты»
						</h2>
					</div>
				</div>
			</div>
			<div>
				<div
					class="major-slider__slide"
				>
					<img
						:src="'/img/landing/role/pic__major-3.jpg' | prodSrcImage"
						alt="Фото"
						class="major-slider__pic"
					>
					<div class="content">
						<h2 class="section-title">
							«Лифт в будущее» вносит вклад в реализацию национального проекта «Образование»
						</h2>
					</div>
				</div>
			</div>
		</VueSlickCarousel>
	</section>
</template>

<script>
export default {
    name: 'ExpertSlider',
    data: () => ({
        soundOn: false,
        isStopped: false,
        mainSliderSettings: {
            arrows: true,
            fade: true,
            focusOnSelect: true,
            waitForAnimate: false,
            infinite: true,
            easing: 'swing',
            dots: true,
            autoplay: true,
            pauseOnFocus: true,
            pauseOnHover: false,
            pauseOnDotsHover: false,
            speed: 500,
            autoplaySpeed: 3300,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        arrows: false
                    }
                }
            ]
        }
    }),
    methods: {
        pauseSlider() {
            this.$refs.mainCarousel.pause()
            this.isStopped = true
        },
    }
};
</script>
