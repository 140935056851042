<template>
	<div
		v-if="materialsInfo"
		class="material"
	>
		<div class="material__content">
			<h2 class="title-block">
				{{ materialsInfo.file_upload_title }}
			</h2>
			<div
				class="description"
				v-html="materialsInfo.file_upload_description"
			/>
		</div>
		<div
			v-if="isLogged"
			class="material__upload"
		>
			<div class="uploaded-materials">
				<label class="upload-materials">
					<input
						ref="file"
						type="file"
						multiple
						class="upload-materials__value"
						:disabled="isUploading || selectedFiles.length >= 10"
						:accept="isProfile ? 'application/msword, application/pdf, image/*' : 'application/pdf, application/vnd.ms-powerpoint'"
						@change="selectFile"
					>
					<div class="upload-materials__title">Прикрепить файлы</div>
					<div
						v-if="isProfile"
						class="title-mono"
					>Поддерживаемые форматы: JPEG, PNG, GIF, PDF, Word</div>
					<div
						v-else
						class="title-mono"
					>Поддерживаемые форматы: PDF, PPTX</div>
					<div
						v-if="errorText && !isProfile"
						class="title-mono"
						style="color: red"
					>Этот формат не поддерживается</div>
				</label>
				<div
					v-if="selectedFiles.length || (files && files.files && files.files.length)"
					class="uploaded-materials__list"
				>
					<div
						v-if="isProfile"
						class="uploaded-materials__title"
					>
						Загрузка - {{ selectedFiles.length + withGradebookFiles.length }}/{{ selectedFiles.length + withGradebookFiles.length | toPlural('файл', 'файла', 'файлов') }}
					</div>
					<div
						v-else
						class="uploaded-materials__title"
					>
						Загрузка - {{ selectedFiles.length }}/{{ selectedFiles.length | toPlural('файл', 'файла', 'файлов') }}
					</div>
					<div v-if="isProfile && withGradebookFiles.length">
						<div
							v-for="item in withGradebookFiles"
							:key="item.id"
							class="uploaded-material"
						>
							<div class="uploaded-material__title title-mono">
								{{ item.name }}
							</div>
							<div
								class="uploaded-material__remove"
								@click="removeFile(item)"
							/>
							<div
								class="uploaded-material__progress"
								style="width: 100%;"
							/>
						</div>
					</div>
					<div
						v-for="(item, index) in selectedFiles"
						:key="index"
						class="uploaded-material"
					>
						<div class="uploaded-material__title title-mono">
							{{ item.name }}
						</div>
						<div
							class="uploaded-material__remove"
							@click="removeFile(item)"
						/>
						<div
							class="uploaded-material__progress"
							style="width: 100%;"
						/>
					</div>
				</div>
			</div>
			<div class="urls">
				<div class="urls__title">
					Вставить ссылку
				</div>
				<div
					v-if="isProfile && withGradebookLinks"
					class="field-simple"
				>
					<div
						v-for="item in withGradebookLinks"
						:key="item.id"
						class="field-simple"
					>
						<div class="field-simple__value-wrap">
							<div
								class="field-simple__remove"
								@click="removeFile(item)"
							/>
							<input
								type="text"
								class="field-simple__value field-simple__value--disabled"
								placeholder="https:/"
								:value="item.name"
							>
						</div>
					</div>
				</div>
				<div
					v-for="(item, index) in linksArr"
					:key="index"
					class="field-simple"
				>
					<div
						class="field-simple__value-wrap"
					>
						<div
							class="field-simple__remove"
							@click="removeLink(item)"
						/>
						<input
							:value="item"
							type="text"
							class="field-simple__value"
							placeholder="https:/"
							@change="addLink"
						>
					</div>
				</div>
				<div
					v-if="linksArr.length < 10"
					class="field-simple"
				>
					<input
						v-model="$v.link.$model"
						type="text"
						class="field-simple__value"
						placeholder="https:/"
						@change="addLink"
					>
					<span
						v-if="$v.link.$error"
						class="help-block form-error"
					>
						Введите корректную ссылку
					</span>
				</div>
			</div>
			<div class="material__button">
				<button
					v-if="!isUploading"
					class="button"
					:class="{'button--disabled': isUploading || (selectedFiles.length === 0 && linksArr.length === 0) }"
					@click="uploadFilesLinks"
				>
					Отправить
				</button>
				<div
					v-else
					class="button orange create-resume-save lg button-preloader"
				>
					Отправляется...
					<div class="button-preloader-icons">
						<div class="button-preloader-icon" />
						<div class="button-preloader-icon" />
						<div class="button-preloader-icon" />
					</div>
				</div>
				<div class="material__notice">
					Нажимая на кнопку, вы соглашаетесь с политикой обработки персональных данных
				</div>
			</div>
		</div>
		<div
			v-else
			class="material__button"
		>
			<button
				class="button"
				@click="modalOpen('regIn', 'test')"
			>
				Зарегистрироваться
			</button>
		</div>
	</div>
</template>

<script>
import Events from '@/api/events'
import {mapActions, mapGetters} from 'vuex';
import User from '@/api/user';
import { onlyURL } from '@/assets/scripts/custom_validators';

export default {
    name: 'Material',
    props: {
        materialsInfo: {
            type: Object,
            default: null
        },
        isProfile: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        isUploading: false,
        selectedFiles: [],
        currentFile: undefined,
        progress: 0,
        link: '',
        linksArr: [],
        errorText: false
    }),
    validations: {
        link: {onlyURL}
    },
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        }),
        ...mapGetters('scholar', {
            tasks: 'getTasks'
        }),
        task() {
            return this.tasks && this.tasks.find( task => task.task_set.find( item => item.is_gradebook))
        },
        files() {
            return this.task && this.task.task_set && this.task.task_set.find( item => item.files)
        },
        withGradebookFiles () {
            return this.files && this.files.files && this.files.files.filter( item => !item.link_url)
        },
        withGradebookLinks () {
            return this.files && this.files.files && this.files.files.filter( item => !item.file_url)
        },
    },
    created() {
        if (this.isProfile) this.getTasks('progress')
    },
    methods: {
        ...mapActions('scholar', {
            getTasks: 'getTasks'
        }),
        selectFile() {
            let files = this.$refs.file.files
            if (files.length > 1) {
                files.forEach( item => {
                    if (this.isProfile) this.selectedFiles.push(item)
                    else {
                        let uploaded = item.name.split('.').pop()
                        if (uploaded !== 'png' && uploaded !== 'jpg' && uploaded !== 'jpeg') {
                            this.selectedFiles.push(item)
                            this.errorText = false
                        } else this.errorText = true
                    }
                })
            } else if (files.length === 1) {
                if (this.isProfile) this.selectedFiles.push(this.$refs.file.files[0])
                else {
                    let uploaded = this.$refs.file.files[0].name.split('.').pop()
                    if (uploaded !== 'png' && uploaded !== 'jpg' && uploaded !== 'jpeg') {
                        this.selectedFiles.push(this.$refs.file.files[0])
                        this.errorText = false
                    } else this.errorText = true
                }
            }
            else return 'no files'
        },
        removeFile(file) {
            if (!file.id) {
                if (this.selectedFiles.length > 1) {
                    this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1)
                } else {
                    this.selectedFiles = []
                }
            } else User.deleteImage(file.id).then(() => this.files.files.splice(this.files.files.indexOf(file), 1))
        },
        addLink(event) {
            if (this.linksArr.length < 10) {
                this.$v.link.$touch();
                if (this.$v.link.$invalid || this.link === '') return;
                else {
                    this.linksArr.push(event.target.value);
                    this.link = '';
                }
            } else return
        },
        removeLink(link) {
            if (this.linksArr.length > 1) {
                this.linksArr.splice(this.linksArr.indexOf(link), 1)
            } else {
                this.linksArr = []
            }
        },
        uploadFilesLinks() {
            this.isUploading = true
            const formData = new FormData();
            if (this.isProfile) {
                if (this.selectedFiles.length) {
                    for (let i = 0; i < this.selectedFiles.length; i++) {
                        let file = this.selectedFiles[i];
                        formData.append('file', file);
                    }
                }
                formData.append('link', this.linksArr.length ? this.linksArr.join() : '');
                User.uploadGradeFiles(formData)
                    .then(() => {
                        this.modalOpen('uploadEventSuccess', this.isProfile)
                        this.selectedFiles = []
                        this.linksArr = []
                    })
                    .catch(err => {
                        this.modalOpen('eventFail', err)
                    })
                    .finally(() => this.isUploading = false)
            } else {
                if (this.selectedFiles.length) {
                    for (let i = 0; i < this.selectedFiles.length; i++) {
                        let file = this.selectedFiles[i];
                        formData.append('files[' + i + ']', file);
                    }
                }
                Events.uploadFiles({
                    data: formData,
                    file_link: this.linksArr,
                    slug: this.$route.params.slug
                })
                    .then(() => {
                        this.modalOpen('uploadEventSuccess')
                        this.selectedFiles = []
                        this.linksArr = []
                    })
                    .catch(err => {
                        this.modalOpen('eventFail', err)
                    })
                    .finally(() => this.isUploading = false)
            }
        }
    }
}
</script>
