<template>
	<section
		v-wow
		class="section-simple subscribe wow fadeInUp"
		data-wow-delay="0.2s"
	>
		<div class="content">
			<div class="subscribe__grid">
				<div class="subscribe__heading">
					<div class="subscribe__title">
						Получай ценные знания по развитию <span>soft skills, приглашения на мероприятия и многое другое!</span>
					</div>
					<div class="subscribe__text">
						Подпишись на нашу рассылку и получи в подарок гайд «Дела без стресса: решаем любые задачи легко»
					</div>
				</div>
				<div class="subscribe__content">
					<div class="field-simple">
						<div class="field-simple__title">
							E-mail
						</div>
						<input
							v-model="$v.email.$model"
							type="text"
							class="field-simple__value"
							:class="{'field-simple__value--error': $v.email.$error}"
							placeholder="Введите свой e-mail"
						>
						<div
							v-if="$v.email.$error"
							class="field-simple__error"
						>
							{{
								!$v.email.required
									? "Это обязательное поле"
									: !$v.email.email
										? "Введите корректный e-mail"
										: "Ошибка поля"
							}}
						</div>
					</div>
					<div class="subscribe__button">
						<button
							v-if="!isSending"
							class="button orange"
							@click.prevent="sendEmail"
						>
							Подписаться
						</button>
						<div
							v-else
							class="button-preloader-icons"
						>
							<div class="button-preloader-icon" />
							<div class="button-preloader-icon" />
							<div class="button-preloader-icon" />
						</div>
						<div class="subscribe__notice">
							Нажимая на кнопку, вы соглашаетесь с политикой обработки персональных данных
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import Tb from '@/api/teachbase';

export default {
    name: 'Subscribe',
    props: {
        role: {
            type: String,
            default: null
        }
    },
    data: () => ({
        email: '',
        isSending: false
    }),
    validations: {
        email: {
            required,
            email
        }
    },
    methods: {
        sendEmail () {
            this.$v.email.$touch();
            if (this.$v.email.$invalid) {
                this.$scrollTo(document.querySelector('.subscribe'));
            } else if (!this.isSending) {
                this.isSending = true
                this.isSended = true
                if (this.role === 'Specialist') {
                    Tb.sendEmailSubscribeExpert({email: this.email})
                        .then(() => {
                            this.modalOpen('landing-scs', 'Вы успешно подписаны!')
                            this.$v.email.$reset()
                            this.isSending = false
                            this.email = ''
                        })
                        .catch(() => {
                            this.isSending = false
                            this.modalOpen('landing-scs', 'Вы уже подписаны!')
                        })
                }
                if (this.role === 'Student') {
                    Tb.sendEmailSubscribe({email: this.email})
                        .then(() => {
                            this.modalOpen('landing-scs', 'Вы успешно подписаны!')
                            this.$v.email.$reset()
                            this.isSending = false
                            this.email = ''
                        })
                        .catch(() => {
                            this.isSending = false
                            this.modalOpen('landing-scs', 'Вы уже подписаны!')
                        })
                }
                if (this.role === 'School') {
                    Tb.sendEmailSubscribeSchool({email: this.email})
                        .then(() => {
                            this.modalOpen('landing-scs', 'Вы успешно подписаны!')
                            this.$v.email.$reset()
                            this.isSending = false
                            this.email = ''
                        })
                        .catch(() => {
                            this.isSending = false
                            this.modalOpen('landing-scs', 'Вы уже подписаны!')
                        })
                }
            }
        },
    }
};
</script>
