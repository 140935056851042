<template>
	<div class="modal-content">
		<img
			:src="payload.certificate_image"
			alt=""
			class="certificate-view-pic"
		>
		<a
			target="_blank"
			:href="payload.certificate_pdf"
			class="button white"
		>
			<div>
				<span>Скачать</span>
			</div>
		</a>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CertificateView',
    data: () => ({
        downloadCert: true,
        isDownloading: false
    }),
    computed: {
        ...mapGetters('user', {
            userID: 'userID'
        }),
        payload() {
            return this.$store.state.modal.payload;
        }
    },
    mounted() {
        if (this.$route.params.id !== JSON.stringify(this.userID))
            this.downloadCert = false;
    }
};
</script>

<style lang="less" scoped>
.certificate-view-pic {
    margin-bottom: 20px;
}
</style>
