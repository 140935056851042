import { contestService, getJWTToken } from './default'

export default {
    getContests(status) {
        return contestService({
            method: 'GET',
            url: `/user/contest/?status=${status}`,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            },
        })
    },
    getContestInfo(contestID) {
        return contestService({
            method: 'GET',
            url: `/user/contest/${contestID}/`,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            },
        })
    },
    getContestUsers() {
        return contestService({
            method: 'GET',
            url: '/user/contest/',
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            },
        })
    },
    getContestNominations(contestID, stageID) {
        return contestService({
            method: 'GET',
            url: `/user/contest/${contestID}/stage/${stageID}/available-nominations/`,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            },
        })
    },
    getContestRequests(id) {
        return contestService({
            method: 'GET',
            url: `/user/contest_request/?contest_id=${id}`,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            },
        })
    },
    uploadFile(data) {
        return contestService({
            method: 'POST',
            url: '/user/contest/uploadfile/',
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
                'Content-Type': 'multipart/form-data'
            },
            data
        })
    },
    sendApplication(contestID, stageID, data) {
        return contestService({
            method: 'POST',
            url: `/user/contest/${contestID}/stage/${stageID}/signin/`,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
                'Content-Type': 'multipart/form-data'
            },
            data
        })
    },
    saveDraft(contestID, stageID, data) {
        return contestService({
            method: 'POST',
            url: `backoffice/user_contests/contest/${contestID}/stage/${stageID}/draft`,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
                'Content-Type': 'multipart/form-data'
            },
            data
        })
    },
    sendReworkApplication(userContestID, data) {
        return contestService({
            method: 'PATCH',
            url: `/user/contest/${userContestID}/`,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
                'Content-Type': 'multipart/form-data'
            },
            data
        })
    },
    sendExpertRequest(contestID, data) {
        return contestService({
            method: 'POST',
            url: `/user/contest/${contestID}/expert/signin/`,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            },
            data
        })
    },
    getContestExpert(contestID) {
        return contestService({
            method: 'GET',
            url: `/user/contest/${contestID}/expert/signin/`,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            },
        })
    },
    startAuth () {
        return contestService({
            method: 'GET',
            url: '/oauth/start_auth/',
        })
    },
    getCertificates() {
        return contestService({
            method: 'GET',
            url: '/user/certificates/',
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            },
        })
    },
    getCertificate(id) {
        return contestService({
            method: 'GET',
            url: `/user/contest/${id}/certificates/`,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            },
        })
    },
}
