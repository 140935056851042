<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<div class="modal-title sm">
				Изменить специализации
			</div>
		</div>

		<div
			v-if="!isLoaded"
			class="preloader sm"
		/>
		<div
			v-else
			class="modal-rubrics-block"
		>
			<form>
				<div class="modal-rubrics-list">
					<label
						v-for="spec in specialisations"
						:key="spec.id"
						class="checkbox-filter"
					>
						<input
							v-model="selected"
							type="checkbox"
							:value="spec"
						>
						<span>{{ spec.name }}</span>
					</label>
				</div>
				<button
					class="button orange"
					@click.prevent="save"
				>
					Сохранить
				</button>
			</form>
			<!-- <div class="info info-link">Назад</div> -->
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters} from 'vuex'

export default {
    data: () => ({
        specialisations: [],
        selected: []
    }),
    computed: {
        ...mapGetters('tb', {
            getAllSpecs: 'getAllSpecs'
        }),
        isLoaded() {
            return !!this.specialisations.length
        },
        payload() {
            return this.$store.state.modal.payload
        }
    },
    created() {
        this.selected = this.payload
        if (this.getAllSpecs) {
            this.specialisations = this.getAllSpecs
        } else {
            this.$store.dispatch('tb/getAllSpecialisations')
                .then(result => this.specialisations = result)
        }
    },
    methods: {
        ...mapActions('modal', ['setSpecialisations']),
        save() {
            this.setSpecialisations(this.selected);
            this.modalClose()
        }
    }
}
</script>
