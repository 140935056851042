import { render, staticRenderFns } from "./LandingContacts.vue?vue&type=template&id=12753c98&scoped=true"
import script from "./LandingContacts.vue?vue&type=script&lang=js"
export * from "./LandingContacts.vue?vue&type=script&lang=js"
import style0 from "./LandingContacts.vue?vue&type=style&index=0&id=12753c98&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12753c98",
  null
  
)

export default component.exports