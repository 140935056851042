<template>
	<div>
		<Navigation />

		<div class="sections">
			<section
				id="home"
				ref="home"
				class="section-simple banner fadeIn"
			>
				<div class="content">
					<h1 class="banner__title">
						Пройди обучение и <span>начни карьеру в компании мечты</span>
					</h1>
					<div class="banner__text">
						Бесплатная программа обучения и карьерного сопровождения для студентов от крупнейших российских компаний
					</div>
					<div
						class="banner__pic"
						:style="'/img/landing/role/pic__banner-2.jpg' | bgrImageCover"
					/>
				</div>
			</section>

			<Partners />

			<div class="content">
				<BannersGrid />
			</div>

			<section
				class="section-simple features fadeInUp"
			>
				<div class="content">
					<h2 class="section-title">
						<span>Мы учим адаптироваться и делать правильный выбор в постоянно меняющейся ситуации.</span> Помогаем строить карьеру в реалиях современного мира.
					</h2>
					<div class="features__list">
						<div class="features__item">
							Помогаем найти не просто работу, а комфортную среду для самореализации, предлагаем вакансии, учим составлять резюме
						</div>
						<div class="features__item">
							Рассказываем о востребованных профессиях и навыках будущего
						</div>
						<div class="features__item">
							Лучшие студенты попадут на стажировку и получат стипендию
						</div>
					</div>
				</div>
			</section>

			<section
				id="courses"
				ref="courses"
				class="section-simple training fadeInUp"
			>
				<div class="content">
					<h2 class="section-title">
						Курсы для студента
					</h2>
					<SortedCourses :role="'Student'" />
				</div>
			</section>

			<section
				id="steps"
				ref="steps"
				class="section-simple steps fadeInUp"
			>
				<div class="content">
					<h2 class="section-title">
						Этапы обучения
					</h2>
					<EducationSteps />
				</div>
			</section>

			<section
				id="events"
				ref="events"
				class="section-simple events fadeInUp"
			>
				<div class="content">
					<div class="section-heading">
						<h2 class="section-title">
							Практика и мероприятия
						</h2>
						<div class="section-text">
							Участвуй в мероприятиях «Лифта в будущее», чтобы попасть проф. тусовку и обзавестись нужными связями
						</div>
					</div>
					<SpecProjects :role="'Student'" />
				</div>
			</section>

			<Subscribe :role="'Student'" />

			<Reviews />
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';
// import AllRolesBanner from '@/components/events/roles/AllRolesBanner';
import Partners from '@/components/events/roles/Partners';
import SortedCourses from '@/components/events/roles/SortedCourses';
import Subscribe from '@/components/events/roles/Subscribe';
import SpecProjects from '@/components/events/roles/SpecProjects';
import Navigation from '@/components/events/roles/Navigation';
import EducationSteps from '@/components/events/roles/EducationSteps';
// import StudentSistem from '@/components/events/roles/StudentSistem';
import Reviews from '@/components/events/roles/Reviews';
// import EventBanner from '@/components/events/roles/EventBanner.vue';
import BannersGrid from '@/components/events/roles/BannersGrid.vue';

export default {
    name: 'Student',
    components: {
        // EventBanner,
        // AllRolesBanner,
				BannersGrid,
        Partners,
        SortedCourses,
        Subscribe,
        SpecProjects,
        Navigation,
        EducationSteps,
        Reviews
    },
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    },
    mounted() {
        window.scrollTo(0,0)
        if(this.isLogged) this.$router.push('/platform')
    }
}
</script>

<style lang="less">
.page-role .score-item__point:before {
    width: 0;
    height: 0;
}
.page-role .event__type {
    position: relative;
    margin-right: 10px;
}
@media (max-width: 1099px){
    .page-role .subscribe {
        display: block;
        margin-bottom: 30px;
    }
}
</style>
