<template>
	<div class="navigation-role">
		<div class="navigation-role__grid">
			<div class="navigation-role__menu">
				<div class="navigation-role__list">
					<div
						class="navigation-role__indicator"
						:style="{'width': linkWidth + 'px', 'left': leftSize + 'px'}"
					/>
					<scrollactive
						v-if="$route.name === 'Student'"
						active-class="navigation-role__link--active"
						always-track
						:offset="80"
						:duration="800"
					>
						<a
							id="homeLink"
							href="#home"
							class="navigation-role__link scrollactive-item"
							@click="getWidth('homeLink')"
						>Лифт в будущее</a>
						<a
							id="brandsLink"
							href="#courses"
							class="navigation-role__link scrollactive-item"
							@click="getWidth('brandsLink')"
						>Курсы</a>
						<a
							id="guiLink"
							href="#internship"
							class="navigation-role__link scrollactive-item"
							@click="getWidth('guiLink')"
						>Стажировки и стипендии</a>
						<a
							id="eventsLink"
							href="#events"
							class="navigation-role__link scrollactive-item"
							@click="getWidth('eventsLink')"
						>Мероприятия</a>
						<a
							id="contactsLink"
							href="#contacts"
							class="navigation-role__link scrollactive-item"
							@click="getWidth('contactsLink')"
						>Контакты</a>
					</scrollactive>
					<scrollactive
						v-if="$route.name === 'School'"
						active-class="navigation-role__link--active"
						always-track
						:offset="80"
						:duration="800"
					>
						<a
							id="homeLink"
							href="#home"
							class="navigation-role__link scrollactive-item"
							@click="getWidth('homeLink')"
						>Лифт в будущее</a>
						<a
							id="brandsLink"
							href="#program"
							class="navigation-role__link scrollactive-item"
							@click="getWidth('brandsLink')"
						>Программа</a>
						<a
							id="guiLink"
							href="#guidance"
							class="navigation-role__link scrollactive-item"
							@click="getWidth('guiLink')"
						>График мероприятий</a>
						<a
							id="contactsLink"
							href="#contacts"
							class="navigation-role__link scrollactive-item"
							@click="getWidth('contactsLink')"
						>Контакты</a>
					</scrollactive>
				</div>
			</div>
			<div class="navigation-role__button">
				<button
					v-if="!isLogged"
					class="button orange"
					@click="signIn"
				>
					Начать обучение
				</button>
				<router-link
					v-else
					to="/courses"
					class="button orange"
				>
					Начать обучение
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'StudentsNavigation',
    data: () => ({
        linkWidth: 151,
        leftSize: 0
    }),
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    },
    mounted() {
        if (this.$route.name === 'Student') {
            setInterval(() => {
                this.getClass()
            }, 100)
        }
        if (this.$route.name === 'School') {
            setInterval(() => {
                this.getClass()
            }, 100)
            if (this.$route.query.program) this.$scrollTo(document.querySelector('#program'))
            if (this.$route.query.teachers) this.$scrollTo(document.querySelector('#teachers'))
            if (this.$route.query.events) this.$scrollTo(document.querySelector('#guidance'))
            if (this.$route.query.blog) this.$scrollTo(document.querySelector('#blog'))
            if (this.$route.query.contacts) this.$scrollTo(document.querySelector('#contacts'))
        }
    },
    methods: {
        getWidth(id) {
            this.linkWidth = document.getElementById(id).offsetWidth
            this.leftSize = document.getElementById(id).getBoundingClientRect().left - 38
        },
        getClass () {
            if (this.$route.name === 'Student') {
                if (document.getElementById('homeLink').classList.contains('navigation-role__link--active')) {
                    this.getWidth('homeLink')
                }
                if (document.getElementById('brandsLink').classList.contains('navigation-role__link--active')) {
                    this.getWidth('brandsLink')
                }
                if (document.getElementById('guiLink').classList.contains('navigation-role__link--active')) {
                    this.getWidth('guiLink')
                }
                if (document.getElementById('eventsLink').classList.contains('navigation-role__link--active')) {
                    this.getWidth('eventsLink')
                }
                if (document.getElementById('contactsLink').classList.contains('navigation-role__link--active')) {
                    this.getWidth('contactsLink')
                }
            }
            if (this.$route.name === 'School') {
                if (document.getElementById('homeLink').classList.contains('navigation-role__link--active')) {
                    this.getWidth('homeLink')
                }
                if (document.getElementById('brandsLink').classList.contains('navigation-role__link--active')) {
                    this.getWidth('brandsLink')
                }
                if (document.getElementById('guiLink').classList.contains('navigation-role__link--active')) {
                    this.getWidth('guiLink')
                }
                if (document.getElementById('contactsLink').classList.contains('navigation-role__link--active')) {
                    this.getWidth('contactsLink')
                }
            }
        },
        signIn() {
            this.modalOpen('regIn', {role: 'Student'})
        }
    }
};
</script>
