<template>
	<div class="modal-content">
		<div class="center">
			<img
				:src="'/img/icons/icon-alert-error.svg' | prodSrcImage"
				class="alert-icon"
			>
			<h3
				v-if="name !== 'conVoiceFail' && payload !== 'Пожалуйста, примите условия оферты и обработки данных'"
				class="modal-title"
			>
				Ошибка
				{{ titleText }}
			</h3>
			<br>
			<div class="text dark">
				<p>{{ payload }}</p>
			</div>
			<div
				v-if="name !== 'courseFail' && name !== 'saveFail'"
				class="button orange lg"
				@click="modalClose(nextModal)"
			>
				{{ buttonText }}
			</div>
		</div>
	</div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('modal', {
            payload: 'getPayload',
            name: 'currentFail'
        }),
        titleText() {
            if (this.name === 'signInFail' || this.name === 'careerLogFail' || this.name === 'fastSignInFail') return 'авторизации'
            else if (this.name === 'regFail' || this.name === 'regEventFail') return 'регистрации'
            else if (this.name === 'courseFail') return 'записи'
            else if (this.name === 'vacancyFail') return 'отклика'
            // else if (this.name === 'passFail') return 'смены пароля'
            else if (this.name === 'saveFail') return 'изменений'
            else if (this.name === 'studyFail') return ''
            else if (this.name === 'createResFail') return 'создания резюме'
            else if (this.name === 'saveResFail') return 'сохранения резюме'

            return ''
        },
        nextModal() {
            if (this.name === 'signInFail') return 'signIn'
            // else if (this.name === 'regFail') return 'regIn'
            else if (this.name === 'vacancyFail') return 'reqVacancy'
            else if (this.name === 'videoContestFail') return 'conReq'
            // else if (this.name === 'passFail') return 'changePass'
            else if (this.name === 'ambsdrFail') return 'ambsdr'
            else if (this.name === 'fastSignInFail') return 'fastVacancy'

            return null
        },
        buttonText() {
            if (this.name === 'signInFail' || this.name === 'careerLogFail' || this.name === 'fastSignInFail') return 'Вернуться к авторизации'
            else if (this.name === 'regFail' || this.name === 'regEventFail' || this.name === 'ambsdrFail' || this.name === 'regUniverFail') return 'Вернуться к регистрации'
            else if (this.name === 'vacancyFail') return 'Добавить резюме'
            // else if (this.name === 'passFail') return 'Изменить пароль'
            else if (this.name === 'videoContestFail') return 'Выбрать другое видео'

            return 'Закрыть'
        }
    }
}
</script>
