import MS from '@/api/microServices'

const actions = {
    getGlobalCities({}, obj) {
        return new Promise(resolve => {
            MS.getGlobalCities(obj)
                .then(response => response.data)
                .then(result => {
                    resolve(result)
                })
                .catch(error => console.dir(error))
        })
    },
    getGlobalRegions({}, obj) {
        return new Promise(resolve => {
            MS.getGlobalRegions(obj)
                .then(response => response.data)
                .then(result => {
                    resolve(result)
                })
                .catch(error => console.dir(error))
        })
    },
}

export default {
    namespaced: true,
    actions
}
