<template>
	<div class="modal-content">
		<img
			:src="payload.image_url"
			alt=""
			class="certificate-view-pic"
		>
		<div v-if="!payload.pdf_url">
			<a
				v-if="downloadCert && payload.cert_file"
				:href="payload.cert_file"
				target="_blank"
				class="button white"
				download="certificate.pdf"
			>Скачать</a>
			<a
				v-else-if="downloadCert"
				:href="payload.cert_url"
				target="_blank"
				class="button white"
				download="certificate.pdf"
			>Скачать</a>
		</div>
		<div v-else>
			<a
				:href="payload.pdf_url"
				target="_blank"
				class="button white"
				download="certificate.pdf"
			>Скачать</a>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CertificateView',
    data: () => ({
        downloadCert: true
    }),
    computed: {
        ...mapGetters('user', {
            userID: 'userID'
        }),
        payload() {
            return this.$store.state.modal.payload;
        }
    },
    mounted() {
        if (this.$route.params.id !== JSON.stringify(this.userID))
            this.downloadCert = false;
    }
};
</script>
