<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<h3 class="modal-title">
				Мой пароль
			</h3>
			<h4 class="modal-subtitle">
				Укажите Ваш Email. Мы вышлем ссылку для восстановления пароля на Ваш адрес для уведомлений или, если он
				не задан, на указанный адрес
			</h4>
		</div>
		<form
			class="form"
			novalidate
			@submit.prevent="recoveryPass"
		>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model="$v.email.$model"
						type="text"
						class="field"
						placeholder="Email"
					>
					<div class="label">Email</div>
					<span
						v-if="$v.email.$error"
						class="help-block form-error"
					>
						{{ !$v.email.required ? 'Это обязательное поле' : !$v.email.email ? 'Введите корректный e-mail' : 'Ошибка поля' }}
					</span>
				</label>
			</div>
			<button
				v-if="!isSended"
				class="button orange lg"
			>
				Выслать пароль
			</button>
			<div
				v-else
				class="button orange lg button-preloader"
			>
				<div class="button-preloader-icons">
					<div class="button-preloader-icon" />
					<div class="button-preloader-icon" />
					<div class="button-preloader-icon" />
				</div>
			</div>
		</form>
	</div>
</template>

<script>

import {required, email} from 'vuelidate/lib/validators'
import User from '@/api/user'

export default {
    data: () => ({
        isSended: false,
        email: ''
    }),
    validations: {
        email: {
            required,
            email
        }
    },
    methods: {
        recoveryPass() {
            this.$v.email.$touch();
            if (this.$v.email.$invalid) {
                console.log('Email is not valid')
            } else if (!this.isSended) {
                this.isSended = true;
                User.recovery({email: this.email})
                    .then(() => {
                        this.modalClose('recLinkSuccess')
                    })
                    .finally(() => this.isSended = false)
            }
        }
    }
}
</script>