<template>
	<div class="modal-content">
		<div class="center">
			<img
				:src="'/img/icons/icon-alert-success.svg' | prodSrcImage"
				class="alert-icon"
			>
			<h3 class="modal-title">
				Вы успешно зарегистрированы на програму
			</h3>
			<br>
			<div
				class="button orange lg"
				@click="close"
			>
				Закрыть
			</div>
		</div>
	</div>
</template>
<script>


export default {
  methods: {
       close() {
            this.modalClose()
       }
  }
}
</script>