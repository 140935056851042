import User from '@/api/user'

const state = {
    tasks: null,
    progress: null,
    board: null,
    prizes: null,
    onlinePrizes: null,
    modalPrize: null,
    popupIndex: 0,
    file_upload: false
}

const actions = {
    getTasks({ commit }, params) {
        return new Promise(resolve => {
            User.getScholarship(localStorage.getItem('userID'), params)
                .then(response => response.data)
                .then(result => {
                    commit('setTasks', result.progress)
                    commit('setProgressValue', result.progress_value)
                    resolve(result)
                })
        })
    },
    getBoard({ commit }, params) {
        return new Promise(resolve => {
            User.getScholarship(localStorage.getItem('userID'), params)
                .then(response => response.data)
                .then(result => {
                    commit('setBoard', result.board)
                    commit('setFileUpload', result.file_upload)
                    resolve(result)
                })
        })
    },
    getPrizes({ commit }, params) {
        return new Promise(resolve => {
            User.getScholarship(localStorage.getItem('userID'), params)
                .then(response => response.data)
                .then(result => {
                    commit('setPrizes', result.prize)
                    resolve(result)
                })
        })
    },
    getOnlinePrizes({ commit }, params) {
        return new Promise(resolve => {
            User.getScholarship(localStorage.getItem('userID'), params)
                .then(response => response.data)
                .then(result => {
                    commit('setOnlinePrizes', result.prize)
                    resolve(result)
                })
        })
    },
    getModalPrize({ commit }, params) {
        return new Promise(resolve => {
            User.getScholarship(localStorage.getItem('userID'), params)
                .then(response => response.data)
                .then(result => {
                    commit('setModalPrize', result.popups)
                    resolve(result)
                })
        })
    }
}

const mutations = {
    setTasks(state, tasks) {
        state.tasks = tasks
    },
    setProgressValue(state, progress) {
        state.progress = progress
    },
    setBoard(state, board) {
        state.board = board
    },
    setPrizes(state, prizes) {
        state.prizes = prizes
    },
    setOnlinePrizes(state, onlinePrizes) {
        state.onlinePrizes = onlinePrizes
    },
    setModalPrize(state, modalPrize) {
        state.modalPrize = modalPrize
    },
    setPopupIndex(state, index) {
        index !== 0 ? state.popupIndex += index : state.popupIndex = index
    },
    setFileUpload(state, file_upload) {
        state.file_upload = file_upload
    }
}

const getters = {
    getTasks: state => state.tasks,
    getProgressValue: state => state.progress,
    getBoard: state => state.board,
    getPrizes: state => state.prizes,
    getOnlinePrizes: state => state.onlinePrizes,
    getModalPrize: state => state.modalPrize,
    getFileUpload: state => state.file_upload,
}



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
