<template>
	<div class="burger-modal">
		<div class="modal-content">
			<div class="burger-modal__body">
				<div
					class="modal-role"
				>
					<div
						class="modal-role__toggle"
						:class="{'modal-role__toggle--active': showRoles}"
						@click="showRoles = !showRoles"
					>
						<span>{{ $route.name === 'Student' ? choseRoleStudent = 'Студентам' : choseRoleStudent = 'Специалистам' }}</span>
					</div>
					<div
						class="modal-role__content"
						:style="styleOptions"
					>
						<a
							v-for="(item, index) in getListStudent"
							:key="index"
							class="modal-role__link"
							@click="changeRoleStudent(item)"
						>{{ item }}</a>
					</div>
				</div>
				<div class="burger-modal__navigation">
					<a
						:href="'/courses'"
						class="burger-modal__link"
						@click.prevent="linkTo('courses')"
					>
						Обучение
					</a>
					<a
						:href="'/tests'"
						class="burger-modal__link"
						@click.prevent="linkTo('tests')"
					>
						Тесты
					</a>
					<a
						:href="'/projects'"
						class="burger-modal__link"
						@click.prevent="linkTo('projects')"
					>
						Практика и мероприятия
					</a>
					<a
						:href="'/internship'"
						class="burger-modal__link"
						@click.prevent="linkTo('internship')"
					>
						Стажировки и вакансии
					</a>
					<a
						:href="'/about-us'"
						class="burger-modal__link"
						@click.prevent="linkTo('about-us')"
					>
						О нас
					</a>
				</div>
			</div>
		</div>
		<div
			v-if="!isLogged"
			class="burger-modal__footer"
		>
			<button
				class="button white"
				@click.prevent="login"
			>
				Войти
			</button>
			<button
				class="button orange"
				@click.prevent="signIn"
			>
				Регистрация
			</button>
		</div>
		<div
			v-else-if="isLogged && userID"
			class="burger-modal__footer"
			@click="goToProfile()"
		>
			<a
				class="user-header"
			>
				<div
					class="user-header__avatar"
					:style="userAvatar | bgrDynamicImageCover"
				/>
			</a>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'MainMenuSecond',
    data: () => ({
        showRoles: false,
        list: [
            'Школьникам',
            'Студентам',
            'Специалистам'
        ],
        choseRoleStudent: 'Студентам'
    }),
    computed: {
        ...mapGetters('user', {
            user: 'userInfo',
            userID: 'userID',
            isLogged: 'isLoggedIn'
        }),
        ...mapGetters('cnst', {
            roles: 'getRolesLanding'
        }),
        styleOptions () {
            return this.showRoles ? 'display: block' : 'display: none'
        },
        getListStudent () {
            return this.list.filter(item => item !== this.choseRoleStudent)
        },
        userAvatar() {
            return this.user && this.user.image_user.length ? this.user.image_user[0].image : IS_DEV ? '/img/avatars/avatar-empty.svg' : '/staticfiles/img/avatars/avatar-empty.svg'
        },
        rolesOptions() {
            return this.roles.map(x => x.value)
        }
    },
    methods: {
        linkTo (url) {
            if (this.$route.fullPath !== '/' + url && url !== '/') {
                window.open(('/' + url), '_blank');
            }
            this.modalClose()
        },
        changeRoleStudent (item) {
            this.choseRoleStudent = item
            if (item === 'Школьникам') this.$router.push('/school')
            if (item === 'Студентам') this.$router.push('/student')
            if (item === 'Специалистам') this.$router.push('/')
            this.modalClose()
        },
        login() {
            this.modalOpen('signIn');
        },
        signIn() {
            this.modalOpen('regIn')
        },
        goToProfile () {
            this.isAvatarClick = !this.isAvatarClick
            this.$router.push(`/profile/${this.userID}`)
            this.modalClose()
        }
    }
}
</script>

<style scoped>

</style>
