<template>
	<header
		v-if="
			!$route.meta.isUnivers &&
				!$route.meta.isMaintenance &&
				!$route.meta.isLandingUr &&
				!$route.meta.isOnboarding
		"
		ref="header"
		class="header-role"
		:class="{'header-role--white': isLogged}"
	>
		<div class="content">
			<div class="header-role__grid">
				<div class="header-role__start">
					<router-link
						v-if="isLogged"
						to="/platform"
						class="logo"
					/>
					<a
						v-else
						href="/"
						class="logo"
					/>
					<div
						v-if="!isLogged && $route.meta.isSchool"
						class="header-role__select"
					>
						<c-select
							:options="rolesOptions"
							:default-data="rolesName"
							choose-role
							@selected="selectRole"
						/>
					</div>
				</div>
				<div
					v-if="usersCount"
					class="header-role__center"
				>
					<div class="header-role__quantity">
						<img
							:src="'/img/icons/icon__user-plus--orange.svg' | prodSrcImage"
							alt="Иконка"
						>
						Нас уже {{ usersCount }}
					</div>
					<h1 class="header-role__title">
						Всероссийская программа профориентации
					</h1>
				</div>
				<div
					class="header-role__end"
				>
					<button
						v-if="!isLogged"
						class="button white"
						@click.prevent="login"
					>
						Войти
					</button>
					<button
						v-if="!isLogged"
						class="button orange"
						@click.prevent="signIn"
					>
						Регистрация
					</button>
					<!--					<div-->
					<!--						v-if="isLogged && user && userStatus"-->
					<!--						class="award-header"-->
					<!--					>-->
					<!--						<img-->
					<!--							:src="userStatus.img | prodSrcImage"-->
					<!--							alt="Медаль"-->
					<!--							class="award-header__pic"-->
					<!--						>-->
					<!--						<div class="award-header__title">-->
					<!--							{{ userStatus.value }} “Лифта в будущее”-->
					<!--						</div>-->
					<!--					</div>-->
					<div
						v-if="isLogged && userID && user"
						class="user-header"
					>
						<div class="user-header__dropdown">
							<router-link
								to="/platform"
								class="user-header__link"
							>
								Главная
							</router-link>
							<a
								class="user-header__link"
								@click="goToProfile()"
							>Личный кабинет</a>
							<BtnToBackOffice class-name="user-header__link" />
							<router-link
								v-if="isRecruiter"
								class="user-header__link"
								to="/search-users"
							>
								Поиск по пользователям
							</router-link>
							<a
								class="user-header__link"
								@click="exitProfile"
							>Выход</a>
						</div>
						<div class="user-header__toggle">
							<div
								class="user-header__avatar"
								:style="userAvatar | bgrDynamicImageCover"
							/>
							<div
								class="user-header__name"
							>
								{{ userData }}
							</div>
						</div>
					</div>
					<div
						v-if="$route.name === 'School'"
						class="header-role__burger"
						@click="openModalMainMenu"
					/>
					<div
						v-else-if="!isLogged"
						class="header-role__burger header-role__burger--desktop"
						@click="modalOpen('mainMenuSecond')"
					/>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import BtnToBackOffice from '@/components/backoffice/BtnToBackOffice';

export default {
    name: 'Header2',
		components: {
			BtnToBackOffice
    },
    data: () => ({
        usersCount: 0,
        isAvatarClick: false,
        choseRole: 'student_low',
    }),
    computed: {
        ...mapGetters('user', {
            user: 'userInfo',
            userID: 'userID',
            isLogged: 'isLoggedIn',
            isRecruiter: 'isRecruiter',
            backOfficeRole: 'getBackOfficeRole'
        }),
        ...mapGetters('cnst', {
            roles: 'getRolesLanding',
            statuses: 'getUserStatus'
        }),
        userAvatar() {
            return this.user && this.user.image_user.length ? this.user.image_user[0].image : IS_DEV ? '/img/avatars/avatar-empty.svg' : '/staticfiles/img/avatars/avatar-empty.svg'
        },
        userData() {
            return this.user && this.user.first_name + ' ' + this.user.last_name
        },
        rolesOptions() {
            return this.roles.map(x => x.value)
        },
        userStatus() {
            return this.statuses.find(x => x.slug === this.user.motivation_status)
        },
        rolesName() {
            return this.$route.name === 'School' ? 'Школьникам' : this.$route.name === 'Student' ? 'Студентам' : this.$route.path === '/' ? 'Специалистам' : 'Школьникам'
        },
    },
    created() {
        this.getUsersCount().then(count => {
            this.usersCount = count
        })
    },
    mounted() {
        document.addEventListener('mousedown', event => {
            if (!event.target.closest('.header-user')) this.isAvatarClick = false
        })
    },
    methods: {
        ...mapActions('user', [
            'getUsersCount',
            'logout'
        ]),
        login() {
            this.modalOpen('signIn');
        },
        signIn() {
            this.modalOpen('regIn')
        },
        selectRole(val) {
            this.choseRole = this.roles.find(x => x.value === val).slug
            if (this.choseRole === 'student_low') this.$router.push('/school')
            if (this.choseRole === 'student_high') this.$router.push('/student')
            if (this.choseRole === 'expert') this.$router.push('/')
        },
        openModalMainMenu () {
            if (this.$route.name === 'Student')this.$router.push('/student?menu=true')
            if (this.$route.name === 'School')this.$router.push('/school?menu=true')
            if (this.$route.name === 'Expert')this.$router.push('/?menu=true')
        },
        goToProfile () {
            this.isAvatarClick = !this.isAvatarClick
            if (this.user && (this.user.user_role === 'Student' || this.user.user_role === 'College' || this.user.user_role === 'Schoolboy')) this.$router.push(`/profile/${this.userID}?prizes=true`)
            else if (this.user && !this.isRecruiter && (this.user.user_role !== 'Student' || this.user.user_role !== 'College' || this.user.user_role !== 'Schoolboy')) this.$router.push(`/profile/${this.userID}`)
            if (this.isRecruiter) this.$router.push('/profile')
            this.modalClose()
        },
        exitProfile() {
            this.logout()
                .then(() => {
                    localStorage.removeItem('isFromMobile')
                    this.isAvatarClick = false;
                    this.modalClose()
                    window.location.href = '/'
                })
        },
    }
}
</script>

<style lang="less">
.header-role {
    z-index: 1102 !important
}
</style>

