<script>
import Cnt from '@/api/contests';

export default {
	props: {
		fileName: {
			type: String,
			default: '',
		},
		acceptFiles: {
			type: String,
			default: '',
		},
		fileSize: {
			type: String,
			default: '',
		},
		oldFile: {
			type: Array,
			default: () => [],
		},
		required: {
			type: Boolean,
			default: false,
		},
		multiCount: {
			type: Number,
			default: 1,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			uploadedFile: '',
			uploadedFileUrls: [],
			isError: false,
			errorText: '',
			errorMultiFile: '',
			isSending: false,
			emmitObject: {},
			uploadingFilesQuey: [],
		};
	},

	computed: {
		canUploadMore() {
			return this.uploadedFileUrls.length >= this.multiCount;
		},
	},

	watch: {
		// Очищаем input[type=file] в dom, если пользователь удалил все загруженные файлы
		uploadedFileUrls() {
			if (this.uploadedFileUrls.length === 0) {
				this.$refs.file.value = null
				this.errorMultiFile = '';
				this.uploadingFilesQuey = [];
			}
		},
	},

	created() {
		if (this.oldFile.length > 0) {
			if (this.multiCount > 1) { // Если пришло несколько файлов массивом
				this.uploadedFileUrls = [... this.oldFile];
			} else if (typeof this.oldFile === 'string') { // Если пришел один файл в виде строки (для поддержки старых заявок)
				this.uploadedFile = this.oldFile;
			} else {
				this.uploadedFile = this.oldFile[0]; // Если пришел один файл массивом
			}
		}
	},


	methods: {
		setError(text) {
			this.isError = true;
			this.errorText = text;
		},
		getFileProperties(file) {
			const fileType = file.name.split('.').pop();
			const size = Math.floor(file.size / 1024 / 1024);
			return { type: fileType, size }
		},

        validateFile(properties) {
            const maxSize = this.fileSize ? this.fileSize : 0;

            if (this.acceptFiles.includes(properties.type) && maxSize >= properties.size) {
                return true;
            }

            if (!this.acceptFiles.includes(properties.type)) {
                this.setError(`Неподдерживаемый формат файла: ${properties.type}`);
                return false;
            }

            if (maxSize < properties.size) {
                this.setError(`Файл слишком большой. Максимальный размер: ${maxSize} MB`);
                return false;
            }

            this.setError('Ошибка');
            return false;
        },

        async sendingFile(formData, fileName) {
			// Проверяем, если уже загрузили по одному файлу больше чем надо
			if (this.canUploadMore) {
				this.setError('Вы пытаетесь загрузить больше файлов');
				return;
			}
			this.isSending = true;
			this.isError = false;
			try {
				const response = await Cnt.uploadFile(formData);
				// Мультизагрузка
				if (this.multiCount > 1) {
					this.uploadedFileUrls.push(response.data.file_url);
					this.emmitObject[this.fileName] = this.uploadedFileUrls;
					// Для отображения статуса загрузки при очереди файлов
					const indexInQuey = this.uploadingFilesQuey.indexOf(fileName);
					this.uploadingFilesQuey.splice(indexInQuey, 1);
					this.uploadingFilesQuey.length === 0 ? this.isSending = false : null;

				// Загрузка одного файла
				} else {
					this.uploadedFile = response.data.file_url;
					this.emmitObject[this.fileName] = [response.data.file_url];
					this.isSending = false;
				}
				this.$emit('uploaded', this.emmitObject);
			} catch (error) {
				this.setError('Ошибка при загрузке, повторите попытку');
			}
		},

		revomeUploadedFile(url) {
			this.uploadedFileUrls.splice(this.uploadedFileUrls.indexOf(url), 1);
			this.emmitObject[this.fileName] = this.uploadedFileUrls;
			this.$emit('uploaded', this.emmitObject);
		},

		uploadFile() {
			this.errorMultiFile = '';
			const fileRef = this.$refs.file;

			// Добавление нескольких файлов с ПК
			if (fileRef.files.length > 1) {
				// Проверка на количество загружаемых файлов в input
				if (this.uploadedFileUrls.length + fileRef.files.length > this.multiCount) {
					this.setError(`Слишком много файлов для загрузки. Необходимо файлов: ${this.multiCount}.`);
					return;
				}

				fileRef.files.forEach((file) => {
					const formData = new FormData();
					const properties = this.getFileProperties(file);
					if (this.validateFile(properties)) {
						formData.append('file', file);
						this.uploadingFilesQuey.push(file.name);
						this.sendingFile(formData, file.name);
					} else {
						this.errorMultiFile = `Ошибка при загрузке файла - ${file.name}`;
					}
				});
			// Добавление одного или по одному файлу
			} else {
				const formData = new FormData();
				const properties = this.getFileProperties(fileRef.files[0]);
				if (this.validateFile(properties)) {
					this.isError = false;
					formData.append('file', fileRef.files[0]);
					this.sendingFile(formData);
				}
			}
		},
	},
};
</script>

<template>
	<div class="contest-task__field">
		<div
			class="uploaded-materials"
			:class="{
				'uploaded-materials--error':
					isError ||
					(isLoading && required && !uploadedFile.length),
			}"
		>
			<label
				class="upload-materials"
				:class="{ 'upload-file-disable': canUploadMore }"
			>
				<input
					ref="file"
					type="file"
					:disabled="canUploadMore"
					:multiple="multiCount > 1"
					class="upload-materials__value"
					@change="uploadFile"
				>
				<div
					v-if="!isSending && !uploadedFile"
					class="upload-materials__title"
				>
					Прикрепить {{ !multiCount ? "файл" : "файлы" }}
				</div>
				<div
					v-if="isSending"
					class="upload-materials__title"
				>
					{{ !multiCount ? "Файл загружается" : "Файлы загружаются" }}, немного подождите ...
				</div>
				<div
					v-if="!isSending && !oldFile && uploadedFile.length > 0"
					class="upload-materials__title"
				>
					{{ !multiCount ? "Файл прикреплен" : "Файлы прикреплены" }}
				</div>
				<div class="title-mono">
					Поддерживаемые форматы: {{ acceptFiles }}
				</div>
			</label>
			<div
				v-if="uploadedFile"
				class="uploaded-materials__list"
			>
				<div class="uploaded-material">
					<div class="uploaded-material__title title-mono">
						{{ uploadedFile }}
					</div>
					<div
						class="uploaded-material__progress"
						style="width: 100%"
					/>
				</div>
			</div>
			<div v-if="uploadedFileUrls.length > 0">
				<div
					v-for="(url, index) in uploadedFileUrls"
					:key="index"
					class="uploaded-material"
				>
					<div class="uploaded-material__title title-mono">
						{{ url }}
					</div>
					<div
						class="uploaded-material__remove"
						@click="revomeUploadedFile(url)"
					/>
					<div
						class="uploaded-material__progress"
						style="width: 100%;"
					/>
				</div>
				<p
					v-if="errorMultiFile"
					class="error-text"
				>
					{{ errorMultiFile }}
				</p>
			</div>
		</div>
		<div
			v-if="isError"
			class="title-mono"
			style="color: red"
		>
			{{ errorText }}
		</div>
		<p
			v-else-if="isLoading && required && !uploadedFile.length"
			class="error-text"
		>
			Обязательно для заполнения
		</p>
	</div>
</template>


<style scoped>
	.upload-file-disable {
		opacity: 0.4;
		pointer-events: none;
	}
</style>
