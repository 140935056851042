<template>
	<section
		v-if="speakersInfo"
		class="section section-experts"
	>
		<div class="content">
			<div class="slider-wrap">
				<VueSlickCarousel
					v-bind="sliderSettings"
					class="experts-slider"
				>
					<div
						v-for="(item, index) in speakersInfo.speakers"
						:key="index"
					>
						<div class="expert">
							<div
								class="expert__avatar"
								:style="item.photo | bgrDynamicImageCover"
							/>
							<div class="expert__content">
								<div class="title-mono">
									{{ speakersInfo.who_speaks }}
								</div>
								<h3 class="title-block">
									{{ item.person_name }}
								</h3>
								<div
									class="expert__position"
									v-html="item.description"
								/>
							</div>
						</div>
					</div>
				</VueSlickCarousel>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Experts',
    props: {
        speakersInfo: {
            type: Object,
            default: null
        },
        eventType: {
            type: String,
            default: null
        }
    },
    data: () => ({
        sliderSettings: {
            infinite: false,
            easing: 'swing',
            arrows: true,
            dots: false,
            autoplay: false,
            autoplaySpeed: 3000,
            pauseOnFocus: true,
            pauseOnHover: false,
            speed: 600,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false
        }
    })
}
</script>
