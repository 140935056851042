import { microService } from './default'

export default {
    getGlobalCities({q, region}) {
        return microService({
            url: '/get_city',
            method: 'GET',
            params: { q, region }
        })
    },
    getGlobalRegions({q}) {
        return microService({
            url: `/get_region?q=${q}`,
            method: 'GET'
        })
    }
}
