<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<div
				v-if="payload"
				class="modal-title sm"
			>
				Подать заявку
			</div>
			<div
				v-else
				class="center"
			>
				<br>
				<div class="modal-title-wrap">
					<div class="modal-title sm">
						Спасибо, заявка подана!
					</div>
				</div>
				<button
					class="button-hollow"
					@click="modalClose"
				>
					Продолжить
				</button>
			</div>
			<form
				v-if="payload"
				class="form"
				if="formRequest"
			>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model.trim="$v.form.fio.$model"
							type="text"
							class="field"
							placeholder="ФИО"
						>
						<div class="label">ФИО</div>
						<span
							v-if="$v.form.fio.$error"
							class="help-block form-error"
						>Это обязательное поле</span>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model.trim="$v.form.phone.$model"
							v-mask="'+7(###)###-##-##'"
							type="text"
							class="field"
							placeholder="Мобильный телефон"
						>
						<div class="label">Мобильный телефон</div>
						<span
							v-if="$v.form.phone.$error"
							class="help-block form-error"
						>
							{{
								!$v.form.phone.required
									? "Это обязательное поле"
									: !$v.form.phone.Phone
										? "Введите корректный номер телефона"
										: "Ошибка поля"
							}}
						</span>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model.trim="$v.form.email.$model"
							type="email"
							class="field"
							placeholder="Email"
						>
						<div class="label">Email</div>
						<span
							v-if="$v.form.email.$error"
							class="help-block form-error"
						>
							{{
								!$v.form.email.required
									? "Это обязательное поле"
									: !$v.form.email.email
										? "Введите корректный e-mail"
										: "Ошибка поля"
							}}
						</span>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label question-field">
						<input
							v-model.trim="$v.form.company_name.$model"
							type="text"
							class="field"
							placeholder="Название компании"
						>
						<div class="label">Название компании</div>
						<div
							class="question"
							data-title="Компания, где проходил или проходишь практику/стажировку"
						>
							<img
								width="20px"
								height="20px"
								:src="'/img/question.png' | prodSrcImage"
								alt=""
							>
						</div>

						<span
							v-if="$v.form.company_name.$error"
							class="help-block form-error"
						>Это обязательное поле</span>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model.trim="$v.form.city.$model"
							type="text"
							class="field"
							placeholder="Город"
						>
						<div class="label">Город</div>
						<span
							v-if="$v.form.city.$error"
							class="help-block form-error"
						>Это обязательное поле</span>
					</label>
				</div>
				<button
					v-if="!isSended"
					class="button orange lg"
					@click.prevent="sendRequest"
				>
					Отправить
				</button>
				<div
					v-else
					class="button orange lg button-preloader"
				>
					<div class="button-preloader-icons">
						<div class="button-preloader-icon" />
						<div class="button-preloader-icon" />
						<div class="button-preloader-icon" />
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { Phone } from '@/assets/scripts/custom_validators';
import Cnt from '@/api/contest';

export default {
	data: () => ({
		isSended: false,
		form: {
			competition: null,
			fio: '',
			phone: '',
			email: '',
			company_name: '',
            city: ''
		}
	}),
	computed: {
		user() {
			return this.$store.state.user.user;
		},
		payload() {
			return this.$store.state.modal.payload;
		},
		settings() {
			return (
				this.$store.state.cmn.settings &&
				this.$store.state.cmn.settings[0]
			);
		},
		contest() {
			return this.$store.state.cnt.contest;
		},
		activeData() {
			return this.$store.state.modal.activeData;
		}
	},
	validations() {
		return {
			form: {
				fio: { required },
				email: { required, email },
				phone: { required, Phone },
				city: { required },
				company_name: { required }
			}
		};
	},
	created() {
		this.form.competition = this.contest.id;
		this.form.fio = `${this.user.last_name ? this.user.last_name : ''} ${
			this.user.first_name ? this.user.first_name : ''
		} ${this.user.middle_name ? this.user.middle_name : ''}`;
		this.form.email = this.user && this.user.email ? this.user.email : '';
		this.form.phone = this.user && this.user.phone ? this.user.phone : '';
		this.form.city =
			this.user && this.user.city && this.user.city.name ? this.user.city.name : '';
	},
	methods: {
		sendRequest() {
			this.$v.form.$touch();
			if (this.$v.form.$invalid) {
				this.$scrollTo(document.querySelector('#formRequest'));
			} else {
				this.isSended = true;
				this.isSended &&
					Cnt.sendContestNew({
						data: this.form
					})
						.then(() => {
							this.$store.commit('modal/resetActiveData');
							this.modalClose('conReqTrainee');
						})
						.catch(() => {
							this.modalOpen('applicationSumbition');
							this.$store.commit(
								'modal/setActiveData',
								this.form
							);
						})
						.finally(() => (this.isSended = true));
			}
		}
	}
};
</script>

<style lang="less" scoped>
.question-field {
	position: relative;
}
.question {
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
	height: 20px;
	cursor: pointer;
	@media @mobile-end{
			display: none;
	}
	&::after {
		display: none;
		content: attr(data-title);
		position: absolute;
		width: max-content;
		left: -350px;
		top: -20px;
		z-index: 1;
		background: rgb(244 244 244);
		font-family: Arial, sans-serif;
		font-size: 11px;
		padding: 5px 10px;
		border-radius: 3px;
		border: 1px solid #333;
	}

}
.question:hover::after {
	display: block;
}
</style>
