import Cnt from '@/api/contest'

const state = {
    catalogue: [],
    cities: [],
    directions: [],
    companies: []
}

const actions = {
    getCatalogue({ commit }, params) {
        return new Promise(resolve => {
            Cnt.getCatalogueInternShip(params)
                .then(response => response.data)
                .then(result => {
                    commit('setCatalogue', result)
                    resolve(result)
                })
        })
    },
    getCities({ commit }, params) {
        return new Promise(resolve => {
            Cnt.getInternshipCities(params)
                .then(response => response.data)
                .then(result => {
                    commit('setCities', result)
                    resolve(result)
                })
        })
    },
    getDirections({ commit }, params) {
        return new Promise(resolve => {
            Cnt.getInternshipDirections(params)
                .then(response => response.data)
                .then(result => {
                    commit('setDirections', result)
                    resolve(result)
                })
        })
    },
    getCompanies({ commit }, params) {
        return new Promise(resolve => {
            Cnt.getInternshipCompanies(params)
                .then(response => response.data)
                .then(result => {
                    commit('setCompanies', result)
                    resolve(result)
                })
        })
    }
}

const mutations = {
    setCatalogue(state, catalogue) {
        state.catalogue = catalogue
    },
    setCities(state, cities) {
        state.cities = cities
    },
    setDirections(state, directions) {
        state.directions = directions
    },
    setCompanies(state, companies) {
        state.companies = companies
    }
}

const getters = {
    getCatalogue: state => state.catalogue,
    getCities: state => state.cities,
    getDirections: state => state.directions,
    getCompanies: state => state.companies
}



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
