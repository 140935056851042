<template>
	<div class="modal-content">
		<div class="center">
			<img
				:src="'/img/icons/icon-alert-success.svg' | prodSrcImage"
				class="alert-icon"
			>
			<h3 class="modal-title">
				{{ titleText }}
			</h3>
			<br>
			<div
				:id="currentIdForMetrics"
				class="button orange lg"
				@click.stop.prevent="nextStep"
			>
				{{ buttonText }}
			</div>
		</div>
	</div>
</template>

<script>

import { mapGetters,mapMutations } from 'vuex'

export default {

    computed: {
        ...mapGetters('modal', {
            payload: 'getPayload',
            name: 'currentSuccess'
        }),
        ...mapGetters( 'user', {
           userID: 'userID',
            isTransitionAfterReg: 'transitionAfterReg',
            prevRoute:'prevRoute'
        }),
        titleText() {
            if (this.name === 'regSuccess') return 'Регистрация прошла успешно'
            else if (this.name === 'passSuccess') return 'Пароль успешно изменен'
            else if (this.name === 'userSuccess') return 'Данные успешно сохранены'
            else if (this.name === 'activSuccess') return 'Профиль успешно активирован'
            else if (this.name === 'courseSuccess') return 'Вы успешно записаны на курс'
            else if (this.name === 'vacancySuccess') return 'Ваш отклик успешно принят'
            else if (this.name === 'recLinkSuccess') return 'Ссылка для восстановления пароля отправлена на Вашу почту'
            else if (this.name === 'changeSuccess') return 'Изменения успешно сохранены'
            else if (this.name === 'mailSuccess') return 'Ваше обращение успешно отправлено'
            else if (this.name === 'createResSuccess') return 'Резюме было успешно создано'
            else if (this.name === 'changeResSuccess') return 'Резюме было успешно изменено'
            else if (this.name === 'createVacSuccess') return 'Вакансия была успешно создана'
            else if (this.name === 'changeVacSuccess') return 'Вакансия была успешно изменена'
            else if (this.name === 'changeCompSuccess') return 'Компания была успешно изменена'
            else if (this.name === 'videoContestSuccess') return 'Ваша заявка успешно принята'
            else if (this.name === 'eventSuccess') return 'Вы успешно зарегистрированы на мероприятие'
            else if (this.name === 'mentorsapplicationSuccess') return 'Вы успешно зарегистрированы на мероприятие'
            else if (this.name === 'ambsdrSuccess') return 'Ваша заявка будет рассмотрена'
            else if (this.name === 'respSuccess') return 'Статус отклика отправлен соискателю'
            else if (this.name === 'actVacSuccess') return 'Вакансия опубликована'
            else if (this.name === 'draftVacSuccess') return 'Вакансия перенесена в черновики'
            else if (this.name === 'archVacSuccess') return 'Вакансия перенесена в архив'
            else if (this.name === 'cmntSuccess') return 'Комментарий успешно сохранен'
            else if (this.name === 'promoSuccess') return 'Промокод успешно отправлен'
            else if (this.name === 'uploadEventSuccess') return 'Материалы успешно отправлены'
            else if (this.name === 'MotivationScs') return 'Материалы успешно отправлены'

            return 'Успешно'
        },
        currentIdForMetrics() {
            if (this.name === 'regSuccess') return 'btnReginSuccess_8'
            return 'btnCourseSuccess_7'
        },
        buttonText() {
            if (this.name === 'courseSuccess') return 'Перейти'
            return 'Закрыть'
        }
    },
    beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from;
		});
	},
    methods: {
        ...mapMutations('user', ['setTransitionAfterReg']),
        nextStep() {
             if (this.name === 'eventSuccess') {
                window.location.reload()
            } else if (this.name === 'createResSuccess') {
                this.$router.push(`/profile/${this.userID}?resumes=true`)
            } else if (this.name === 'changeResSuccess') {
                this.$router.push(`/profile/${this.userID}?resumes=true`)
            } else if (this.name === 'userSuccess') {
                 this.$router.go(-1)
            } else if (this.name === 'regSuccess' && (this.$route.meta.isCourse ||
                 this.$route.meta.isTest ||
             this.$route.meta.isEventConstructor ||
             this.$route.meta.isOldEventConstructor)) {
                window.location.reload()
            } else if (this.name === 'uploadEventSuccess' && this.payload) {
                window.location.reload()
            } else if (this.name === 'vacancySuccess') {
                window.location.reload()
            } else if (this.name === 'vacancySuccess') {
                window.location.reload()
            } else if (this.name === 'createVacSuccess' || this.name === 'archVacSuccess' || this.name === 'actVacSuccess' || this.name === 'draftVacSuccess') {
                this.$router.push('/profile?vacancies=true')
            } else if (this.name === 'goSuccess') {
                this.$router.push('/')
            } else if (this.name === 'respSuccess') {
                this.$router.push('/profile?responses=true')
            } else if (this.name === 'courseSuccess') {
                window.open(this.payload)
            }
            this.modalClose()
        }
    }
}
</script>
