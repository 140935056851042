<template>
	<div class="field-wrap">
		<label
			:class="{
				'field-label': true,
				'field-label--is-white': isWhite
			}"
		>
			<input
				v-model.trim="localValue"
				v-mask="mask"
				:min="min"
				:max="max"
				:type="type"
				:class="{
					'field-input': true,
					'field-input--is-capitalize': isCapitalize
				}"
				:placeholder="placeholder"
				@blur="$emit('blurInput')"
			>
			<label
				v-if="!notLabel"
				:class="{
					'label-input': true,
					'label-input--pos-top': localValue ,
					'label-input--is-sidebar': isSidebar && localValue
				}"
			>{{ label }}</label>
			<slot />
		</label>
	</div>
</template>

<script>
export default {
  props: {
    mask: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: 'Label'
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      required: false,
      default: 0 || ''
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    notLabel: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    isCapitalize: {
      type: Boolean,
      default: false
    },
    isSidebar: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localValue: ''
    }
  },
  watch: {
    value: {
      handler (newValue) {
        if (newValue !== this.localValue) {
          this.localValue = newValue
        }
      },
      immediate: true
    },
    localValue (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('input', newValue)
      }
    }
  },

  beforeMount () {
    this.localValue = this.value
  }
}
</script>

<style lang="less" scoped>
.field-wrap {
  position: relative;
  display: block;
  margin-bottom: 35px;
}
.label-input {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  display: block;
  font-weight: 400;
  font-size: 16px;
  color: #999;
  transition: all 0.2s ease-in-out;
  &--pos-top {
    transform: initial;
    color: #999;
    top: -7px;
    font-size: 12px;
  }
  &--is-sidebar {
    display:none;
  }
}
.field-input {
  position:relative;
  z-index: 10;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: block;
  width: 100%;
  padding: 5px 15px;
  font-size: 16px;
  color: #000;
  border-bottom: 2px solid #cbcbcb;
}
.field-label {
  height: 100%;
  &--is-white {
    border: 3px solid white;
    border-radius: 18px;
    input {
      border: none;
    }
    .field-input {
      text-align: center;
      &:focus {
        color: white;
        &::placeholder {
          color: #979da0;
        }
      }
      &::placeholder {
        color: white;
      }
    }
  }
  .field-input {
    &--is-capitalize {
      text-transform: capitalize;
    }
  }
}
</style>
