<template>
	<section class="section section-inset">
		<div class="content content--md">
			<h2 class="title-section">
				Призы программы
			</h2>
			<div class="inset inset--orange">
				<div class="inset__body">
					<div class="inset__tabs">
						<div
							class="inset__tab"
							:class="{'inset__tab--active': isActiveFirst}"
							@click="activeTab(1)"
						>
							Студентам вузов
						</div>
						<div
							class="inset__tab"
							:class="{'inset__tab--active': isActiveSecond}"
							@click="activeTab(2)"
						>
							Студентам колледжей
						</div>
						<div
							class="inset__tab"
							:class="{'inset__tab--active': isActiveThird}"
							@click="activeTab(3)"
						>
							Школьникам
						</div>
					</div>
					<div class="inset__contents">
						<div
							class="inset__content"
							:style="isActiveFirst ? 'display: block' : 'display: none'"
						>
							<div class="awards">
								<div class="awards__header">
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<img
												:src="'/img/logo--black.svg' | prodSrcImage"
												alt="Лифт в будущее"
												class="awards__logo"
											>
											<div class="awards__notice">
												Твой прогресс на платформе
											</div>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/pages/event/pic__award-0--x2.png' | prodSrcImage"
												alt="Медаль"
												class="awards__medal"
											>
											<div class="awards__medal-title">
												Cтипендиат
											</div>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/pages/event/pic__award-1--x2.png' | prodSrcImage"
												alt="Медаль"
												class="awards__medal"
											>
											<div class="awards__medal-title">
												Амбассадор
											</div>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/pages/event/pic__award-2--x2.png' | prodSrcImage"
												alt="Медаль"
												class="awards__medal"
											>
											<div class="awards__medal-title">
												Профи
											</div>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/pages/event/pic__award-3--x2.png' | prodSrcImage"
												alt="Медаль"
												class="awards__medal"
											>
											<div class="awards__medal-title">
												Продвинутый
											</div>
										</div>
									</div>
								</div>
								<div class="awards__body">
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Cтипендия <br>
												20 000 ₽  х 5 месяцев
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Промокод Озон <br>
												номиналом 30 000 ₽
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Наставник из отрасли
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Карьерная консультация
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Онлайн-призы
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							class="inset__content"
							:style="isActiveSecond ? 'display: block' : 'display: none'"
						>
							<div class="awards">
								<div class="awards__header">
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<img
												:src="'/img/logo--black.svg' | prodSrcImage"
												alt="Лифт в будущее"
												class="awards__logo"
											>
											<div class="awards__notice">
												Твой прогресс на платформе
											</div>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/pages/event/pic__award-0--x2.png' | prodSrcImage"
												alt="Медаль"
												class="awards__medal"
											>
											<div class="awards__medal-title">
												Cтипендиат
											</div>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/pages/event/pic__award-1--x2.png' | prodSrcImage"
												alt="Медаль"
												class="awards__medal"
											>
											<div class="awards__medal-title">
												Амбассадор
											</div>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/pages/event/pic__award-2--x2.png' | prodSrcImage"
												alt="Медаль"
												class="awards__medal"
											>
											<div class="awards__medal-title">
												Профи
											</div>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/pages/event/pic__award-3--x2.png' | prodSrcImage"
												alt="Медаль"
												class="awards__medal"
											>
											<div class="awards__medal-title">
												Продвинутый
											</div>
										</div>
									</div>
								</div>
								<div class="awards__body">
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Cтипендия <br>
												7 500 ₽  х 5 месяцев
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Промокод Озон <br>
												номиналом 15 000 ₽
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Наставник из отрасли
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Карьерная консультация
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Онлайн-призы
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							class="inset__content"
							:style="isActiveThird ? 'display: block' : 'display: none'"
						>
							<div class="awards">
								<div class="awards__header">
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<img
												:src="'/img/logo--black.svg' | prodSrcImage"
												alt="Лифт в будущее"
												class="awards__logo"
											>
											<div class="awards__notice">
												Твой прогресс на платформе
											</div>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/pages/event/pic__award-0--x2.png' | prodSrcImage"
												alt="Медаль"
												class="awards__medal"
											>
											<div class="awards__medal-title">
												Cтипендиат
											</div>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/pages/event/pic__award-1--x2.png' | prodSrcImage"
												alt="Медаль"
												class="awards__medal"
											>
											<div class="awards__medal-title">
												Амбассадор
											</div>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/pages/event/pic__award-2--x2.png' | prodSrcImage"
												alt="Медаль"
												class="awards__medal"
											>
											<div class="awards__medal-title">
												Профи
											</div>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/pages/event/pic__award-3--x2.png' | prodSrcImage"
												alt="Медаль"
												class="awards__medal"
											>
											<div class="awards__medal-title">
												Продвинутый
											</div>
										</div>
									</div>
								</div>
								<div class="awards__body">
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Cтипендия <br>
												5 000 ₽  х 5 месяцев
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Промокод Озон <br>
												номиналом 5 000 ₽
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Профориентационная консультация 30-40 минут
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__minus--circle--white.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
									<div class="awards__row">
										<div class="awards__column awards__column--title">
											<h3 class="title-element">
												Онлайн-призы
											</h3>
										</div>
										<div class="awards__column awards__column--0">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--1">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--2">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
										<div class="awards__column awards__column--3">
											<img
												:src="'/img/icons/icon__check--circle--green.svg' | prodSrcImage"
												alt="Иконка"
												class="awards__icon"
											>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="!isLogged"
				class="button-action"
			>
				<button
					class="button"
					@click="modalOpen('regIn', 'test')"
				>
					Зарегистрироваться
				</button>
			</div>
		</div>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Prizes',
    data: () => ({
        isActiveFirst: true,
        isActiveSecond: false,
        isActiveThird: false
    }),
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    },
    methods: {
        activeTab(num) {
            if (num === 1) {
                this.isActiveFirst = !this.isActiveFirst
                this.isActiveThird = false
                this.isActiveSecond = false
            }
            if (num === 2) {
                this.isActiveSecond = !this.isActiveSecond
                this.isActiveFirst = false
                this.isActiveThird = false
            }
            if (num === 3) {
                this.isActiveThird = !this.isActiveThird
                this.isActiveFirst = false
                this.isActiveSecond = false
            }
        }
    }
};
</script>
