<template>
	<section
		v-if="organizerInfo"
		class="section section-organizer"
	>
		<div class="content content--xs">
			<div class="organizer">
				<div
					class="description"
					v-html="organizerInfo.event_organizer_position"
				/>
				<div class="organizer__content">
					<div class="title-mono">
						Организатор
					</div>
					<div class="title-block">
						{{ organizerInfo.event_organizer_name }}
					</div>
					<div class="organizer__contacts">
						<div class="organizer__contact">
							<div class="title-mono">
								Контактый телефон:
							</div>
							<div class="organizer__contact-value">
								{{ organizerInfo.event_organizer_phone }}
							</div>
						</div>
						<div class="organizer__contact">
							<div class="title-mono">
								Электронная почта:
							</div>
							<div class="organizer__contact-value">
								{{ organizerInfo.event_organizer_email }}
							</div>
						</div>
						<div class="organizer__contact">
							<div class="title-mono">
								Время работы:
							</div>
							<div class="organizer__contact-value">
								{{ organizerInfo.event_organizer_schedule }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Organizer',
    props: {
        organizerInfo: {
            type: Object,
            default: null
        }
    }
}
</script>
