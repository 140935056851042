import {HTTP, getToken} from './default'

export default {
    getStudyPlace(id) {
        return HTTP({
            url: `/user/users/${id}/education/`,
            method: 'GET'
        })
    },
    addStudyPlace({ data, id }) {
        return HTTP({
            url: `/user/users/${id}/education/`,
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data
        })
    },
    changeStudyPlace({ place, user_id, education_id }) {
        return HTTP({
            url: `/user/users/${user_id}/education/${education_id}/`,
            method: 'PUT',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data: place
        })
    },
    removeStudyPlace({ user_id, education_id }) {
        return HTTP({
            url: `/user/users/${user_id}/education/${education_id}/`,
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    getStudyPlaceResume(id) {
        if (getToken()) {
            return HTTP({
                url: `/teachbase/summary/${id}/education/`,
                method: 'GET',
                headers: {
                    'Authorization': `Token ${getToken()}`
                }
            })
        } else {
            return HTTP({
                url: `/teachbase/summary/${id}/education/`,
                method: 'GET'
            })
        }
    },
    addStudyPlaceResume({ data, id }) {
        return HTTP({
            url: `/teachbase/summary/${id}/education/`,
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data
        })
    },
    changeStudyPlaceResume({ place, resume_id, education_id }) {
        return HTTP({
            url: `/teachbase/summary/${resume_id}/education/${education_id}/`,
            method: 'PUT',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data: place
        })
    },
    removeStudyPlaceResume({ resume_id, education_id }) {
        return HTTP({
            url: `/teachbase/summary/${resume_id}/education/${education_id}/`,
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
}
