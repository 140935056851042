<template>
	<div class="happenings">
		<router-link
			v-for="(item, index) in banners"
			:key="index"
			:to="item.link ? item.link : routeMindFormat"
			class="card-audience"
		>
			<img
				:src="item.image"
				alt="Фото"
				class="card-audience__pic"
			>
			<div class="card-audience__content">
				<div class="card-audience__icon" />
				<div class="card-audience__title">
					{{ item.title }}
				</div>
				<div class="card-audience__text">
					{{ item.text }}
				</div>
			</div>
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'BannersGrid',

	data() {
		return {
			banners: [
				{
					link: '',
					image: '/img/test/test-1.png',
					title: 'Формат мышления',
					text: 'Тест на определение формата мышления, выяви свои уникальные способности личности',
				},
				{
					link: '/contest/career/',
					image: '/img/pages/event/pic__banner-6.jpg',
					title: 'Программа стажировок',
					text: '«Лифт в будущее»: программа наставничества, стажировок и практик в ведущих компаниях России',
				},
				{
					link: '/events/grants/',
					image: '/img/pages/event/pic__banner-5.png',
					title: 'Первое рабочее место',
					text: 'Повышаем карьерные возможности обучающихся путем поддержки программ и проектов в области карьерного консультирования и трудоустройства',
				},
			],
		}
	},
};
</script>
