<template>
	<div class="modal-content">
		<div class="modal-notice__block">
			<img
				:src="'/img/icons/icon-modal-success.png' | prodSrcImage"
				alt="successCheck"
				class="modal-notice__icon"
			>
			<h4 class="modal-notice__title">
				Ключ успешно сгенерирован и добавлен
			</h4>
		</div>
	</div>
</template>

<script>
export default {
    name: 'AddKeySuccess'
}
</script>

<style scoped>

</style>
