<template>
	<div class="modal-content">
		<div class="center">
			<br>
			<div class="modal-title-wrap">
				<div class="modal-title sm">
					{{ title }}
				</div>
				<h4
					v-if="subtitle"
					class="modal-subtitle"
				>
					{{ subtitle }}
				</h4>
			</div>
			<button
				class="button-hollow"
				@click="modalClose"
			>
				{{ buttonText }}
			</button>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    computed: {
        ...mapGetters('modal', {
            payload: 'getPayload'
        }),
        setPayload() {
            if (this.payload) {
                if (this.payload.includes('-')) {
                    return this.payload.split('-')[0]
                } else {
                    return this.payload
                }
            } else {
                return null
            }
        },
        title() {
            if (this.setPayload === 'accept') return 'Спасибо, ваш голос принят!'
            else if (this.setPayload === 'stop') return 'Прием заявок будет открыт 24 февраля'
            else if (this.setPayload === 'finish') return 'Голосование завершено'
            return 'Голос снят'

        },
        subtitle() {
            if (this.setPayload === 'accept' || this.setPayload === 'stop' || this.setPayload === 'finish') return false
            return 'Не забудьте выбрать понравившееся видео до окончания голосования!'
        },
        buttonText() {
            if (this.setPayload === 'finish') return 'Закрыть'
            else return 'Продолжить'
        }
    }
}
</script>
