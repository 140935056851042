import Tb from '@/api/teachbase'

const state = {
    courses: null,
    popularCourses: null,
    course: null,
    vacancies: null,
    vacancy: null,
    sections: null,
    topics: null,
    videos: null,
    specialisations: null,
    types: null,
    field_activity: null,
    resumes: null,
    resume: null,
    blogArticles: null,
    projects: null,
    program:null,
    programs:[],
    typesCourses: [],
    actualProjects: [],
    onboardingAnswers: localStorage.getItem('onboardingAnswers') ? localStorage.getItem('onboardingAnswers').split(',') : []
}

const actions = {
    getAllCourses({ commit }, filter) {
        return new Promise(resolve => {
            Tb.getCourses(filter)
                .then(response => response.data)
                .then(payload => {
                    if (filter) {
                        if (filter.page >= 1) {
                            commit('setCourses', payload.results)
                            resolve({ count: payload.count, result: payload.results, _meta: payload._meta })
                        } else {
                            commit('setCourses', payload)
                            resolve(payload)
                        }
                    } else {
                        commit('setCourses', payload)
                        resolve(payload)
                    }
                })
        })
    },
    getAllPrograms({commit},filter) {
        return new Promise(resolve => {
            Tb.getPrograms(filter)
                .then(response => response.data)
                .then(payload => {
                    commit('setPrograms', payload.results)
                    resolve(payload.results)
                })
        })
    },
    getPopularCourses({ commit }) {
        return new Promise(resolve => {
            Tb.getPopularCourses()
                .then(response => response.data)
                .then(payload => {
                    commit('setPopularCourses', payload)
                    resolve(payload)
                })
        })
    },
    getCourse({ commit }, id) {
        return new Promise((resolve) => {
            Tb.getCourse(id)
                .then(response => response.data)
                .then(result => {
                    commit('setCourse', result)
                    resolve(result)
                })
        })
    },
    getProgram({ commit }, slug) {
        return new Promise((resolve) => {
            Tb.getProgram(slug)
                .then(response => response.data)
                .then(result => {
                    commit('setProgram', result)
                    resolve(result)
                })
        })
    },
    getAllVacancies({ commit }, filter) {
        return new Promise((resolve) => {
            !filter ? commit('setVacancies', undefined) : false;
            Tb.getVacancies(filter)
                .then(response => response.data)
                .then(payload => {
                    commit('setVacancies', payload.results)
                    resolve(payload.count)
                })
        })
    },
    getVacancy({ commit }, id) {
        return new Promise(resolve => {
            Tb.getVacancy(id)
                .then(response => response.data)
                .then(result => {
                    commit('setVacancy', result)
                    resolve(result)
                })
        })
    },
    getAllSpecialisations({ commit }) {
        return new Promise(resolve => {
            Tb.getSpecialisation()
                .then(response => response.data)
                .then(result => {
                    commit('setSpecialisation', result)
                    resolve(result)
                })
        })
    },
    getAllTypesOfEmployment({ commit }) {
        return new Promise(resolve => {
            Tb.getTypeOfEmployment()
                .then(response => response.data)
                .then(result => {
                    commit('setAllTypes', result)
                    resolve(result)
                })
        })
    },
    getAllCompanyActivity({ commit }) {
        return new Promise(resolve => {
            Tb.getFieldActivity()
                .then(response => response.data)
                .then(result => {
                    commit('setFieldActivity', result)
                    resolve(result)
                })
        })
    },
    getAllSections({ commit, state }, params) {
        return new Promise(resolve => {
            if (!state.sessions) {
                Tb.getSections(params)
                    .then(response => response.data)
                    .then(result => {
                        commit('setSections', result)
                        resolve(result)
                    })
            }
        })
    },
    getAllTypeCourses({ commit, state }, params) {
        return new Promise(resolve => {
            if (!state.sessions) {
                Tb.getTypesCourses(params)
                    .then(response => response.data)
                    .then(result => {
                        commit('setTypesCourses', result)
                        resolve(result)
                    })
            }
        })
    },
    getAllTopics({ commit }) {
        return new Promise(resolve => {
            Tb.getTopics()
                .then(response => response.data)
                .then(data => {
                    commit('setTopics', data)
                    resolve(data)
                })
        })
    },
    startCourse({}, payload) {
        return new Promise(resolve => {
            Tb.startCourse(payload)
                .then(response => response.data)
                .then(result => resolve(result))
                .catch(error => console.log(error))
        })
    },
    startProgram({}, payload) {
        return new Promise(resolve => {
            Tb.startProgram(payload)
                .then(response => response.data)
                .then(result => resolve(result))
                .catch(error => console.log(error))
        })
    },
    startVacancy(_, payload) {
        // return new Promise(resolve => {
        return Tb.startVacancyXHR(payload)

        // Tb.startVacancy(payload)
        //     .then(response => response.data)
        //     .then(result => {
        //         resolve()
        //     })
        // })
    },
    getAllVideos({ commit }, params) {
        return new Promise(resolve => {
            Tb.getAllVideos(params)
                .then(response => response.data)
                .then(result => {
                    commit('setVideos', result)
                    resolve(result)
                })
        })
    },
    getBlogArticles({ commit }, params) {
        return new Promise(resolve => {
            Tb.getBlogArticles(params)
                .then(response => response.data)
                .then(result => {
                    commit('setBlogArticles', result)
                    resolve(result)
                })
        })
    },
    getSpecialProjects({ commit }, filter) {
        return new Promise(resolve => {
            Tb.getSpecialProjects(filter)
                .then(response => response.data)
                .then(result => {
                    commit('setSpecialProjects', result)
                    resolve(result)
                })
        })
    },
    getAllResumes({ commit }, uid) {
        return new Promise(resolve => {
            Tb.getAllResumes(uid)
                .then(response => response.data)
                .then(result => {
                    // if (state.resumes && state.resumes.size !== result.length || !state.resumes) {
                    commit('setResumes', result)
                    resolve(result)
                    // }
                })
        })
    },
    getResume({ commit }, {id, uid}) {
        return new Promise(resolve => {
            Tb.getResume({id, uid})
                .then(response => response.data)
                .then(result => {
                    // if (state.resumes && state.resumes.size !== result.length || !state.resumes) {
                    commit('setResume', result)
                    resolve(result)
                    // }
                })
        })
    },
    createResume({ dispatch }, resume) {
        return new Promise((resolve, reject) => {
            Tb.createResume(resume)
                .then(response => response.data)
                .then(result => {
                    resolve(result)
                    dispatch('getAllResumes')
                })
                .catch(error => {
                    if (new RegExp(/4\d\d/, 'g').test(error.response.status) && error.response.data)
                        reject({
                            field: error.response.data.error_field,
                            text: error.response.data.error_text
                        })
                })
        })
    },
    changeResume(_, { id, resume }) {
        return new Promise((resolve, reject) => {
            Tb.changeResume({ resume, id })
                .then(() => resolve(true))
                .catch(error => {
                    if (new RegExp(/4\d\d/, 'g').test(error.response.status) && error.response.data) reject(error.response.data.error_text)
                })
        })
    },
    doubleResume({ commit }, id) {
        return new Promise(resolve => {
            Tb.doubleResume(id)
                .then(response => {
                    response.data
                    commit('setResumes', [response.data])
                })
                .then(() => {
                    resolve(true)
                })
        })
    },
    removeWorkPlace({}, id) {
        return new Promise(resolve => {
            Tb.removeWorkPlace(id)
                .then(() => resolve(id))
        })
    },
    removeStudyPlace({}, {user_id, education_id}) {
        return new Promise(resolve => {
            Tb.removeStudyPlace({user_id, education_id})
                .then(() => resolve({user_id, education_id}))
        })
    },
    removeResume({ commit }, id) {
        return new Promise(resolve => {
            Tb.removeResume(id)
                .then(() => {
                    commit('removeResume', id)
                    resolve(true)
                })
        })
    },
    getActualProjects({ commit }, params) {
        return new Promise(resolve => {
            Tb.getActualProjects(params)
                .then(response => response.data)
                .then(payload => {
                    commit('setActualProjects', payload)
                    resolve(payload)
                })
        })
    }
}

const mutations = {
    setCourses(state, courses) {
        state.courses = courses
    },
    setPrograms(state, programs) {
        state.programs = programs
    },
    setPopularCourses(state, courses) {
        state.popularCourses = courses
    },
    setCourse(state, course) {
        state.course = course
    },
    setProgram(state, program) {
        state.program = program
    },
    setVacancies(state, vacancies) {
        state.vacancies = vacancies
    },
    setVacancy(state, vacancy) {
        state.vacancy = vacancy
    },
    setCompany(state, company) {
        state.company = company
    },
    setSections(state, sections) {
        state.sections = sections
    },
    setTypesCourses(state, types) {
        state.typesCourses = types
    },
    setVideos(state, videos) {
        state.videos = videos
    },
    setBlogArticles(state, blogArticles) {
        state.blogArticles = blogArticles
    },
    setSpecialProjects(state, projects) {
        state.projects = projects
    },
    setSpecialisation(state, specs) {
        state.specialisations = specs
    },
    setFieldActivity(state, activities) {
        state.field_activity = activities
    },
    setAllTypes(state, types) {
        state.types = types
    },
    setTopics(state, topics) {
        state.topics = topics
    },
    setResumes(state, resumes) {
        if (!state.resumes) {
            state.resumes = new Map()
        }
        resumes.forEach(resume => state.resumes.set(resume.id, resume))
    },
    setResume(state, resume) {
        state.resume = resume
    },
    removeResume(state, id) {
        state.resumes.delete(id)
    },
    setActualProjects(state, projects) {
        state.actualProjects = projects
    },
    setOnboardingAnswers(state, answers) {
        state.onboardingAnswers.push(answers)
        localStorage.setItem('onboardingAnswers', state.onboardingAnswers)
    },
}

const getters = {
    resumes: state => state.resumes,
    getPopularCourses: state => state.popularCourses,
    getAllCourses: state => state.courses,
    getCourse: state => state.course,
    getVacancy: state => state.vacancy,
    getCompany: state => state.company,
    getAllSections: state => state.sections,
    getAllTypesCourses: state => state.typesCourses,
    getAllVacancies: state => state.vacancies,
    getAllVideos: state => state.videos,
    getBlogArticles: state => state.blogArticles,
    getSpecialProjects: state => state.projects,
    getAllSpecs: state => state.specialisations,
    getAllActivities: state => state.field_activity,
    getAllTypes: state => state.types,
    getAllSubsection: state => state.topics,
    getAllResumeForResponse: state => {
        if (state.resumes) {
            let temp = []
            for (let value of state.resumes.values()) {
                temp.push({ id: value.id, name: value.preferred_position })
            }
            return temp;
        }
        return undefined
    },
    getProgram: state => state.program,
    getPrograms: state => state.programs.filter((program) => program.id !== state.program.id),
    getActualProjects: state => state.actualProjects,
}



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
