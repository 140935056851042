<template>
	<div class="recaptcha">
		<VueRecaptcha
			:sitekey="siteKey"
			:load-recaptcha-script="true"
			@verify="validate"
		/>
	</div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha'
import Validation from '@/api/recaptchaValidate'
export default {
    components: {VueRecaptcha},
    data () {
        return {
            siteKey: '6LdYFmEqAAAAANo_hgp1MWCX6PloQ_LBCkEFqhfb'
        }
    },
    methods: {
        validate (response) {
            this.$emit('validate', response)
            // Validation.validate({Response: response}).then(result => {
            //     this.$emit('validate', result.objectResult.success)
            // }).catch(error => console.log(error))
        }
    }
}
</script>

<style lang="less">
.recaptcha {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 78px
}
</style>
