<template>
	<div class="modal-content">
		<div
			v-if="payload.id"
			class="media"
		>
			<video
				ref="video"
				width="100%"
				height="100%"
				preload="none"
				autoplay
				loop
				muted
				controls
				playsinline
				controlsList="nodownload"
				:src="payload.src"
			/>
		</div>
		<div
			v-else
			class="media"
		>
			<video
				ref="video"
				width="100%"
				height="100%"
				preload="none"
				autoplay
				loop
				muted
				controls
				playsinline
				controlsList="nodownload"
				:src="payload.src | prodSrcImage"
			/>
		</div>
	</div>
</template>

<script>

import {mapActions} from 'vuex'

export default {
    data: () => ({
        isHalfProgress: false,
        statuses: {
            'opened': 0,
            'views_fifty': 0,
            'views_hundred': 0
        }
    }),
    computed: {
        payload() {
            return this.$store.state.modal.payload
        }
    },
    created() {
        if (this.payload.progress) {
            this.statuses.opened = 1
            this.video({id: this.payload.id, status: this.statuses})
        }
    },
    mounted() {
        this.$refs.video.play()
    },
    methods: {
        ...mapActions('cmn', {
            video: 'setVideoActivity'
        }),
        progress() {
            if (this.payload && this.payload.progress && !this.isHalfProgress && this.$refs.video.currentTime > this.$refs.video.duration / 2) {
                this.isHalfProgress = true
                this.statuses.opened = 1
                this.statuses.views_fifty = 1
                this.video({id: this.payload.id, status: this.statuses})
            }
        },
        finish() {
            if (this.payload.progress) {
                this.statuses.opened = 1
                this.statuses.views_fifty = 1
                this.statuses.views_hundred = 1
                this.video({id: this.payload.id, status: this.statuses})
            }
        }
    }
}
</script>
