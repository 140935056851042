<template>
	<div class="modal-content">
		<div class="achievement-modal">
			<img
				:src="task.icon"
				alt=""
				class="achievement-modal__pic"
			>
			<div class="title-block">
				{{ task.title_popup }}
			</div>
			<div class="achievement-modal__text">
				{{ task.text }}
			</div>
			<div class="achievement-modal__list">
				<div
					v-for="(item, index) in withoutGradebook"
					:key="index"
					class="achievement-modal__point"
				>
					<div
						class="achievement-modal__step"
						v-html="item.title"
					/>
					<div class="achievement-modal__ready">
						<div
							v-if="item.user_point !== false"
							class="achievement-modal__value"
						>
							{{ item.user_point }}/{{ item.point }}
						</div>
						<!--						<img-->
						<!--							v-if="item.finish"-->
						<!--							:src="'/img/icons/icon__check-achieved.svg' | prodSrcImage"-->
						<!--							alt="Готово"-->
						<!--							class="achievement-modal__icon"-->
						<!--						>-->
						<!--						<img-->
						<!--							v-else-->
						<!--							:src="'/img/icons/icon__check-unachieved.svg' | prodSrcImage"-->
						<!--							alt="Готово"-->
						<!--							class="achievement-modal__icon"-->
						<!--						>-->
					</div>
				</div>
				<div
					v-if="withGradebook"
					class="achievement-modal__point achievement-modal__point--upload"
				>
					<div
						class="achievement-modal__step"
						v-html="withGradebook.title"
					/>
					<label class="achievement-modal__upload">
						<input
							ref="file"
							type="file"
							multiple
							accept="application/msword, application/pdf, image/*"
							class="achievement-modal__upload-value"
							@change="selectFile"
						>
						Загрузить
					</label>
				</div>
				<div
					v-if="withGradebook && selectedImages && selectedImages.length"
					class="uploaded-files"
				>
					<div
						v-for="(image, index) in selectedImages"
						:key="index"
						class="uploaded-file"
					>
						<div class="uploaded-file__title title-mono">
							{{ image.name }}
						</div>
						<div
							class="uploaded-file__remove"
							@click="removeImage(image)"
						/>
						<div
							class="uploaded-file__progress"
							style="width: 100%;"
						/>
					</div>
				</div>
				<div
					v-if="withGradebook && withGradebookFiles && withGradebookFiles.length"
					class="uploaded-files"
				>
					<div
						v-for="image in withGradebookFiles"
						:key="image.id"
						class="uploaded-file"
					>
						<div class="uploaded-file__title title-mono">
							{{ image.name }}
						</div>
						<div
							class="uploaded-file__remove"
							@click="removeImage(image)"
						/>
						<div
							class="uploaded-file__progress"
							style="width: 100%;"
						/>
					</div>
				</div>
			</div>
			<div class="achievement-modal__footer">
				<button
					class="achievement-modal__navigation"
					@click="tasks[mainIndex - 1] ? modalOpen('ach-program', {task: tasks[mainIndex - 1], tasks, index: mainIndex - 1}) : ''"
				>
					<img
						v-if="tasks[mainIndex - 1]"
						:src="'/img/icons/icon__prev.svg' | prodSrcImage"
						alt="Предыдущий"
					>
					<img
						v-else
						:src="'/img/icons/icon__prev--disabled.svg' | prodSrcImage"
						alt="Предыдущий"
					>
				</button>
				<button
					class="button orange"
					@click="modalClose"
				>
					Отлично
				</button>
				<button
					class="achievement-modal__navigation"
					@click="tasks[mainIndex + 1] ? modalOpen('ach-program', {task: tasks[mainIndex + 1], tasks, index: mainIndex + 1}) : ''"
				>
					<img
						v-if="tasks[mainIndex + 1]"
						:src="'/img/icons/icon__next.svg' | prodSrcImage"
						alt="Предыдущий"
					>
					<img
						v-else
						:src="'/img/icons/icon__next--disabled.svg' | prodSrcImage"
						alt="Предыдущий"
					>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import User from '@/api/user';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'Prize',
    data: () => ({
        selectedImages: []
    }),
    computed: {
        ...mapGetters('scholar', {
            tasks: 'getTasks'
        }),
        task() {
            return this.tasks && this.tasks.find( item => item.title === this.$store.state.modal.payload.task.title)
        },
        mainIndex() {
            return this.$store.state.modal.payload.index;
        },
        withoutGradebook () {
            return this.task.task_set.filter( item => !item.is_gradebook)
        },
        withGradebook () {
            return this.task.task_set.find( item => item.is_gradebook)
        },
        withGradebookFiles () {
            return this.withGradebook.files.filter( item => !item.link_url)
        },
    },
    created() {
        this.getTasks('progress')
    },
    methods: {
        ...mapActions('scholar', {
            getTasks: 'getTasks'
        }),
        selectFile() {
            let files = this.$refs.file.files
            if (files.length > 1) {
                files.forEach( item => {
                    this.selectedImages.push(item)
                })
            } else if (files.length === 1) {
                this.selectedImages.push(this.$refs.file.files[0])
            }
            this.uploadImages()
        },
        removeImage (image) {
            if (!image.id) this.selectedImages.splice(this.selectedImages.indexOf(image), 1)
            else {
                User.deleteImage(image.id).then(() => this.withGradebook.files.splice(this.withGradebook.files.indexOf(image), 1))
            }
        },
        uploadImages() {
            const formData = new FormData();
            if (this.selectedImages.length) {
                for (let i = 0; i < this.selectedImages.length; i++) {
                    let file = this.selectedImages[i];
                    formData.append('file', file)
                }
            }
            User.uploadGradeFiles(formData)
                .then(res => {
                    console.log(res.statusText)
                })
                .catch(err => {
                    console.log(err.statusText)
                })
        }
    }
}
</script>
