import Re from '@/api/recruiter'

const state = {
    companies: undefined,
    vacancies: undefined,
    responses: undefined,
    vacancy: undefined,
    selectedVacancies: undefined,
    selectedResponses: undefined,
    isLoaded: false,
}

const actions = {
    async getAllCompanies({ commit }, filter) {
        return new Promise((resolve) => {
            Re.getAllCompanies(filter)
                .then(response => response.data)
                .then(result => {
                    commit('setAllCompanies', result)
                    resolve(result)
                })
        })
    },
    async getAllVacancies({ commit }, filter) {
        return new Promise(resolve => {
            Re.getAllVacancies(filter)
                .then(response => response.data)
                .then(result => {
                    commit('setAllVacancies', result)
                    resolve(result)
                })
        })
    },
    async createVacancy({ commit }, data) {
        try {
            let response = await Re.createVacancy(data)

            if (response.status === 201) {
                commit('addNewVacancy', response.data)
            } else {
                console.dir(response)
            }
        } catch (error) {
            console.dir(error)
            throw new Error(error.response.data.error_text)
        }
    },
    async editVacancy({ commit, dispatch }, { id, data }) {
        try {
            let response = await Re.editVacancy({ id, data })
            if (response.status === 200) {
                commit('editVacancy', response.data)
                dispatch('getAllVacancies')
                return response.data
            }
        } catch (error) {
            console.dir(error)
            throw new Error(error.response.data.error_text)
        }
    },
    async editVacancyStatus({ commit }, objects_update) {
        return new Promise(resolve => {
            Re.editVacancyStatus(objects_update)
                .then(response => {
                    return response.status
                })
                .then(result => {
                    commit('editVacancyStatus', result)
                    resolve(result)
                })
        })
    },
    async removeVacancy({ commit }, { id, ids }) {
        try {
            if (id) {
                let response = await Re.removeVacancy(id)

                if (response.status === 200) {
                    commit('removeVacancy', { id, ids })
                }
            } else if (ids) {
                let response = await Re.removeVacancies(ids)

                if (response.status === 200) {
                    commit('removeVacancy', { id, ids })
                }
            }
        } catch (error) {
            throw new Error(error)
        }
    },
    async getAllResponses({ commit }, filter) {
        return new Promise(resolve => {
            Re.getAllResponses(filter)
                .then(response => response.data)
                .then(result => {
                    commit('setAllResponses', result)
                    resolve(result)
                })
        })
    },
    async changeResponse({ dispatch }, { id, data }) {
        try {
            let response = await Re.changeResponse({ id, data })

            if (response.status === 200) {
                dispatch('getAllResponses')
                return response.data
            }
        } catch (error) {
            console.dir(error)
            throw new Error(error.response.data.error_text)
        }
    },
    async changeResponseStatus({ commit }, objects_update) {
        commit('setIsLoaded', true)
        return new Promise(resolve => {
            Re.changeResponseStatus(objects_update)
                .then(response => {
                    return response.status
                })
                .then(result => {
                    commit('changeResponseStatus', result)
                    resolve(result)
                })
        })
    },
    async removeResponse({ commit }, { id, ids }) {
        try {
            if (id) {
                let response = await Re.removeResponse(id)

                if (response.status === 200) {
                    commit('removeResponse', { id, ids })
                }
            } else if (ids) {
                let response = await Re.removeResponses(ids)

                if (response.status === 200) {
                    commit('removeResponse', { id, ids })
                }
            }
        } catch (error) {
            throw new Error(error)
        }
    },
    setSelectedVacancies({commit}, objects_update) {
        commit('setSelectedVacancies', objects_update)
    },
    setSelectedResponses({commit}, responses) {
        commit('setSelectedResponses', responses)
    },
}

const mutations = {
    setAllCompanies(state, companies) {
        state.companies = companies
    },
    setAllVacancies(state, vacancies) {
        state.vacancies = vacancies
    },
    setAllResponses(state, responses) {
        state.responses = responses
    },
    addNewVacancy(state, vacancy) {
        state.vacancy = vacancy
    },
    editVacancy(state, vacancy) {
        if (state.vacancies) {
            state.vacancies.results[state.vacancies.results.findIndex(x => x.id === vacancy.id)] = vacancy
        }
    },
    editVacancyStatus(state, vacancy) {
        if (state.vacancies) {
            state.vacancies.results[state.vacancies.results.findIndex(x => x.id === vacancy.id)] = vacancy
        }
    },
    removeVacancy(state, { id, ids }) {
        if (id) {
            state.vacancies = state.vacancies.results.map(x => ({ ...x, active: x.id === id ? false : x.active }))
        } else if (ids) {
            ids.forEach(x => state.vacancies.results[state.vacancies.results.findIndex(y => y.id === x)].active = false)
        }
    },
    removeResponse(state, { id, ids }) {
        if (id) {
            state.responses = state.responses.results.map(x => ({ ...x, active: x.id === id ? false : x.active }))
        } else if (ids) {
            ids.forEach(x => state.responses.results[state.responses.results.findIndex(y => y.id === x)].active = false)
        }
    },
    setSelectedVacancies(state, objects_update) {
        state.selectedVacancies = objects_update;
    },
    setSelectedResponses(state, responses) {
        state.selectedResponses = responses;
    },
    setIsLoaded(state, responses) {
        state.isLoaded = responses
    }
}

const getters = {
    getCompanies: state => state.companies,
    getVacancies: state => state.vacancies,
    getResponses: state => state.responses,
    getSelectedVacancies: state => state.selectedVacancies,
    getSelectedResponses: state => state.selectedResponses,
    getIsLoaded: state => state.isLoaded,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
