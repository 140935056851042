<template>
	<div>
		<div
			v-if="!isLogged"
			class="action"
		>
			<button
				class="button"
				@click="modalOpen('regIn', 'test')"
			>
				Зарегистрироваться
			</button>
		</div>

		<Banner />

		<Audience />

		<Steps />

		<!--		<Prizes />-->

		<!--		<Slider />-->

		<!--		<Subscribe />-->

		<Skills />

		<!--		<Regulations />-->

		<Questions />
	</div>
</template>

<script>
import {mapGetters} from 'vuex';
import Banner from '@/components/events/scholarship/Banner';
import Audience from '@/components/events/scholarship/Audience';
import Prizes from '@/components/events/scholarship/Prizes';
import Questions from '@/components/events/scholarship/Questions';
import Regulations from '@/components/events/scholarship/Regulations';
import Skills from '@/components/events/scholarship/Skills';
import Slider from '@/components/events/scholarship/Slider';
import Steps from '@/components/events/scholarship/Steps';
import Subscribe from '@/components/events/scholarship/Subscribe';

export default {
    name: 'Scholarship',
    components: {
        Banner,
        Audience,
        Questions,
        Skills,
        Steps,
    },
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    }
}
</script>
