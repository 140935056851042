var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.slider)?_c('section',{directives:[{name:"wow",rawName:"v-wow"}],staticClass:"section-simple actual wow fadeInUp",attrs:{"data-wow-delay":"0.2s"}},[_c('div',{staticClass:"content"},[_c('h2',{staticClass:"section-title"},[_vm._v(" Актуально прямо сейчас ")]),_c('div',{staticClass:"actual__slider-wrap",on:{"click":_vm.pauseActualSlider}},[_c('VueSlickCarousel',_vm._b({ref:"actualCarousel",staticClass:"actual-slider",class:{'active-actual-button': _vm.isStoppedActual},on:{"swipe":_vm.pauseActualSlider}},'VueSlickCarousel',_vm.actualSliderSettings,false),_vm._l((_vm.slider),function(slide){return _c('div',{key:slide.id},[(slide.show_on_specialist_landing)?_c('div',[(slide.video)?_c('div',{staticClass:"actual-slider__slide",style:(_vm._f("bgrDynamicImageCover")(slide.image_slider[0].image))},[_c('div',{staticClass:"actual-slider__video"},[_c('video',{attrs:{"autoplay":"autoplay","muted":"muted","loop":"loop"},domProps:{"muted":true}},[_c('source',{attrs:{"src":slide.video.file,"type":"video/mp4"}})])]),_c('div',{staticClass:"actual-slider__content"},[_c('h2',{staticClass:"title-section",domProps:{"innerHTML":_vm._s(slide.name)}}),_c('div',{staticClass:"actual-slider__text",domProps:{"innerHTML":_vm._s(slide.description)}}),(
										slide.button_url &&
											slide.open_button_url_in_another_window
									)?_c('a',{staticClass:"button",attrs:{"href":slide.button_url,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(slide.button_name))]):_vm._e(),(
										slide.button_url &&
											!slide.open_button_url_in_another_window
									)?_c('a',{staticClass:"button",attrs:{"href":slide.button_url},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(slide.button_name))]):_vm._e(),(
										slide.button === 'Регистрация' &&
											!_vm.isLogged
									)?_c('button',{staticClass:"button",on:{"click":function($event){$event.preventDefault();return _vm.modalOpen('regIn')}}},[_vm._v(" Регистрация ")]):_vm._e()])]):_c('div',{staticClass:"actual-slider__slide",style:(_vm._f("bgrDynamicImageCover")(slide.image_slider[0].image))},[_c('div',{staticClass:"actual-slider__content"},[_c('h2',{staticClass:"title-section",domProps:{"innerHTML":_vm._s(slide.name)}}),_c('div',{staticClass:"actual-slider__text",domProps:{"innerHTML":_vm._s(slide.description)}}),(
										slide.button_url &&
											slide.open_button_url_in_another_window
									)?_c('a',{staticClass:"button",attrs:{"href":slide.button_url,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(slide.button_name))]):_vm._e(),(
										slide.button_url &&
											!slide.open_button_url_in_another_window
									)?_c('a',{staticClass:"button",attrs:{"href":slide.button_url},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(slide.button_name))]):_vm._e(),(
										slide.button === 'Регистрация' &&
											!_vm.isLogged
									)?_c('button',{staticClass:"button",on:{"click":function($event){$event.preventDefault();return _vm.modalOpen('regIn')}}},[_vm._v(" Регистрация ")]):_vm._e()])])]):_vm._e()])}),0)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }