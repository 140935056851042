<template>
	<div class="modal-content">
		<div class="result">
			<div class="result__body">
				<div class="result__header">
					<div class="result__title">
						{{ payload.item.competence.name }}
					</div>
				</div>
				<div
					class="progress-result"
					:class="{'progress-result--green': payload.item.score > 59,
						'progress-result--red': payload.item.score < 28,
						'progress-result--yellow': payload.item.score > 29 && payload.item.score < 60}"
				>
					<div class="progress-result__value">
						{{ payload.item.score }}%
					</div>
					<div class="progress-result__track">
						<div
							class="progress-result__line"
							:style="{'width': payload.item.score + '%'}"
						/>
						<div
							class="progress-result__self"
							:style="{'right': 100 - payload.item.self_score + '%'}"
						>
							{{ payload.item.self_score }}% - самооценка
						</div>
						<div
							class="progress-result__group"
							:style="{'right': 100 - payload.item.group_score + '%'}"
						>
							{{ payload.item.group_score }}% - оценка по группе
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="payload.item.competence.description"
				class="result__text"
			>
				{{ payload.item.competence.description }}
			</div>
			<div class="recommendation">
				<div class="recommendation__title">
					Рекомендуемые курсы
				</div>
				<div class="recommendation__list">
					<span
						v-for="course in payload.result.survay.courses"
						:key="course.id"
					>
						<router-link
							target="_blank"
							class="recommendation-item"
							:to="`/courses/${course.slug}-${course.id}`"
						>{{ course.name }}</router-link>
					</span>
				</div>
			</div>
			<div class="result__footer">
				<button
					class="button orange"
					@click.stop="modalClose"
				>
					Отлично
				</button>
			</div>
		</div>
	</div>
</template>

<script>

export default {
    name: 'CompetenceMore',
    computed: {
        payload() {
            return this.$store.state.modal.payload;
        }
    }
}
</script>

<style lang="less" scoped>
.result {
    border: none !important;
    padding: 0 !important;
}
</style>
