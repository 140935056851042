<template>
	<div
		class="check-notification"
		:class="{ show: isActive }"
	>
		<div class="inner">
			<button
				class="closer"
				@click="close"
			/>
			<div
				class="extra"
				v-html="mainTextHTML"
			/>
			<!--<button class="downloader" v-if="textDownloader">Скачать {{ textDownloader }}</button>-->
			<template v-if="type === 'vac'">
				<button
					v-if="env !== 'actual'"
					class="remover"
					@click="actual"
				>
					Актуализировать
				</button>
				<button
					v-if="env !== 'draft'"
					class="remover"
					@click="draft"
				>
					В черновик
				</button>
				<button
					v-if="env !== 'archive'"
					class="remover"
					@click="archive"
				>
					Архивировать
				</button>
			</template>
			<template v-else-if="type === 'resp'">
				<button
					v-if="env !== 'new'"
					class="remover"
					@click="fresh"
				>
					В новые
				</button>
				<button
					v-if="env !== 'under_consideration'"
					class="remover"
					@click="watch"
				>
					На рассмотрение
				</button>
				<button
					v-if="env !== 'invitation'"
					class="remover"
					@click="invite"
				>
					Пригласить
				</button>
				<button
					v-if="env !== 'refusal'"
					class="remover"
					@click="refusal"
				>
					Отказать
				</button>
			</template>
		</div>
	</div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    computed: {
        ...mapGetters('ntf', [
            'count',
            'env'
        ]),
        ...mapGetters('rec', {
            selectedResponses: 'getSelectedResponses',
            selectedVacancies: 'getSelectedVacancies',
        }),
        type() {
            return this.$store.state.ntf.type
        },
        payload() {
            return this.$store.state.ntf.payload
        },
        mainTextHTML() {
            if (this.count > 1) {
                if (this.type === 'vac') {
                    return `Выбраны <span>${this.count} вакансии</span>`
                } else if (this.type === 'resp') {
                    return `Выбраны <span>${this.count} отклика</span>`
                }
            } else {
                if (this.type === 'vac') {
                    return 'Выбрана <span>1 вакансия</span>'
                } else if (this.type === 'resp') {
                    return 'Выбран <span>1 отклик</span>'
                }    
            }

            return ''
        },
        isActive() {
            return this.$store.state.ntf.isActive
        },
        textDownloader() {
            if (this.type === 'vac') {
                return 'вакансию'
            }

            return ''
        }
    },
    methods: {
        ...mapActions('rec', ['setSelectedVacancies', 'setSelectedResponses']),
        ...mapMutations('ntf', [
            'setSubmit',
            'setActive',
        ]),
        actual() {
            this.setActive(false)
            this.$store.dispatch('rec/editVacancyStatus', this.changeVacanciesStatus('actual'))
                .then(() => this.hideNotify())
                .then(() => this.setSubmit(true))
                .catch(() => {
                    this.showNotify('vac', {
                        count: this.count,
                        env: this.env,
                    })
                })
        },
        draft() {
            this.setActive(false)
            this.$store.dispatch('rec/editVacancyStatus', this.changeVacanciesStatus('draft'))
                .then(() => this.hideNotify())
                .then(() => this.setSubmit(true))
                .catch(() => {
                    this.showNotify('vac', {
                        count: this.count,
                        env: this.env,
                    })
                })
        },
        archive() {
            this.setActive(false)
            this.$store.dispatch('rec/editVacancyStatus', this.changeVacanciesStatus('archive'))
                .then(() => this.hideNotify())
                .then(() => this.setSubmit(true))
                .catch(() => {
                    this.showNotify('vac', {
                        count: this.count,
                        env: this.env,
                    })
                })
        },
        fresh() {
            this.setActive(false)
            this.$store.dispatch('rec/changeResponseStatus', this.changeResponseStatus('new'))
                .then(() => this.hideNotify())
                .then(() => this.setSubmit(true))
                .catch(() => {
                    this.showNotify('resp', {
                        count: this.count,
                        env: this.env,
                    })
                })
        },
        watch() {
            this.setActive(false)
            this.$store.dispatch('rec/changeResponseStatus', this.changeResponseStatus('under_consideration'))
                .then(() => this.hideNotify())
                .then(() => this.setSubmit(true))
                .catch(() => {
                    this.showNotify('resp', {
                        count: this.count,
                        env: this.env,
                    })
                })
        },
        invite() {
            this.setActive(false)
            this.$store.dispatch('rec/changeResponseStatus', this.changeResponseStatus('invitation'))
                .then(() => this.hideNotify())
                .then(() => this.setSubmit(true))
                .catch(() => {
                    this.showNotify('resp', {
                        count: this.count,
                        env: this.env,
                    })
                })
        },
        refusal() {
            this.setActive(false)
            this.$store.dispatch('rec/changeResponseStatus', this.changeResponseStatus('refusal'))
                .then(() => this.hideNotify())
                .then(() => this.setSubmit(true))
                .catch(() => {
                    this.showNotify('resp', {
                        count: this.count,
                        env: this.env,
                    })
                })
        },
        changeVacanciesStatus(status) {
            return this.selectedVacancies.map(x => {
                return {id: x, status: status}
            })
        },
        changeResponseStatus(status) {
            return this.selectedResponses.map(x => {
                return {id: x, status: status}
            })
        },
        close() {
            this.setSelectedVacancies([])
            this.setSelectedResponses([])
            this.hideNotify()
        }
    }
}
</script>
