<template>
	<div>
		<ul class="breadcrumbs">
			<li
				v-for="(crumb, index) in sortedCrumbs"
				:key="index"
			>
				<a
					:href="crumb.link"
					@click.prevent="linkTo(crumb.link)"
				>{{ crumb.name }}</a>
			</li>
			<li>
				<span v-if="pageName">{{ pageName }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'Breadcrumb',
    props: {
        pageName: {
            type: String,
            default: null
        },
        additionalPages: {
            type: Array,
            default: null
        }
    },
    data () {
        return {
            breadcrumbList: []
        }
    },
    computed: {
        ...mapGetters( 'user', {
            userID: 'userID'
        }),
        user() {
            return this.$store.state.user.user
        },
        isRecruiter() {
            return this.user && this.$store.getters['user/isRecruiter']
        },
        sortedCrumbs() {
            if (this.$route.path === `/profile/${this.$route.params.id}` && this.$route.params.id === this.userID) {
                if (this.$route.query.courses) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список вакансий' && item.name !== 'Список компаний' && item.name !== 'Избранные кандидаты' && item.name !== 'Резюме' && item.name !== 'Сертификаты' && item.name !== 'Отклики' && item.name !== 'Избранные вакансии' && item.name !== 'Программы' && item.name !== this.pageName && !item.link.includes('?response=true&action=watch&id=') && !item.link.includes('?resume=true&action=watch&id='))
                }
                if (this.$route.query.resumes) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список вакансий' && item.name !== 'Список компаний' && item.name !== 'Избранные кандидаты' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Отклики' && item.name !== 'Избранные вакансии' && item.name !== 'Программы' && item.name !== this.pageName && !item.link.includes('?response=true&action=watch&id='))
                }
                if (this.$route.query.certificates) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список вакансий' && item.name !== 'Список компаний' && item.name !== 'Избранные кандидаты' && item.name !== 'Курсы' && item.name !== 'Отклики' && item.name !== 'Избранные вакансии'&& item.name !== 'Программы' && item.name !== this.pageName && !item.link.includes('?response=true&action=watch&id='))
                }
                if (this.$route.query.resume) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список вакансий' && item.name !== 'Список компаний' && item.name !== 'Избранные кандидаты' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Отклики' && item.name !== 'Избранные вакансии' && item.name !== 'Программы' && item.name !== this.pageName && !item.link.includes('?response=true&action=watch&id='))
                }
                if (this.$route.query.responses) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список вакансий' && item.name !== 'Список компаний' && item.name !== 'Избранные кандидаты' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Резюме' && item.name !== 'Избранные вакансии' && item.name !== 'Программы' && item.name !== this.pageName && !item.link.includes('?resume=true&action=watch&id=') && !item.link.includes('?response=true&action=watch&id='))
                }
                if (this.$route.query.response) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список вакансий' && item.name !== 'Список компаний' && item.name !== 'Избранные кандидаты' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Резюме' && item.name !== 'Избранные вакансии' && item.name !== 'Программы' && item.name !== this.pageName && !item.link.includes('?resume=true&action=watch&id='))
                }
                if (this.$route.query.favoritesVacancies) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список вакансий' && item.name !== 'Список компаний' && item.name !== 'Избранные кандидаты' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Резюме' && item.name !== 'Отклики' && item.name !== 'Программы' && item.name !== this.pageName && !item.link.includes('?resume=true&action=watch&id='))
                }
                if (this.$route.query.programs) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список вакансий' && item.name !== 'Список компаний' && item.name !== 'Избранные кандидаты' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Резюме' && item.name !== 'Отклики' && item.name !== 'Избранные вакансии' && item.name !== this.pageName && !item.link.includes('?resume=true&action=watch&id='))
                }
                if (Object.keys(this.$route.query).length === 0) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item =>
                        item.name !== 'Резюме' &&
                        item.name !== 'Отклики' &&
                        item.name !== 'Курсы' &&
                        item.name !== 'Программы' &&
                        item.name !== 'Сертификаты' &&
                        item.name !== 'Список вакансий' &&
                        item.name !== 'Список компаний' &&
                        item.name !== 'Избранные кандидаты' &&
                        item.name !== this.pageName &&
                        !item.link.includes('?response=true&action=watch&id=') &&
                        !item.link.includes('?resume=true&action=watch&id=') &&
                        !item.link.includes('?company=true&action=watch&id=') &&
                        !item.link.includes('?vacancy=true&action=watch&id=') &&
                        !item.link.includes('?response=true&action=watch&id='))
                }
            }
            else if (this.$route.path === '/profile') {
                if (this.$route.query.companies) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список вакансий' && item.name !== 'Отклики' && item.name !== 'Избранные кандидаты' && item.name !== 'Резюме' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Избранные вакансии' && item.name !== 'Доступ к данным' && item.name !== this.pageName && !item.link.includes('profile?company=true&action=watch&id=') && !item.link.includes('profile?vacancy=true&action=watch&id=') && !item.link.includes('profile?response=true&action=watch&id='))
                }
                if (this.$route.query.company) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список вакансий' && item.name !== 'Отклики' && item.name !== 'Избранные кандидаты' && item.name !== 'Резюме' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Избранные вакансии' && item.name !== 'Доступ к данным' && item.name !== this.pageName && !item.link.includes('profile?vacancy=true&action=watch&id=') && !item.link.includes('profile?response=true&action=watch&id='))
                }
                if (this.$route.query.vacancies) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список компаний' && item.name !== 'Отклики' && item.name !== 'Избранные кандидаты' && item.name !== 'Резюме' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Избранные вакансии' && item.name !== 'Доступ к данным' && item.name !== this.pageName && !item.link.includes('profile?company=true&action=watch&id=') && !item.link.includes('profile?vacancy=true&action=watch&id=') && !item.link.includes('profile?response=true&action=watch&id='))
                }
                if (this.$route.query.vacancy) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список компаний' && item.name !== 'Отклики' && item.name !== 'Избранные кандидаты' && item.name !== 'Резюме' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Избранные вакансии' && item.name !== 'Доступ к данным' && item.name !== this.pageName && !item.link.includes('profile?company=true&action=watch&id=') && !item.link.includes('profile?response=true&action=watch&id='))
                }
                if (this.$route.query.responses) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список компаний' && item.name !== 'Список вакансий' && item.name !== 'Избранные кандидаты' && item.name !== 'Резюме' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Избранные вакансии' && item.name !== 'Доступ к данным' && item.name !== this.pageName && !item.link.includes('profile?company=true&action=watch&id=') && !item.link.includes('profile?vacancy=true&action=watch&id=') && !item.link.includes('profile?response=true&action=watch&id='))
                }
                if (this.$route.query.response) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список компаний' && item.name !== 'Список вакансий' && item.name !== 'Избранные кандидаты' && item.name !== 'Резюме' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Избранные вакансии' && item.name !== 'Доступ к данным' && item.name !== this.pageName && !item.link.includes('profile?company=true&action=watch&id=') && !item.link.includes('profile?vacancy=true&action=watch&id='))
                }
                if (this.$route.query.favorites) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список компаний' && item.name !== 'Список вакансий' && item.name !== 'Отклики' && item.name !== 'Резюме' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Избранные вакансии' && item.name !== 'Доступ к данным' && item.name !== this.pageName && !item.link.includes('profile?company=true&action=watch&id=') && !item.link.includes('profile?vacancy=true&action=watch&id=') && !item.link.includes('profile?response=true&action=watch&id='))
                }
                if (this.$route.query.access) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item => item.name !== 'Список компаний' && item.name !== 'Список вакансий' && item.name !== 'Отклики' && item.name !== 'Резюме' && item.name !== 'Сертификаты' && item.name !== 'Курсы' && item.name !== 'Избранные вакансии' && item.name !== 'Программы' && item.name !== this.pageName && !item.link.includes('profile?company=true&action=watch&id=') && !item.link.includes('profile?vacancy=true&action=watch&id=') && !item.link.includes('profile?response=true&action=watch&id='))
                }
                if (Object.keys(this.$route.query).length === 0) {
                    return this.breadcrumbList && this.breadcrumbList.filter(item =>
                        item.name !== 'Резюме' &&
                        item.name !== 'Отклики' &&
                        item.name !== 'Курсы' &&
                        item.name !== 'Программы' &&
                        item.name !== 'Сертификаты' &&
                        item.name !== 'Список вакансий' &&
                        item.name !== 'Список компаний' &&
                        item.name !== 'Избранные кандидаты' &&
                        item.name !== this.pageName &&
                        !item.link.includes('?response=true&action=watch&id=') &&
                        !item.link.includes('?resume=true&action=watch&id=') &&
                        !item.link.includes('?company=true&action=watch&id=') &&
                        !item.link.includes('?vacancy=true&action=watch&id=') &&
                        !item.link.includes('?response=true&action=watch&id='))
                }
            }
            return this.breadcrumbList && this.breadcrumbList.filter(crumb => crumb.name !== this.pageName )
        }
    },
    watch: {
        '$route' () {
            this.updateList()
        }
    },
    mounted () {
        this.updateList()
    },
    methods: {
        ...mapActions('user', [
            'changePageName'
        ]),
        updateList () {
            this.breadcrumbList = []
            this.breadcrumbList = this.$route.meta.breadcrumb
            if (this.additionalPages) this.breadcrumbList.push(...this.additionalPages)
            let resArr = [];
            this.breadcrumbList.forEach(function(item){
                let i = resArr.findIndex(x => x.name == item.name);
                if(i <= -1){
                    resArr.push(item);
                }
            });
            this.breadcrumbList = resArr
        },
        linkTo(url = '') {
            if (url === `/profile/${this.$route.params.id}`) this.changePageName('Профиль')
            if (url === `/profile/${this.$route.params.id}?companies=true`) this.changePageName('Компании')
            if (url === `/profile/${this.$route.params.id}?courses=true`) this.changePageName('Курсы')
            if (url === `/profile/${this.$route.params.id}?programs=true`) this.changePageName('Программы')
            if (url === `/profile/${this.$route.params.id}?vacancies=true`) this.changePageName('Вакансии')
            if (url === `/profile/${this.$route.params.id}?resumes=true`) this.changePageName('Резюме')
            if (url === `/profile/${this.$route.params.id}?responses=true`) this.changePageName('Отклики')
            if (url === `/profile/${this.$route.params.id}?favorites=true`) this.changePageName('Избранные кандидаты')
            if (url === `/profile/${this.$route.params.id}?favoritesVacancies=true`) this.changePageName('Избранные вакансии')
            if (this.$route.fullPath !== url) this.$router.push(url)
        },
    }
}
</script>

<style lang="less">
@import "../assets/styles/breadcrumbs";
</style>
