var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",class:_vm.PageClass},[(
			!_vm.$route.meta.isUnivers &&
				!_vm.$route.meta.isMaintenance &&
				!_vm.$route.meta.isLandingUr &&
				!_vm.$route.meta.isChristmas)?_c('lift-header',{ref:"header"}):_vm._e(),_c('main',{staticClass:"body"},[_c('transition',{attrs:{"name":"slide-fade"}}),_c('transition',{attrs:{"name":"slide-fade"}}),(!_vm.$route.meta.isLandingUr)?_c('h1',{staticClass:"seo-title",attrs:{"id":"seo-title"}},[_vm._v(" >Образовательная инициатива Благотворительного фонда «Система» ")]):_vm._e(),_c('router-view')],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isActiveModal)?_c('modal'):_vm._e()],1),_c('notify'),_c('lift-footer'),_c('transition-group',{attrs:{"name":"slide-up","tag":"div"}},[(
				_vm.isShowCookie &&
					!_vm.$route.meta.isUnivers &&
					!_vm.$route.meta.isMaintenance &&
					!_vm.$route.meta.isLiftNoRefusal &&
					!_vm.$route.meta.isLandingUr &&
					!_vm.$route.meta.isSchool &&
					!_vm.$route.meta.isUnsubscribed
			)?_c('div',{key:"cookie",staticClass:"cookies"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"text dark"},[_c('p',[_vm._v(" В целях обеспечения функционирования сайта и проведения статистических исследований и обзоров осуществляется автоматический сбор информации о файлах cookie и IP-адресах. Нажимая кнопку «Согласен» или продолжая использовать сайт, Вы даете согласие на использование данных технологий для нашего сайта. ")])])]),_c('div',{staticClass:"box"},[_c('button',{staticClass:"button",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.acceptCookies.apply(null, arguments)}}},[_vm._v(" Согласен ")])])])])]):_vm._e(),(
				_vm.isUserActivation &&
					!_vm.$route.meta.isLiftNoRefusal &&
					!_vm.$route.meta.isLandingUr &&
					!_vm.$route.meta.name === 'Онбординг' &&
					!_vm.$route.meta.isUnsubscribed &&
					!_vm.$route.meta.isCatalog
			)?_c('div',{key:"alert_1",staticClass:"alert"},[_c('div',{staticClass:"alert-close",on:{"click":function($event){return _vm.closeAlert(1)}}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"alert-text"},[_c('img',{staticClass:"alert-icon",attrs:{"src":_vm._f("prodSrcImage")('/img/icons/icon-alert-warning.svg'),"alt":""}}),_c('div',{staticClass:"text dark sm"},[_c('p',[_vm._v(" Для того, чтобы мы могли рекомендовать вам наиболее подходящие обучающие материалы и вакансии, пожалуйста, заполните свой профиль. ")])])])]),_c('div',{staticClass:"box"},[_c('button',{staticClass:"button orange",on:{"click":function($event){$event.stopPropagation();return _vm.fillProfile.apply(null, arguments)}}},[_vm._v(" Заполнить ")])])])])]):_vm._e(),(
				_vm.isUserCreation &&
					!_vm.$route.meta.isLiftNoRefusal &&
					!_vm.$route.meta.isLandingUr &&
					!_vm.$route.meta.name === 'Онбординг' &&
					!_vm.$route.meta.isUnsubscribed &&
					!_vm.$route.meta.isCatalog)?_c('div',{key:"alert_2",staticClass:"alert"},[_c('div',{staticClass:"alert-close",on:{"click":function($event){return _vm.closeAlert(2)}}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"alert-text"},[_c('img',{staticClass:"alert-icon",attrs:{"src":_vm._f("prodSrcImage")('/img/icons/icon-alert-warning.svg'),"alt":""}}),_c('div',{staticClass:"text dark sm"},[_c('p',[_vm._v(" Для того, чтобы вы могли создавать резюме и откликаться на вакансии, пожалуйста, заполните свой профиль. ")])])])]),_c('div',{staticClass:"box"},[_c('button',{staticClass:"button orange",on:{"click":function($event){$event.stopPropagation();return _vm.fillProfile.apply(null, arguments)}}},[_vm._v(" Заполнить ")])])])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }