<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		v-if="banner"
		class="banner"
		:style="banner.main_banner_image | bgrDynamicImageCover"
	>
		<div class="banner__content">
			<h2 class="title-mono">
				{{ eventType === 'event' ? 'Мероприятие' :
					eventType === 'survey' ? 'Тест' :
					eventType === 'contest' ? 'Конкурс'
					: 'Кейс' }}
			</h2>
			<h1 class="title-section">
				{{ banner.main_banner_title }}
			</h1>
			<div
				class="description"
				v-html="banner.main_banner_description"
			/>
			<ConstructorButton
				:btn-name="banner.main_banner_button_text"
				:btn-link="banner.main_banner_button_link"
				:btn-color="banner.main_banner_button_color"
				:actual-tests="actualTests"
				:is-apply-contest="isApplyContest"
				:btn-variations="btnVariations"
				:is-active="isActiveBtn"
				:is-disable-profile-check="isDisableProfileCheck"
			/>
		</div>
	</div>
</template>

<script>
import ConstructorButton from '@/components/ConstructorComponents/ConstructorButton';
export default {
    name: 'Banner',
    components: {
        ConstructorButton
    },
    props: {
        banner: {
            type: Object,
            default: null
        },
        eventType: {
            type: String,
            default: null
        },
        isApplyContest: {
            type: Boolean,
            default: false
        },
        isDisableProfileCheck: {
            type: Boolean,
            default: false
        },
        isActiveBtn: {
            type: Boolean,
            default: false
        },
        actualTests: {
            type: Array,
            default: null
        },
        btnVariations: {
            type: Object,
            default: null
        }
    }
}
</script>
