<template>
	<section
		v-if="photoInfo"
		class="section section-images"
	>
		<div
			class="content"
			@click="pauseSlider"
		>
			<VueSlickCarousel
				ref="imagesSlider"
				class="images-slider"
				:class="{'active-button': isStopped}"
				v-bind="sliderSettings"
				@swipe="pauseSlider"
			>
				<div
					v-for="(item, index) in photoInfo"
					:key="index"
				>
					<div
						class="images-slider__slide"
						:style="item.photo | bgrDynamicImageCover"
					/>
				</div>
			</VueSlickCarousel>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Images',
    props: {
        photoInfo: {
            type: Array,
            default: null
        }
    },
    data: () => ({
        isStopped: false,
        sliderSettings: {
            arrows: true,
            fade: true,
            focusOnSelect: true,
            waitForAnimate: false,
            infinite: true,
            easing: 'swing',
            dots: true,
            autoplay: true,
            pauseOnFocus: true,
            pauseOnHover: false,
            pauseOnDotsHover: false,
            speed: 500,
            autoplaySpeed: 3300,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        arrows: false
                    }
                }
            ]
        },
    }),
    methods: {
        pauseSlider() {
            this.$refs.imagesSlider.pause()
            this.isStopped = true
        },
    }
}
</script>

<style lang="less">
.page-event .images-slider.active-button .slick-dots li.slick-active button:after{
    width: 100%;
    animation: none;
}
</style>
