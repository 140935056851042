import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import AsyncComputed from 'vue-async-computed'
import App from './App.vue'
import store from './store'
import router from './router'
import SlideUpDown from 'vue-slide-up-down'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueMobileDetection from 'vue-mobile-detection';

import './assets/styles/reset.less';
import './assets/styles/animations.less';

import './assets/styles/layout.less';
import './assets/styles/styles.less';
import './assets/styles/modal.less';
import './assets/styles/scoped.less';


import Select from './components/Select';
import Select2 from './components/selector';

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

import VueNumber from 'vue-number-animation'
Vue.use(VueNumber)

import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);

import vWow from 'v-wow'
Vue.use(vWow)

import VueTagManager from 'vue-tag-manager'

Vue.config.productionTip = false;

Vue.prototype.$eventBus = new Vue()

Vue.prototype.$testENV = process.env.VUE_APP_ROOT_URL

Vue.use(VueMobileDetection);

Vue.use(VueTagManager, {
    gtmId: 'GTM-KTWWWKG'
})

Vue.use(VueYandexMetrika, {
    id: 69160240,
    env: 'production',
    childIframe: true,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    params: window.yaParams
})

Vue.use(AsyncComputed)
Vue.use(VueMask)
Vue.use(Vuelidate)
Vue.use(VueScrollTo, {
    duration: 900,
    offset: -120,
    easing: 'cubic-bezier(.17,.67,.83,.67)'
});

Vue.component('VueSlickCarousel', VueSlickCarousel)
Vue.component('c-select', Select)
Vue.component('select-2', Select2)
Vue.component('slide-up-down', SlideUpDown)

Vue.mixin({
    filters: {
        bgrDynamicImageCover: url => `background-image: url(${url});`,
        bgrImageCover: url => `background-image: url(${IS_DEV ? '' : '/staticfiles'}${url});`,
        prodSrcImage: url => `${IS_DEV ? '' : '/staticfiles'}${url}`,
        durationFilter(time) {
            return `${ Math.floor(time / 60) > 0 ? Math.floor(time / 60) + ' ч. ' : '' }${time % 60 ? (time % 60) + ' мин.' : ''}`;
        },
        durationFormat: val => `Продолжительность ${Math.floor(val / 60) !== 0 ? Math.floor(val / 60) + ' ч.' : ''}  ${val % 60 !== 0 ? val % 60 + ' мин.' : ''}`,
        dutiesFormat: txt => {
            const formattedText = txt.replace(/<[^>]+>/g, '').replace(/[\r?\n|\—|\–|\−|\-|•]/g, '').replace(/\.\s?/g, '. ')
            return `${formattedText.slice(0, 256)}${formattedText.length > 256 ? '...' : ''}`
        },
        salaryFormat(val) {
            const intl = new Intl.NumberFormat('en-En')
            if (val) return `${intl.format(val)} ₽`
            else if (val === 0) return '0 ₽'
            else return ''
        },
        toPlural: (value, one, two, five, isShowValue = true) => {
            let n = Math.abs(value);
            n %= 100;
            const quantity = isShowValue ? value : '';
            if (n >= 5 && n <= 20) {
                return `${quantity} ${five}`;
            }
            n %= 10;
            if (n === 1) {
                return `${quantity} ${one}`;
            }
            if (n >= 2 && n <= 4) {
                return `${quantity} ${two}`;
            }
            return `${quantity} ${five}`;
        },
        dateFormat(date) {
            return new Intl.DateTimeFormat('ru-RU', {
                day: 'numeric',
                month: '2-digit',
                year: 'numeric'
            }).format(new Date(date))
        }
    },
    data: () => ({
        routeMindFormat: '/survey/mindformat/',
        isHeaderOffset: false,
        carouselSettings: {
            arrows: true,
            dots: true,
            infinite: false,
            'slides-to-show': 3,
            'slides-to-scroll': 3,
            draggable: false,
            speed: 600,
            swipe: true,
            'touch-move': true,
            responsive: [
                {
                    breakpoint: 1099,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    }),
    mounted() {
        // Редактируем title на каждой странице
        // console.log(this.$route)
        // Добавляем немного анимации на появление блоков
        document.querySelectorAll('.wow').forEach(wow => {
            let observer = new IntersectionObserver((ents) => {
                if (ents[0].isIntersecting) {
                    observer.disconnect()
                    wow.classList.add('fadeIn')
                }
            }, {threshold: 0.10})

            observer.observe(wow)
        })

        // Растягиваем картинки
        // document.querySelectorAll('.image-cover-wrap > .image-cover').forEach(function(item){
        //     let imageSrc = item.getAttribute('src');
        //     item.parentElement.style.backgroundImage = 'url('+imageSrc+')';
        // });

        // следим за Шапкой
        window.addEventListener('scroll', () => window.scrollY > 10 ? this.isHeaderOffset = true : this.isHeaderOffset = false)
    },
    methods: {
        modalOpen(name, payload = null) {
            this.$store.commit('modal/open', {name, payload})
        },
        modalClose(name = null, payload = null) {
            this.$store.commit('modal/close', {changeTo: name, payload})
            this.$eventBus.$emit('modal-close')
        },
        showNotify(type, payload) {
            this.$store.commit('ntf/show', {type, payload})
        },
        hideNotify() {
            this.$store.commit('ntf/hide')
        },
        addressFormat: (city, street, house, house_building, apartment) => {
            let result = '';
            result += city ? city : '';
            result += street && result ? `, ул. ${street}` : street ? `ул. ${street}` : '';
            result += house && result ? `, дом ${house}` : house ? `дом ${house}` : '';
            result += house_building && result ? `, корпус ${house_building}` : house_building ? `корпус ${house_building}` : '';
            result += apartment && result ? `, офис. ${apartment}` : apartment ? `офис. ${apartment}` : '';
            return result;
        }
    },
})

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
