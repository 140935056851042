<template>
	<div class="modal-content">
		<div class="mentor-block">
			<div
				class="mentor-pic"
				:style="payload.image | bgсImageCover"
			/>
			<div class="modal-title-wrap">
				<div class="modal-title sm">
					{{ payload.name }}
				</div>
			</div>
			<div
				class="text dark sm"
				v-text="payload.position"
			/>
			<div
				class="text dark sm"
				style="white-space: break-spaces;"
				v-text="payload.about"
			/>
		</div>
	</div>
</template>

<script>

export default {
    filters: {
        bgсImageCover: url => `background-image: url(${url});`
    },
    computed: {
        payload() {
            return this.$store.state.modal.payload
        }
    }
}
</script>
