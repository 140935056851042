<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<h3 class="modal-title">
				Войти
			</h3>
			<h4 class="modal-subtitle">
				У вас ещё нет аккаунта?
			</h4>
			<button
				id="btnRegistration_3"
				class="close-button info"
				@click="modalClose('regIn')"
			>
				Зарегистрироваться сейчас
			</button>
		</div>
		<form
			id="formSignIn"
			class="form"
			novalidate
			@submit.stop.prevent="signIn"
		>
			<BaseInput
				v-model="modalAuth.email"
				label="Email"
				:type="'text'"
				:style="'height: 42px;'"
			>
				<span
					v-if="$v.modalAuth.email.$error"
					class="help-block form-error"
				>
					{{ emailErrorMsg }}
				</span>
			</BaseInput>
			<BaseInput
				v-model="modalAuth.pass"
				label="Пароль"
				:type="'password'"
				:style="'height: 42px;'"
			>
				<span
					v-if="$v.modalAuth.pass.$error"
					class="help-block form-error"
				>
					{{ passwordErrorMsg }}
				</span>
			</BaseInput>
			<div class="field-wrap button-auth">
				<BaseErrorMessageForm
					v-if="responseError.isShow"
					:text="responseError.text"
				/>
				<BaseButton
					id="btnLogin_2"
					class="sign-in-button"
					:is-preloader="isSigning"
					orange
				>
					Войти
				</BaseButton>
			</div>
			<button
				class="close-button info"
				@click="modalClose('recPass')"
			>
				Забыли пароль?
			</button>
		</form>
		<!-- <div class="signup-social-buttons">
			<div class="text sm dark">
				<p>Войти с помощью:</p>
			</div>
			<BaseButton
				v-for="(social, index) in socialButton"
				:key="index"
				class="social-button"
				light-border
				@clickButton="loginExternal(social.name)"
			>
				<div
					:class="social.name"
					class="icon"
				/>
			</BaseButton>
		</div> -->
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators';
import BaseButton from '../BaseComponents/BaseButton.vue';
import BaseInput from '@/components/BaseComponents/BaseInput.vue';
import BaseErrorMessageForm from '@/components/BaseComponents/BaseErrorMessageForm.vue';

export default {
    components: {
        BaseButton,
        BaseInput,
        BaseErrorMessageForm,
    },
    data: () => ({
        isSigning: false,
		responseError: {
			isShow: false,
			text: 'Ошибка авторизации',
		},
        modalAuth: {
            phone: '',
            email: '',
            pass: ''
        },
        googleSignInParams: {
            client_id:
                '457482015700-9rcpico458nj8gnepkd5uooi5gpik66l.apps.googleusercontent.com'
        },
        socialButton: [
            {
                id: 1,
                name: 'yandex'
            },
            {
                id: 2,
                name: 'mailru'
            },
            {
                id: 3,
                name: 'vk'
            },
            {
                id: 4,
                name: 'google'
            }
        ]
    }),
    validations: {
        modalAuth: {
            email: {
                required,
                email,
				maxLength: maxLength(128),
            },
            pass: {
                required,
                minLength: minLength(8),
				maxLength: maxLength(128),
            }
        }
    },
    computed: {
        payload() {
            return this.$store.state.modal.payload;
        },
		passwordErrorMsg() {
			if (!this.$v.modalAuth.pass.required) {
				return 'Это обязательное поле';
			}
			if (!this.$v.modalAuth.pass.minLength) {
				return `Пароль должен состоять из букв и цифр, не менее ${this.$v.modalAuth.pass.$params.minLength.min} символов`;
			}
			if (!this.$v.modalAuth.pass.maxLength) {
				return `Пароль должен состоять из букв и цифр, не более ${this.$v.modalAuth.pass.$params.maxLength.max} символов`;
			}						
			return 'Ошибка поля';
		},
		emailErrorMsg() {
			if (!this.$v.modalAuth.email.required) {
				return 'Это обязательное поле';
			}
			if (!this.$v.modalAuth.email.email) {
				return 'Введите корректный e-mail';
			}
			if (!this.$v.modalAuth.email.maxLength) {
				return `Email не должен содержать больше, чем ${this.$v.modalAuth.email.$params.maxLength.max} символов`;
			}
			return 'Ошибка поля';
		}
    },
    created() {
        if (IS_DEV) {
            this.modalAuth.email = 'b.asanbekova@teachbase.ru';
            this.modalAuth.pass = 'Qwerty1234!';
        }
        if (this.payload && this.payload.email) {
            this.modalAuth.email = this.payload.email;
        }
    },
    methods: {
        ...mapActions('user', [
            'login',
            'detail',
            'loginExternal',
            'getStudyPlace'
        ]),
        ...mapActions('tb', ['getAllCourses', 'getAllSections']),
        signIn() {
            this.$v.modalAuth.$touch();
            if (this.$v.modalAuth.$invalid) {
                this.$scrollTo(document.querySelector('#formSignIn'));
            } else if (!this.isSigning) {
                this.isSigning = true;
				this.responseError.isShow = false;
                this.login({
                    email: this.modalAuth.email.toLowerCase(),
                    pass: this.modalAuth.pass,
                    pathName: window.location.pathname,
                    referrer_page: window.location.pathname
                })
                    .then(result => {
                        localStorage.removeItem('nameComponentOnboarding')
                        localStorage.removeItem('currentAim')
                        localStorage.removeItem('onboardingAnswers')
                        localStorage.removeItem('isOnboardingFinished')
                        Promise.all([
                            this.detail(),
                            this.getAllCourses(),
                            this.getAllSections()
                        ]);
                        if (!IS_DEV) {
                            this.$gtm?.push({
                                'event': 'logIn',
                                'userID': result.id,
                                'roleUser': result.user_role
                            })
                        }
                        setTimeout(() => {
                            if (!IS_DEV) {
                                if (result.auth_current_page && this.$isMobile()) {
                                    localStorage.setItem('isFromMobile', result.auth_current_page)
                                    window.location.href = result.auth_current_page
                                }
                                window.location.href = result.path;
                            }
                            this.modalClose();
                            if ((this.$route.fullPath === '/' ||
                                    this.$route.fullPath === '/school' ||
                                    this.$route.fullPath === '/student') &&
                                !result.auth_current_page) this.$router.push('/platform')
                            if (this.$route.params.slug === 'ocean') window.location.href = 'https://okean.moyastrana.ru/'
                            if (this.$route.query.redirect_url !== null && (this.$route.fullPath.includes('/external_oauth'))) {
                                localStorage.setItem('redirectURL', this.$route.query.redirect_url);
                                window.location.href = 'https://liftbudushhego.moyastrana.ru'
                            }
                        }, 2000)
                    })
                    .then(() => this.getStudyPlace())
                    .catch(error => {
						this.responseError.isShow = true;
						for (let key in error) {
							this.responseError.text = error[key][0];
						}
                        this.isSigning = false;
                    })
            }
        }
    }
};
</script>
<style lang="less" scoped>
.sign-in-button {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-family: "SF Pro Display", sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 14px;
}
.social-button {
    margin: 12px 5px 0 0;
    display: inline-block;
    line-height: 42px;
    padding: 0 22px;
}
.close-button {
    font-size: 16px;
    &:focus {
        color: #3c3c3b;
    }
    &:hover {
        color: #ff8e0d;
    }
}
.signup-social-buttons {
    padding-top: 30px;
}
.form {
    .field-wrap {
        // margin-bottom: 0;
    }
}
.button-auth {
    margin-bottom:0;
}
</style>
