<template>
	<div
		class="page"
		:class="PageClass"
	>
		<lift-header
			v-if="
				!$route.meta.isUnivers &&
					!$route.meta.isMaintenance &&
					!$route.meta.isLandingUr &&
					!$route.meta.isChristmas"
			ref="header"
		/>
		<main class="body">
			<transition name="slide-fade" />
			<transition name="slide-fade" />
			<h1
				v-if="!$route.meta.isLandingUr"
				id="seo-title"
				class="seo-title"
			>
				>Образовательная инициатива Благотворительного фонда «Система»
			</h1>
			<router-view />
		</main>

		<transition name="fade">
			<modal v-if="isActiveModal" />
		</transition>

		<notify />

		<lift-footer />

		<transition-group
			name="slide-up"
			tag="div"
		>
			<div
				v-if="
					isShowCookie &&
						!$route.meta.isUnivers &&
						!$route.meta.isMaintenance &&
						!$route.meta.isLiftNoRefusal &&
						!$route.meta.isLandingUr &&
						!$route.meta.isSchool &&
						!$route.meta.isUnsubscribed
				"
				key="cookie"
				class="cookies"
			>
				<div class="content">
					<div class="grid">
						<div class="box">
							<div class="text dark">
								<p>
									В целях обеспечения функционирования сайта и
									проведения статистических исследований и
									обзоров осуществляется автоматический сбор
									информации о файлах cookie и IP-адресах.
									Нажимая кнопку «Согласен» или продолжая
									использовать сайт, Вы даете согласие на
									использование данных технологий для нашего
									сайта.
								</p>
							</div>
						</div>
						<div class="box">
							<button
								class="button"
								@click.stop.prevent="acceptCookies"
							>
								Согласен
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="
					isUserActivation &&
						!$route.meta.isLiftNoRefusal &&
						!$route.meta.isLandingUr &&
						!$route.meta.name === 'Онбординг' &&
						!$route.meta.isUnsubscribed &&
						!$route.meta.isCatalog
				"
				key="alert_1"
				class="alert"
			>
				<div
					class="alert-close"
					@click="closeAlert(1)"
				/>
				<div class="content">
					<div class="grid">
						<div class="box">
							<div class="alert-text">
								<img
									:src="
										'/img/icons/icon-alert-warning.svg'
											| prodSrcImage
									"
									alt=""
									class="alert-icon"
								>
								<div class="text dark sm">
									<p>
										Для того, чтобы мы могли рекомендовать
										вам наиболее подходящие обучающие
										материалы и вакансии, пожалуйста,
										заполните свой профиль.
									</p>
								</div>
							</div>
						</div>
						<div class="box">
							<button
								class="button orange"
								@click.stop="fillProfile"
							>
								Заполнить
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="
					isUserCreation &&
						!$route.meta.isLiftNoRefusal &&
						!$route.meta.isLandingUr &&
						!$route.meta.name === 'Онбординг' &&
						!$route.meta.isUnsubscribed &&
						!$route.meta.isCatalog"
				key="alert_2"
				class="alert"
			>
				<div
					class="alert-close"
					@click="closeAlert(2)"
				/>
				<div class="content">
					<div class="grid">
						<div class="box">
							<div class="alert-text">
								<img
									:src="'/img/icons/icon-alert-warning.svg' | prodSrcImage"
									alt=""
									class="alert-icon"
								>
								<div class="text dark sm">
									<p>
										Для того, чтобы вы могли создавать резюме и откликаться на вакансии, пожалуйста,
										заполните свой профиль.
									</p>
								</div>
							</div>
						</div>
						<div class="box">
							<button
								class="button orange"
								@click.stop="fillProfile"
							>
								Заполнить
							</button>
						</div>
					</div>
				</div>
			</div>
		</transition-group>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { getCookie } from './assets/scripts/cookies';
import Header from './components/Header';
import Footer from './components/Footer';
import Modal from './components/modal/Modal';
import Notify from './components/notify';

export default {
    name: 'App',
    components: {
        'lift-header': Header,
        'lift-footer': Footer,
        'modal': Modal,
        'notify': Notify
    },
    data: () => ({
        isAvatarClick: false,
        isShowCookie: !localStorage.getItem('agreementCookie'),
        isShowProfileText: getCookie('profileCookie') ? true : false,
        isShowUserProfile: !sessionStorage.getItem('fill_profile'),
        isShowUserCreate: false
    }),
    computed: {
        ...mapState('user', [
            'id'
        ]),
        ...mapState('scholar', [
            'popupIndex'
        ]),
        ...mapGetters('user', {
            user: 'userInfo',
            userID: 'userID',
            isLogged: 'isLoggedIn',
            isVerification: 'userIsVerification',
            isCreateResume: 'userIsCreateResume',
            isRecruiter: 'isRecruiter',
            showUserCreate: 'isShowUserCreate'
        }),
        ...mapGetters('modal', {
            isActiveModal: 'isActive'
        }),
        isUserActivation() {
            return this.isLogged &&
                this.user &&
                this.isShowUserProfile &&
                !this.isVerification &&
                !this.$route.meta.isUnivers &&
                !this.$route.meta.isMaintenance &&
                !this.isRecruiter &&
                !this.$route.path.includes('contest')
        },
        isUserCreation() {
            return this.isLogged &&
                this.user &&
                this.isShowUserCreate &&
                !this.isRecruiter &&
                !this.$route.meta.isUnivers &&
                !this.$route.meta.isMaintenance
        },
        PageClass () {
            return {
                'clouds': this.$route.meta.isUnivers || this.$route.meta.isMaintenance,
                'lesson-sd': this.$route.meta.isLandingUr,
                'page-testing-competencies': this.$route.meta.isTest,
                'page-role': this.$route.meta.isSchool,
                'page-course': this.$route.meta.isCourse,
                'page-catalog': this.$route.meta.isCatalog,
                'page-event': this.$route.meta.isEventConstructor,
                'page-profile': this.$route.meta.isProfile,
                'page-courses': this.$route.meta.isCourses,
                'page-events': this.$route.meta.isProjects,
                'page-tests': this.$route.meta.isTests,
                'page-christmas': this.$route.meta.isChristmas,
                'page-contests': this.$route.fullPath.includes('contests=true') || this.$route.fullPath.includes('contest=true')
            }
        }
    },
    watch: {
        showUserCreate: {
            handler() {
                this.isShowUserCreate = this.showUserCreate
            },
            deep: true
        },
        isLogged() {
            if(!this.isLogged) {
                this.setShowUserCreate(false)
            }
        },
        $route (){
            this.getUserData()
        }
    },
    mounted() {
        this.getUserData()
        if (localStorage.getItem('userRole') === 'null' &&
            !localStorage.getItem('isFromMobile')) this.modalOpen('add-role')
    },
    beforeDestroy() {
        this.setShowUserCreate(false)
    },
    methods: {
        ...mapActions('user', [
            'detail',
            'getStudyPlace',
            'change',
            'getBackOfficeRole'
        ]),
        ...mapMutations('user', [
            'setShowUserCreate'
        ]),
        ...mapActions('scholar', {
            getPrize: 'getModalPrize',
        }),
        acceptCookies() {
            this.isShowCookie = false;
			localStorage.setItem('agreementCookie', 'true')
        },
        fillProfile() {
            this.$router.push(`/profile${this.userID}?prizes=true`)
            this.isShowUserProfile = false
            this.isShowUserCreate = false
        },
        closeAlert(id) {
            if (id === 1) {
                this.isShowUserProfile = false
                sessionStorage.setItem('fill_profile', 1)
            }
            if (id === 2) {
                this.isShowUserCreate = false
                this.setShowUserCreate(false)
            }
        },
        getUserData () {
            localStorage.getItem('userID') &&
            this.detail()
                .then(res => {
                    if (!localStorage.getItem('jwtToken')) localStorage.setItem('jwtToken', res.jwt_token);
                    if (res.auth_current_page && this.$isMobile()) {
                        localStorage.setItem('isFromMobile', res.auth_current_page)
                        this.change({
                            'auth_current_page': null
                        })
                    }
                    if ((!res.is_onbording &&
                            !res.is_recruiter &&
                            this.$route.fullPath !== '/onboarding') &&
                        (!this.$route.meta.isCourse &&
                            !this.$route.meta.isTest &&
                            !this.$route.meta.isEventConstructor &&
                            !this.$route.meta.isOldEventConstructor) &&
                        !localStorage.getItem('isFromMobile')
                    ) this.$router.push('/onboarding')
                    if (res.motivation_popup) {
                        this.getPrize('popup').then(res => {
                            if (res.popups.length) this.modalOpen('get-prize', res.popups[this.popupIndex])
                        })
                    }
                })
                .then(() => this.getBackOfficeRole())
        }
    },
};
</script>

<style lang="less">
.body {
    padding-top: 80px !important;
}
.slide-up {
	&-enter-active,
	&-leave-active {
		transition: all 0.3s linear;
	}

	&-enter,
	&-leave-to {
		transform: translateY(10%);
		opacity: 0;
	}
}

@media (max-width: 767px) {
	.body {
		overflow: hidden;
	}
}
</style>
