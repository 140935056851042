<template>
	<div class="modal-content">
		<div
			v-if="payload"
			class="media"
		>
			<iframe :src="payload" />
		</div>
	</div>
</template>


<script>

export default {
    computed: {
        payload() {
            return this.$store.state.modal.payload
        }
    }
}
</script>