<template>
	<section
		v-if="descBlock"
		class="section section-description"
	>
		<div class="content content--md">
			<h2 class="title-section">
				{{ descBlock.how_things_work_title }}
			</h2>
			<div
				class="description"
				v-html="descBlock.how_things_work_text"
			/>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Description',
    props: {
        descBlock: {
            type: Object,
            default: null
        },
    }
}
</script>
