<template>
	<section class="section section-images">
		<div
			class="content"
			@click="pauseSlider"
		>
			<VueSlickCarousel
				ref="imagesSlider"
				v-bind="sliderSettings"
				class="images-slider images-slider--stipend"
				@swipe="pauseSlider"
			>
				<div>
					<div class="images-slider__slide">
						<div class="images-slider__content">
							<h2 class="title-section">
								Наблюдай свой прогресс
							</h2>
							<div class="description">
								Каждое твое действие на платформе будет учитываться и отражаться в личном кабинете.
							</div>
						</div>
						<div class="images-slider__pic-wrap">
							<img
								:src="'/img/pages/event/pic__image-1.png' | prodSrcImage"
								alt="Фото"
								class="images-slider__pic"
							>
						</div>
					</div>
				</div>
				<div>
					<div class="images-slider__slide images-slider__slide--column">
						<div class="images-slider__content">
							<img
								:src="'/img/logo--white.svg' | prodSrcImage"
								alt="Лифт в будущее"
								class="images-slider__logo"
							>
							<h2 class="title-section">
								Лучшие участники получат стипендию
							</h2>
						</div>
						<div class="images-slider__pic-wrap">
							<img
								:src="'/img/pages/event/pic__image-2.png' | prodSrcImage"
								alt="Фото"
								class="images-slider__pic"
							>
						</div>
					</div>
				</div>
				<div>
					<div class="images-slider__slide images-slider__slide--column">
						<div class="images-slider__content">
							<img
								:src="'/img/logo--white.svg' | prodSrcImage"
								alt="Лифт в будущее"
								class="images-slider__logo"
							>
							<h2 class="title-section">
								Чем больше действий совершено, тем круче приз
							</h2>
						</div>
						<div class="images-slider__pic-wrap">
							<div class="images-slider__prizes">
								<div class="images-slider__prize">
									<img
										:src="'/img/pages/catalog/pic__achievement-2--x2.png' | prodSrcImage"
										alt="Приз"
									>
									<div class="title-element">
										Карьерная консультация
									</div>
								</div>
								<div class="images-slider__prize">
									<img
										:src="'/img/pages/catalog/pic__achievement-3--x2.png' | prodSrcImage"
										alt="Приз"
									>
									<div class="title-element">
										Наставник из отрасли
									</div>
								</div>
								<div class="images-slider__prize">
									<img
										:src="'/img/pages/catalog/pic__achievement-5--x2.png' | prodSrcImage"
										alt="Приз"
									>
									<div class="title-element">
										Сертификат Озон
									</div>
								</div>
								<div class="images-slider__prize">
									<img
										:src="'/img/pages/catalog/pic__achievement-4--x2.png' | prodSrcImage"
										alt="Приз"
									>
									<div class="title-element">
										Стипендия
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="images-slider__slide images-slider__slide--column">
						<div class="images-slider__content">
							<img
								:src="'/img/logo--white.svg' | prodSrcImage"
								alt="Лифт в будущее"
								class="images-slider__logo"
							>
							<h2 class="title-section">
								Совершив определенное количество действий, ты разблокируешь этим награду
							</h2>
						</div>
						<div class="images-slider__pic-wrap">
							<img
								:src="'/img/pages/event/pic__image-3.png' | prodSrcImage"
								alt="Фото"
								class="images-slider__pic"
							>
						</div>
					</div>
				</div>
			</Vueslickcarousel>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Slider',
    data: () => ({
        isStopped: false,
        sliderSettings: {
            arrows: true,
            fade: true,
            focusOnSelect: true,
            waitForAnimate: false,
            infinite: true,
            easing: 'swing',
            dots: true,
            autoplay: true,
            pauseOnFocus: true,
            pauseOnHover: false,
            pauseOnDotsHover: false,
            speed: 500,
            autoplaySpeed: 3300,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        arrows: false
                    }
                }
            ]
        },
    }),
    methods: {
        pauseSlider() {
            this.$refs.imagesSlider.pause()
            this.isStopped = true
        }
    }
};
</script>
