import Cmn from '@/api/common'


const state = {
    slider: null,
    settings: null
}

const actions = {
    getImage({}, id) {
        return new Promise(resolve => {
            Cmn.getImage(id)
                .then(response => response.data.image)
                .then(result => resolve(result))
        })
    },
    createImage({}, form) {
        return new Promise(resolve => {
            Cmn.createImage(form)
                .then(response => response.data)
                .then(result => resolve(result))
        })
    },
    removeImage({}, id) {
        return new Promise(resolve => {
            Cmn.removeImage(id)
                .then(() => {
                    resolve()
                })
        })
    },
    updateImage({}, obj) {
        return new Promise(resolve => {
            Cmn.updateImage(obj)
                .then(response => response.data)
                .then(result => resolve(result))
        })
    },
    postMail({}, data) {
        return new Promise((resolve) => {
            Cmn.postMail(data)
                .then(() => resolve())
        })
    },
    getAllEducations({}, obj) {
        return new Promise(resolve => {
            Cmn.getAllEducations(obj)
                .then(response => response.data.results)
                .then(result => resolve(result))
                .catch(error => console.dir(error))
        })
    },
    getGlobalCities({}, obj) {
        return new Promise(resolve => {
            Cmn.getGlobalCities(obj)
                .then(response => response.data.results)
                .then(result => {
                    resolve(result)
                })
                .catch(error => console.dir(error))
        })
    },
    getGlobalSchools({}, obj) {
        return new Promise(resolve => {
            Cmn.getGlobalSchools(obj)
                .then(response => response.data)
                .then(result => {
                    resolve(result)
                })
                .catch(error => console.dir(error))
        })
    },
    getVacancyCities({}, obj) {
        return new Promise(resolve => {
            Cmn.getVacancyCities(obj)
                .then(response => response.data)
                .then(result => resolve(result))
                .catch(error => console.dir(error))
        })
    },
    getAllCompanies({}, obj) {
        return new Promise(resolve => {
            Cmn.getAllCompanies(obj)
                .then(response => response.data)
                .then(result => resolve(result))
                .catch(error => console.dir(error))
        })
    },
    async getMainSlider({commit}) {
        let response = await Cmn.getMainSlider()
        commit('setMainSlider', response.data)
    },
    async getSettings({commit}) {
        return new Promise(resolve => {
            Cmn.getSettings()
                .then(response => response.data)
                .then(result => {
                    commit('setSettings', result)
                    resolve(result)
                })
        })
    },
    async setVideoActivity({}, {id, status}) {
        let response = await Cmn.setVideoActivity({id, status})
    }
}

const mutations = {
    setMainSlider(state, slider) {
        state.slider = slider
    },
    setSettings(state, settings) {
        state.settings = settings
    }
}

const getters = {
    getMainSlider: state => state.slider,
    getSettings: state => state.settings
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
