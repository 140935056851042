<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<div class="modal-title">
				{{ title }}
			</div>
		</div>

		<div class="form-step-group">
			<div
				class="form-step-item"
				:class="{ 'active': step === 1, 'ready': step > 1 }"
			>
				<div class="form-step-index">
					Шаг 1
				</div>
				<div class="form-step-title">
					Авторизация
				</div>
			</div>
			<div
				class="form-step-item"
				:class="{ 'active': step === 2, 'ready': step > 2 }"
			>
				<div class="form-step-index">
					Шаг 2
				</div>
				<div class="form-step-title">
					Основная информация
				</div>
			</div>
			<div
				class="form-step-item"
				:class="{ 'active': step === 3 }"
			>
				<div class="form-step-index">
					Шаг 3
				</div>
				<div class="form-step-title">
					Подача заявки
				</div>
			</div>
		</div>

		<transition
			name="slide"
			mode="out-in"
		>
			<component
				:is="currentStep"
				:user="userInfo"
				:letter="coverLetter"
				:errors="errors"
				:vacancy="payload"
				@set-errors="setErrors"
				@reset-errors="resetErrors"
				@prev-step="prevStep"
				@next-step="nextStep"
			/>
		</transition>
	</div>
</template>

<script>

import FastStep_1 from './FastStep_1'
import FastStep_2 from './FastStep_2'
import FastStep_3 from './FastStep_3'

export default {
    components: {
        step_1: FastStep_1,
        step_2: FastStep_2,
        step_3: FastStep_3
    },
    data: () => ({
        step: 1,
        userInfo: undefined,
        coverLetter: undefined,
        errors: undefined
    }),
    computed: {
        isLogged() {
            return this.$store.getters['user/isLoggedIn']
        },
        currentStep() {
            return `step_${this.step}`
        },
        payload() {
            return this.$store.getters['modal/getPayload']
        },
        title() {
            if (this.payload && this.payload.type_employment) {
                if (this.payload.type_employment === 'internship') return 'Быстрый отклик на стажировку'
                else return 'Быстрый отклик на вакансию'
            }
            return 'Быстрый отклик'
        }
    },
    created() {
        if (this.isLogged) this.step = 2
        if (this.payload && this.payload.step)  this.step = this.payload.step
    },
    methods: {
        setErrors(errors) {
            this.errors = errors
        },
        resetErrors(slug) {
            if (this.errors && this.errors.field === slug) this.errors = undefined
        },
        nextStep(data) {
            this.step = ++this.step

            if (!data) this.errors = undefined
            if (data && data.step === 2) this.userInfo = data.form
        },
        prevStep(data) {
            this.step = --this.step
            
            if (data && data.error) this.errors = data.error
            if (data && data.step === 2) this.userInfo = data.form
            if (data && data.step === 3) this.coverLetter = data.form
        }
    }
}
</script>

<style lang="less">
.slide-enter-active {
    transition: all .3s ease;
}
.slide-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-enter {
    transform: translateX(-5%);
    opacity: 0;
}
.slide-leave-to{
    transform: translateX(5%);
    opacity: 0;
}
</style>
