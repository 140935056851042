<template>
	<section
		v-if="videoMain"
		class="section section-video"
	>
		<div class="content">
			<h2 class="title-section">
				{{ videoMain.event_video_title }}
			</h2>
			<div v-if="videoInfo">
				<div
					v-for="(item, index) in videoInfo"
					:key="index"
				>
					<video
						v-if="item.type === 'video'"
						controls
						:src="item.video"
						class="video"
					/>
					<div
						v-else
						class="media"
						v-html="item.iframe"
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Video',
    props: {
        videoMain: {
            type: Object,
            default: null
        },
        videoInfo: {
            type: Array,
            default: null
        }
    },
}
</script>
