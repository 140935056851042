<template>
	<div class="modal-content">
		<div class="achievement-modal">
			<img
				:src="'/img/pages/catalog/pic__achievement-3--x2.png' | prodSrcImage"
				alt=""
				class="achievement-modal__pic"
			>
			<div class="title-block">
				Наставник
			</div>
			<div class="achievement-modal__text">
				Старший опытный друг, который все объяснит и поможет новичку на старте карьеры влиться в коллектив  и разобраться в деталях.
			</div>
			<div class="achievement-modal__list">
				<div class="achievement-modal__point">
					<div class="achievement-modal__step">
						Заполненный профиль
					</div>
					<div class="achievement-modal__ready">
						<div class="achievement-modal__value">
							1/1
						</div>
						<img
							:src="'/img/icons/icon__check-achieved.svg' | prodSrcImage"
							alt="Готово"
							class="achievement-modal__icon"
						>
					</div>
				</div>
				<div class="achievement-modal__point">
					<div class="achievement-modal__step">
						Не мение 15 пройденных курсов
					</div>
					<div class="achievement-modal__ready">
						<div class="achievement-modal__value">
							0/15
						</div>
						<img
							:src="'/img/icons/icon__check-unachieved.svg' | prodSrcImage"
							alt="Готово"
							class="achievement-modal__icon"
						>
					</div>
				</div>
				<div class="achievement-modal__point">
					<div class="achievement-modal__step">
						Не мение 2 пройденного теста
					</div>
					<div class="achievement-modal__ready">
						<div class="achievement-modal__value">
							0/2
						</div>
						<img
							:src="'/img/icons/icon__check-unachieved.svg' | prodSrcImage"
							alt="Готово"
							class="achievement-modal__icon"
						>
					</div>
				</div>
				<div class="achievement-modal__point">
					<div class="achievement-modal__step">
						Практика или мероприятие
					</div>
					<div class="achievement-modal__ready">
						<div class="achievement-modal__value">
							0/1
						</div>
						<img
							:src="'/img/icons/icon__check-unachieved.svg' | prodSrcImage"
							alt="Готово"
							class="achievement-modal__icon"
						>
					</div>
				</div>
				<div class="achievement-modal__point achievement-modal__point--upload">
					<div class="achievement-modal__step">
						Зачетная книжка без троек
					</div>
					<label class="achievement-modal__upload">
						<input
							type="file"
							multiple
							class="achievement-modal__upload-value"
						>
						Загрузить
					</label>
				</div>
				<div class="uploaded-files">
					<div class="uploaded-file">
						<div class="uploaded-file__title title-mono">
							Анкета_25.pdf
						</div>
						<div class="uploaded-file__remove" />
						<div
							class="uploaded-file__progress"
							style="width: 40%;"
						/>
					</div>
					<div class="uploaded-file">
						<div class="uploaded-file__title title-mono">
							Тест_1.doc
						</div>
						<div class="uploaded-file__remove" />
						<div
							class="uploaded-file__progress"
							style="width: 80%;"
						/>
					</div>
					<div class="uploaded-file">
						<div class="uploaded-file__title title-mono">
							Фото_июль_013418.jpg
						</div>
						<div class="uploaded-file__remove" />
					</div>
					<div class="uploaded-file">
						<div class="uploaded-file__title title-mono">
							Фото_август_012496.jpg
						</div>
						<div class="uploaded-file__remove" />
					</div>
				</div>
			</div>
			<div class="achievement-modal__footer">
				<button class="achievement-modal__navigation">
					<img
						:src="'/img/icons/icon__prev.svg' | prodSrcImage"
						alt="Предыдущий"
					>
				</button>
				<button class="button orange">
					Отлично
				</button>
				<button class="achievement-modal__navigation">
					<img
						:src="'/img/icons/icon__next.svg' | prodSrcImage"
						alt="Предыдущий"
					>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'StaticProgram'
}
</script>

<style scoped>

</style>
