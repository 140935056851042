<template>
	<a
		v-if="backOfficeRole !== null"
		:class="className"
		@click="goToBackOffice()"
	>
		{{ linkToBackOffice }}
	</a>
</template>

<script>
import { mapGetters } from 'vuex';
import Cnt from '@/api/contests'
export default {
    name: 'BtnToBackOffice',
	props: {
		className: {
			type: String,
			require: true,
			default: ''
		}
	},
    computed: {
      ...mapGetters('user', {
          backOfficeRole: 'getBackOfficeRole'
      }),
	  linkToBackOffice () {
		if (this.backOfficeRole === 2) return 'Кабинет модератора'
		else if (this.backOfficeRole === 3) return 'Кабинет эксперта'
		return 'Перейти в бекофис'
		}
    },
	methods: {
		goToBackOffice() {
			Cnt.startAuth().then((response) => {
				const url = response.data.auth_url;
				window.open(url, '_blank')
			})
		}
	}
};
</script>
