import Tb from '@/api/teachbase'

const state = {
    organization: null,
    organizationVacancies: null,
    organizationCities: null,
    organizationActivities: null,
    organizationCompanies: null,
    organizationSpecialization: null,
    organizationType: null,
    organizations: null
}

const actions = {
    getOrganization({ commit }, id) {
        return new Promise(resolve => {
            Tb.getOrganization(id)
                .then(response => response.data)
                .then(result => {
                    commit('setOrganization', result)
                    resolve(result)
                })
        })
    },
    getOrganizations({ commit }) {
        return new Promise(resolve => {
            Tb.getOrganizations()
                .then(response => response.data.results)
                .then(result => {
                    commit('setOrganizations', result)
                    resolve(result)
                })
        })
    },
    getOrganizationVacancies({ commit }, filter) {
        return new Promise((resolve) => {
            !filter ? commit('setOrganizationVacancies', undefined) : false;
            Tb.getVacanciesOrganization(filter)
                .then(response => response.data)
                .then(payload => {
                    commit('setOrganizationVacancies', payload.results)
                    resolve(payload)
                })
        })
    },
    getOrganizationCities({ commit }, filter) {
        return new Promise(resolve => {
            Tb.getOrganizationCities(filter)
                .then(response => response.data)
                .then(result => {
                    commit('setOrganizationCities', result)
                    resolve(result)
                })
        })
    },
    getOrganizationActivities({ commit }, filter) {
        return new Promise(resolve => {
            Tb.getOrganizationActivities(filter)
                .then(response => response.data)
                .then(result => {
                    commit('setOrganizationActivities', result)
                    resolve(result)
                })
        })
    },
    getOrganizationCompanies({ commit }, filter) {
        return new Promise(resolve => {
            Tb.getOrganizationCompanies(filter)
                .then(response => response.data)
                .then(result => {
                    commit('setOrganizationCompanies', result)
                    resolve(result)
                })
        })
    },
    getOrganizationSpecialisation({ commit }, filter) {
        return new Promise(resolve => {
            Tb.getOrganizationSpecialisation(filter)
                .then(response => response.data)
                .then(result => {
                    commit('setOrganizationSpecialisation', result)
                    resolve(result)
                })
        })
    },
    getOrganizationTypeEmployment({ commit }, id) {
        return new Promise(resolve => {
            Tb.getOrganizationTypeEmployment(id)
                .then(response => response.data)
                .then(result => {
                    commit('setOrganizationTypeEmployment', result)
                    resolve(result)
                })
        })
    }
}

const mutations = {
    setOrganization(state, organization) {
        state.organization = organization
    },
    setOrganizations(state, organizations) {
        state.organizations = organizations
    },
    setOrganizationVacancies(state, organizationVacancies) {
        state.organizationVacancies = organizationVacancies
    },
    setOrganizationCities(state, organizationCities) {
        state.organizationCities = organizationCities
    },
    setOrganizationActivities(state, organizationActivities) {
        state.organizationActivities = organizationActivities
    },
    setOrganizationCompanies(state, organizationCompanies) {
        state.organizationCompanies = organizationCompanies
    },
    setOrganizationSpecialisation(state, organizationSpecialization) {
        state.organizationSpecialization = organizationSpecialization
    },
    setOrganizationTypeEmployment(state, organizationType) {
        state.organizationType = organizationType
    }
}

const getters = {
    getOrganization: state => state.organization,
    getOrganizations: state => state.organizations,
    getOrganizationVacancies: state => state.organizationVacancies,
    getOrganizationCities: state => state.organizationCities,
    getOrganizationActivities: state => state.organizationActivities,
    getOrganizationCompanies: state => state.organizationCompanies,
    getOrganizationSpecialisation: state => state.organizationSpecialization,
    getOrganizationTypeEmployment: state => state.organizationType
}



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
