const state = {
    type: '',
    payload: undefined,
    isActive: false,
    isSubmit: false,
}

// const actions = {
//     show({ commit }, { type, payload }) {
//         commit('show', { type, payload })
//     }
// }

const mutations = {
    show(state, { type, payload }) {
        state.type = type
        state.payload = payload
        state.isActive = true
    },
    hide(state) {
        state.type = ''
        state.payload = undefined
        state.isActive = false
    },
    setSubmit(state, payload) {
        state.isSubmit = payload;
    },
    setActive(state, payload) {
        state.isActive = payload;
    },
}

const getters = {
    count: state => state.payload && state.payload.count,
    env: state => state.payload ? state.payload.env : null,
    getSubmit: state => state.isSubmit,
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}
