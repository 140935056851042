<template>
	<div class="modal-content">
		<div class="signup-sd">
			<div class="signup-sd__title">
				зарегистрироваться
			</div>
			<div class="signup-sd__form">
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model.trim="$v.form.first_name.$model"
							type="text"
							class="field"
							placeholder="Имя"
							style="text-transform: capitalize"
						>
						<div class="label">Имя</div>
						<span
							v-if="$v.form.first_name.$error"
							class="help-block form-error"
						>
							{{
								!$v.form.first_name.required ? 'Это обязательное поле' : $v.form.first_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректное имя'
							}}
						</span>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model.trim="$v.form.last_name.$model"
							type="text"
							class="field"
							placeholder="Фамилия"
							style="text-transform: capitalize"
						>
						<div class="label">Фамилия</div>
						<span
							v-if="$v.form.last_name.$error"
							class="help-block form-error"
						>
							{{
								!$v.form.last_name.required ? 'Это обязательное поле' : $v.form.last_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректную Фамилию'
							}}
						</span>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model.trim="$v.form.email.$model"
							type="email"
							class="field"
							placeholder="E-mail"
						>
						<div class="label">E-mail</div>
						<span
							v-if="$v.form.email.$error"
							class="help-block form-error"
						>
							{{
								!$v.form.email.required ? 'Это обязательное поле' : !$v.form.email.email ? 'Введите корректный e-mail' : 'Ошибка поля'
							}}
						</span>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model="$v.form.phone.$model"
							v-mask="'+7(###)###-##-##'"
							type="text"
							class="field"
							placeholder="Телефон"
						>
						<div class="label">Телефон</div>
						<span
							v-if="$v.form.phone.$error"
							class="help-block form-error"
						>
							{{
								!$v.form.phone.required ? 'Это обязательное поле' : !$v.form.phone.Phone ? 'Введите корректный номер телефона' : 'Ошибка поля'
							}}
						</span>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model.trim="$v.form.city.name.$model"
							type="text"
							class="field"
							placeholder="Город"
							style="text-transform: capitalize"
						>
						<div class="label">Город</div>
						<span
							v-if="$v.form.city.name.$error"
							class="help-block form-error"
						>
							{{
								!$v.form.city.name.required ? 'Это обязательное поле' : 'Ошибка поля'
							}}
						</span>
					</label>
				</div>
				<c-select
					class="field-wrap"
					:options="eduOptions"
					:name="'Образование'"
					:default-data="form.study.education"
					:is-error="$v.form.study.education.$dirty && !$v.form.study.education.required"
					@selected="selectEdu"
				/>
			</div>
			<div class="signup-sd__footer">
				<button
					v-if="!isSending"
					class="button-sd"
					@click="registration"
				>
					Отправить
				</button>
				<div
					v-else
					class="button-sd button-preloader"
				>
					<div class="button-preloader-icons">
						<div class="button-preloader-icon" />
						<div class="button-preloader-icon" />
						<div class="button-preloader-icon" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Cnt from '@/api/contest';
import {mapGetters} from 'vuex';
import {email, required, sameAs} from 'vuelidate/lib/validators';
import {alphaSpace, Phone} from '@/assets/scripts/custom_validators';

export default {
    name: 'SignInUr',
    data: () => ({
        isSending: false,
        form: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            city: {
                name: ''
            },
            study: {
                education: ''
            },
            external_service: {
                slug: 'ur2022'
            }
        },
        isPersonal: false,
        isAgree: false
    }),
    validations: {
        form: {
            first_name: {
                required,
                alphaSpace
            },
            last_name: {
                required,
                alphaSpace
            },
            phone: {
                required,
                Phone
            },
            city: {
                name: {
                    required
                }
            },
            email: {
                required,
                email
            },
            study: {
                education: {
                    required
                },
            },
        },
        isPersonal: {
            sameAs: sameAs(() => true)
        },
        isAgree: {
            sameAs: sameAs(() => true)
        }
    },
    computed: {
        ...mapGetters('cnst', {
            eduOptions: 'getEducation'
        }),
    },
    methods: {
        registration() {
            this.$v.form.$touch();
            if (this.$v.form.$invalid) {
                console.log('Error in modal Reg');
            } else if (!this.isSending || !this.$v.form.$invalid) {
                this.isSending = true
                if(this.$metrika && (window.location.hostname === 'stage.lift.tbproject.ru' || window.location.hostname === 'lift-bf.ru')) {
                    this.$metrika.reachGoal('urok22')
                }
                Cnt.registerToEvent({ data: this.form, slug:  this.form.external_service.slug })
                    .then(() => {
                        if((window.location.hostname === 'stage.lift.tbproject.ru' || window.location.hostname === 'lift-bf.ru') && this.$metrika) {
                            this.$metrika.reachGoal('ur2022')
                        }
                        this.modalOpen('eventSuccess')
                        this.$v.form.$reset()
                        this.$v.isPersonal.$reset()
                        this.$v.isAgree.$reset()
                        this.form = {
                            first_name: '',
                            last_name: '',
                            phone: '',
                            email: '',
                            city: {
                                name: ''
                            },
                            study: {
                                education: ''
                            }
                        }
                        this.isPersonal = false
                        this.isAgree = false
                    })
                    .catch(error => this.modalOpen('eventFail', error.response.data.error_text))
                    .finally(() => this.isSending = false)
            }
        },
        selectEdu(val) {
            this.form.study.education = val;
        }
    }
}
</script>
