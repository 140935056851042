<template>
	<div>
		<component :is="testPage" />
	</div>
</template>

<script>
import StartPage from '@/pages/test/StartPage';
import Task from '@/pages/test/Task';
import Results from '@/pages/test/Results';
import SelfRating from '@/pages/test/SelfRating';
import { mapGetters } from 'vuex';

export default {
    name: 'Index',
    components: {
        StartPage,
        Task,
        SelfRating,
        Results
    },
    data: () => ({
        usersCount: 0
    }),
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        }),
        testPage() {
            if (this.$route.query.task) return 'Task';
            if (this.$route.query.self_rating) return 'SelfRating';
            if (this.$route.params.id === 'mindformat') return 'StartPage';
            else if (this.$route.query.results) return 'Results';
            else return 'StartPage';
        },
    },
    mounted() {
        if (this.$route.query.modal === 'reg-form-drugoye-delo' && !this.isLogged) this.modalOpen('regIn')
    }
}
</script>

<style lang="less">
@import '../../assets/styles/pages/testing-competencies';
</style>
