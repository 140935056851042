<template>
	<section class="section section-questions">
		<div class="content">
			<div class="questions">
				<h2 class="title-section">
					Частые вопросы
				</h2>
				<div class="questions__list">
					<div
						v-for="(item, index) in infoQA"
						:key="index"
						class="question"
						:class="{'question--active': isActive === index}"
						@click="toggleItem(index)"
					>
						<div class="question__toggle">
							<div class="question__index">
								{{ index + 1 }}
							</div>
							<div
								class="question__title"
							>
								{{ item.question }}
							</div>
						</div>
						<transition name="fade">
							<div
								class="question__content"
								:style="isActive === index? 'display: block' : 'display: none'"
							>
								<div class="description">
									{{ item.answer }}
								</div>
							</div>
						</transition>
					</div>
				</div>
				<div
					v-if="!isLogged"
					class="button-wrap"
				>
					<button
						class="button"
						@click="modalOpen('regIn', 'test')"
					>
						Зарегистрироваться
					</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Questions',
    data: () => ({
        infoQA: [
            {
                'question': 'Зачтут ли мне тесты, пройденные до появления на платформе стипендиальной программы?',
                'answer': 'Нет, во втором сезоне стипендиальной программы не предусмотрен зачет курсов или тестов для прогресса. Проходите их просто так, на платформе много разных полезностей.'
            },
            {
                'question': 'Что делать, если написано, что нужно загрузить конкурсную работу, а кнопки для загрузки пока нет?',
                'answer': 'С 20 мая мы откроем возможность загружать конкурсную работу через личный кабинет.'
            },
            {
                'question': 'Вы будете смотреть на успеваемость?',
                'answer': 'Нет, во втором сезоне программы успеваемость не учитывается.'
            },
            {
                'question': 'Обнулится ли мой прогресс на платформе, если я получу стипендию?',
                'answer': 'Прогресс по пройденным активностям на платформе не обнулится, но играть роль в этой стипендиальной программе не будет'
            },
            {
                'question': 'Как я узнаю о результате проверки моей конкурсной работы?',
                'answer': 'После технической экспертизы и проверки экспертом твоей работы в личном кабинете появится результат в баллах.'
            },
            {
                'question': 'До какого возраста можно претендовать на стипендию?',
                'answer': 'Участниками могут быть граждане РФ в возрасте до 30 лет. Однако, обучение в вузе или ссузе для стипендиата должно быть первым в его жизни.'
            },
            {
                'question': 'Я учусь в магистратуре, я могу претендовать на стипендию?',
                'answer': 'Да, обучающиеся по образовательным программам магистратуры последнего курса могут участвовать.'
            },
            {
                'question': 'Могут ли граждане других стран, кроме РФ, принимать участие в стипендиальной программе? Я обучаюсь в Екатеринбурге, но паспорт Казахстана',
                'answer': 'Принимать участие могут только граждане РФ.'
            },
            {
                'question': 'Как именно формируется рейтинг из лучших при составлении рейтинга на стипендию? ',
                'answer': 'Не более 600 участников смогут пройти в финал и защитить свою работу перед экспертами или пройти собеседование с HR-специалистами.'
            },
            {
                'question': 'Где я могу посмотреть общий рейтинг?',
                'answer': 'Все списки будут опубликованы в разделе «Новости» на этой странице.'
            },
            {
                'question': 'Как загрузить справку об обучении?',
                'answer': 'Справку об обучении участник прикрепляет в личном кабинете, больше ее никуда отправлять не нужно.'
            },
            {
                'question': 'Можно ли участвовать в программе студентам медицинского университета?',
                'answer': 'Никаких ограничений по специальностям студентов для получения стипендии не предусмотрено.'
            },
            {
                'question': 'Обязательно ли прикреплять справку об обучении и предполагаемом сроке обучения?',
                'answer': 'Да, этот документ обязателен. Его нужно загрузить в соответствующее поле в личном кабинете, а уже после отправить работу. Перед тем, как подавать заявку, обязательно посмотри Положение о конкурсе, чтобы увидеть, кто может принимать участие в программе.'
            },
            {
                'question': 'Когда будут известны даты публикации рейтингов и других важных этапов?',
                'answer': 'Все даты и основные этапы программы можно найти в разделе «Этапы программы».'
            }
        ],
        isActive: null
    }),
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    },
    methods: {
        toggleItem(index) {
            this.isActive = this.isActive === index ? null : index;
        }
    }
};
</script>
