<template>
	<div class="sections">
		<div
			v-if="!ageGroup.length && !fullCompetence.length"
			class="preloader"
			style="margin-top: 100px"
		/>
		<section
			v-else
			class="self"
		>
			<div class="content">
				<div class="panel-testing">
					<div class="self__title">
						Оцени свои навыки по шкале
					</div>
					<div
						class="self__list"
					>
						<div
							v-for="item in fullCompetence"
							:key="item.id"
							class="self-item"
						>
							<div
								class="self-item__indicator"
							/>
							<div class="self-item__header">
								<div class="self-item__title">
									{{ item.name }}
								</div>
							</div>
							<div class="scale">
								<div class="scale__track">
									<div class="range-slider-item">
										<Slider
											v-model="item.range"
											:step="1"
											:format="format"
											@change="getChosePercent(item)"
										/>
									</div>
								</div>
								<div class="scale__grid">
									<div class="scale__start">
										<div class="scale__percent">
											0%
										</div>
										<div class="scale__text">
											Навык отсутствует
										</div>
									</div>
									<div class="scale__center">
										<div class="scale__percent">
											50%
										</div>
										<div class="scale__text">
											Средний уровень
										</div>
									</div>
									<div class="scale__end">
										<div class="scale__percent">
											100%
										</div>
										<div class="scale__text">
											Высокий уровень
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="age">
						<div class="block-title">
							Выбери свой возраст
						</div>
						<div class="age__list">
							<label
								v-for="item in ageGroup"
								:key="item.id"
								class="age-item"
							>
								<input
									v-model="choseAge"
									type="radio"
									name="name-age"
									class="age-item__value"
									:value="item.id"
								>
								<div class="age-item__text">{{ item.name }}</div>
							</label>
						</div>
					</div>
					<div class="panel-testing__footer">
						<button
							v-if="!isSending"
							class="button orange"
							@click="sendSelfCompetence"
						>
							Закончить тестирование
							<img
								:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
						<div
							v-else
							class="button orange button-preloader"
							style="width:132px"
						>
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import Slider from '@vueform/slider/dist/slider.vue2.js';
// import '@vueform/slider/themes/default.css';
import '../../assets/styles/pages/self-raiting-test.css';
import {mapActions, mapGetters, mapMutations} from 'vuex';
export default {
    name: 'SelfRating',
    components: {
        Slider
    },
    data: () => ({
				test: 0,
        range: 0,
        format: function (value) {
            return `${value}%`
        },
        choseCompetence: [],
        choseAge: 0,
        isSending: false
    }),
    computed: {
        ...mapGetters('tst', {
            competence: 'getCompetence',
            ageGroup: 'getAgeGroup',
        }),
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        }),
        fullCompetence () {
            return this.competence && this.competence.map( item => ({
                id: item.id,
                name: item.competence.name,
                description: item.competence.description,
                range: 0
            }))
        }
    },
    mounted() {
        if (!this.isLogged) this.$router.push(`/tests/${this.$route.params.id}`)
        else this.getCompetence(localStorage.getItem('userSurvayId'))
    },
    methods: {
        ...mapActions('tst', [
            'getTest',
            'getCompetence',
            'sendCompetence'
        ]),
        ...mapMutations('tst', [
            'setSelfCompetence',
        ]),
        getChosePercent(item) {
            this.choseCompetence.push(item)
        },
        sendSelfCompetence () {
            let selfCompetence = [];
            this.choseCompetence.filter(function(item){
                let i = selfCompetence.findIndex(x => x.id === item.id);
                if(i <= -1){
                    selfCompetence.push({id: item.id, range: item.range});
                }
                return null
            })
            selfCompetence.forEach( item => {
                item.self_score = item.range
                delete item.range
            })

            if (selfCompetence.length > 0 && this.choseAge !== 0) {
                this.isSending = true
                if(this.$metrika && (window.location.hostname === 'stage.lift.tbproject.ru' || window.location.hostname === 'lift-bf.ru') && (this.$route.params.id === '3' || this.$route.params.id === '5')) {
                    this.$metrika.reachGoal('longtestend')
                }
                this.sendCompetence({id: localStorage.getItem('userSurvayId'), data: {
                        user_age_group_id: this.choseAge,
                        usercompetence_set: selfCompetence
                    }}).then(() => {
                        this.isSending = false
                        this.$scrollTo(document.querySelector('header.header-role'))
                        this.$router.push(`/tests/${this.$route.params.id}?results=true`)
                })
            }
        }
    }
}
</script>

<style lang="less">
.self .slider-connect, .self .slider-base {
    background: #F2F3F5 !important;
    border-radius: 0;
}
.self .slider-horizontal .slider-handle {
    height: 40px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #F2F3F5;
    box-shadow: 0 3px 23px #f2f3f5 0 6px 14px -6px rgb(24 39 75 / 12%) !important;
    width: 15px;
    top: -18px;
    transition: all 0.2s ease-in-out;
}
.self .slider-horizontal .slider-handle:hover {
    background: #EF722E;
}
.self .slider-tooltip {
    padding: 5px 10px;
    background: #fff;
    border: 1px solid #F2F3F5;
    box-shadow: 0 3px 23px #f2f3f5, 0 6px 14px -6px rgb(24 39 75 / 12%);
    border-radius: 8px;
    text-align: center;
    margin-bottom: 20px;
    color: #000;
    font-size: 18px;
}
.self .slider-horizontal .slider-tooltip:before {
    content: none
}
</style>
