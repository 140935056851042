const state = {
    education: [
        'Неоконченное среднее',
        'Среднее',
        'Среднее специальное',
        'Неоконченное среднее специальное',
        'Неоконченное высшее',
        'Высшее',
        'Ученая степень'
    ],
    activities: [
        'За 3 дня',
        'За неделю',
        'За месяц',
        'За год',
        'За все время'
    ],
    accessPages: [
        '20',
        '40',
        '60',
        '80',
        '100'
    ],
    employments: [
        {
            slug: 'full_employment',
            value: 'Полная занятость'
        },
        {
            slug: 'part_time_employment',
            value: 'Частичная занятость'
        },
        {
            slug: 'project_work',
            value: 'Проектная работа'
        },
        {
            slug: 'internship',
            value: 'Стажировка'
        },
        {
            slug: 'volunteering',
            value: 'Волонтерство'
        }
    ],
    userEmployments: [
        {
            slug: 'full_employment',
            value: 'Полная занятость',
            priority: 1
        },
        {
            slug: 'part_time_employment',
            value: 'Частичная занятость',
            priority: 2
        },
        {
            slug: 'project_work',
            value: 'Проектная работа',
            priority: 3
        },
        {
            slug: 'internship',
            value: 'Стажировка',
            priority: 4
        },
        {
            slug: 'volunteering',
            value: 'Волонтерство',
            priority: 5
        }
    ],
    responseStatuses: [
        {
            slug: 'new',
            name: 'Новый'
        },
        {
            slug: 'under_consideration',
            name: 'На рассмотрении'
        },
        {
            slug: 'invitation',
            name: 'Приглашение'
        },
        {
            slug: 'refusal',
            name: 'Отказ'
        }
    ],
    vacancyStatuses: [
        {
            slug: 'actual',
            name: 'Актуальные'
        },
        {
            slug: 'draft',
            name: 'Черновик'
        },
        {
            slug: 'archive',
            name: 'Архивные'
        }
    ],
    advancedSearch: [
        {
            slug: 'strict',
            name: 'Искать точную фразу'
        },
        {
            slug: 'search',
            name: 'Везде'
        },
        {
            slug: 'about_me',
            name: 'О себе'
        },
        {
            slug: 'education',
            name: 'Образование'
        },
        {
            slug: 'job_at_work',
            name: 'Опыт работы'
        },
        {
            slug: 'job_position',
            name: 'Желаемая должность'
        }
    ],
	roles: [
        {
			slug: 'Schoolboy',
			value: 'Школьник'
		},
		{
			slug: 'Student',
			value: 'Студент'
		},
		{
			slug: 'Specialist',
			value: 'Специалист'
		},
	],
	userStatus: [
        {
			slug: 'ADVANCED',
			value: 'Продвинутый',
            img: '/img/pages/event/pic__award-3--x2.png'
		},
		{
			slug: 'PRO',
			value: 'Профи',
            img: '/img/pages/event/pic__award-2--x2.png'
		},
		{
			slug: 'AMBASSADOR',
			value: 'Амбассадор',
            img: '/img/pages/event/pic__award-1--x2.png'
		},
		{
			slug: 'FELLOW',
			value: 'Стипендиат',
            img: '/img/pages/event/pic__award-0--x2.png'
		}
	],
    rolesActual: [
        {
            slug: 'student_low',
            value: 'Школьник'
        },
        {
            slug: 'student_high',
            value: 'Студент'
        },
        {
            slug: 'teacher',
            value: 'Учитель'
        },
        {
            slug: 'specialist',
            value: 'Специалист'
        },
        {
            slug: 'recruiter',
            value: 'Рекрутер'
        },
        {
            slug: 'manager',
            value: 'Менеджер'
        },
        {
            slug: 'unknown',
            value: 'Неизвестно'
        }
	],
    rolesLanding: [
        {
            slug: 'student_low',
            value: 'Школьникам'
        },
        {
            slug: 'student_high',
            value: 'Студентам'
        },
        {
            slug: 'expert',
            value: 'Специалистам'
        }
    ],
    categories: [
        {
            slug: 'course',
            value: 'Курс'
        },
        {
            slug: 'case',
            value: 'Кейс'
        },
        {
            slug: 'practice',
            value: 'Практика'
        },
        {
            slug: 'test',
            value: 'Тест'
        },
        {
            slug: 'article',
            value: 'Статья'
        },
        {
            slug: 'event',
            value: 'Мероприятие'
        },
        {
            slug: 'program',
            value: 'Программа'
        },
        {
            slug: 'contest',
            value: 'Конкурс'
        }
    ],
    userGroup: [
        {
            label: 'Schoolboy',
            value: 1
        },
        {
            label: 'College',
            value: 2
        },
        {
            label: 'Student',
            value: 3
        },
        {
            label: 'Specialist',
            value: 4
        },
        {
            label: 'Teacher',
            value: 5
        },
    ],
    requestStatuses: [
        {
            label: 'Отправлен на проверку',
            value: 1,
        },
        {
            label: 'Ожидает модерации',
            value: 2,
        },
        {
            label: 'Требует доработки',
            value: 3,
        },
        {
            label: 'Ожидает экспертной оценки',
            value: 4,
        },
        {
            label: 'Отклонен',
            value: 5,
        },
        {
            label: 'В процессе оценки',
            value: 6,
        },
        {
            label: 'Получена оценка',
            value: 7,
        },
        {
            label: 'Подтверждение оценки',
            value: 8,
        },
        {
            label: 'Допущен к следующему этапу',
            value: 9,
        },
        {
            label: 'В архиве',
            value: 10,
        },
        {
            label: 'Черновик',
            value: -1,
        },
    ],
    months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
    ]

}

const getters = {
    getEducation: state => state.education,
    getEmployments: state => state.employments,
    getUserEmployments: state => state.userEmployments,
    getRS: state => state.responseStatuses,
    getVS: state => state.vacancyStatuses,
    getActivities: state => state.activities,
    getAccessPages: state => state.accessPages,
    getAdvancedSearch: state => state.advancedSearch,
    getRoles: state => state.roles,
    getRolesLanding: state => state.rolesLanding,
    getCategories: state => state.categories,
    getUserStatus: state => state.userStatus,
    getUserGroup: state => state.userGroup,
    getRequestStatuses: state => state.requestStatuses,
    getMonths: state => state.months,
}



export default {
	namespaced: true,
	state,
	getters
};
