<template>
	<div class="modal-content">
		<div class="center">
			<div class="modal-title-wrap">
				<div class="modal-title sm">
					Авторизация
				</div>
				<h4 class="modal-subtitle">
					Принимать участие могут только авторизованные пользователи. Войдите в свой аккаунт или зарегистрируйтесь.
				</h4>
			</div>
			<div class="buttons-list">
				<button
					class="button-hollow"
					@click="signIn"
				>
					Вход
				</button>
				<button
					class="button blue"
					@click="regIn"
				>
					Регистрация
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  methods: {
    signIn() {
       this.modalOpen('signIn') 
    },
    regIn() {
       this.modalOpen('regIn') 
    },
    modalClose() {
       this.modalClose('noAuth') 
    },
  }
}
</script>