<template>
	<div class="modal-content">
		<div class="recording-video-block">
			<div class="media">
				<video
					ref="preview"
					width="100%"
					height="100%"
					autoplay
					preload="metadata"
					:controls="isRecorded"
					controlsList="nodownload"
				/>
				<input
					ref="file"
					type="file"
					accept="video/*"
					capture="user"
					style="display: none"
				>
				<div
					v-if="recorder.isTimer"
					class="recording-video-countdown-block"
				>
					<div class="recording-video-countdown-value">
						{{ recorder.timer / 1000 }}
					</div>
					<div class="recording-video-countdown-title">
						До начала записи
					</div>
				</div>
				<div class="recording-video-control-group">
					<button
						v-if="isStart && !recorder.isTimer"
						class="recording-video-control-item"
						@click="start"
					>
						Начать запись
						<img
							:src="'/img/icons/icon-record.svg' | prodSrcImage"
							alt="Начать запись"
						>
					</button>
					<button
						v-if="isPauseStop"
						class="recording-video-control-item"
						@click="pause"
					>
						Пауза
						<img
							:src="'/img/icons/icon-video-pause-white.svg' | prodSrcImage"
							alt="Пауза"
						>
					</button>
					<button
						v-if="isResumeStopRepeat"
						class="recording-video-control-item"
						@click="resume"
					>
						Продолжить
						<img
							:src="'/img/icons/icon-video-play-white.svg' | prodSrcImage"
							alt="Продолжить"
						>
					</button>
					<button
						v-if="isPauseStop || isResumeStopRepeat"
						class="recording-video-control-item"
						@click="stop"
					>
						Остановить запись
						<img
							:src="'/img/icons/icon-video-stop-white.svg' | prodSrcImage"
							alt="Остановить запись"
						>
					</button>
					<button
						v-if="isRepeat || isResumeStopRepeat"
						class="recording-video-control-item"
						@click="repeat"
					>
						Начать заново
						<img
							:src="'/img/icons/icon-repeat-white-2.svg' | prodSrcImage"
							alt="Начать заново"
						>
					</button>
				</div>
			</div>

			<div class="recording-video-content">
				<div
					v-for="(item, index) in recorder.errors"
					:key="index"
					class="recording-video-output red"
				>
					{{ item }}
				</div>
				<!-- <div class="recording-video-output green">Видео успешно записано</div> -->
				<div class="recording-video-progress-title">
					Осталось до конца лимита:
				</div>
				<div class="progress">
					<div
						class="progress-line"
						:style="{ 'width': limiting}"
					/>
				</div>
				<div class="button-group">
					<button
						class="button-hollow"
						@click="modalOpen('fastVacancy', { step: 3 })"
					>
						Назад
					</button>
					<button
						class="button"
						:class="isRecorded ? 'blue' : 'gray'"
						@click="save"
					>
						Сохранить
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    data: () => ({
        recorder: {
            el: undefined,
            isTimer: false,
            isStarted: false,
            isStoped: false,
            isResumed: false,
            isPaused: false,
            limit: 25 * Math.pow(2, 20),
            timer: 3 * 1000,
            chunks: [],
            errors: [],
            options: {
                controls: false
            }
        }
    }),
    computed: {
        limiting() {
            if (this.recorder.chunks.length) {
                const result = this.recorder.chunks.reduce((x, y) => x += y.size, 0) / this.recorder.limit * 100
                return `${result.toFixed(2)}%`
            } else {
                return '0%'
            }
        },
        isStart() {
            return !this.recorder.isStarted && !this.recorder.isStoped && !this.recorder.isResumed && !this.recorder.isPaused
        },
        isPauseStop() {
            return (this.recorder.isStarted || this.recorder.isResumed) && !this.recorder.isStoped && !this.recorder.isPaused
        },
        isRepeat() {
            return this.recorder.isStoped && !(this.recorder.isStarted && this.recorder.isResumed && this.recorder.isPaused)
        },
        isResumeStopRepeat() {
            return this.recorder.isPaused && !(this.recorder.isStarted && this.recorder.isStoped && this.recorder.isResumed)
        },
        isRecorded() {
            return this.recorder.isStoped && !!this.recorder.chunks.length
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            navigator.mediaDevices.getUserMedia({
                audio: true,
                video: true
            }).then(stream => {
                this.recorder.errors = []
                this.$refs.preview.srcObject = stream

                this.recorder.el = new MediaRecorder(stream, { mimeType: 'video/webm' })
                this.recorder.el.ondataavailable = e => {
                    const currentSize = this.recorder.chunks.reduce((accum, current) => accum += current.size, 0)
                    if (currentSize + e.data.size < this.recorder.limit) {
                        // Проблема записи "последней мили"
                        if (this.recorder.isStarted || this.recorder.isResumed) this.recorder.chunks.push(e.data)
                    } else {
                        this.stop()
                        this.recorder.errors.push('Достигнут лимит записи!')
                    }
                }
            }).catch(() => {
                navigator.permissions.query({ name: 'camera' })
                    .then(permissionObj => permissionObj.state === 'denied' ? this.recorder.errors.push('Доступ к камере запрещен!') : false)
                navigator.permissions.query({ name: 'microphone' })
                    .then(permissionObj => permissionObj.state === 'denied' ? this.recorder.errors.push('Доступ к микрофону запрещен!') : false)
            })
        },
        start() {
            this.recorder.timer = 3000
            this.recorder.isTimer = true
            setTimeout(() => {
                this.recorder.isTimer = false
                this.recorder.isStarted = true
                this.recorder.el.start(1000)
            }, this.recorder.timer)
            const timer = setInterval(() => {
                if (this.recorder.timer > 0) this.recorder.timer -= 1000
                else clearInterval(timer)
            }, 1000)
        },
        stop() {
            if (this.recorder.el.state === 'recording' || this.recorder.el.state === 'paused') {
                this.recorder.isStarted = false
                this.recorder.isResumed = false
                this.recorder.isPaused = false
                this.recorder.isStoped = true
                this.recorder.el.stop()
                this.$refs.preview.srcObject.getTracks().forEach(stop)
                this.$refs.preview.srcObject = null
                this.$refs.preview.src = URL.createObjectURL(new Blob(this.recorder.chunks))
            }
        },
        resume() {
            this.recorder.timer = 3000
            this.recorder.isTimer = true
            this.recorder.isStarted = false
            this.recorder.isStoped = false
            this.recorder.isPaused = false
            setTimeout(() => {
                this.recorder.isTimer = false
                this.recorder.isResumed = true
                this.recorder.el.resume()
            }, this.recorder.timer)
            const timer = setInterval(() => {
                if (this.recorder.timer > 0) this.recorder.timer -= 1000
                else clearInterval(timer)
            }, 1000)
        },
        pause() {
            this.recorder.isStarted = false
            this.recorder.isStoped = false
            this.recorder.isResumed = false
            this.recorder.isPaused = true
            this.recorder.el.pause()
        },
        repeat() {
            this.recorder.isStarted = false
            this.recorder.isStoped = false
            this.recorder.isResumed = false
            this.recorder.isPaused = false
            this.recorder.chunks = []
            this.recorder.errors = []
            this.init()
        },
        save() {
            if (this.isRecorded) {
                const blob = new Blob(this.recorder.chunks, { type: 'video/webm' })
                this.modalOpen('fastVacancy', { step: 3, recordVideo: blob })
            }
        }
    }
}
</script>
