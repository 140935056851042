import Cnt from '@/api/contests'


const state = {
    contests: undefined,
    requests: undefined
}

const actions = {
    getAllContests({ commit }, status) {
        Cnt.getContests(status)
            .then(response => response.data)
            .then(results => commit('setContests', results))
    },
    getContestsRequests({ commit }, id) {
        Cnt.getContestRequests(id)
            .then(response => response.data)
            .then(result => commit('setRequests', result))
    },
}

const mutations = {
    setContests(state, contests) {
        state.contests = contests
    },
    setRequests(state, requests) {
        state.requests = requests
    },
}

const getters = {
    getContests: state => state.contests,
    getRequests: state => state.requests,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
