<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<h3 class="modal-title">
				{{ title }}
			</h3>
		</div>
		<form
			class="form"
			novalidate
			@submit.prevent="changePass"
		>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.newPass.$model"
						type="password"
						class="field"
						placeholder="Новый пароль"
					>
					<div class="label">Новый пароль</div>
					<span
						v-if="$v.newPass.$error"
						class="help-block form-error"
					>
						{{
							!$v.newPass.required
								? "Это обязательное поле"
								: !$v.newPass.password ||
									!$v.newPass.minLength
									? `Пароль должен состоять из букв (минимум одна заглавная буква), символов и цифр, не менее 8 символов`
									: "Ошибка поля"
						}}
					</span>
				</label>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.confNew.$model"
						type="password"
						class="field"
						placeholder="Повторите пароль"
					>
					<div class="label">Повторите пароль</div>
					<span
						v-if="$v.confNew.$error"
						class="help-block form-error"
					>
						{{
							!$v.confNew.required
								? "Это обязательное поле"
								: !$v.confNew.sameAsPassword
									? "Пароли не совпадают"
									: "Ошибка поля"
						}}
					</span>
				</label>
			</div>
			<BaseErrorMessageForm
				v-if="responseError.isShow"
				:text="responseError.text"
			/>
			<button
				v-if="!isSended"
				class="button orange lg"
			>
				Сохранить изменения
			</button>
			<div
				v-else
				class="button orange lg button-preloader"
			>
				<div class="button-preloader-icons">
					<div class="button-preloader-icon" />
					<div class="button-preloader-icon" />
					<div class="button-preloader-icon" />
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { password } from '@/assets/scripts/custom_validators';
import User from '@/api/user';
import BaseErrorMessageForm from '@/components/BaseComponents/BaseErrorMessageForm.vue';

export default {
	components: {
		BaseErrorMessageForm,
	},
	data: () => ({
		isSended: false,
		newPass: '',
		confNew: '',
		responseError: {
			isShow: false,
			text: '',
		}
	}),
	validations: {
		newPass: {
			required,
			minLength: minLength(8),
      password
		},
		confNew: {
			required,
			sameAsPassword: sameAs(function() {
				return this.newPass;
			})
		},
		validationGroup: ['newPass', 'confNew']
	},
	computed: {
		payload() {
			return this.$store.state.modal.payload;
		},
		title() {
			if (this.payload && this.payload.title) return this.payload.title;
			else return 'Смена пароля';
		}
	},
	methods: {
		errorHandler(errorText) {
			this.responseError.isShow = true;
			this.responseError.text = errorText;
		},
		changePass() {
			this.responseError.isShow = false;
			this.$v.validationGroup.$touch();
			if (this.$v.validationGroup.$invalid) {
				console.log('Somewhere error');
			} else {
				if (this.payload && !this.payload.title && this.payload.upid && !this.isSended) {
					this.isSended = true;
					User.recovery({
						pass: this.newPass,
						upid: this.payload.upid
					})
						.then(() => this.modalClose('passSuccess'))
						.catch(error => this.errorHandler(error.response.data.error_text))
						.finally(() => (this.isSended = false));
				} else if (this.payload?.isBackOffice && !this.isSended) {
					this.isSended = true;
					User.setNewPassword(
							this.newPass,
							this.payload.email_token,
							this.payload.ttl,
							this.payload.uid
					)
						.then(() => this.modalClose('passSuccess'))
						.catch(error => this.errorHandler(error.response.data.detail))
						.finally(() => (this.isSended = false));
				} else if (!this.isSended) {
					this.isSended = true;
					User.newpass(this.newPass)
						.then(() => this.modalClose('passSuccess'))
						.catch(error => this.errorHandler(error.response.data.new_password[0]))
						.finally(() => (this.isSended = false));
				}
			}
		}
	}
};
</script>
