<template>
	<button
		class="base-button"
		:class="classNames"
		@click="$emit('clickButton')"
	>
		<slot />
		<div
			v-if="isPreloader"
			class="button-preloader-icons"
		>
			<div class="button-preloader-icon" />
			<div class="button-preloader-icon" />
			<div class="button-preloader-icon" />
		</div>
	</button>
</template>

<script>
export default {
    props: {
        orange: {
            type: Boolean,
            default: false
        },
        blue: {
            type: Boolean,
            default: false
        },
        gray: {
            type: Boolean,
            default: false
        },
        transparent: {
            type: Boolean,
            default: false
        },
        lightBorder: {
            type: Boolean,
            default: false
        },
        whiteBorder: {
            type: Boolean,
            default: false
        },
        favorite: {
            type: Boolean,
            default: false
        },
        favoriteActive: {
            type: Boolean,
            default: false
        },
        isDefaultPadding: {
            type: Boolean,
            default: true
        },
        isPreloader: {
            type: Boolean,
            default: false
        },
        lg: {
            type: Boolean,
            default: false
        },
        md: {
            type: Boolean,
            default: false
        },
        isBorderBottom: {
            type: Boolean,
            default: false
        },
        isBorderBottomNone: {
            type: Boolean,
            default: false
        },
        maxWidth: {
            type: Boolean,
            default: false
        },
        shadowBorder: {
            type: Boolean,
            default: false
        },
        
    },
    computed: {
        classNames() {
            return {
                'base-button--orange': this.orange,
                'base-button--blue': this.blue,
                'base-button--gray': this.gray,
                'base-button--favorite': this.favorite,
                'base-button--favorite-active': this.favoriteActive,
                'base-button--light-border': this.lightBorder,
                'base-button--shadow-border': this.shadowBorder,
                'base-button--white-border': this.whiteBorder,
                'base-button--transparent': this.transparent,
                'base-button--default-padding': this.isDefaultPadding,
                'base-button--preloader': this.isPreloader,
                'base-button--lg': this.lg,
                'base-button--md': this.md,
                'base-button--border-bottom': this.isBorderBottom,
                'base-button--border-bottom-none': this.isBorderBottomNone,
                'base-button--max-width': this.maxWidth,
            };
        }
    }
};
</script>

<style lang="less" scoped>
.base-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 16px;
    &--default-padding {
        padding: 12px 25px;
    }
    &--max-width {
        width:100%;
    }
    &--orange {
        background-color: #ef722e;
        color: #ffffff;
        &:hover {
            box-shadow: 10px 10px 25px rgba(239, 114, 46, 0.2);
        }
    }
    &--blue {
        background-color: #1c56b2;
        color: white;
        border-radius: 5px;
        &:hover {
            box-shadow: 10px 10px 25px rgba(28, 86, 178, 0.2);
        }
    }
    &--shadow-border {
        box-shadow: 10px 10px 25px rgba(255, 255, 255, 0.2);
        &:hover {
            box-shadow: 10px 10px 25px rgb(28 86 178 / 20%);
        }
    }
    &--gray {
        background-color: #6c6d6d;
        color: white;
        border-radius: 5px;
        &:hover {
            box-shadow: 10px 10px 25px rgb(108, 109, 109);
        }
    }
    &--transparent {
        background-color: transparent;
        color: black;
    }
    &--favorite {
        display: flex;
        position: relative;
        background-color: transparent;
        font-size: 16px;
        color: #999;
        .transition;
        @media @oldy-end {
            font-size: 15px;
        }
        &::before {
            content: "";
            display: block;
            opacity: 0.4;
            width: 18px;
            height: 16px;
            margin-right: 10px;
            background: url("../../assets/img/icons/icon-favorite.svg") center
            center no-repeat;
        }
        &:hover {
            background-color: transparent;
            color: black;
            &::before {
                opacity: 1;
            }
        }
        &-active {
            &::before {
                opacity: 1;
                background-image: url("../../assets/img/icons/icon-favorite-active.svg");
            }
        }
    }
    &--light-border {
        border: 1px solid #dbdbdb;
        &:hover {
            background-color: #ef722e;
            border-color: transparent;
            color: white;
        }
    }
    &--white-border {
        border: 1px solid #ffffff;
        color: white;
        &:hover {
            background-color: #ef722e;
            border-color: transparent;
            color: white;
        }
    }
    &--preloader {
        &:hover {
            box-shadow:initial;
        }
    }
    &--lg {
        height: 60px;
        line-height: 58px;
    }
    &--md {
        height:52px;
    }
    &--border-bottom {
        padding: 0;
        border-radius: 0;
        border-bottom: 1px solid #999;
        font-size: 13px;
        color: #999;

        &:hover {
            border-bottom-color: #ef722e;
            color: #ef722e;
        }
        &-none {
            display: inline-block;
            padding: 0;
            font-size: 13px;
            cursor: pointer;
            color: @color-light;
            &:hover {
                color: #EF722E;
            }
        }
    }
}
.button-preloader-icons {
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 0;
    background-color: #1c56b2;
    border-radius: 5px;
}
</style>
