<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<div class="modal-title sm">
				Изменить сферы деятельности
			</div>
		</div>

		<div
			v-if="!isLoaded"
			class="preloader sm"
		/>
		<div
			v-else
			class="modal-rubrics-block"
		>
			<form>
				<div class="modal-rubrics-list">
					<label
						v-for="activity in activities"
						:key="activity.id"
						class="checkbox-filter"
					>
						<input
							v-model="selected"
							type="checkbox"
							:value="activity"
						>
						<span>{{ activity.name }}</span>
					</label>
				</div>
				<button
					class="button orange"
					@click.prevent="save"
				>
					Сохранить
				</button>
			</form>
			<!-- <div class="info info-link">Назад</div> -->
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    data: () => ({
        activities: [],
        selected: []
    }),
    computed: {
        ...mapGetters('tb', {
            getAllActivities: 'getAllActivities'
        }),
        isLoaded() {
            return !!this.activities.length
        },
        payload() {
            return this.$store.state.modal.payload
        }
    },
    created() {
        this.selected = this.payload
        if (this.getAllActivities) {
            this.activities = this.getAllActivities
        } else {
            this.$store.dispatch('tb/getAllCompanyActivity')
                .then(result => this.activities = result)
        }
    },
    methods: {
      ...mapActions('modal', ['setActivities']),
        save() {
            this.setActivities(this.selected);
            this.modalClose()
        }
    }
}
</script>
