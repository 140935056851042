import { render, staticRenderFns } from "./SortedCourses.vue?vue&type=template&id=010e499a&scoped=true"
import script from "./SortedCourses.vue?vue&type=script&lang=js"
export * from "./SortedCourses.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "010e499a",
  null
  
)

export default component.exports