<template>
	<!-- <div v-if="test.landing_url">
		<a
			:href="test.landing_url"
			class="test"
		>
			<img
				:src="test.image"
				alt="Фото"
				class="test__pic"
			>
			<div class="test__content">
				<div class="test__icon" />
				<div class="test__title">
					{{ test.name }}
				</div>
			</div>
		</a>
	</div>
	<div v-else>
		<router-link
			:to="`/tests/${test.id}`"
			class="test"
		>
			<img
				:src="test.image"
				alt="Фото"
				class="test__pic"
			>
			<div class="test__content">
				<div class="test__icon" />
				<div class="test__title">
					{{ test.name }}
				</div>
			</div>
		</router-link>
	</div> -->
	<div>
		<router-link
			:to="test.landing_url ? link : `/tests/${test.id}`"
			class="test"
		>
			<img
				:src="test.image"
				alt="Фото"
				class="test__pic"
			>
			<div class="test__content">
				<div class="test__icon" />
				<div class="test__title">
					{{ test.name }}
				</div>
			</div>
		</router-link>
	</div>
</template>

<script>
export default {
    name: 'TestCard',
    props: {
        test: {
            type: Object,
            default: null
        }
    },
		computed: {
			link() {
				return new URL(this.test?.landing_url).pathname;
			},
		}
};
</script>
