<template>
	<div class="sections">
		<section class="results">
			<div class="content">
				<div
					v-if="!result"
					class="preloader"
					style="margin-top: 100px"
				/>
				<div
					v-else
					class="results__title"
				>
					Результаты теста <br>
					«{{ result.survay.survay_name }}»
				</div>
				<div class="results__date">
					дата тестирования {{ result.created }}
				</div>
				<div class="results__name">
					{{ result.user }}
				</div>
				<div class="results__list">
					<div
						v-for="item in competence"
						:key="item.id"
						class="result"
					>
						<div class="result__header">
							<div class="result__title">
								{{ item.competence.name }}
							</div>
						</div>
						<div
							class="progress-result"
							:class="{'progress-result--green': item.score > 59,
								'progress-result--red': item.score < 28,
								'progress-result--yellow': item.score > 28 && item.score < 60}"
						>
							<div class="progress-result__value">
								{{ item.score }}%
							</div>
							<div class="progress-result__track">
								<div
									class="progress-result__line"
									:style="{'width': item.score + '%'}"
								/>
								<div
									class="progress-result__self"
									:style="{'right': 100 - item.self_score + '%'}"
								>
									{{ item.self_score }}% - самооценка
								</div>
								<div
									class="progress-result__group"
									:style="{'right': 100 - item.group_score + '%'}"
								>
									{{ item.group_score }}% - оценка по группе
								</div>
							</div>
						</div>
						<div class="result__footer">
							<div
								class="result__more"
								data-modal="#modal-result"
								@click="modalOpen('comp-more', {item, result})"
							>
								Смотреть подробнее
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="user"
					class="results-footer"
				>
					<div class="results-footer__title">
						Поделиться результатами:
						<div class="social-contact-group">
							<ShareNetwork
								network="vk"
								:title="'Результаты теста «' + result.survay.survay_name + '»'"
								:url="result.certificate_sharing_image"
							>
								<a class="social-contact-item">
									<img
										:src="'/img/icons/icon-contact-vk-2-md.png' | prodSrcImage"
										alt=""
									>
								</a>
							</ShareNetwork>
						</div>
					</div>
					<div class="results-footer__text">
						Ты всегда сможешь посмотреть свои результаты в личном кабинете
					</div>
					<router-link
						:to="user.is_onbording ? `/profile/${userID}?tests=true` : '/onboarding'"
						class="button orange"
					>
						Перейти в личный кабинет
					</router-link>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'Results',
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            userID: 'userID',
            user:'userInfo'
        }),
        ...mapGetters('tst', {
            result: 'getResultTest',
            competence: 'getCompetence'
        }),
    },
    mounted() {
        if (!this.isLogged) this.$router.push(`/tests/${this.$route.params.id}`)
        else this.getCompetence(localStorage.getItem('userSurvayId')).then(res => {
            const meta = document.createElement('meta');
            meta.property = 'og:image';
            meta.content = res[0].certificate_sharing_image;
            document.getElementsByTagName('head')[0].appendChild(meta);
        })
    },
    methods: {
        ...mapActions('tst', [
            'getCompetence'
        ])
    }
}
</script>

<style lang="less" scoped>
.page-testing-competencies .progress-result__group {
    margin-right: -20px;
}
</style>
