import Tst from '@/api/tests'

const state = {
    tests: null,
    test: null,
    selectedanswer: [],
    answers: null,
    competence: [],
    ageGroup: [],
    selfCompetence: [],
    userTests: [],
    userSurvayId: null,
    resultTest: [],
    isPassed: false
}

const actions = {
    getAllTests({ commit }) {
        return new Promise(resolve => {
            Tst.getAllTests()
                .then(response => response.data)
                .then(result => {
                    commit('setTests', result)
                    resolve(result)
                })
        })
    },
    getCompetence({ commit }, id) {
        return new Promise(resolve => {
            Tst.getCompetence(id)
                .then(response => response.data)
                .then(result => {
                    commit('setResultTest', result[0])
                    commit('setCompetence', result[0].usercompetence_set)
                    commit('setAgeGroup', result[0].age_groups)
                    resolve(result)
                })
        })
    },
    getUserTests({ commit }) {
        return new Promise(resolve => {
            Tst.getUserTests()
                .then(response => response.data)
                .then(result => {
                    commit('setUserTests', result)
                    resolve(result)
                })
        })
    },
    sendCompetence({}, {id, data}) {
        return new Promise(resolve => {
            Tst.sendCompetence({id, data})
                .then(response => response.data)
                .then(result => {
                    resolve(result)
                })
        })
    },
    getTest({ commit }, id) {
        return new Promise(resolve => {
            Tst.getTest(id)
                .then(response => response.data)
                .then(result => {
                    commit('setTest', result)
                    commit('setIsPassed', result.is_passed)
                    resolve(result)
                })
        })
    },
    sendTestAnswers({ commit }, data) {
        return new Promise(resolve => {
            Tst.sendAnswers(data)
                .then(response => response.data)
                .then(result => {
                    commit('setResult', result)
                    resolve(result)
                })
        })
    },
}

const mutations = {
    setTests(state, tests) {
        state.tests = tests
    },
    setCompetence(state, competence) {
        state.competence = competence
    },
    setAgeGroup(state, ageGroup) {
        state.ageGroup = ageGroup
    },
    setSelfCompetence(state, competence) {
        state.selfCompetence = competence
    },
    setTest(state, test) {
        state.test = test.case_set
    },
    setAnswers(state, answer) {
        state.selectedanswer.push(answer)
    },
    setResult(state, result) {
        state.answers = result
    },
    setUserTests(state, tests) {
        state.userTests = tests
    },
    setUserSurvayID(state, id) {
        state.userSurvayId = id
        localStorage.setItem('userSurvayId', state.userSurvayId)
    },
    setResultTest(state, res) {
        state.resultTest = res
    },
    setIsPassed(state, isPassed) {
        state.isPassed = isPassed
    }
}

const getters = {
    getAllTests: state => state.tests,
    getUserTests: state => state.userTests,
    getCompetence: state => state.competence,
    getTest: state => state.test,
    getAnswers: state => state.selectedanswer,
    getSelfCompetence: state => state.selfCompetence,
    getUserSurvayID: state => state.userSurvayId,
    getAgeGroup: state => state.ageGroup,
    getResultTest: state => state.resultTest,
    isPassed: state => state.isPassed,
}



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
