<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<div class="modal-title sm">
				Добавление образования
			</div>
		</div>

		<form class="form">
			<div class="field-wrap">
				<div class="cells">
					<div class="cell">
						<div class="field-wrap">
							<label class="field-label">
								<c-select
									:options="options.edu"
									:name="'Образование'"
									:default-data="study.edu"
									:is-error="$v.study.edu.$error"
									@selected="selectEdu"
								/>
							</label>
						</div>
						<div
							v-if="isExtraStudy"
							class="field-wrap"
						>
							<label class="field-label">
								<input
									v-model="$v.study.spec.$model"
									type="text"
									class="field"
									placeholder="Специализация"
								>
								<div class="label">Специализация</div>
								<span
									v-if="$v.study.spec.$error"
									class="help-block form-error"
								>
									Не более {{ $v.study.spec.$params.maxLength.max }} символов
								</span>
							</label>
						</div>
					</div>
					<div class="cell">
						<div
							v-if="isExtraStudy"
							class="field-wrap"
						>
							<label class="field-label">
								<c-select
									:name="'Учебное заведение'"
									autosearch-education
									:is-error="$v.study.edu_fac.$error"
									@selected="selectEduFac"
								/>
							</label>
						</div>
						<div
							v-if="isExtraStudy"
							class="field-wrap"
						>
							<label class="field-label">
								<c-select
									:options="options.grad_year"
									:name="'Год окончания'"
									:is-error="$v.study.grad_year.$error"
									@selected="selectYear"
								/>
							</label>
						</div>
					</div>
				</div>
			</div>
			<button
				class="button orange lg"
				@click.stop.prevent="addNewStudy"
			>
				Добавить
			</button>
		</form>
	</div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
    data: () => ({
        isExtraStudy: false,
        study: {
            edu: '',
            edu_fac: '',
            spec: '',
            grad_year: 0
        },
        options: {
            grad_year: []
        }
    }),
    validations() {
        if (this.isExtraStudy) {
            return {
                study: {
                    edu: {
                        required
                    },
                    edu_fac: {
                        required
                    },
                    spec: {
                        maxLength: maxLength(128)
                    },
                    grad_year: {
                        required
                    }
                }
            }
        } else {
            return {
                study: {
                    edu: {
                        required
                    }
                }
            }
        }
    },
    computed: {
        ...mapGetters('cnst', {
            eduOptions: 'getEducation'
        }),
    },
    created() {
        let start = 1980;
        while (start <= 2027) {
            this.options.grad_year.push(start++)
        }
    },
    methods: {
        ...mapMutations('tb', {
            add: 'addNewResumeStudy'
        }),
        addNewStudy() {
            this.$v.study.$touch()
            if (this.$v.study.$invalid) {
                console.log('Error in Study modal')
            } else {
                let temp = {
                    education: this.study.edu,
                    specialization: {
                        name: this.study.spec
                    },
                    education_facility: {
                        name: this.study.edu_fac
                    },
                    graduation_year: this.study.grad_year ? this.study.grad_year : 0
                }
                this.add(temp)
                this.modalClose()
            }
        },
        selectEdu(val) {
            this.study.edu = val;
            if (val.toLowerCase() === 'неоконченное среднее' || val.toLowerCase() === 'среднее') this.isExtraStudy = false
            else this.isExtraStudy = true
        },
        selectEduFac(val) {
            this.study.edu_fac = val.toUpperCase();
        },
        selectYear(val) {
            this.study.grad_year = val
        },
    }
}
</script>
