<template>
	<section
		v-if="skillsMain"
		class="section section-skills"
	>
		<div class="content content--md">
			<!-- У блока .skills есть 8 модификаторов меняющих цвет фона: skills--black, skills--orange, skills--blue, skills--pink, skills--peach, skills--yellow, skills--lightblue, skills--lightgreen -->
			<div
				class="skills"
				:class="ColorClass"
			>
				<div class="skills__header">
					<h2 class="title-section">
						{{ skillsMain.if_you_are_title }}
					</h2>
				</div>
				<div
					v-if="skillsInfo"
					class="skills__content"
				>
					<div
						v-for="(item, index) in skillsInfo"
						:key="index"
						class="skill"
					>
						<div
							class="skill__text"
							v-html="item.description"
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Skills',
    props: {
        skillsMain: {
            type: Object,
            default: null
        },
        skillsInfo: {
            type: Array,
            default: null
        }
    },
    computed: {
        ColorClass() {
            if (this.skillsMain.if_you_are_background_color === '#000000') return 'skills--black'
            if (this.skillsMain.if_you_are_background_color === '#D4F2FF') return 'skills--lightgreen'
            if (this.skillsMain.if_you_are_background_color === '#EF722E') return 'skills--orange'
            if (this.skillsMain.if_you_are_background_color === '#004494') return 'skills--blue'
            if (this.skillsMain.if_you_are_background_color === '#F8C8FF') return 'skills--pink'
            if (this.skillsMain.if_you_are_background_color === '#FFD5D6') return 'skills--peach'
            if (this.skillsMain.if_you_are_background_color === '#FFF8D5') return 'skills--yellow'
            if (this.skillsMain.if_you_are_background_color === '#D5F2FF') return 'skills--lightblue'
            return ''
        }
    }
}
</script>
