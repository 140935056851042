<template>
	<div class="modal-content">
		<div class="welcome-modal">
			<div class="title-block">
				Добро пожаловать на платформу «Лифт в будущее»!
			</div>
			<div class="welcome-modal__text">
				Мы ценим индивидуальный подход и хотим, чтобы вы легко находили полезную информацию. Для этого расскажите нам немного о себе
			</div>
			<div class="field-questionary">
				<div class="field-questionary__title">
					Выберите профиль на платформе
				</div>
				<c-select
					:options="roleOptions"
					:default-data="
						modalReg.role
							? roles.find(x => x.slug === modalReg.role).value
							: modalReg.role
					"
					:is-error="$v.modalReg.role.$error"
					is-role
					@selected="selectRole"
				/>
			</div>
			<div class="welcome-modal__text">
				Чтобы вы могли легко восстановить свой пароль, если забудете, укажите ваш номер телефона. Обещаем, что никому его не передадим и не будем присылать на него спам.
			</div>
			<div class="field-simple">
				<div class="field-simple__title">
					Введите номер
				</div>
				<input
					v-model="$v.modalReg.phone.$model"
					v-mask="'+7(###)###-##-##'"
					type="text"
					class="field-simple__value"
					:class="{'field-simple__value--error': $v.modalReg.phone.$error}"
					placeholder="+7 ("
				>
				<div
					v-if="$v.modalReg.phone.$error"
					class="field-simple__error"
				>
					{{
						!$v.modalReg.phone.required
							? "Это обязательное поле"
							: !$v.modalReg.phone.Phone
								? "Введите корректный номер телефона"
								: "Ошибка поля"
					}}
				</div>
			</div>
			<div class="welcome-modal__footer">
				<BaseButton
					style="margin: 0 auto"
					class="button"
					:is-preloader="isSigning"
					orange
					@clickButton="editData"
				>
					Отправить
				</BaseButton>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import {Phone} from '@/assets/scripts/custom_validators';
import BaseButton from '@/components/BaseComponents/BaseButton';

export default {
    name: 'AddRole',
    components: {
        BaseButton
    },
    data: () => ({
        isSigning: false,
        modalReg: {
            phone: '+7(',
            role: ''
        }
    }),
    validations: {
        modalReg: {
            phone: {
                required,
                Phone
            },
            role: {
                required
            }
        }
    },
    computed: {
        ...mapGetters('cnst', {
            roles: 'getRoles'
        }),
        roleOptions() {
            return this.roles.map(x => x.value);
        }
    },
    methods: {
        ...mapActions('user', {
            save: 'change'
        }),
        ...mapMutations('user', {
            setUserRole: 'setUserRole'
        }),
        selectRole(value) {
            this.modalReg.role = this.roles.find(
                rol => rol.value === value
            ).slug;
        },
        editData() {
            this.$v.modalReg.$touch();
            if (this.$v.modalReg.$invalid) {
                console.log('Error in modal Reg');
            } else if (!this.isSended || !this.$v.modalReg.$invalid) {
                this.isSigning = true
                this.save({
                    user_role: this.modalReg.role,
                    phone: this.modalReg.phone
                }).then(response => {
                    this.isSigning = false
                    this.modalClose()
                    localStorage.setItem('userRole', response.user_role)
                    this.setUserRole(response.user_role)
                    if (!response.is_onbording) this.$router.push('/onboarding')
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
