<template>
	<div
		v-if="projects"
		class="events__slider"
	>
		<VueSlickCarousel
			class="slider"
			v-bind="carouselCareerSettings"
		>
			<div
				v-for="project in projects"
				:key="project.id"
			>
				<div class="event">
					<a
						:href="project.url"
						target="_blank"
						class="event__pic"
						:style="project.image | bgrDynamicImageCover"
					>
						<span
							v-for="(section, index) in project.sections"
							:key="index"
							class="event__type"
						>{{ section }}</span>
					</a>
					<div class="event__content">
						<h3 class="event__title">
							{{ project.title }}
						</h3>
						<a
							:href="project.url"
							class="more-link"
							target="_blank"
						>
							Подробнее
							<img
								:src="'/img/icons/icon__arrow-right--orange.svg' | prodSrcImage"
								alt="Иконка"
							>
						</a>
					</div>
				</div>
			</div>
		</VueSlickCarousel>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'SpecProjects',
    props: {
        role: {
            type: String,
            default: null
        }
    },
    data: () => ({
        carouselCareerSettings: {
            arrows: true,
            infinite: false,
            dots: true,
            'slides-to-show': 4,
            'slides-to-scroll': 4,
            draggable: false,
            speed: 600,
            swipe: true,
            'touch-move': true,
            responsive: [
                {
                    breakpoint: 1099,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    }),
    computed: {
        ...mapGetters('tb', {
            projects: 'getSpecialProjects'
        })
    },
    async created() {
        await this.getSpecialProjects({
            visible_to: this.role
        })
    },
    methods: {
        ...mapActions('tb', [
            'getSpecialProjects'
        ])
    }
};
</script>

<style scoped>

</style>
