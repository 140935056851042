import {HTTP, getToken} from './default'

export default {
    getFirstThreeBlocks(slug) {
        return HTTP({
            url: `/event/${slug}/landing_first_three_blocks/`,
            method: 'GET',
        })
    },
    getSecondFourBlocks(slug) {
        return HTTP({
            url: `/event/${slug}/landing_second_four_blocks/`,
            method: 'GET',
        })
    },
    getThirdFourBlocks(slug) {
        return HTTP({
            url: `/event/${slug}/landing_third_four_blocks/`,
            method: 'GET',
        })
    },
    getRemainingBlocks(slug) {
        return HTTP({
            url: `/event/${slug}/landing_remaining_blocks/`,
            method: 'GET',
        })
    },
    uploadFiles({data, file_link, slug}) {
        return HTTP({
            url: `/event/${slug}/upload_file/`,
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`,
                'Content-Type': 'multipart/form-data'
            },
            data,
            params: {
                file_link: file_link.length ? file_link.join() : ''
            }
        })
    },
    getAccessToken() {
        return HTTP({
            url: '/auth/get_external_access_token/',
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
        })
    }
}
