<template>
	<div
		:class="modalClass"
		@mousedown="clickOutModal"
		@keydown.esc.stop.prevent="modalClose()"
	>
		<div class="modal-container">
			<div class="modal-inner">
				<div
					class="modal-body"
					:class="{'modal-signup-sd': currentActive === 'sign-in-ur'}"
				>
					<div
						v-if="currentActive !== 'add-role' && currentActive !== 'get-prize'"
						class="modal-close"
						data-modal-close
						@click.stop="modalClose"
					/>
					<component :is="currentActive" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {mapGetters} from 'vuex'

import ApplicationSumbition from './ApplicationSumbition.vue'
import NoAuth from './NoAuth.vue'
import SignIn from './SignIn'
import RegIn from './RegIn'
import StdSuccess from './StdSuccess'
import StdFail from './StdFail'
import RecoveryPass from './RecoveryPass'
import ChangePass from './ChangePass'
import MainMenu from './MainMenu'
import SendPromoCode from './SendPromoCode'
import UserActivate from './UserActivate'
import Activation from './Activation'
import Video from './Video'
import MainBannerVideo from './MainBannerVideo'
import Payload from './Payload'
import DeleteUser from './DeleteUser'
import AddStudy from './AddStudy'
import helpLift from './helpLift'
import isRegisteredEvent from './isRegisteredEvent'
import signInUr from './SignInUr'
import CompMore from './CompetenceMore'

import DonateSuccess from './christmas/DonateSuccess'
import ShowQR from './christmas/ShowQR'

// Vacancy
import ReqVacancy from './vacancy/ReqVacancy'
import FastVacancy from './vacancy/FastVacancy'
import RecordVideo from './vacancy/RecordVideo'


// Ambassadors
import Ambassadors from './Ambassadors'
import AmbassadorsForStudents from './AmbassadorsForStudents'
import AmbdForStdNastavnik from './AmbdForStdNastavnik'
import Mentor from './Mentor'

// Contests
import ConRequest from './contest/Request'
import RequestTrainee from './contests/RequestTrainee'
import ConNonAuth from './contest/NonAuth'
import ConVoice from './contest/Voice'
import ApplyToContest from './contest/ApplyToContest.vue'
import RequestSuccess from './contest/RequestSuccess.vue'

// Recruiter
import changeFieldActivity from './cabinet/recruiter/changeFieldActivity'
import changeQuestionVacancy from './cabinet/recruiter/changeQuestionVacancy'
import helpModalQuestions from './cabinet/recruiter/helpModalQuestions'
import changeSpec from './cabinet/recruiter/changeSpec'
import DeletedFavoriteVacancyVue from './vacancy/DeletedFavoriteVacancy.vue'
import CertificateView from '@/components/modal/cabinet/user/CertificateView';
import addKey from '@/components/modal/cabinet/recruiter/addKey';
import MainMenu2 from '@/components/modal/MainMenu2';
import TestView from '@/components/modal/cabinet/user/TestView';
import LandingScs from '@/components/modal/LandingScs';
import Teacher from '@/components/modal/catalog/Teacher';
import addRole from '@/components/modal/addRole';
import Program from '@/components/modal/achievement/Program';
import StaticProgram from '@/components/modal/achievement/StaticProgram';
import Prize from '@/components/modal/achievement/Prize';
import MotivationSuccess from '@/components/modal/achievement/MotivationSuccess';
import userIsNotComplete from '@/components/modal/cabinet/user/userIsNotComplete.vue';
import AboutExpert from '@/components/modal/contest/expert/AboutExpert.vue';
import ChooseNominations from '@/components/modal/contest/expert/ChooseNominations.vue';
import AddStudyWorkPlaces from '@/components/modal/contest/expert/AddStudyWorkPlaces.vue';

export default {
    components: {
        'applicationSumbition': ApplicationSumbition,
        'noAuth': NoAuth,
        'signIn': SignIn,
        'regIn': RegIn,
        'stdSuccess': StdSuccess,
        'stdFail': StdFail,
        'SendPromoCode': SendPromoCode,
        'recPass': RecoveryPass,
        'changePass': ChangePass,
        'mainMenu': MainMenu,
        'mainMenuSecond': MainMenu2,
        'activat': Activation,
        'userActiv': UserActivate,
        'video_': Video,
        'mBanVideo': MainBannerVideo,
        'payload': Payload,
        'reqVacancy': ReqVacancy,
        'fastVacancy': FastVacancy,
        'recordVideo': RecordVideo,
        'testing': Payload,
        'delete': DeleteUser,
        'addStudy': AddStudy,
        'conReq': ConRequest,
        'conReqTrainee': RequestTrainee,
        'conNonAuth': ConNonAuth,
        'conVoice': ConVoice,
        'ambsdr': Ambassadors,
        'ambsdr-2': AmbassadorsForStudents,
        'ambsdr-3': AmbdForStdNastavnik,
        'mentor': Mentor,
        'changeFieldAct': changeFieldActivity,
        'changeQuestionVacancy': changeQuestionVacancy,
        'helpModalQuestions': helpModalQuestions,
        'changeSpec': changeSpec,
        'deletedFavoriteVacancy': DeletedFavoriteVacancyVue,
        'helpLift': helpLift,
        'cert-view': CertificateView,
        'test-view': TestView,
        'addKeySuc': addKey,
        'is-registered-event': isRegisteredEvent,
        'sign-in-ur': signInUr,
        'comp-more': CompMore,
        'catalog-teach': Teacher,
        'landing-scs': LandingScs,
        'add-role': addRole,
        'ach-program': Program,
        'static-program': StaticProgram,
        'get-prize': Prize,
        'motivation-scs': MotivationSuccess,
        'donate-scs': DonateSuccess,
        'show-qr': ShowQR,
        'apply-contest': ApplyToContest,
        'contest-ok': RequestSuccess,
        'is-user-complete': userIsNotComplete,
        'about-expert': AboutExpert,
        'choose-nominations': ChooseNominations,
        'add-sp-wp': AddStudyWorkPlaces
    },
    computed: {
        ...mapGetters('modal', [
            'currentActive',
            'currentSuccess'
        ]),
        modalClass() {
            if (this.currentActive === 'mainMenu') return 'modal-full modal-menu'
            else if (this.currentActive === 'testing') return 'modal-full modal-testing'
            else if (this.currentActive === 'mainMenuSecond') return 'modal-full modal-burger'
            else if (this.currentActive === 'payload') return 'modal modal-media'
            else if (this.currentActive === 'video_') return 'modal modal-video'
            else if (this.currentActive === 'cert-view') return 'modal modal-certificate'
            else if (this.currentActive === 'recordVideo') return 'modal modal-recording-video'
            else if (this.currentActive === 'mBanVideo') return 'modal-full modal-video-full'
            else if (this.currentActive === 'addStudy'
                || this.currentActive === 'changeFieldAct'
                || this.currentActive === 'changeQuestionVacancy'
                || this.currentActive === 'changeSpec'
                || this.currentActive === 'mentor') return 'modal lg'
            else if (this.currentActive === 'fastVacancy') return 'modal xl'
            else if (this.currentActive === 'sign-in-ur') return 'modal modal-signup-sd'
            else if (this.currentActive === 'conReq' && !this.payload) return 'modal modal-non-close'
            else if (this.currentActive === 'comp-more') return 'modal modal-result-details'
            else if (this.currentActive === 'landing-scs') return 'modal modal-subscribed'
            else if (this.currentActive === 'catalog-teach') return 'modal modal-certificate'
            else if (this.currentActive === 'add-role') return 'modal modal-welcome'
            else if (this.currentActive === 'get-prize') return 'modal modal-prize'
            else if (this.currentActive === 'apply-contest'
                || this.currentActive === 'about-expert'
                || this.currentActive === 'add-sp-wp'
                || this.currentActive === 'choose-nominations') return 'modal xl'
            else if (this.currentActive === 'contest-ok') return 'modal modal--md'
            else if (this.currentActive === 'is-user-complete') return 'modal modal--m'
            else if (this.currentActive === 'motivation-scs'
                || this.currentActive === 'donate-scs'
                || this.currentActive === 'show-qr' ) return 'modal modal-subscribed'
            else if (this.currentActive === 'ach-program'
                || this.currentActive === 'static-program') return 'modal modal-achievement'
            else return 'modal'
        }
    },
    methods: {
        clickOutModal(event) {
            if (!event.target.closest('.modal-body')
                && this.currentActive !== 'add-role'
                && this.currentActive !== 'get-prize'
                && this.currentActive !== 'apply-contest'
                && this.currentActive !== 'about-expert'
                && this.currentActive !== 'add-sp-wp'
                && this.currentActive !== 'choose-nominations'
            ) {
                this.modalClose()
            }
        }
    }
}
</script>

<style lang="less" scoped>
.modal.modal-video.modal-banner-video .modal-body {
    max-width: 93%;
}
.modal-signup-sd.modal-body {
    max-width: 350px !important;
    padding: 40px 30px 45px;
    box-shadow: 0 4px 33px rgb(0 0 0 / 29%);
    border-radius: 17px;
}
</style>
