<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<div
				v-if="payload"
				class="modal-title sm"
			>
				Подать заявку
			</div>
			<div
				v-else
				class="modal-title sm"
			>
				Прием заявок завершен
			</div>
		</div>
		<form
			v-if="payload"
			class="form"
		>
			<div class="text dark sm">
				Персональная информация:
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.form.team_name.$model"
						type="text"
						class="field"
						placeholder="Название команды"
					>
					<div class="label">Название команды</div>
					<span
						v-if="$v.form.team_name.$error"
						class="help-block form-error"
					>Это обязательное поле</span>
				</label>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.form.email.$model"
						type="email"
						class="field"
						placeholder="Email"
					>
					<div class="label">Email</div>
					<span
						v-if="$v.form.email.$error"
						class="help-block form-error"
					>
						{{ !$v.form.email.required ? 'Это обязательное поле' : !$v.form.email.email ? 'Введите корректный e-mail' : 'Ошибка поля' }}
					</span>
				</label>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.form.phone.$model"
						v-mask="'+7(###)###-##-##'"
						type="text"
						class="field"
						placeholder="Мобильный телефон"
					>
					<div class="label">Мобильный телефон</div>
					<span
						v-if="$v.form.phone.$error"
						class="help-block form-error"
					>
						{{ !$v.form.phone.required ? 'Это обязательное поле' : !$v.form.phone.Phone ? 'Введите корректный номер телефона' : 'Ошибка поля' }}
					</span>
				</label>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.form.company_name.$model"
						type="text"
						class="field"
						placeholder="Название компании"
					>
					<div class="label">Название компании</div>
					<span
						v-if="$v.form.company_name.$error"
						class="help-block form-error"
					>Это обязательное поле</span>
				</label>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.form.position.$model"
						type="text"
						class="field"
						placeholder="Должность"
					>
					<div class="label">Должность</div>
					<span
						v-if="$v.form.position.$error"
						class="help-block form-error"
					>Это обязательное поле</span>
				</label>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<textarea
						v-model.trim="$v.form.team_members.$model"
						rows="3"
						class="field"
						placeholder="ФИО участников"
					/>
					<div class="label">ФИО участников</div>
					<span
						v-if="$v.form.team_members.$error"
						class="help-block form-error"
					>Это обязательное поле</span>
				</label>
			</div>
			<div class="text dark sm">
				Конкурсная работа:
			</div>
			<div class="upload-wrap">
				<label
					v-if="!form.video.value"
					class="upload"
				>
					<input
						ref="file"
						type="file"
						@change="selectVideo"
					>
					<div class="upload-info">
						<img
							:src="'/img/icons/icon-arrow-upload.svg' | prodSrcImage"
							alt="Загрузить видео на конкурс"
						>
						<div
							class="upload-info-title"
							v-html="validFormat"
						/>
					</div>
					<div class="upload-title">Нажмите, чтобы загрузить Ваше видео</div>
					<span
						v-if="$v.form.video.value.$error"
						class="help-block form-error"
					>Это обязательное поле</span>
					<span
						v-else-if="form.video.error"
						class="help-block form-error"
						v-text="form.video.error"
					/>
				</label>
				<div
					v-else-if="isSended"
					class="uploading"
				>
					<div class="progress">
						<div
							class="progress-line"
							:style="progress"
						/>
					</div>
				</div>
				<div
					v-else
					class="uploaded"
				>
					<div
						v-if="form.video.value"
						class="uploaded-title"
					>
						{{ form.video.value.name }}
					</div>
					<div
						class="uploaded-remove"
						@click="form.video.value = undefined"
					/>
				</div>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.form.name.$model"
						type="text"
						class="field"
						placeholder="Профессия, которой посвящен видеоролик"
					>
					<div class="label ">
						<span class="hidden-mobile">Профессия, которой посвящен видеоролик</span>
						<span class="visible-mobile">О какой профессии ролик</span>
					</div>
					<span
						v-if="$v.form.name.$error"
						class="help-block form-error"
					>Это обязательное поле</span>
				</label>
			</div>
			<div class="field-wrap">
				<c-select
					:name="'Номинация'"
					:options="form.nominations.enum"
					:default-data="form.nominations.value"
					:is-error="$v.form.nominations.value.$error"
					@selected="selectNomination"
				/>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<textarea
						v-model="form.comment"
						rows="3"
						class="field"
						placeholder="Комментарии к работе"
						data-validation="required"
					/>
					<div class="label">Комментарии к работе</div>
				</label>
			</div>
			<button
				v-if="!isSended"
				class="button orange lg"
				@click.prevent="sendRequest"
			>
				Отправить
			</button>
			<div
				v-else
				class="button orange lg button-preloader"
			>
				<div class="button-preloader-icons">
					<div class="button-preloader-icon" />
					<div class="button-preloader-icon" />
					<div class="button-preloader-icon" />
				</div>
			</div>
		</form>
	</div>
</template>

<script>

import { required, email } from 'vuelidate/lib/validators'
import { Phone } from '@/assets/scripts/custom_validators'
import Cnt from '@/api/contest'
import {mapGetters} from 'vuex'

export default {
    data: () => ({
        isSended: false,
        percentProgress: 0,
        form: {
            team_name: '',
            email: '',
            phone: '',
            company_name: '',
            position: '',
            name: '',
            nominations: {
                value: '',
                enum: []
            },
            comment: '',
            video: {
                el: undefined,
                value: undefined,
                error: ''
            },
            team_members: ''
        }
    }),
    computed: {
        ...mapGetters('modal', {
            payload: 'getPayload',
            activeData: 'getActiveData'
        }),
        ...mapGetters('user', {
            user: 'userInfo',
        }),
        ...mapGetters('cnt', {
            contest: 'getContest',
        }),
        // ...mapGetters('cmn', {
        //     settings: 'getContest',
        // }),
        settings() {
            return this.$store.state.cmn.settings && this.$store.state.cmn.settings[0]
        },
        validFormat() {
            return `Допустимые форматы: ${this.settings && this.settings.video_format ? this.settings.video_format.toUpperCase() : 'MP4, AVI, MOV'};<br>
            Длительность видеоролика должна составлять до ${this.settings && this.settings.video_duration ? Math.ceil(this.settings.video_duration / 60) : '5'} минут(ы);<br>
            Размер видеоролика не должен превышать ${this.settings && this.settings.video_max_size ? this.settings.video_max_size + 'Мбайт' : '1Гбайт'};<br>
            Максимальное разрешение Full HD (${this.settings && this.settings.video_resolution ? this.settings.video_resolution : '8K'}).`
        },
        progress() {
            return `width: ${this.percentProgress * 100}%;`
        }
    },
    validations() {
        return {
            form: {
                team_name: { required },
                email: { required, email },
                phone: { required, Phone },
                company_name: { required },
                position: { required },
                name: { required },
                nominations: { value: { required }},
                video: { value: { required }},
                team_members: { required }
            }
        }
    },
    created() {
        if (this.activeData) {
            this.form = JSON.parse(JSON.stringify(this.activeData))
            this.form.video.value = this.activeData.video.value
            this.form.video.el = this.activeData.video.el
            this.eventUploadProgress(this.form.video.el)
        } else {
            this.form.email = this.user && this.user.email ? this.user.email : ''
            this.form.phone = this.user && this.user.phone ? this.user.phone : ''
            this.contest.nominations.forEach(item => this.form.nominations.enum.push(item.name))
        }

        !this.settings && this.$store.dispatch('cmn/getSettings')
    },
    methods: {
        selectNomination(val) {
            this.form.nominations.value = val
        },
        sendRequest() {
            this.$v.form.$touch()

            if (this.$v.form.$invalid) {
                console.log('Errors in Request')
            } else {
                let formData = new FormData()

                formData.append('user', this.$store.state.user.user.id)
                for (let key in this.form) {
                    if (key === 'nominations') formData.append(key, this.contest.nominations.find(item => item.name === this.form[key].value).id)
                    else if (key === 'video') formData.append(key, this.form[key].value)
                    else formData.append(key, this.form[key])
                }

                this.isSended = true
                this.isSended && Cnt.sendContest({ data: formData, inputFile: this.form.video.el })
                    .then(() => {
                        this.$store.commit('modal/resetActiveData')
                        this.modalClose('videoContestSuccess')
                    })
                    .catch(error => {
                        this.$store.commit('modal/setActiveData', this.form)
                        this.modalClose('videoContestFail', error.response.data.error_text)
                    })
                    .finally(() => this.isSended = true)
            }
        },
        selectVideo(event) {
            let file = event.target.files[0]

            if (this.settings.video_max_size && ((file.size / 2**20) > this.settings.video_max_size)) {
                this.form.video.error = 'Файл превышает допустимый размер'
                console.log('Video file is very large')
            } else if (!file.type || this.settings.video_format && !this.isFormatVideoValid(file, this.settings.video_format)) {
                this.form.video.error = 'Файл не соответствует нужному формату'
                console.log('Video file hasn`t need format')
            } else {
                this.form.video.error = ''
                this.form.video.value = file
                this.eventUploadProgress(event.target)
            }
        },
        isFormatVideoValid(video, format) {
            if (format === 'mov' && (video.type.includes(format) || video.type.includes('quicktime'))) return true
            else if (format === 'mp4' && video.type.includes(format)) return true
            else if (format === 'avi' && video.type.includes(format)) return true

            return false
        },
        eventUploadProgress(el) {
            this.form.video.el = el
            this.form.video.el.addEventListener('uploadprogress',
                event => this.percentProgress = Math.fround(event.detail.loaded / event.detail.total), false)
        }
    }
}
</script>
