<template>
	<section class="section section-steps">
		<div class="content content--md">
			<div class="steps">
				<h2 class="title-section">
					Этапы программы
				</h2>
				<div class="steps__list">
					<div class="step">
						<div class="step__index title-mono">
							1 этап
						</div>
						<div class="step__period">
							С 29 марта 2023 г.
						</div>
						<div class="step__text">
							Регистрация на платформе «Лифт в будущее» и заполнение личного профиля
						</div>
						<div
							v-if="step1"
							class="step__description"
						>
							Подробно заполни личный профиль на платформе. Очень важно, чтобы твои данные были корректными. Регистрация на платформе возможна с 29 марта по 30 июня 2023 г.
						</div>
						<div
							class="step__toggle-wrap"
							@click="step1 = !step1"
						>
							<div class="step__toggle">
								{{ step1 ? 'Свернуть' : 'Подробнее' }}
							</div>
						</div>
					</div>
					<div class="step">
						<div class="step__index title-mono">
							2 этап
						</div>
						<div class="step__period">
							с 15 апреля 2023 г.
						</div>
						<div class="step__text">
							Прохождение теста на платформе «Лифт в будущее»
						</div>
						<div
							v-if="step2"
							class="step__description"
						>
							Наш тест поможет тебе оценить потенциал и особенности твоего мышления.
							Развивай soft и hard skills, проходя бесплатные курсы.
						</div>
						<div
							class="step__toggle-wrap"
							@click="step2 = !step2"
						>
							<div class="step__toggle">
								{{ step2 ? 'Свернуть' : 'Подробнее' }}
							</div>
						</div>
					</div>
					<div class="step">
						<div class="step__index title-mono">
							3 этап
						</div>
						<div class="step__period">
							С 20 мая 2023 г.
						</div>
						<div class="step__text">
							Загрузка конкурсной работы
						</div>
						<div
							v-if="step3"
							class="step__description"
						>
							Пробуй свои силы в решении заданий от реальных работодателей. Выбери одну из предложенных номинаций и загрузи свою работу до 30 июня 2023 (до 23:55 мск).
						</div>
						<div
							class="step__toggle-wrap"
							@click="step3 = !step3"
						>
							<div class="step__toggle">
								{{ step3 ? 'Свернуть' : 'Подробнее' }}
							</div>
						</div>
					</div>
					<div class="step">
						<div class="step__index title-mono">
							4 этап
						</div>
						<div class="step__period">
							до 28 сентября 2023 г.
						</div>
						<div class="step__text">
							Заочная экспертиза работ
						</div>
						<div
							v-if="step4"
							class="step__description"
						>
							Экспертный совет определит список финалистов, которые будут приглашены на онлайн-мероприятие (дистанционную защиту или собеседование) для определения списка стипендиатов.
						</div>
						<div
							class="step__toggle-wrap"
							@click="step4 = !step4"
						>
							<div class="step__toggle">
								{{ step4 ? 'Свернуть' : 'Подробнее' }}
							</div>
						</div>
					</div>
					<div class="step">
						<div class="step__index title-mono">
							5 этап
						</div>
						<div class="step__period">
							28 сентября – 30 октября 2023 г.
						</div>
						<div class="step__text">
							Финал Программы
						</div>
						<div
							v-if="step5"
							class="step__description"
						>
							Финалисты участвуют в дистанционной защите проектов или проходят собеседование с HR-специалистами компаний Группы АФК «Система».
						</div>
						<div
							class="step__toggle-wrap"
							@click="step5 = !step5"
						>
							<div class="step__toggle">
								{{ step5 ? 'Свернуть' : 'Подробнее' }}
							</div>
						</div>
					</div>
					<div class="step">
						<div class="step__index title-mono">
							6 этап
						</div>
						<div class="step__period">
							30 октября 2023 г.
						</div>
						<div class="step__text">
							Объявление стипендиатов
						</div>
						<div
							v-if="step6"
							class="step__description"
						>
							Публикация списка победителей стипендиальной программы.
						</div>
						<div
							class="step__toggle-wrap"
							@click="step6 = !step6"
						>
							<div class="step__toggle">
								{{ step6 ? 'Свернуть' : 'Подробнее' }}
							</div>
						</div>
					</div>
					<div class="step">
						<div class="step__index title-mono">
							7 этап
						</div>
						<div class="step__period">
							С ноября 2023 г. по март 2024 г.
						</div>
						<div class="step__text">
							Выплата стипендий, ежемесячно
						</div>
						<div
							v-if="step7"
							class="step__description"
						>
							Целых 5 месяцев получай до 20 000 рублей и трать их на саморазвитие и все необходимое для карьерного роста.
						</div>
						<div
							class="step__toggle-wrap"
							@click="step7 = !step7"
						>
							<div class="step__toggle">
								{{ step7 ? 'Свернуть' : 'Подробнее' }}
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="!isLogged"
					class="button-wrap"
				>
					<button
						class="button"
						@click="modalOpen('regIn', 'test')"
					>
						Зарегистрироваться
					</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Steps',
    data: () => ({
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        step6: false,
        step7: false,
    }),
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    }
};
</script>
