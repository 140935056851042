<template>
	<div class="modal-content">
		<div class="modal-prize__steps">
			<div
				v-for="(item, index) in payload.progress_bar"
				:key="index"
				class="modal-prize__step"
				:class="{'modal-prize__step--active': item.finish}"
			>
				<img
					:src="item.icon"
					alt="Приз"
					class="modal-prize__step-icon"
				>
				<div class="modal-prize__tooltip">
					{{ item.tips }}
				</div>
			</div>
		</div>
		<div class="modal-prize__title title-block">
			{{ payload.title }}
			<img
				:src="'/img/landing/role/pic__subscribed--x2.png' | prodSrcImage"
				alt="Иконка"
				class="modal-prize__title-icon"
			>
		</div>
		<div
			class="modal-prize__text"
			v-html="payload.text_area"
		/>
		<div
			v-if="payload.type === 'file'"
			class="modal-prize__text"
		>
			<p>
				Гайд: <a
					:href="payload.action"
					target="_blank"
				>{{ payload.action }}</a>
			</p>
		</div>
		<div
			v-if="coupon !== ''"
			class="modal-prize__promocode"
		>
			<div
				class="promocode"
				:class="{'promocode--active': isCopied}"
				@click="copyToClipBoard(coupon)"
			>
				{{ coupon }}
				<div class="promocode__tooltip">
					Промокод скопирован
				</div>
			</div>
		</div>
		<div class="modal-prize__notice">
			{{ payload.help_text }}
		</div>
		<div v-if="payload.action !== 'not_action'">
			<div
				v-if="payload.action !== '/motivation/present/?action=get_user_profile'"
				class="modal-prize__buttons"
			>
				<button
					v-if="!isLoading"
					class="button"
					@click="getData(payload, payload.type === 'url' ? 'Записаться' :
						coupon === '' ? 'Забрать приз': 'Активировать')"
				>
					{{ payload.type === 'url' ? 'Записаться' :
						coupon === '' ? 'Забрать приз': 'Активировать' }}
				</button>
				<div
					v-else-if="isLoading"
					class="button orange button-preloader"
				>
					Немного подождите...
					<div class="button-preloader-icons">
						<div class="button-preloader-icon" />
						<div class="button-preloader-icon" />
						<div class="button-preloader-icon" />
					</div>
				</div>
				<button
					class="button button--hollow"
					@click="openNextModal"
				>
					Не сейчас
				</button>
			</div>
			<div
				v-else
				class="modal-prize__buttons"
			>
				<button
					class="button"
					@click="getData(payload, 'Забрать приз')"
				>
					Подать заявку
				</button>
				<button
					class="button button--hollow"
					@click="openNextModal"
				>
					Не сейчас
				</button>
			</div>
		</div>
		<div v-else>
			<div class="modal-prize__buttons">
				<button
					class="button"
					@click="openNextModal"
				>
					Отлично
				</button>
			</div>
		</div>
		<div class="modal-prize__brands-wrap">
			<VueSlickCarousel
				v-bind="sliderSettings"
				class="modal-prize__brands-slider"
			>
				<div>
					<div class="modal-prize__brand">
						<img
							:src="'/img/brands/brand__mts--black.svg' | prodSrcImage"
							alt="МТС"
							class="modal-prize__brand-logo"
						>
					</div>
				</div>
				<div>
					<div class="modal-prize__brand">
						<img
							:src="'/img/brands/brand__segezha--black.svg' | prodSrcImage"
							alt="Segezha Group"
							class="modal-prize__brand-logo"
						>
					</div>
				</div>
				<div>
					<div class="modal-prize__brand">
						<img
							:src="'/img/brands/brand__etalon--black.svg' | prodSrcImage"
							alt="Эталон"
							class="modal-prize__brand-logo"
						>
					</div>
				</div>
				<div>
					<div class="modal-prize__brand">
						<img
							:src="'/img/brands/brand__step--black.svg' | prodSrcImage"
							alt="Степь Агрохолдинг"
							class="modal-prize__brand-logo"
						>
					</div>
				</div>
				<div>
					<div class="modal-prize__brand">
						<img
							:src="'/img/brands/brand__binnopharm--black.svg' | prodSrcImage"
							alt="Binnopharm Group"
							class="modal-prize__brand-logo"
						>
					</div>
				</div>
				<div>
					<div class="modal-prize__brand">
						<img
							:src="'/img/brands/brand__medsi--black.svg' | prodSrcImage"
							alt="Медси"
							class="modal-prize__brand-logo"
						>
					</div>
				</div>
				<div>
					<div class="modal-prize__brand">
						<img
							:src="'/img/brands/brand__besk--black.svg' | prodSrcImage"
							alt="АО БЭСК"
							class="modal-prize__brand-logo"
						>
					</div>
				</div>
			</VueSlickCarousel>
		</div>
		<!-- Картинки енота: pic__racoon-8--x2.png, pic__racoon-9--x2.png, pic__racoon-10--x2.png, pic__racoon-11--x2.png -->
		<img
			:src="'/img/racoon/pic__racoon-8--x2.png' | prodSrcImage"
			alt="Енот"
			class="modal-prize__pic"
		>
	</div>
</template>

<script>
import User from '@/api/user';
import {mapGetters, mapMutations, mapState} from 'vuex';

export default {
    name: 'Prize',
    data: () => ({
        isLoading: false,
        isCopied: false,
        coupon: '',
        sliderSettings: {
            infinite: false,
            easing: 'swing',
            arrows: true,
            dots: false,
            autoplay: false,
            autoplaySpeed: 3000,
            pauseOnFocus: false,
            pauseOnHover: false,
            speed: 600,
            draggable: false,
            slidesToShow: 7,
            slidesToScroll: 1,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 601,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 501,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 401,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    }),
    computed: {
        ...mapGetters('scholar', [
            'getModalPrize',
        ]),
        ...mapState('scholar', [
            'popupIndex'
        ]),
        payload() {
            return this.$store.state.modal.payload
        }
    },
    methods: {
        ...mapMutations('scholar', ['setPopupIndex']),
        getData(item, text) {
            if (item.type === 'url' || item.type === 'file') window.open(item.action, '_blank')
            if (item.type === 'method' && text === 'Забрать приз') {
                this.isLoading = true
                User.getPrize(item.action)
                    .then( res => {
                        if (res.data.coupon) this.coupon = res.data.coupon
                        if (res.data.user_profile) {
                            this.$router.push(res.data.user_profile)
                            this.modalClose()
                        }
                        this.isLoading = false
                    })
                    .catch(err => console.log(err))
            } else if (item.type === 'method' && text === 'Активировать') {
                if (localStorage.getItem('userRole') === 'Student' ||
                    localStorage.getItem('userRole') === 'College') window.open('https://www.ozon.ru/my/codes', '_blank')
                if (localStorage.getItem('userRole') === 'Schoolboy') window.open('https://music.vk.com/promocode', '_blank')
                User.UsedPrize(this.payload.present_id, 'popup=true').then(() => this.modalClose())
            }
            if (item.type !== 'method') User.UsedPrize(this.payload.present_id, 'popup=true').then(() => this.modalClose())
        },
        copyToClipBoard(textToCopy){
            navigator.clipboard.writeText(textToCopy);
            this.isCopied = true
            setTimeout(() => this.isCopied = false, 3000)
        },
        openNextModal() {
            User.UsedPrize(this.payload.present_id, 'popup=true').then( () => {
                this.modalClose()
                if (this.popupIndex < this.getModalPrize.length - 1) {
                    this.setPopupIndex(1)
                    this.modalOpen('get-prize', this.getModalPrize[this.popupIndex])
                } else this.setPopupIndex(0)
            })
        }
    }
}
</script>
