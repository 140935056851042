import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import modal from './modules/modal'
import tb from './modules/teachbase'
import cmn from './modules/common'
import ms from './modules/microServices'
import cnt from './modules/contest' // ToDo: переименовать
import contest from './modules/contests' // ToDo: переименовать
import rec from './modules/recruiter'
import ntf from './modules/notify'
import is from './modules/internship'
import org from './modules/organization'
import cmp from './modules/company'
import smpl from './modules/eventSample'
import cnst from './modules/constValues'
import searchUsers from './modules/search-users'
import access from './modules/access'
import esg from './modules/esg'
import tst from './modules/tests'
import events from './modules/events'
import scholar from './modules/scholarship'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        user,
        modal,
        tb,
        cmn,
        ms,
        cnt,
        rec,
        ntf,
        is,
        org,
        cmp,
        smpl,
        cnst,
        searchUsers,
        access,
        esg,
        tst,
        events,
        scholar,
        contest
    },
    strict: debug
})
