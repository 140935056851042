<template>
	<section
		v-wow
		class="section-simple brands wow fadeInUp"
		data-wow-delay="0.2s"
	>
		<div class="content">
			<div class="brands__list">
				<div
					v-for="(item, index) in images"
					:key="index"
					class="brand"
				>
					<div
						v-if="item.main"
						class="brand__title"
					>
						Главный партнер
					</div>
					<img
						:src="item.url | prodSrcImage"
						:alt="item.alt"
						class="brand__logo"
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'ExpertPartners',
    data: () => ({
        images: [
            {
                url: '/img/brands/brand1.svg',
                alt: 'Система'
            },
            {
                url: '/img/brands/brand2.svg',
                alt: 'МТС'
            },
            {
                url: '/img/brands/brand3.svg',
                alt: 'Segezha Group'
            },
            {
                url: '/img/brands/brand4.svg',
                alt: 'Эталон'
            },
            {
                url: '/img/brands/brand5.svg',
                alt: 'Степь Агрохолдинг'
            },
            {
                url: '/img/brands/brand6.svg',
                alt: 'Binnopharm Group'
            },
            {
                url: '/img/brands/brand7.svg',
                alt: 'Медси'
            },
            {
                url: '/img/brands/brand8.svg',
                alt: 'АО БЭСК'
            }
        ]
    })
};
</script>

<style scoped>
img {
    width: 140px;
    height: 100px;
}
</style>
