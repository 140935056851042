export default class Formater {

    constructor(data = []) {
        this.INPUT_DATA = data
        this.OUTPUT_DATA = undefined
    }

    setOptions(options) {
        this.INPUT_DATA = options

        return this
    }

    getFormated(type = 'default') {
        // type = 'default' || 'city' || 'cityVacancy'
        if (type === 'city') this.formatCity()
        else if (type === 'company') this.formatCompany()
        else if (type === 'reCompany') this.formatReCompany()
        else if (type === 'university') this.formatUniversity()
        else this.formatDefault()

        return this.OUTPUT_DATA
    }

    formatDefault() {
        if (this.INPUT_DATA.length) this.OUTPUT_DATA = new Set(this.INPUT_DATA)
        else this.OUTPUT_DATA = new Set()
    }

    formatCompany() {
        if (this.INPUT_DATA && this.INPUT_DATA.length) this.OUTPUT_DATA = new Set(this.INPUT_DATA.map(x => x.name))
        else this.OUTPUT_DATA = new Set()
    }

    formatReCompany() {
        if (this.INPUT_DATA && this.INPUT_DATA.length) this.OUTPUT_DATA = new Set(this.INPUT_DATA.map(x => x.name))
        else this.OUTPUT_DATA = new Set()
    }

    formatUniversity() {
        if (this.INPUT_DATA && this.INPUT_DATA.length) this.OUTPUT_DATA = new Set(this.INPUT_DATA.map(x => `${x.full_name}, ${x.city}`))
        else this.OUTPUT_DATA = new Set()
    }

    async formatCity() {

    }
}
