import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
const Courses = () => import('@/pages/Courses.vue')
const Course = () => import('@/pages/course/Course.vue')
const Career = () => import('@/pages/Career.vue')
const Vacancies = () => import('@/pages/Vacancies.vue')
const Vacancy = () => import('@/pages/Vacancy.vue')
const Company = () => import('@/pages/company/index')
const Organization = () => import('@/pages/organization/index')
const Contacts = () => import('@/pages/Contacts.vue')
const Cabinet = () => import('@/pages/cabinet')
const Projects = () => import('@/pages/Projects')
const ContestOscar = () => import('@/pages/contest')
const Users = () => import('@/pages/Users')
const Program = () => import('@/pages/program/')

//authorization
const AuthError = () => import('@/pages/authorization/AuthError')
const AuthCallback = () => import('@/pages/authorization/AuthCallback')

// extra
const Developers = () => import('@/pages/extra/Developers.vue')
const Error = () => import('@/pages/extra/Error.vue')
const Empty = () => import('@/pages/extra/Empty.vue')
const External = () => import('@/pages/extra/External.vue')
const Maintenance = () => import('@/pages/extra/Maintenance.vue')
const Grade = () => import('@/pages/extra/Grade.vue')

// docs
const Agreement = () => import('@/pages/docs/Agreement.vue')
const Annex = () => import('@/pages/docs/Annex.vue')
const Privacy = () => import('@/pages/docs/Privacy.vue')

// contest
const Contest = () => import('@/pages/contests/trainee/')
const Final = () => import('@/pages/contest/Final_2.vue')

// events
const Landing = () => import('@/pages/events/Landing.vue')
const LandingUr = () => import('@/pages/events/LandingUr.vue')
const LiftWithoutRefusal = () => import('@/pages/events/LiftWithoutRefusal.vue')
const AmbassadorsForStudents = () => import('@/pages/events/AmbassadorsForStudents.vue')
import AmbdForStdNastavnik from '@/pages/events/AmbdForStdNastavnik';
import School from '@/pages/events/School';
import Tests from '@/pages/Tests';
import Test from '@/pages/test/index';
import Student from '@/pages/events/Student';
import LandingContacts from '@/pages/events/LandingContacts';
import Unsubscribed from '@/pages/extra/Unsubscribed';
import Subscribed from '@/pages/extra/Subscribed';
import Expert from '@/pages/events/Expert';
import EventConstructor from '@/pages/events/EventConstructor';
import Scholarship from '@/pages/events/Scholarship';
import Study from '@/pages/extra/Study';

const Golang = () => import('@/pages/events/Golang.vue')
const Internship = () => import('@/pages/Internship.vue')
const Esg = () => import('@/pages/events/esg')


// University
const University_1 = () => import('@/pages/univers/Univers_1.vue')
const University_2 = () => import('@/pages/univers/Univers_2.vue')
const University_3 = () => import('@/pages/univers/Univers_3.vue')
const University_4 = () => import('@/pages/univers/Univers_4.vue')
const University_5 = () => import('@/pages/univers/Univers_5.vue')
const University_6 = () => import('@/pages/univers/Univers_6.vue')
const University_7 = () => import('@/pages/univers/Univers_7.vue')
const University_8 = () => import('@/pages/univers/Univers_8.vue')
const University_9 = () => import('@/pages/univers/Univers_9.vue')
const University_10 = () => import('@/pages/univers/Univers_10.vue')
const University_11 = () => import('@/pages/univers/Univers_11.vue')
const University_12 = () => import('@/pages/univers/Univers_12.vue')


const Onboarding = () => import('@/pages/onboarding/')

const Catalog = () => import('@/pages/catalog/index')

Vue.use(VueRouter)

const routes = [
    {
        path: '/dev/form',
        component: Developers
    },
    {
        path: '/',
        component: Expert,
        name: 'Expert',
        meta: {
            name: 'Специалистам',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isSchool: true
        }
    },
    {
        path: '/oauth/mobile',
        component: Expert,
        name: 'MainForMobileLogin',
        meta: {
            name: 'Главная',
            isSchool: true
        }
    },
    {
        path: '/external_oauth',
        component: Empty,
        name: 'Empty',
        meta: {
            name: 'Главная',
            isSchool: true
        }
    },
    {
        path: '/courses',
        component: Courses,
        name: 'Courses',
        meta: {
            name: 'Курсы',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isCourses: true
        },
        alias: window.__sections__.map(x => `/courses/${x.slug}`)
    },
    {
        path: '/courses/:slug',
        component: Course,
        props: true,
        name: 'Course',
        meta: {
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isCourse: true
        }
    },
    {
        path: '/courses/program/:slug/:id',
        component: Program,
        props: true,
        name: 'Program',
        meta: {
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isCourse: true
        },
    },
    {
        path: '/career',
        component: Career,
        name: 'Career',
        meta: {
            name: 'Кем быть',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ]
        }
    },
    {
        path: '/bez-otkaza',
        component: LiftWithoutRefusal,
        name: 'LiftWithoutRefusal',
        meta: {
            name: '#ЛИФТБЕЗОТКАЗА',
            isLiftNoRefusal: true
        }
    },
    {
        path: '/vacancies',
        component: Vacancies,
        name: 'Vacancies',
        meta: {
            name: 'Вакансии',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ]
        }
    },
    {
        path: '/vacancies/:id',
        component: Vacancy,
        props: true,
        name: 'Vacancy',
        meta: {
            name: 'Вакансия',
            breadcrumb: [
                { name: 'Главная', link: '/' },
                { name: 'Список вакансий', link: '/vacancies' },
            ]
        }
    },
    {
        path: '/company/:id/',
        component: Company,
        props: true,
        meta: {
            name: 'Компания',
            breadcrumb: [
                { name: 'Главная', link: '/' },
                { name: 'Список вакансий', link: '/vacancies' },
            ]
        }
    },
    {
        path: '/organization/:id',
        component: Organization,
        props: true,
        meta: {
            name: 'Актив',
            breadcrumb: [
                { name: 'Главная', link: '/' },
                { name: 'Каталог стажировок', link: '/internship' }
            ]
        }
    },
    {
        path: '/contest/mama-ya-stazhor',
        component: Contest,
        meta: {
            name: 'Конкурс',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ]
        }
    },
    {
        path: '/contest/oscar',
        component: ContestOscar,
        meta: {
            name: 'Конкурс',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ]
        }
    },
    {
        path: '/contest/oscar/final',
        component: Final,
        meta: {
            name: 'Финал конкурса',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ]
        }
    },
    {
        path: '/projects',
        component: Projects,
        name: 'Projects',
        meta: {
            name: 'Спецпроекты',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isProjects: true
        }
    },
    {
        path: '/tests',
        component: Tests,
        name: 'Tests',
        meta: {
            name: 'Тестирование компетенций',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isTests: true
        }
    },
    {
        path: '/tests/:id/',
        component: Test,
        name: 'Test',
        meta: {
            name: 'Тестирование компетенций',
            isTest: true

        }
    },
    {
        path: '/school',
        component: School,
        name: 'School',
        meta: {
            name: 'Школьникам',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isSchool: true
        }
    },
    {
        path: '/student',
        component: Student,
        name: 'Student',
        meta: {
            name: 'Студентам',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isSchool: true
        }
    },
    {
        path: '/about-us',
        component: LandingContacts,
        name: 'LandingContacts',
        meta: {
            name: 'О нас',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isSchool: true
        }
    },
    {
        path: '/contacts',
        component: Contacts,
        meta: {
            name: 'О нас',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ]
        }
    },
    {
        path: '/unsubscribed',
        component: Unsubscribed,
        meta: {
            name: 'Вы отписались от получения писем',
            isUnsubscribed: true
        }
    },
    {
        path: '/study',
        component: Study,
        meta: {
            name: 'Мы рады, что у вас все замечательно!',
            isUnsubscribed: true
        }
    },
    {
        path: '/subscribed',
        component: Subscribed,
        meta: {
            name: 'Вы успешно подписались',
            isSubscribed: true
        }
    },
    {
        path: '/golang',
        component: Golang,
        meta: {
            name: 'Golang',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ]
        }
    },
    {
        path: '/ambassadors',
        component: AmbassadorsForStudents,
        meta: {
            name: 'Cтуденты-cтаршеклассникам',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ]
        }
    },
    {
        path: '/nastavnik',
        component: AmbdForStdNastavnik,
        meta: {
            name: 'Cтуденты-cтаршеклассникам',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ]
        }
    },
    {
        path: '/event/:slug',
        component: Landing,
        props: true,
        meta: {
            name: 'Мероприятие',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isOldEventConstructor: true
        }
    },
    {
        path: '/events/:slug',
        component: EventConstructor,
        name: 'EventConstructor',
        props: true,
        meta: {
            name: 'Практика и мероприятия',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isEventConstructor: true
        }
    },
    {
        path: '/cases/:slug',
        component: EventConstructor,
        props: true,
        meta: {
            name: 'Практика и мероприятия',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isEventConstructor: true
        }
    },
    {
        path: '/survey/:slug',
        component: EventConstructor,
        props: true,
        meta: {
            name: 'Практика и мероприятия',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isEventConstructor: true
        }
    },
    {
        path: '/contest/:slug',
        component: EventConstructor,
        props: true,
        meta: {
            name: 'Практика и мероприятия',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isEventConstructor: true
        }
    },
    {
        path: '/stipendia',
        component: Scholarship,
        props: true,
        meta: {
            name: 'Стипендия «Лифт в будущее»',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isEventConstructor: true
        }
    },
    {
        path: '/ur2022',
        component: LandingUr,
        props: true,
        meta: {
            name: 'Всероссийский урок по устойчивому развитию — Лифт в будущее',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isLandingUr: true
        }
    },
    {
        path: '/profile',
        component: Cabinet,
        meta: {
            requiresAuth: true,
            name: 'Профиль',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ]
        }
    },
    {
        path: '/profile/:id',
        component: Cabinet,
        meta: {
            requiresAuth: true,
            name: 'Профиль',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isProfile: true
        }
    },
    {
        path: '/search-users',
        component: Users,
        meta: {
            requiresAuth: true,
            name: 'Поиск по пользователям',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ]
        }
    },
    {
        path: '/privacy-policy',
        component: Privacy,
        beforeEnter: (to, from, next) => {
            store.commit('modal/close', {});
            next();
        },
        meta: {
            name: 'Политика обработки и защиты персональных данных'
        }
    },
    {
        path: '/agreement',
        component: Agreement,
        beforeEnter: (to, from, next) => {
            store.commit('modal/close', {});
            next();
        },
        meta: {
            name: 'Пользовательское соглашение'
        }
    },
    {
        path: '/agreement-annex',
        component: Annex,
        meta: {
            name: 'Список партнеров'
        }
    },
    {
        path: '/external_service',
        component: External
    },
    {
        path: '/internship',
        component: Internship,
        name: 'Internship',
        meta: {
            name: 'Стажировки',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ]
        }
    },
    /* ========== */
    /* UNIVERSITY */
    /* ========== */
    {
        path: '/university-kgu',
        component: University_1,
        meta: {
            slug: 'university-KGU',
            isUnivers: true,
            name: 'Регистрация на мероприятие'
        }
    },
    {
        path: '/university-reu',
        component: University_2,
        meta: {
            slug: 'university-REU',
            isUnivers: true,
            name: 'Регистрация на мероприятие'
        }
    },
    {
        path: '/university-petrgu',
        component: University_3,
        meta: {
            slug: 'university-PetrGU',
            isUnivers: true,
            name: 'Регистрация на мероприятие'
        }
    },
    {
        path: '/anonsgranta',
        component: University_4,
        meta: {
            slug: 'anonsgranta',
            isUnivers: true,
            name: 'Регистрация на мероприятие'
        }
    },
    {
        path: '/university-mai',
        component: University_5,
        meta: {
            slug: 'university-MAI',
            isUnivers: true,
            name: 'Регистрация на мероприятие'
        }
    },
    {
        path: '/university-dongau',
        component: University_6,
        meta: {
            slug: 'university-DonGAU',
            isUnivers: true,
            name: 'Регистрация на мероприятие'
        }
    },
    {
        path: '/urok-proforientatsii',
        component: University_7,
        meta: {
            slug: 'urok-proforientatsii',
            isUnivers: true,
            name: 'Регистрация на мероприятие'
        }
    },
    {
        path: '/mentorsapplication',
        component: University_8,
        meta: {
            slug: 'mentorsapplication',
            isUnivers: true,
            name: 'Регистрация на мероприятие'
        }
    },
    {
        path: '/tambovskij-gu',
        component: University_9,
        meta: {
            slug: 'tambovskij-gu',
            isUnivers: true,
            name: 'Регистрация на мероприятие'
        }
    },
    {
        path: '/voronezhskij-gu',
        component: University_10,
        meta: {
            slug: 'voronezhskij-gu',
            isUnivers: true,
            name: 'Регистрация на мероприятие'
        }
    },
    {
        path: '/safu',
        component: University_11,
        meta: {
            slug: 'safu',
            isUnivers: true,
            name: 'Регистрация на мероприятие'
        }
    },
    {
        path: '/proforientatsionnaja-studija-22-04',
        component: University_12,
        meta: {
            slug: 'proforientatsionnaja-studija-22-04',
            isUnivers: true,
            name: 'Регистрация на мероприятие'
        }
    },
    {
        path: '*',
        component: Error,
        meta: {
            name: 'Страница не найдена'
        }
    },
    {
        path: '/maintenance',
        component: Maintenance,
        meta: {
            name: 'Технические работы',
            isMaintenance: true
        }
    },
    {
        path: '/learning-esg',
        component: Esg,
        meta: {
            name: 'Обучение с ESG',
        }
    },
    {
        path: '/onboarding',
        component: Onboarding,
        name: 'Onboarding',
        meta: {
            name: 'Онбординг',
            isOnboarding: true
        }
    },
    {
        path: '/platform',
        component: Catalog,
        name: 'Catalog',
        meta: {
            name: 'Витрина',
            breadcrumb: [
                { name: 'Главная', link: '/' }
            ],
            isCatalog: true
        }
    },
    {
        path: '/grade',
        component: Grade,
        name: 'Grade',
        meta: {
            name: 'Спасибо за поставленную оценку!'
        }
    },
    {
        path: '/oauth/error',
        name: 'oauthError',
        component: AuthError,
    },
    {
        path: '/authorization/callback',
        name: 'authCallback',
        component: AuthCallback,
    },
]
const options = {
    mode: 'history',
    linkActiveClass: '',
    routes
}

const router = new VueRouter(options)

router.beforeEach((to, _, next) => {
    store.commit('ntf/hide')
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['user/isLoggedIn']) {
            next();
            return
        }
        next('/')
    } else {
        next()
    }
    if (to.path === '/signout') {
        store.dispatch('user/logout')
            .then(() => next('/'))
    }
    if (to.path === '/nastavnik' || to.path === '/nastavnik/' || to.path === '/ambassadors' || to.path === '/ambassadors/') {
        next('/events/st-st')
    }
    if (to.path === '/ur2022' || to.path === '/ur2022/') {
        next('/events/artifacts_school')
    }
    if (to.path === '/event/pythonstart' || to.path === '/event/pythonstart/') {
        next('/events/python')
    }
    if (to.path === '/stipendia' || to.path === '/stipendia/') {
        next('/contest/stipendia')
    }
    if (to.path === '/internship' || to.path === '/internship/') {
        next('/events/career')
    }
    if (to.query.modal_open === 'changepass') {
        store.commit('modal/open', {
            name: 'changePass',
            payload: {
                email: to.query.email,
                upid: to.query.upid
            }
        })
    }
    if (to.query.modal_open === 'signIn' || to.query.modal_open === 'signin') {
        store.commit('modal/open', {
            name: 'signIn',
        })
    }
    if (to.query.modal_open === 'newpass') {
        store.commit('modal/open', {
            name: 'changePass',
            payload: {
                title: 'Установка пароля',
                isBackOffice: true,
                email_token: to.query.email_token,
                ttl: to.query.ttl,
                uid: to.query.uid
            }
        })
    }

})

router.afterEach((to) => {
    const seoTitle = document.getElementById('seo-title')
    if (to.name === 'Courses' || to.name === 'Program') {
        seoTitle.textContent = 'Бесплатные онлайн видеокурсы для школьников и студентов'
        document.title = 'Бесплатные онлайн видеокурсы для школьников и студентов. Лифт в будущее – образовательная онлайн-платформа'
        document.head.querySelector('meta[name="description"]').setAttribute('content', 'Бесплатные онлайн видеокурсы для школьников и студентов. Лифт в будущее это образовательная инициатива, которая призвана помочь в выборе профессии, научить как общаться, как повысить личную эффективность, как управлять проектами, прокачать скиллы в Digital технологиях и устроиться на работу.')
        document.head.querySelector('meta[property="og:description"]').setAttribute('content', 'Бесплатные онлайн видеокурсы для школьников и студентов. Лифт в будущее это образовательная инициатива, которая призвана помочь в выборе профессии, научить как общаться, как повысить личную эффективность, как управлять проектами, прокачать скиллы в Digital технологиях и устроиться на работу.')
    } else if (to.name === 'Career') {
        seoTitle.textContent = 'Тест на определение профориентации'
        document.title = 'Тест на определение профориентации бесплатно для школьников и студентов. Лифт в будущее – образовательная онлайн-платформа'
        document.head.querySelector('meta[name="description"]').setAttribute('content', 'Бесплатный тест на определение профессиональной ориентации для школьников и студентов. Результаты доступны сразу после прохождения. Тест поможет тебе лучше узнать свои сильные стороны и таланты. И на основе личностных характеристик подобрать профессию.')
        document.head.querySelector('meta[property="og:description"]').setAttribute('content', 'Бесплатный тест на определение профессиональной ориентации для школьников и студентов. Результаты доступны сразу после прохождения. Тест поможет тебе лучше узнать свои сильные стороны и таланты. И на основе личностных характеристик подобрать профессию.')
        document.head.querySelector('meta[name="robots"]').setAttribute('content', 'index, nofollow')
    } else if (to.name === 'Vacancies') {
        seoTitle.textContent = 'Вакансии и стажировки для студентов и школьников'
        document.title = 'Вакансии и стажировки для студентов и школьников без опыта работы'
        document.head.querySelector('meta[name="description"]').setAttribute('content', 'Вакансии и стажировки для школьников и студентов в компаниях Корпорации АФК «Система». Стажировки и вакансии для молодых специалистов в компаниях МТС, МТС-Банк, OZON, Агрохолдинг «СТЕПЬ», Segezha Group, Биннофарм Групп, Cosmos Hotel Group, Бизнес-Недвижимость и др.')
        document.head.querySelector('meta[property="og:description"]').setAttribute('content', 'Вакансии и стажировки для школьников и студентов в компаниях Корпорации АФК «Система». Стажировки и вакансии для молодых специалистов в компаниях МТС, МТС-Банк, OZON, Агрохолдинг «СТЕПЬ», Segezha Group, Биннофарм Групп, Cosmos Hotel Group, Бизнес-Недвижимость и др.')
        document.head.querySelector('meta[name="robots"]').setAttribute('content', 'index, nofollow')
    } else {
        document.title = `${to.meta.name} - Лифт в будущее – образовательная онлайн-платформа. Проект Благотворительного фонда «Система»`
        document.head.querySelector('meta[name="description"]').setAttribute('content', 'Образовательная инициатива, которая призвана помочь в выборе профессии, прокачать скилы и устроиться на работу.')
        document.head.querySelector('meta[property="og:description"]').setAttribute('content', 'Образовательная инициатива, которая призвана помочь в выборе профессии, прокачать скилы и устроиться на работу.')
    }

    document.head.querySelector('meta[property="og:title"]').setAttribute('content', document.title)
})

export default router
