import { HTTP, getToken } from './default'

export default {
    getAllTests() {
        if (getToken()) {
            return HTTP({
                url: '/survay/',
                method: 'GET',
                headers: {
                    Authorization: `Token ${getToken()}`
                }
            });
        } else {
            return HTTP({
                url: '/survay/',
                method: 'GET'
            });
        }
    },
    getCompetence(id) {
        return HTTP({
            url: `/survay/user-survay/${id}/`,
            method: 'GET',
            headers: {
                Authorization: `Token ${getToken()}`
            }
        });
    },
    getUserTests() {
        return HTTP({
            url: '/survay/user-survay/',
            method: 'GET',
            headers: {
                Authorization: `Token ${getToken()}`
            }
        });
    },
    sendCompetence({id, data}) {
        return HTTP({
            url: `/survay/user-survay/${id}/`,
            method: 'PUT',
            headers: {
                Authorization: `Token ${getToken()}`
            },
            data
        });
    },
    getTest(id) {
        return HTTP({
            url: `/survay/${id}/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    sendAnswers(data) {
        return HTTP({
            url: '/survay/user-survay/',
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data
        })
    },
    downloadResult(id) {
        return HTTP({
            url: `/survay/user-sertificat-survay/${id}/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`,
                'Content-Type': 'application/octet-stream'
            },
            responseType: 'blob'
        })
    },
    getMindFormat() {
        return HTTP({
            url: '/mindformat/get_link/',
            method: 'GET',
            headers: {
                Authorization: `Token ${getToken()}`
            }
        });
    },
    signUpMindFormat() {
        return HTTP({
            url: '/mindformat/sign_up/',
            method: 'POST',
            headers: {
                Authorization: `Token ${getToken()}`
            }
        });
    },
}
