<template>
	<section
		v-if="vacancies.length"
		v-wow
		class="section-simple job wow fadeInUp"
		data-wow-delay="0.2s"
	>
		<div class="content">
			<h2 class="section-title">
				Стажировки и вакансии
			</h2>
			<div class="job__list">
				<BaseCardVacancies
					v-for="vacancy in vacancies"
					:key="vacancy.id"
					:is-favorite="isFavorited(vacancy.id)"
					:is-favorite-vacancy="isFavorited(vacancy.id)"
					is-position-sidebar
					is-landing-role
					:vacancy="vacancy"
					class="card-job"
				/>
			</div>
			<div class="button-more">
				<router-link
					target="_blank"
					to="/vacancies"
					class="button"
				>
					Смотреть все вакансии
				</router-link>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Tb from '@/api/teachbase';
import BaseCardVacancies from '@/components/BaseComponents/BaseCardVacancies';

export default {
    name: 'Vacancies',
    components: {
        BaseCardVacancies
    },
    data: () => ({
        vacancies: []
    }),
    computed: {
        ...mapGetters('user', {
            favoriteVacancies: 'favoriteVacancies'
        }),
        favoritesVacanciesId() {
            return this.favoriteVacancies.map((el) => {
                return el.id
            })
        },
        isFavorite() {
            return this.favoritesVacanciesId.includes(this.vacancy.id)
        },
    },
    created () {
        Tb.getVacancies({
            vacancy_name: 'Инженер-конструктор (стажер)'
        }).then(response => this.vacancies.push(response.data.results[0]))
        Tb.getVacancies({
            vacancy_name: 'СТАЖЕР-РАЗРАБОТЧИК'
        }).then(response => this.vacancies.push(response.data.results[0]))
    },
    methods: {
        ...mapActions('tb', [
            'getAllVacancies'
        ]),
        isFavorited(id) {
            return this.favoritesVacanciesId.includes(id)
        }
    }
};
</script>
