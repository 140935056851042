<template>
	<div class="content">
		<h2 class="section-title">
			Блог
		</h2>
		<div
			v-if="!blogArticles"
			class="preloader sm"
			style="margin-top: 50px;"
		/>
		<div
			v-else
			class="blog__slider"
		>
			<VueSlickCarousel
				class="slider"
				v-bind="carouselBlogSettings"
			>
				<div
					v-for="article in blogArticles"
					:key="article.id"
				>
					<div class="blog-item">
						<a
							:href="article.url"
							target="_blank"
							class="blog-item__pic"
							:style="article.image | bgrDynamicImageCover"
						/>
						<h3 class="blog-item__title">
							{{ article.title }}
						</h3>
						<a
							:href="article.url"
							target="_blank"
							class="more-link"
						>
							Подробнее
							<img
								class="more-link__img"
								:src="'/img/icons/icon__arrow-right--orange.svg' | prodSrcImage"
								alt="Иконка"
							>
						</a>
					</div>
				</div>
			</VueSlickCarousel>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Blog',
    data: () => ({
        carouselBlogSettings: {
            arrows: true,
            infinite: false,
            dots: true,
            'slides-to-show': 3,
            'slides-to-scroll': 3,
            draggable: false,
            speed: 600,
            swipe: true,
            'touch-move': true,
            responsive: [
                {
                    breakpoint: 1099,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    }),
    computed: {
        ...mapGetters('tb', {
            blogArticles: 'getBlogArticles'
        })
    },
    created() {
        this.getBlogArticles()
    },
    methods: {
        ...mapActions('tb', [
            'getBlogArticles'
        ])
    }
};
</script>

<style scoped>

</style>
