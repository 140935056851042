import Content from '@/api/contest'


const state = {
    contests: undefined,
    contest: undefined,
    contestTest: null,
}

const actions = {
    getAllContests({ commit }) {
        Content.getAllContests()
            .then(response => response.data)
            .then(results => commit('setContests', results))
    },
    getContest({commit},slug) {
        Content.getContest(slug)
        .then(response => response.data)
        .then(results => commit('setContest', results))
    }
}

const mutations = {
    setContests(state, contests) {
        state.contests = contests
        state.contest = contests.find(item => item.active)
    },
    setContest(state, contest) {
        state.contest = contest
    } 
}

const getters = {
    getContest: state => state.contest
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}