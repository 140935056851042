import SU from '@/api/search-users'

const state = {
    filters: [],
    users: []
}

const actions = {
    getFilters({ commit }) {
        return new Promise(resolve => {
            SU.getUsersFilters()
                .then(response => response.data)
                .then(result => {
                    commit('setFilters', result.filters)
                    resolve(result)
                })
        })
    },
    getUsersData({ commit }, params) {
        return new Promise(resolve => {
            SU.getUsersData(params)
                .then(response => response.data)
                .then(payload => {
                    commit('setUsers', payload.results)
                    resolve(payload.count)
                })
        })
    },
    sendTurbo({}, id) {
        return new Promise(() => {
            SU.sendTurbo(id)
                .then(response => response.data)
                .catch(err => {
                    if (err.response.status === 304) return
                })
        })
    }
}

const mutations = {
    setFilters(state, filters) {
        state.filters = filters
    },
    setUsers(state, users) {
        state.users = users
    }
}

const getters = {
    getFilters: state => state.filters,
    getUsers: state => state.users,
}



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
