<template>
	<div class="modal-content">
		<div class="center">
			<h3 class="modal-title">
				Удалить профиль
			</h3>
			<br>
			<div class="text dark">
				<p>Вы действительно хотите удалить свой профиль?</p>
			</div>
			<div class="buttons">
				<div class="cells">
					<div class="cell">
						<div
							class="button-hollow lg"
							@click="deleteUser"
						>
							Удалить
						</div>
					</div>
					<div class="cell">
						<div
							class="button orange lg"
							@click="modalClose()"
						>
							Отменить
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('user', {
            id: 'userID'
        })
    },
    methods: {
        ...mapActions('user', [
            'delete'
        ]),
        deleteUser() {
            this.delete().then(() => {
                this.modalClose()
                this.$router.push('/')
            })
        }
    }
}
</script>