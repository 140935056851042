<template>
	<div class="sections">
		<section
			v-if="task"
			class="task"
		>
			<div class="content">
				<div class="panel-testing">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								{{ counter }}
							</div>
							<div class="steps-number__total">
								/{{ test.length }}
							</div>
						</div>
						<div
							v-for="step in counter"
							:key="step"
							class="step step--active"
						/>
						<div
							v-for="step in test.length - counter"
							:key="step + 'unactive'"
							class="step"
						/>
					</div>
					<div class="task__text">
						{{ task.text }}
					</div>
					<div
						v-for="(item, index) in answers"
						:key="index"
						class="task__list"
					>
						<label class="task-item">
							<input
								v-model="answer_id"
								:value="item.id"
								type="radio"
								name="name-task"
								class="task-item__value"
							>
							<div class="task-item__text">{{ item.text }}</div>
						</label>
					</div>
					<div class="panel-testing__footer">
						<button
							v-if="!isSending"
							class="button orange"
							:disabled="!answer_id"
							@click.prevent="sendAnswer"
						>
							Далее
							<img
								:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
						<div
							v-else
							class="button orange button-preloader"
							style="width:132px"
						>
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
    name: 'Task',
    data: () => ({
        isLastQuestion: false,
        isSending: false,
        task: null,
        answer_id: null,
        counter: 0,
        isAnswerChecked: false
    }),
    computed: {
        ...mapGetters('tst', {
            test: 'getTest',
            selectedAnswers: 'getAnswers'
        }),
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        }),
        answers () {
            return this.task && this.task.answer_set
        }
    },
    created() {
        this.getTest(this.$route.params.id).then(()=> this.getTasks())
    },
    mounted() {
        if (!this.isLogged) this.$router.push(`/tests/${this.$route.params.id}`)
    },
    methods: {
        ...mapActions('tst', [
            'getTest',
            'sendTestAnswers'
        ]),
        ...mapMutations('tst', [
            'setAnswers',
            'setUserSurvayID'
        ]),
        getTasks() {
            this.task = this.test[this.counter]
            this.counter++
            if (this.test[this.test.length - 1] === this.task) {
                this.isLastQuestion = true
            }
        },
        sendAnswer() {
            this.setAnswers(this.answer_id)
            this.answer_id = null
            this.$scrollTo(document.querySelector('header.header-role'))
            this.getTasks()
            if (this.isLastQuestion && this.selectedAnswers.length === this.test.length) {
                this.isSending = true
                this.sendTestAnswers({
                    survay_id: this.$route.params.id,
                    selectedanswer: this.selectedAnswers
                }).then( res => {
                    this.isSending = false
                    this.setUserSurvayID(res.id)
                    this.$router.push(`/tests/${this.$route.params.id}?self_rating=true`)
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
