<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<h3 class="modal-title">
				Активировать аккаунт
			</h3>
			<h4 class="modal-subtitle">
				Чтобы использовать функции платформы «Лифт в будущее», пожалуйста, заполните свой
				профиль.
			</h4>
		</div>
		<form
			class="form"
			novalidate
			@submit.stop.prevent="activatUser"
		>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.modalAct.first_name.$model"
						type="text"
						class="field"
						placeholder="Имя"
						style="text-transform: capitalize"
					>
					<div class="label">Имя</div>
					<span
						v-if="$v.modalAct.first_name.$error"
						class="help-block form-error"
					>
						{{ !$v.modalAct.first_name.required ? 'Это обязательное поле' : !$v.modalAct.first_name.onlyAlpha ? 'Только буквы' : 'Ошибка поля' }}
					</span>
				</label>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.modalAct.last_name.$model"
						type="text"
						class="field"
						placeholder="Фамилия"
						style="text-transform: capitalize"
					>
					<div class="label">Фамилия</div>
					<span
						v-if="$v.modalAct.last_name.$error"
						class="help-block form-error"
					>
						{{ !$v.modalAct.last_name.required ? 'Это обязательное поле' : !$v.modalAct.last_name.onlyAlpha ? 'Только буквы' : 'Ошибка поля' }}
					</span>
				</label>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.modalAct.middle_name.$model"
						type="text"
						class="field"
						placeholder="Отчество"
						style="text-transform: capitalize"
					>
					<div class="label">Отчество</div>
					<span
						v-if="$v.modalAct.middle_name.$error"
						class="help-block form-error"
					>
						{{ !$v.modalAct.middle_name.required ? 'Это обязательное поле' : !$v.modalAct.middle_name.onlyAlpha ? 'Только буквы' : 'Ошибка поля' }}
					</span>
				</label>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.modalAct.email.$model"
						type="text"
						class="field"
						placeholder="Email"
					>
					<div class="label">Email</div>
					<span
						v-if="$v.modalAct.email.$error"
						class="help-block form-error"
					>
						{{ !$v.modalAct.email.required ? 'Это обязательное поле' : !$v.modalAct.email.email ? 'Введите корректный e-mail' : 'Ошибка поля' }}
					</span>
				</label>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.modalAct.phone.$model"
						type="text"
						class="field"
						placeholder="Телефон"
					>
					<div class="label">Телефон</div>
					<span
						v-if="$v.modalAct.phone.$error"
						class="help-block form-error"
					>
						{{ !$v.modalAct.phone.required ? 'Это обязательное поле' : !$v.modalAct.phone.Phone ? 'Введите корректный номер телефона' : 'Ошибка поля' }}
					</span>
				</label>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.modalAct.city.$model"
						type="text"
						class="field"
						placeholder="Город"
					>
					<div class="label">Город</div>
					<span
						v-if="$v.modalAct.city.$error"
						class="help-block form-error"
					>Это обязательное поле</span>
				</label>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="modalAct.birthday"
						v-mask="'##.##.####'"
						type="text"
						class="field"
						placeholder="Дата рождения"
						@input="checkedBirthday"
					>
					<div class="label">Дата рождения</div>
					<span
						v-if="(modalAct.isBirthdayError && modalAct.birthdayInputCount > 1) || (modalAct.isBirthdayError && modalAct.isBirthdayDirty)"
						class="help-block form-error"
					>
						{{
							!modalAct.birthday ? 'Это обязательное поле' :
							modalAct.isBirthdayDirty && modalAct.isBirthdayError ? 'Формат даты ДД.ММ.ГГГГ' : 'Ошибка поля'
						}}
					</span>
				</label>
			</div>
			<h4 class="modal-subtitle">
				Смена пароля
			</h4>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.modalAct.pass.newPass.$model"
						type="password"
						class="field"
						placeholder="Новый пароль"
					>
					<div class="label">Новый пароль</div>
					<span
						v-if="$v.modalAct.pass.newPass.$error"
						class="help-block form-error"
					>
						{{ !$v.modalAct.pass.newPass.required ? 'Это обязательное поле' : !$v.modalAct.pass.newPass.minLength ? `Пароль должен состоять из букв и цифр, не менее ${$v.modalAct.pass.newPass.$params.minLength.min} символов` : 'Ошибка поля' }}
					</span>
				</label>
			</div>
			<div class="field-wrap">
				<label class="field-label">
					<input
						v-model.trim="$v.modalAct.pass.repeatNew.$model"
						type="password"
						class="field"
						placeholder="Повторите пароль"
					>
					<div class="label">Повторите пароль</div>
					<span
						v-if="$v.modalAct.pass.repeatNew.$error"
						class="help-block form-error"
					>
						{{ !$v.modalAct.pass.repeatNew.required ? 'Это обязательное поле' : !$v.modalAct.pass.repeatNew.sameAsPassword ? 'Пароли не совпадают' : 'Ошибка поля' }}
					</span>
				</label>
			</div>
			<div class="field-wrap sm">
				<label class="checkbox">
					<input
						v-model="modalAct.isMailing"
						type="checkbox"
					>
					<span>Подписаться на почтовую рассылку. Только важные новости, раз в неделю.</span>
				</label>
			</div>
			<div class="field-wrap sm">
				<label class="checkbox">
					<input
						v-model="modalAct.isAccept"
						type="checkbox"
						@change="$v.modalAct.isAccept.$touch()"
					>
					<span>Соглашаюсь с условиями обработки
						<router-link to="/privacy-policy">персональных данных</router-link>.
					</span>
					<span
						v-if="$v.modalAct.isAccept.$error"
						class="help-block form-error"
					>Это обязательное поле</span>
				</label>
			</div>
			<button
				v-if="!isSended"
				class="button orange lg"
			>
				Активировать аккаунт
			</button>
			<div
				v-else
				class="button orange lg button-preloader"
			>
				<div class="button-preloader-icons">
					<div class="button-preloader-icon" />
					<div class="button-preloader-icon" />
					<div class="button-preloader-icon" />
				</div>
			</div>
		</form>
	</div>
</template>

<script>

import {mapState, mapActions} from 'vuex';
import {required, email, minLength, sameAs} from 'vuelidate/lib/validators';
import {onlyAlpha, birthdayUntil, Phone} from '@/assets/scripts/custom_validators';

export default {
    data: () => ({
        isSended: false,
        modalAct: {
            first_name: '',
            last_name: '',
            middle_name: '',
            email: '',
            phone: '',
            city: '',
            birthday: '',
            pass: {
                newPass: '',
                repeatNew: ''
            },
            isMailing: false,
            isAccept: false,
            isBirthdayError: false,
            isBirthdayDirty: false,
            birthdayInputCount: 0
        }
    }),
    validations: {
        modalAct: {
            first_name: {
                required,
                onlyAlpha
            },
            last_name: {
                required,
                onlyAlpha
            },
            middle_name: {
                required,
                onlyAlpha
            },
            email: {
                required,
                email
            },
            phone: {
                required,
                Phone
            },
            city: {
                required
            },
            // birthday: {
            //     required
            // },
            pass: {
                newPass: {
                    required,
                    minLength: minLength(8)
                },
                repeatNew: {
                    required,
                    sameAsPassword: sameAs(function () {
                        return this.modalAct.pass.newPass
                    })
                }
            },
            isAccept: {
                sameAs: sameAs(() => true)
            }
        }
    },
    computed: {
        ...mapState('user', [
            'user',
            'id'
        ])
    },
    mounted() {
        this.getUserInfo()
            .then(user => {
                let [year, month, day] = user.birthday ? user.birthday.split('-') : ['', '', '']

                this.modalAct = {
                    ...this.modalAct,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    middle_name: user.middle_name,
                    email: user.email,
                    phone: user.phone,
                    birthday: `${day}.${month}.${year}`,
                    city: user.city ? user.city.name : '',
                }
                this.isLoaded = true
            })
    },
    methods: {
        ...mapActions('user', {
            getUserInfo: 'detail',
            save: 'change',
            saveNewPass: 'newpass',
            activate: 'activate'
        }),
        activatUser() {
            this.$v.modalAct.$touch();

            this.modalAct.birthdayInputCount++;

            if (this.$v.modalAct.$invalid) {
                console.log('Error in modal Activation')
            } else if (this.modalAct.birthday && this.modalAct.isBirthdayError) {
                console.log('Birthday error')
            } else if (!this.isSended) {
                let [day, month, year] = this.modalAct.birthday.split('.');

                this.isSended = true;
                this.save({
                    first_name: this.modalAct.first_name,
                    last_name: this.modalAct.last_name,
                    middle_name: this.modalAct.middle_name,
                    email: this.modalAct.email,
                    mailing_messages: this.modalAct.isMailing,
                    phone: this.modalAct.phone,
                    city: {
                        name: this.modalAct.city
                    },
                    birthday: `${year}-${month}-${day}`
                })
                    .then(() => {
                        this.saveNewPass(this.modalAct.pass.newPass)
                            .then(() => {
                                this.activate()
                                    .then(() => {
                                        this.modalClose('activSuccess')
                                        this.isSended = false;
                                    })
                                    .catch(error => console.dir(error))
                            })
                            .catch(error => console.log('Error in main User pass block', error))
                    })
                    .catch(error => console.log('Error in main User info', error))
            }
        },
        checkedBirthday() {
            if (this.modalAct.birthday) {
                this.modalAct.isBirthdayError = !birthdayUntil(this.modalAct.birthday)
                this.modalAct.isBirthdayDirty = this.modalAct.birthdayInputCount >= 1 ? true : false;
                this.modalAct.birthdayInputCount++;
            }
        }
    }
}
</script>
