<template>
	<section class="section section-inset">
		<div class="content content--md">
			<h2 class="title-section">
				Правила программы
			</h2>
			<div class="inset">
				<div class="inset__body">
					<div class="inset__tabs">
						<div
							class="inset__tab"
							:class="{'inset__tab--active': isActiveFirst}"
							@click="activeTab(1)"
						>
							Студентам вузов
						</div>
						<div
							class="inset__tab"
							:class="{'inset__tab--active': isActiveSecond}"
							@click="activeTab(2)"
						>
							Студентам колледжей
						</div>
						<div
							class="inset__tab"
							:class="{'inset__tab--active': isActiveThird}"
							@click="activeTab(3)"
						>
							Школьникам
						</div>
					</div>
					<div class="inset__contents">
						<div
							class="inset__content"
							:style="isActiveFirst ? 'display: block' : 'display: none'"
						>
							<div class="rewards">
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-1--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Онлайн-призы (гайды/промокоды)
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Каждый пройденный курс</li>
										</ul>
									</div>
								</div>
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-2--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Карьерная консультация
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Заполненный профиль</li>
											<li>3 онлайн-курса</li>
											<li>1 тест</li>
										</ul>
									</div>
								</div>
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-3--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Наставник из отрасли
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Заполненный профиль</li>
											<li>5 онлайн-курсов</li>
											<li>1 тест</li>
											<li>1 мероприятие или 1 решенный кейс</li>
										</ul>
									</div>
								</div>
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-5--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Сертификат Озон на 30 000 ₽
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Заполненный профиль</li>
											<li>7 онлайн-курсов</li>
											<li>1 тест</li>
											<li>1 мероприятие или 2 решенных кейса</li>
										</ul>
									</div>
								</div>
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-4--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Стипендия 20 000 ₽
											</div>
											<div class="title-mono">
												х 5 месяцев
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Заполненный профиль</li>
											<li>7 онлайн-курсов</li>
											<li>1 тест</li>
											<li>1 мероприятие или 2 решенных кейса</li>
											<li>Зачетка без троек</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="inset__content"
							:style="isActiveSecond ? 'display: block' : 'display: none'"
						>
							<div class="rewards">
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-1--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Онлайн-призы (гайды/промокоды)
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Каждый пройденный курс</li>
										</ul>
									</div>
								</div>
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-2--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Карьерная консультация
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Заполненный профиль</li>
											<li>3 онлайн-курса</li>
											<li>1 тест</li>
										</ul>
									</div>
								</div>
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-3--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Наставник из отрасли
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Заполненный профиль</li>
											<li>5 онлайн-курсов</li>
											<li>1 тест</li>
											<li>1 мероприятие или 1 решенный кейс</li>
										</ul>
									</div>
								</div>
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-5--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Сертификат Озон на 15 000 ₽
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Заполненный профиль</li>
											<li>7 онлайн-курсов</li>
											<li>1 тест</li>
											<li>1 мероприятие или 2 решенных кейса</li>
										</ul>
									</div>
								</div>
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-4--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Стипендия 7 500 ₽
											</div>
											<div class="title-mono">
												х 5 месяцев
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Заполненный профиль</li>
											<li>7 онлайн-курсов</li>
											<li>1 тест</li>
											<li>1 мероприятие или 2 решенных кейса</li>
											<li>Зачетка без троек</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="inset__content"
							:style="isActiveThird ? 'display: block' : 'display: none'"
						>
							<div class="rewards">
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-1--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Онлайн-призы (гайды/промокоды)
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Каждый пройденный курс</li>
										</ul>
									</div>
								</div>
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-7--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Промокод на VK Музыку
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Заполненный профиль</li>
											<li>1 онлайн-курс</li>
											<li>1 тест</li>
										</ul>
									</div>
								</div>
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-2--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Профориентационная консультация
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Заполненный профиль</li>
											<li>2 онлайн-курса</li>
											<li>1 тест</li>
										</ul>
									</div>
								</div>
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-5--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Сертификат Озон на 5000 ₽
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Заполненный профиль</li>
											<li>2 онлайн-курса</li>
											<li>1 тест</li>
											<li>1 мероприятие или 1 решенный кейс</li>
										</ul>
									</div>
								</div>
								<div class="reward">
									<div class="reward__intro">
										<img
											:src="'/img/pages/catalog/pic__achievement-4--x2.png' | prodSrcImage"
											alt="Приз"
											class="reward__pic"
										>
										<div class="reward__title">
											<div class="title-element">
												Стипендия 5 000 ₽
											</div>
											<div class="title-mono">
												х 5 месяцев
											</div>
										</div>
									</div>
									<div class="description">
										<ul>
											<li>Заполненный профиль</li>
											<li>2 онлайн-курса</li>
											<li>1 тест</li>
											<li>1 мероприятие или 1 решенный кейс</li>
											<li>Четверть без троек</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="!isLogged"
				class="button-action"
			>
				<button
					class="button"
					@click="modalOpen('regIn', 'test')"
				>
					Зарегистрироваться
				</button>
			</div>
		</div>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Regulations',
    data: () => ({
        isActiveFirst: true,
        isActiveSecond: false,
        isActiveThird: false
    }),
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    },
    methods: {
        activeTab(num) {
            if (num === 1) {
                this.isActiveFirst = !this.isActiveFirst
                this.isActiveThird = false
                this.isActiveSecond = false
            }
            if (num === 2) {
                this.isActiveSecond = !this.isActiveSecond
                this.isActiveFirst = false
                this.isActiveThird = false
            }
            if (num === 3) {
                this.isActiveThird = !this.isActiveThird
                this.isActiveFirst = false
                this.isActiveSecond = false
            }
        }
    }
};
</script>
