<template>
	<section class="section section-subscribe">
		<div class="content">
			<h2 class="title-block">
				Подпишись на рассылку, чтобы всегда быть в курсе новостей о программе
			</h2>
			<div class="subscribe">
				<div class="field-simple">
					<div class="field-simple__title">
						E-mail
					</div>
					<input
						v-model="$v.email.$model"
						type="text"
						class="field-simple__value"
						:class="{'field-simple__value--error': $v.email.$error}"
						placeholder="Введите свой e-mail"
					>
				</div>
				<div
					v-if="$v.email.$error"
					class="field-simple__error"
				>
					Введите корректный e-mail
				</div>
				<div class="subscribe__button">
					<button
						v-if="!isSending"
						class="button"
						data-modal="#modal-subscribed"
						@click="sendEmail"
					>
						Подписаться
					</button>
					<div
						v-else
						class="button orange lg button-preloader"
					>
						Отправляется
						<div class="button-preloader-icons">
							<div class="button-preloader-icon" />
							<div class="button-preloader-icon" />
							<div class="button-preloader-icon" />
						</div>
					</div>
					<div class="subscribe__notice">
						Нажимая на кнопку, вы соглашаетесь с политикой обработки персональных данных
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import {email, required} from 'vuelidate/lib/validators';
import User from '@/api/user';

export default {
    name: 'Subscribe',
    data: () => ({
        email: '',
        isSending: false
    }),
    validations: {
        email: {
            required,
            email
        }
    },
    methods: {
        sendEmail () {
            this.$v.email.$touch();
            if (this.$v.email.$invalid) {
                console.log('Error');
            } else if (!this.isSending || !this.$v.email.$invalid) {
                this.isSending = true
                User.sendMotivationEmail({email: this.email})
                    .then(res => {
                        this.modalOpen('motivation-scs', res)
                        this.isSending = false
                    })
                    .catch(() => {
                        this.isSending = false
                        this.modalOpen('stdFail', 'Вы уже подписаны на рассылку')
                    })
            }
        }
    }
};
</script>
