<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<img
				:src="'/landings/lift-bez-otkaza/img/qr.png' | prodSrcImage"
				alt=""
				class="qr__item"
			>
			<div class="qr__title">
				Если Вы клиент Сбербанка -  в настройках  приложения "СберБанк Онлайн" должна быть включена оплата через систему быстрых платежей
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'HelpLift'
}
</script>

<style lang="less" scoped>
.qr__item{
    margin-left: -20px !important;
    margin-top: 45px !important;
    width: 420px;
}
.qr__title {
    text-align: center;
}
@media (max-width: 767px) {
    .qr__item{
        width: 300px;
        margin-left: 0 !important;
    }
}
</style>
