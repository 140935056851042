<template>
	<div class="modal-content">
		<div class="center">
			<h3 class="modal-title">
				Удалить вакансию из избранных
			</h3>
			<br>
			<div class="text dark">
				<p>Вы действительно хотите удалить вакансию?</p>
			</div>
			<div class="buttons">
				<div class="cells">
					<div class="cell">
						<div
							class="button-hollow lg"
							@click="deleted"
						>
							Удалить
						</div>
					</div>
					<div class="cell">
						<div
							class="button orange lg"
							@click="modalClose()"
						>
							Отменить
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('modal', {
            payload: 'getPayload',
            name: 'deletedFavoriteVacancy'
        })
    },
    methods: {
        ...mapActions('user', ['addFavoriteVacancy']),
        deleted() {
            this.addFavoriteVacancy(this.payload).then(() => {
                this.modalClose();
                this.$store.dispatch('user/getFavoriteVacancies');
            });
        }
    }
};
</script>
