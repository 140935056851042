<template>
	<section
		v-wow
		class="section wow fadeIn"
		data-wow-delay="0.2s"
	>
		<div class="content">
			<div class="system-response">
				<img
					:src="'/img/racoon/pic__racoon-2.png' | prodSrcImage"
					alt="Енот"
					class="system-response__pic"
				>
				<div class="title-section">
					Вы отписались от получения писем.
				</div>
				<div class="text">
					Спасибо, что были с нами все это время. <br>
					Удачи в карьере и саморазвитии!
				</div>
				<router-link
					to="/"
					class="button orange"
				>
					Перейти на главную
					<img
						:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
						alt="Иконка"
						class="button__icon-right"
					>
				</router-link>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Unsubscribed',
    mounted () {
        this.$scrollTo(document.querySelector('header.header-role'))
    }
}
</script>

<style scoped>

</style>
