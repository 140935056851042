<template>
	<section
		v-if="stepsMain"
		class="section section-steps"
	>
		<div class="content content--xs">
			<img
				:src="stepsMain.event_stages_image"
				alt="Енот"
				class="steps-pic"
			>
			<div class="steps">
				<h2 class="title-section">
					{{ stepsMain.event_stages_title }}
				</h2>
				<div
					v-if="stepsInfo"
					class="steps__list"
				>
					<StepsInfo
						v-for="(item, index) in sortedSteps"
						:key="index"
						class="step"
						:step="item"
					/>
				</div>
				<div
					v-if="stepsMain.event_stages_button_text"
					class="button-wrap"
				>
					<ConstructorButton
						:btn-name="stepsMain.event_stages_button_text"
						:btn-link="stepsMain.event_stages_button_link"
						:btn-color="stepsMain.event_stages_button_color"
						:actual-tests="actualTests"
						:is-apply-contest="isApplyContest"
						:btn-variations="btnVariations"
						:is-active="isActiveBtn"
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ConstructorButton from '@/components/ConstructorComponents/ConstructorButton';
import StepsInfo from '@/components/ConstructorComponents/StepsInfo';

export default {
    name: 'Steps',
    components: {
        ConstructorButton,
        StepsInfo
    },
    props: {
        stepsMain: {
            type: Object,
            default: null
        },
        stepsInfo: {
            type: Array,
            default: null
        },
        actualTests: {
            type: Array,
            default: null
        },
        isApplyContest: {
            type: Boolean,
            default: false
        },
        isActiveBtn: {
            type: Boolean,
            default: false
        },
        btnVariations: {
            type: Object,
            default: null
        }
    },
    computed: {
        sortedSteps () {
            return this.stepsInfo &&  this.stepsInfo.filter(item => !!item.priority).sort((a, b) => a.priority - b.priority)
        }
    }
}
</script>

