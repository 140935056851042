<template>
	<div>
		<section
			v-if="eventSampleSTORE"
			class="section section-banner"
		>
			<div class="content">
				<Breadcrumb
					:page-name="eventType === 'event' ? 'Мероприятие' :
						eventType === 'survey' ? 'Тест' :
						eventType === 'contest' ? 'Конкурс' : 'Кейс'"
				/>
				<Banner
					:banner="banner"
					:event-type="eventType"
					:actual-tests="actualTests"
					:is-apply-contest="isApplyContest"
					:btn-variations="btnVariations"
					:is-active-btn="isActiveBtn"
					:is-disable-profile-check="isDisableProfileCheck"
				/>
			</div>
		</section>

		<Directions
			:directions="directions"
		/>

		<About
			:about-info="aboutInfo"
			:actual-tests="actualTests"
			:is-apply-contest="isApplyContest"
			:btn-variations="btnVariations"
			:is-active-btn="isActiveBtn"
		/>

		<Audience
			v-if="isAudienceShow"
			:audience-main="audienceMain"
			:audience-info="audienceInfo"
		/>

		<Video
			v-if="isLogged"
			:video-main="videoMain"
			:video-info="videoInfo"
		/>

		<Description :desc-block="descBlock" />

		<Skills
			v-if="isSkillsShow"
			:skills-main="skillsMain"
			:skills-info="skillsInfo"
		/>

		<Steps
			:steps-main="stepsMain"
			:steps-info="stepsInfo"
			:actual-tests="actualTests"
			:is-apply-contest="isApplyContest"
			:btn-variations="btnVariations"
			:is-active-btn="isActiveBtn"
		/>

		<Brands
			:brands-main="brandsMain"
			:brands-info="brandsInfo"
		/>

		<Prizes
			:prizes-main="prizesMain"
			:prizes-info="prizesInfo"
		/>

		<Images
			v-if="isLogged"
			:photo-info="photoInfo"
		/>

		<Leaders :leaders-info="leadersInfo" />

		<Experts
			:speakers-info="speakersInfo"
			:event-type="eventType"
		/>

		<Questions
			:faq-info="faqInfo"
			:actual-tests="actualTests"
			:is-apply-contest="isApplyContest"
			:btn-variations="btnVariations"
			:is-active-btn="isActiveBtn"
		/>

		<section
			v-if="materialsInfo && isLogged"
			class="section section-material"
		>
			<div class="content content--md">
				<Material
					:materials-info="materialsInfo"
				/>
			</div>
		</section>

		<Organizer :organizer-info="organizerInfo" />

		<Downloads :extra-info="extraInfo" />
	</div>
</template>

<script>
import Brands from '@/components/ConstructorComponents/Brands';
import Breadcrumb from '@/components/Breadcrumb';
import Directions from '@/components/ConstructorComponents/Directions';
import Banner from '@/components/ConstructorComponents/Banner';
import About from '@/components/ConstructorComponents/About';
import Skills from '@/components/ConstructorComponents/Skills';
import Audience from '@/components/ConstructorComponents/Audience';
import Description from '@/components/ConstructorComponents/Description';
import Steps from '@/components/ConstructorComponents/Steps';
import Prizes from '@/components/ConstructorComponents/Prizes';
import Video from '@/components/ConstructorComponents/Video';
import Images from '@/components/ConstructorComponents/Images';
import Leaders from '@/components/ConstructorComponents/Leaders';
import Experts from '@/components/ConstructorComponents/Experts';
import Questions from '@/components/ConstructorComponents/Questions';
import Material from '@/components/ConstructorComponents/Material';
import Organizer from '@/components/ConstructorComponents/Organizer';
import Downloads from '@/components/ConstructorComponents/Downloads';
import {mapActions, mapGetters} from 'vuex';
import ContestsAPI from '@/api/contests';

export default {
    name: 'EventConstructor',
    components: {
        Breadcrumb,
        Banner,
        Directions,
        About,
        Skills,
        Audience,
        Description,
        Steps,
        Brands,
        Prizes,
        Video,
        Images,
        Leaders,
        Experts,
        Questions,
        Organizer,
        Downloads,
        Material
    },
    data: () => ({
        eventType: 'event',
        isSended: false,
        isActiveBtn: false,
        isRegistered: false,
        isRegisteredToMindFormat: false,
        isDisableProfileCheck: false,
        contestID: 0,
        stageID: 0,
        actualTests: [],
        btnVariations: {
            isExpert: false,
            isExpertPass: false,
            isParticipant: false,
            isParticipantPass: false,
            isMindformatTest: false,
            isExpertRole: [],
            isUserRole: [],
        },
    }),
    computed: {
        ...mapGetters('events', {
            banner: 'getBanner',
            directions: 'getInformers',
            aboutInfo: 'getAboutInfo',
            skillsMain: 'getSkillsMain',
            skillsInfo: 'getSkillsInfo',
            isSkillsShow: 'isSkillsShow',
            audienceMain: 'getAudienceMain',
            audienceInfo: 'getAudienceInfo',
            isAudienceShow: 'isAudienceShow',
            descBlock: 'getDescriptionBlock',
            stepsMain: 'getStepsMain',
            stepsInfo: 'getStepsInfo',
            brandsMain: 'getBrandsMain',
            brandsInfo: 'getBrandsInfo',
            prizesMain: 'getPrizesMain',
            prizesInfo: 'getPrizesInfo',
            videoMain: 'getVideoMain',
            videoInfo: 'getVideoInfo',
            photoInfo: 'getPhotoInfo',
            leadersInfo: 'getLeaders',
            speakersInfo: 'getSpeakers',
            faqInfo: 'getFAQ',
            materialsInfo: 'getMaterials',
            organizerInfo: 'getOrganizer',
            extraInfo: 'getExtra'
        }),
        ...mapGetters('smpl', {
            eventSampleSTORE: 'getEventSample'
        }),
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            isJWTToken: 'isJWTToken',
            user: 'userInfo',
            backofficeRole: 'getBackOfficeRole'
        }),
        ...mapGetters('tst', {
            tests: 'getAllTests'
        }),
        ...mapGetters('cnst', {
            userGroup: 'getUserGroup',
        }),
        isApplyContest () {
            return this.actualTests.length === 0 && this.isRegistered && this.isRegisteredToMindFormat
        },
    },
    created() {
        this.getEventSample(this.$route.params.slug).then(() => {
            if (this.$route.query && this.$route.query.technopark === 'true') {
                this.modalOpen('regIn', {external_service: 'Технопарк' })
            }

            this.isRegistered = this.eventSampleSTORE.is_current_user_already_registered;
            this.isActiveBtn = this.eventSampleSTORE.form_active;

            this.eventSampleSTORE.type === null ? this.eventType = 'event' : this.eventType = this.eventSampleSTORE.type;

            if (this.eventType === 'case' && !this.$route.fullPath.includes(`/cases/${this.$route.params.slug}`)) this.$router.push(`/cases/${this.$route.params.slug}`)
            if (this.eventType === 'event' && !this.$route.fullPath.includes(`/events/${this.$route.params.slug}`)) this.$router.push(`/events/${this.$route.params.slug}`)
            if (this.eventType === 'survey' && !this.$route.fullPath.includes(`/survey/${this.$route.params.slug}`)) this.$router.push(`/survey/${this.$route.params.slug}`)
            if (this.eventType === 'contest') {
                if (this.$route.fullPath !== `/contest/${this.$route.params.slug}`) this.$router.push(`/contest/${this.$route.params.slug}`);
                if (this.eventSampleSTORE.is_mindformat_test_done) this.isRegisteredToMindFormat = true;
                if (this.isLogged) {
                    this.detail().then(() => this.getContest(this.eventSampleSTORE.contest_id));
                }
            }
        })
        this.getFirstThreeBlocks(this.$route.params.slug);
        this.getSecondFourBlocks(this.$route.params.slug);
        this.getThirdFourBlocks(this.$route.params.slug);
        this.getRemainingBlocks(this.$route.params.slug);
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
    },
    methods: {
        ...mapActions('events', [
            'getFirstThreeBlocks',
            'getSecondFourBlocks',
            'getThirdFourBlocks',
            'getRemainingBlocks'
        ]),
        ...mapActions('smpl', [
            'getEventSample',
        ]),
        ...mapActions('tst', [
            'getAllTests'
        ]),
        ...mapActions('user', [
            'detail'
        ]),

        async getContest(contestId) {
            const contestInfoRes = await ContestsAPI.getContestInfo(contestId);
            this.contestInfo(contestInfoRes.data);
            this.getStopList(contestInfoRes.data)
            try {
                const contestRequestRes = await ContestsAPI.getContestRequests(contestId);
                this.contestRequest(contestRequestRes.data);
            } catch (error) {
                this.btnVariations.isParticipantPass = true;
            }

            try {
                await ContestsAPI.getContestExpert(contestId);
                this.btnVariations.isExpertPass = false;
            } catch (error) {
                this.btnVariations.isExpertPass = true;
            }
        },

        contestInfo(data) {
            if (!this.btnVariations.isMindformatTest) this.btnVariations.isMindformatTest = !data.is_mindformat_test;
            if (!this.isRegisteredToMindFormat) this.isRegisteredToMindFormat = !data.is_mindformat_test;
            this.isDisableProfileCheck = data.is_disable_profile_check;

            if (data?.legacy_tests.length > 0) {
                this.getAllTests().then((allTestsRes) => {
                    data.legacy_tests.forEach((tst) => {
                        let res = allTestsRes.find((item) => item.id === tst && !item.is_passed);
                        if (res !== undefined) this.actualTests.push(allTestsRes.find((item) => item.id === tst && !item.is_passed));
                    });
                })
            }

            const mappingRoles = (array) => {
                const resultArray = [];
                array.forEach((grouparr) => {
                    let tmp = this.userGroup.filter((group) => grouparr === group.value)
                    tmp.forEach(group => {
                        if (grouparr === group.value) {
                            resultArray.push(group.label);
                        }
                    })
                });
                return resultArray;
            };

            const mapExpertRoles = mappingRoles(data.expert_groups);
            const mapUserRoles = mappingRoles(data.user_groups);
            const currentUserRole = localStorage.getItem('userRole');

            if (this.backofficeRole !== 'null') this.btnVariations.isExpertRole = mapExpertRoles.includes(currentUserRole);
            this.btnVariations.isUserRole = mapUserRoles.includes(currentUserRole);
        },

        contestRequest(data) {
            if (data.length > 0) {
                this.btnVariations.isParticipantPass = true;
                this.btnVariations.isParticipant = true;
                this.btnVariations.isExpert = false;
            }
        },
        getStopList (data) {
            if (this.isLogged && this.user) {
                data.email_blacklist.forEach((item) => {
                    if (this.user.email === item) this.$router.push('/platform')
                })
            }
        }
    }
}
</script>

<style lang="less">
@import '../../assets/styles/pages/event';
</style>
