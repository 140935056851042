import { HTTP } from './default'

export default {
    getImage(id) {
        return HTTP({
            url: `/teachbase/image/${id}/`,
            method: 'GET',
        })
    },
    createImage(data) {
        return HTTP({
            url: '/common/image/',
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data
        })
    },
    updateImage({ form, id }) {
        return HTTP({
            url: `/common/image/${id}/`,
            method: 'PUT',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: form
        })
    },
    removeImage(id) {
        return HTTP({
            url: `/common/image/${id}/`,
            method: 'DELETE',
            data: {
                id
            }
        })
    },
    postMail(data) {
        return HTTP({
            url: '/mail/create/feedback/',
            method: 'POST',
            data
        })
    },
    getAllEducations({ search, page }) {
        return HTTP({
            url: `/common/educational_institutions/?search=${search}&page=${page}`,
            method: 'GET'
        })
    },
    getGlobalSchools({ search = '', page = 1, page_size } = new Object()) {
        return HTTP({
            url: '/user/shools/',
            method: 'GET',
            params: {
                search,
                page,
                page_size
            }
        })
    },
    getVacancyCities({search = ''} = new Object()) {
        return HTTP({
            url: '/common/vacancy/cities/',
            method: 'GET',
            params: {
                search
            }
        })
    },
    getAllCompanies({search = ''} = new Object()) {
        return HTTP({
            url: '/teachbase/vacancies/company/',
            method: 'GET',
            params: {
                search
            }
        })
    },
    getMainSlider() {
        return HTTP({
            url: '/common/sliders/'
        })
    },
    getSettings() {
        return HTTP({
            url: '/common/settings/'
        })
    },
    setVideoActivity({ id, status }) {
        return HTTP({
            url: `/common/activity/video/${id}/`,
            method: 'PUT',
            data: status
        })
    }
}
