import {HTTP, getToken} from './default'

export default {
    loginMobile(user, path) {
        return HTTP({
            url: 'auth/mobile/',
            method: 'POST',
            headers: {
                'Current-Page': path
            },
            data: {
                code: user.code
            }
        })
    },
    login(user, path) {
        return HTTP({
            url: 'auth/login/',
            method: 'POST',
            headers: {
                'Current-Page': path
            },
            data: {
                email: user.email,
                password: user.pass,
                referrer_page: user.referrer_page
            }
        })
    },
    loginExternal(net) {
        window.location.href = `https://lift-bf.ru/accounts/${net}/login/`
        // return HTTP({
        //     baseURL: 'https://lift-bf.ru/accounts',
        //     url: `/${net}/login/`,
        //     method: 'GET'
        // })
    },
    register(data, path = '/') {
        return HTTP({
            url: 'auth/register/',
            method: 'POST',
            headers: {
                'Current-Page': path
            },
            data
        })
    },
    registerEvent(data, path = '/') {
        return HTTP({
            url: `event/register/${data.external_service.slug}/`,
            method: 'PUT',
            headers: {
                'Current-Page': path
            },
            data
        })
    },
    authExternal(upid) {
        return HTTP({
            url: `https://lift-bf.ru/api/v1/auth/external_service/?upid=${upid}`,
            method: 'GET'
        })
    },
    recovery({email = null, pass, upid}) {
        let data = null;

        if (email) data = {email}
        else data = {password: pass, upid}

        return HTTP({
            url: 'auth/recovery_password/',
            method: 'PUT',
            data
        })
    },
    change({data, id}) {
        return HTTP({
            url: `user/${id}/`,
            method: 'PUT',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data
        })
    },
    changeOnboarding({data, id}) {
        return HTTP({
            url: `user/${id}/update_user_role_after_onboarding/`,
            method: 'PUT',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data
        })
    },
    downloadProfile(id) {
        return HTTP({
            url: `user/users/${id}/download/`,
            method: 'GET'
        })
    },
    downloadResume(id) {
        return HTTP({
            url: `/recruiter/download/summary/${id}/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    activate(id) {
        return HTTP({
            url: `user/${id}/`,
            method: 'PUT',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data: {
                is_verification: true
            }
        })
    },
    detail(id) {
        return HTTP({
            url: `user/${id}/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    getRole() {
        return HTTP({
            url: '/user/users/current_user/',
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    setNewPassword(pass, token, ttl, uid) {
        return HTTP({
            url: `/auth/password_change/?email_token=${token}&ttl=${ttl}&uid=${uid}&date=${Date.now()}`,
            method: 'PUT',
            data: {
                new_password: pass
            }
        })
    },
    newpass(pass) {
        return HTTP({
            url: '/auth/password_change/',
            method: 'PUT',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data: {
                new_password: pass,
                is_verification: true
            }
        })
    },
    career(id, stop = false) {
        if (!stop) {
            return HTTP({
                url: `/user/iframe/${id}/`,
                method: 'GET'
            })
        } else {
            return HTTP({
                url: `/user/iframe/${id}/`,
                method: 'PUT',
                data: {
                    close_iframe: false
                }
            })
        }
    },
    logout() {
        return HTTP({
            url: 'auth/logout/',
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    delete(id) {
        return HTTP({
            url: `user/${id}/`,
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    getAllResponses(ordering) {
        return HTTP({
            url: '/user/vacancy_response/',
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            params: {
                ordering
            }
        })
    },
    getResponse(id) {
        return HTTP({
            url: `/user/vacancy_response/${id}/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    changeResponse({id, data}) {
        return HTTP({
            url: `/user/vacancy_response/${id}/`,
            method: 'PUT',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data
        })
    },
    getUsersCount() {
        return HTTP({
            url: 'user/users/counter/',
            method: 'GET'
        })
    },
    getFavoriteVacancies(id) {
        return HTTP({
            method: 'GET',
            url: `user/users/${id}/favorite_vacancies/`,
            headers: {
                'Authorization': `Token ${getToken()}`
            },
        })
    },
    addFavoriteVacancy(id) {
        return HTTP({
            method: 'POST',
            url: `/teachbase/vacancies/${id}/favorite/`,
            headers: {
                'Authorization': `Token ${getToken()}`
            },
        })
    },
    getCertificates({page = 1, uid} = new Object()) {
        if (!uid) {
            return HTTP({
                method: 'GET',
                url: '/user_stats/certificates/',
                headers: {
                    'Authorization': `Token ${getToken()}`
                },
                params: {
                    page
                }
            })
        } else {
            return HTTP({
                method: 'GET',
                url: 'user_stats/certificates/',
                headers: {
                    'Authorization': `Token ${getToken()}`
                },
                params: {
                    page, uid
                }
            })
        }
    },
    getProgramCertificates({page = 1, uid} = new Object()) {
        if (!uid) {
            return HTTP({
                method: 'GET',
                url: '/program/certificates/',
                headers: {
                    'Authorization': `Token ${getToken()}`
                },
                params: {
                    page
                }
            })
        } else {
            return HTTP({
                method: 'GET',
                url: '/program/certificates/',
                headers: {
                    'Authorization': `Token ${getToken()}`
                },
                params: {
                    page, uid
                }
            })
        }
    },
    getCoursesInProgress({page = 1, id} = new Object()) {
        return HTTP({
            method: 'GET',
            url: `/user/users/${id}/courses/in_progress/`,
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            params: {
                page
            }
        })
    },
    getCompletedCourses({page = 1, id} = new Object()) {
        return HTTP({
            method: 'GET',
            url: `/user/users/${id}/courses/completed/`,
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            params: {
                page
            }
        })
    },
    getPrograms({page = 1,status} = new Object()) {
        return HTTP({
            method: 'GET',
            url: `/program/?status=${status}`,
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            params: {
                page
            }
        })
    },
    checkEmail(email) {
        return HTTP({
            url: 'auth/check/',
            method: 'POST',
            data: {
                email
            }
        })
    },
    checkPromoCode(code) {
        return HTTP({
            url: `/referral/code/validate?referral_code=${code}`,
            method: 'GET',
        })
    },
    sendPromoCode(data, id) {
        return HTTP({
            url: `/user/users/${id}/promo-code/`,
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data
        })
    },
    getScholarship(id,params) {
        return HTTP({
            method: 'GET',
            url: `user/users/${id}/task/?field=${params}`,
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    UsedPrize(id, params) {
        return HTTP({
            method: 'POST',
            url: `/motivation/user-present/${id}/?${params}`,
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    uploadGradeFiles(data) {
        return HTTP({
            url: '/motivation/files/',
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`,
                'Content-Type': 'multipart/form-data'
            },
            data
        })
    },
    deleteImage(id) {
        return HTTP({
            url: `/motivation/files/${id}/`,
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    getPrize(url) {
        return HTTP({
            method: 'GET',
            url: `${url}`,
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    sendMotivationEmail(data) {
        return HTTP({
            url: '/motivation/email_subscribe/',
            method: 'POST',
            data
        })
    },
    isUserComplete(id) {
        return HTTP({
            url: `user/users/${id}/profile/complete/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
}
