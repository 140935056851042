<template>
	<div>
		<Navigation />

		<div class="sections">
			<section
				id="home"
				ref="home"
				class="section-simple banner fadeIn"
			>
				<div class="content">
					<h1 class="banner__title">
						Узнай больше о современных профессиях! <span>Пройди обучение и получи стипендию</span>
					</h1>
					<div
						class="banner__pic"
						:style="'/img/landing/role/pic__banner-3.jpg' | bgrImageCover"
					/>
				</div>
			</section>

			<Partners />

			<section
				id="directions"
				ref="directions"
				tabindex="1"
				class="section-simple directions fadeInUp"
			>
				<SchoolMenu />
			</section>

			<section
				id="program"
				ref="program"
				class="section-simple guidance fadeInUp"
			>
				<div class="content">
					<div class="guidance__pretitle">
						Проект реализуется Благотворительным фондом «Система»
					</div>
					<h2 class="section-title">
						<span>«Лифт в будущее»</span> - бесплатная программа профориентации и обучения от крупнейших работодателей страны
					</h2>
					<div class="guidance__list">
						<div class="guidance__item">
							<div class="guidance__title">
								74% старшеклассников говорят,
								что они мало знают о рынке труда
							</div>
							Мы сами создаем рабочие места в регионах и поэтому можем рассказать о перспективных направлениях в учебе
						</div>
						<div class="guidance__item">
							<div class="guidance__title">
								24% учеников выбирают вуз, ориентируясь на мнение родителей
							</div>
							«Лифт в будущее» поможет сделать выбор, который подойдет именно тебе и станет стартовой точкой для саморазвития и карьеры
						</div>
					</div>
				</div>
			</section>

			<div class="content">
				<BannersGrid />
			</div>

			<section
				id="banner"
				ref="banner"
				class="section-simple banner fadeInUp"
			>
				<div class="content">
					<h1 class="banner__title banner__title--program">
						Программа предназначена для учеников <span>8-11 классов</span> и <span>учителей</span> из любого региона России
					</h1>
				</div>
			</section>

			<section
				class="section-simple steps fadeInUp"
			>
				<div class="content">
					<h2 class="section-title">
						Программа для учеников
					</h2>
					<SchoolProgram />
				</div>
			</section>

			<section
				class="section-simple test fadeInUp"
			>
				<div class="content">
					<a
						v-if="!isLogged"
						class="test__panel"
						@click="signIn()"
					>
						<h3 class="title-section">Тест на профориентацию</h3>
						<img
							:src="'/img/icons/icon__link-jump.svg' | prodSrcImage"
							alt="Ссылка"
							class="test__icon"
						>
					</a>
					<router-link
						v-else
						class="test__panel"
						to="career"
						target="_blank"
					>
						<h3 class="title-section">
							Тест на профориентацию
						</h3>
						<img
							:src="'/img/icons/icon__link-jump.svg' | prodSrcImage"
							alt="Ссылка"
							class="test__icon"
						>
					</router-link>
				</div>
			</section>

			<!--			<section-->
			<!--				id="teachers"-->
			<!--				ref="teachers"-->
			<!--				class="section-simple steps fadeInUp"-->
			<!--			>-->
			<!--				<TeachersProgram />-->
			<!--			</section>-->

			<section
				id="guidance"
				ref="guidance"
				class="section-simple events fadeInUp"
			>
				<div class="content">
					<div class="section-heading">
						<h2 class="section-title">
							Практика и мероприятия
						</h2>
						<div class="section-text">
							Участвуй в мероприятиях «Лифта в будущее», чтобы попасть проф. тусовку и обзавестись нужными связями
						</div>
					</div>
					<SpecProjects :role="'Schoolboy'" />
				</div>
			</section>

			<section
				id="blog"
				ref="blog"
				class="section-simple blog fadeInUp"
			>
				<Blog />
			</section>

			<Subscribe :role="'School'" />

			<Reviews />
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';
// import AllRolesBanner from '@/components/events/roles/AllRolesBanner';
import Partners from '@/components/events/roles/Partners';
import Subscribe from '@/components/events/roles/Subscribe';
import Navigation from '@/components/events/roles/Navigation';
import SpecProjects from '@/components/events/roles/SpecProjects';
import Blog from '@/components/events/roles/Blog';
// import TeachersProgram from '@/components/events/roles/TeachersProgram';
import SchoolProgram from '@/components/events/roles/SchoolProgram';
import SchoolMenu from '@/components/events/roles/SchoolMenu';
import Reviews from '@/components/events/roles/Reviews';
// import EventBanner from '@/components/events/roles/EventBanner.vue';
import BannersGrid from '@/components/events/roles/BannersGrid.vue';

export default {
    name: 'School',
    components: {
        // EventBanner,
        // AllRolesBanner,
				BannersGrid,
        Partners,
        Subscribe,
        Navigation,
        SpecProjects,
        Blog,
        SchoolProgram,
        SchoolMenu,
        Reviews
    },
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    },
    mounted() {
        window.scrollTo(0,0)
        if(this.isLogged) {
            this.$router.push('/platform')
        }
    },
    methods: {
        signIn() {
            this.modalOpen('regIn', {role: 'Schoolboy'})
        }
    }
}
</script>

<style lang="less">
@import "../../assets/styles/pages/landing__role";
.page-role .navigation-role__menu {
    background: #efefef;
}
</style>
