<template>
	<label
		class="checkbox"
		:class="{
			'checkbox--filter': isCheckMark,
			'checkbox--square': isSquare,
			'checkbox--is-sidebar': isSidebar
		}"
	>
		<input
			v-model="localValue"
			type="checkbox"
			:value="id"
		>
		<span
			:class="{ 'checkbox__label--is-sidebar': isSidebar }"
		>{{ label }}
			<a
				v-if="textLink && link"
				:href="link"
				target="_blank"
			>{{ textLink }}.</a>
		</span>
		<span
			v-if="isError"
			class="help-block form-error"
		>Это обязательное поле</span>
		<div
			v-if="isShowTooltip"
			class="question-field"
		>
			<div
				class="question-active"
				data-title="Скрыть пользователей, которых вы просматривали за последние 6 месяцев"
			>
				<img
					width="20px"
					height="20px"
					:src="'/img/question.png' | prodSrcImage"
					alt=""
				>
			</div>
		</div>
	</label>
</template>
<script>
export default {
    props: {
        value: {
            type: [Array, Boolean],
            default: () => [] || false
        },
        label: {
            type: String,
            default: 'Label checkbox'
        },
        isCheckMark: {
            type: Boolean,
            default: true
        },
        isSidebar: {
            type: Boolean,
            default: false
        },
        isShowTooltip: {
            type: Boolean,
            default: false
        },
        isSquare: {
            type: Boolean,
            default: false
        },
        id: {
            type: [Number, String],
            default: null
        },
        textLink: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: ''
        },
        isError: {
            type: Boolean,
            default: false
        },
        count: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            localValue: false,
            isArray: false
        };
    },
    watch: {
        value: {
            handler(newValue) {
                if (newValue !== this.localValue) {
                    this.localValue = newValue;
                }
            },
            immediate: true
        },
        localValue(newValue, oldValue) {
            if (newValue !== oldValue && newValue !== this.value) {
                this.$emit('input', newValue);
            }
        }
    }
};
</script>
<style lang="less" scoped>
.checkbox--filter {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: @color-text;
    cursor: pointer;
    .transition;
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    & + .checkbox {
        margin-top: 20px;
    }
    input {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: -99999px;
        &:checked {
            & + span {
                &:before {
                    background: @color-main;
                    border-color: @color-main;
                }
                &:after {
                    opacity: 1;
                }
            }
        }
        &.error {
            & + span {
                &:before {
                    //background-color: #ffd9d9 !important;
                    //border-color: #ffaeae !important;
                }
            }
        }
        & + span {
            position: relative;
            display: inline-block;
            padding-left: 40px;
            line-height: 1.2;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                width: 23px;
                height: 23px;
                background: none;
                border: 1px solid #d3d3d3;
                border-radius: 4px;
                .transition;
            }
            &:after {
                opacity: 0;
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                width: 23px;
                height: 23px;
                background: url(../../assets/img/icons/icon-checkbox-filter-check.png)
                center center no-repeat;
                .transition;
            }
            @media @laptop-start {
                min-height: 19px;
            }
        }
    }
    .form-error {
        left: -5px;
        margin-top: 7px;
    }
}
.checkbox--square {
    position: relative;
    min-height: 23px;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    .transition;
    @media @desktop-end {
        font-size: 16px;
    }
    @media @mobile-end {
        font-size: 15px;
    }
    @media @oldy-end {
        font-size: 14px;
    }
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    &.top {
        input {
            & + span {
                min-height: 25px;
                padding-top: 2px;
                &:before {
                    top: 0;
                    margin-top: 0;
                }
                &:after {
                    top: 0;
                    margin-top: 6px;
                }
            }
        }
    }
    & + .checkbox {
        margin-top: 20px;
    }
    input {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: -99999px;
        &:checked {
            & + span {
                &:before {
                    background: #c4c4c4;
                    border-color: #c4c4c4 !important;
                }
                &:after {
                    opacity: 1;
                }
            }
        }
        &.error {
            & + span {
                &:before {
                    border-color: @color-error;
                }
            }
        }
        & + span {
            word-break: break-word;
            position: relative;
            display: inline-block;
            padding-left: 40px;
            line-height: 1.3;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: -12px;
                width: 23px;
                height: 23px;
                border-radius: 0;
                background: #c4c4c4;
                .transition;
            }
            &:after {
                opacity: 0;
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 5px;
                margin-top: -7px;
                width: 13px;
                height: 13px;
                background: #1e1e1e;
                .transition;
            }
            a {
                color: inherit;
                text-decoration: underline;
                &:hover {
                    color: @color-main;
                }
            }
        }
    }
}
.checkbox--is-sidebar {
    input {
        &:checked {
            & + span {
                &:before {
                    background: @color-main;
                    border-color: @color-main;
                }
                &:after {
                    opacity: 1;
                }
            }
        }
        & + span {
            position: relative;
            display: inline-block;
            padding-left: 40px;
            line-height: 1.2;
            &::before,&::after {
                top: 50%;
                transform: translateY(2.5px);
            }
            @media @laptop-start {
                min-height: 19px;
            }
        }
    }
}
.question-field {
    position: relative;
}
.question-active {
    position: absolute;
    right: 25px;
    top: -48px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    @media @mobile-end{
        display: none;
    }
    &::after {
        display: none;
        content: attr(data-title);
        position: absolute;
        width: max-content;
        left: -280px;
        top: -20px;
        z-index: 1;
        background: rgb(244 244 244);
        font-family: Arial, sans-serif;
        font-size: 11px;
        padding: 5px 10px;
        border-radius: 3px;
        border: 1px solid #333;
    }

}
.question-active:hover::after {
    display: block;
}
</style>
