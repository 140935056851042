<template>
	<section
		v-if="leadersInfo"
		class="section section-leaders"
	>
		<div class="content">
			<h2 class="title-section">
				{{ leadersInfo.tasks_from_leaders_title }}
			</h2>
			<div
				class="description"
				v-html="leadersInfo.tasks_from_leaders_text"
			/>
			<div
				v-if="leadersInfo.leaders"
				class="leaders"
			>
				<div
					v-for="(item, index) in leadersInfo.leaders"
					:key="index"
					class="leader"
					:style="item.photo | bgrDynamicImageCover"
				>
					<div class="leader__content">
						<div class="title-element">
							{{ item.person_name }}
						</div>
						<div
							class="leader__position"
							v-html="item.description"
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Leaders',
    props: {
        leadersInfo: {
            type: Object,
            default: null
        }
    },
}
</script>
