<template>
	<div class="dialog">
		<h3 class="block-title">
			Для прохождения конкурса необходимо заполнить все поля профиля
		</h3>
		<div class="dialog__buttons">
			<a
				:href="`/profile/${payload}`"
				class="button button--blue"
			>Заполнить профиль</a>
			<button
				class="button button--hollow"
				@click.stop="modalClose"
			>
				Назад
			</button>
		</div>
	</div>
</template>

<script>
export default {
    computed: {
        payload() {
            return this.$store.state.modal.payload;
        }
    },
}
</script>
