<template>
	<div class="vacancy-quick-response-block">
		<div class="text dark sm">
			<p>
				<b>Отлично, мы будем рады, если ты расскажешь немного о себе!</b><br>
				Ответь на вопросы заполнив форму или запиши видео.
			</p>
		</div>
		<div class="upload-video-button-group">
			<div
				v-if="isUploading"
				class="uploaded-video-button-item"
			>
				<div class="progress">
					<div
						class="progress-line"
						:style="progressUploadStyle"
					/>
				</div>
			</div>
			<div
				v-else-if="isVideo"
				class="uploaded-video-button-item"
			>
				<div
					class="uploaded-video-button-remove"
					@click="removeVideo"
				/>
				<span class="uploaded-video-button-title">{{ nameVideo }}</span>
				<div class="uploaded-video-button-subtitle">
					({{ sizeVideo }})
				</div>
			</div>
			<div
				v-else
				class="cells"
			>
				<div class="cell">
					<label class="upload-video-button-item">
						<input
							ref="file"
							type="file"
							@change="selectVideo"
						>
						<div class="upload-video-button-title">
							Загрузить видео
							<img
								:src="
									'/img/icons/icon-angle-right.svg'
										| prodSrcImage
								"
								alt="Загрузить видео"
							>
						</div>
						<div class="upload-video-button-subtitle">
							допустимые форматы: mov, mp4
							<br>
							допустимый размер: до 25 Мегабайт
						</div>
					</label>
				</div>
				<div class="cell">
					<button
						class="upload-video-button-item"
						@click="recordVideo"
					>
						<div class="upload-video-button-title">
							Записать видео
							<img
								:src="
									'/img/icons/icon-angle-right.svg'
										| prodSrcImage
								"
								alt="Записать видео"
							>
						</div>
						<div class="upload-video-button-subtitle">
							допустимый размер: до 25 Мегабайт
						</div>
					</button>
				</div>
			</div>
			<div
				v-if="stepForm.video.error"
				class="recording-video-output red"
			>
				{{ stepForm.video.error }}
			</div>
		</div>
		<form id="sendForm">
			<div
				v-if="!isCustomQuestions"
				class="field-wrap"
			>
				<label class="field-label">
					<textarea
						v-model="stepForm.spec"
						rows="4"
						class="field"
						placeholder="Как ты выбрал свою специальность? Расскажи о своих профессиональных интересах."
					/>
					<div class="label">
						<span
							class="hidden-mobile"
						>Как ты выбрал свою специальность? Расскажи о своих
							профессиональных интересах.</span>
						<span
							class="visible-mobile"
						>Как ты выбрал свою специальность?</span>
					</div>
					<span
						v-if="$v.stepForm.spec && $v.stepForm.spec.$error"
						class="help-block form-error"
					>Это обязательное поле</span>
				</label>
			</div>
			<div
				v-if="!isCustomQuestions"
				class="field-wrap"
			>
				<label class="field-label">
					<textarea
						v-model="stepForm.achiev"
						rows="4"
						class="field"
						placeholder="Что является твоим главным достижением?"
					/>
					<div class="label">
						<span
							class="hidden-mobile"
						>Что является твоим главным достижением?</span>
						<span
							class="visible-mobile"
						>Какое твоё главное достижение?</span>
					</div>
					<span
						v-if="$v.stepForm.achiev && $v.stepForm.achiev.$error"
						class="help-block form-error"
					>
						Это обязательное поле
					</span>
				</label>
			</div>
			<div
				v-if="!isCustomQuestions"
				class="field-wrap"
			>
				<label class="field-label">
					<textarea
						v-model="stepForm.goal"
						rows="4"
						class="field"
						placeholder="Какую цель на стажировку ты перед собой ставишь?"
					/>
					<div class="label">
						<span
							class="hidden-mobile"
						>Какую цель на стажировку ты перед собой
							ставишь?</span>
						<span
							class="visible-mobile"
						>Какую цель на стажировку ты ставишь?</span>
					</div>
					<span
						v-if="$v.stepForm.goal && $v.stepForm.goal.$error"
						class="help-block form-error"
					>
						Это обязательное поле
					</span>
				</label>
			</div>
			<div
				v-if="isCustomQuestions"
				class="custom-questions"
			>
				<div
					v-for="(question, index) in $v.questions.$each.$iter"
					:key="index"
					class="field-wrap"
				>
					<label class="field-label">
						<textarea
							v-model="question.answer.$model"
							rows="4"
							class="field"
							:placeholder="question.$model.text"
						/>
						<div class="label">
							<span>{{ question.$model.text }}</span>
						</div>
						<span
							v-if="question.$error"
							class="help-block form-error"
						>
							Это обязательное поле
						</span>
					</label>
				</div>
			</div>
			<div class="cells">
				<div class="cell">
					<div class="field-wrap md">
						<button
							class="button-hollow"
							@click.prevent="prevStep"
						>
							Назад
						</button>
					</div>
				</div>
				<div class="cell">
					<div class="field-wrap md">
						<button
							v-if="!isSending"
							class="button orange full"
							@click.prevent.stop="send"
						>
							Отправить заявку
						</button>
						<button
							v-else
							class="button orange full"
						>
							Заявка отправляется...
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import {requiredIf} from 'vuelidate/lib/validators';
import Tb from '@/api/teachbase';
import { mapActions } from 'vuex';
import UserEd from '@/api/userEducation';

export default {
	props: {
		user: undefined,
		letter: undefined,
	},
	data: () => ({
        vacancy: {},
		isSending: false,
		isUploading: false,
		userLocal: undefined,
		progressUpload: undefined,
		studyPlaces: [],
		newResumeID: 0,
		stepForm: {
			video: {
				el: undefined,
				value: undefined,
				limit: 25 * Math.pow(2, 20),
				format: ['mov', 'mp4'],
				error: ''
			},
			spec: '',
			achiev: '',
			goal: ''
		}
	}),
	computed: {
		questions() {
			return this.vacancy.questions_for_quick_apply_form && this.vacancy.questions_for_quick_apply_form
				.map(el => {
					return {
						...el,
						answer: ''
					};
				})
				.sort((a, b) => a.priority - b.priority);
		},
		isLogged() {
			return this.$store.getters['user/isLoggedIn'];
		},
		payload() {
			return this.$store.getters['modal/getPayload'];
		},
		isVideo() {
			return this.stepForm.video.value;
		},
		isCustomQuestions() {
			return this.vacancy.questions_for_quick_apply_form && (!!this.vacancy.questions_for_quick_apply_form.length);
		},
		nameVideo() {
			if (this.isVideo) {
				if (this.stepForm.video.value.type === 'video/webm')
					return 'Экспромт.mp4';
				else return this.stepForm.video.value.name;
			}

			return false;
		},
		sizeVideo() {
			if (this.isVideo) {
				const megaSize =
					this.stepForm.video.value.size / Math.pow(2, 20);
				const kiloSize =
					this.stepForm.video.value.size / Math.pow(2, 10);
				if (megaSize > 1) return `${megaSize.toFixed(1)} Мегабайт`;
				else if (kiloSize < 1000)
					return `${kiloSize.toFixed(1)} Килобайт`;
			}

			return false;
		},
		activeData() {
			return this.$store.state.modal.activeData;
		},
		progressUploadStyle() {
			return `width: ${this.progressUpload * 100}%`;
		}
	},
	validations() {
        if (this.isCustomQuestions) {
            return {
                questions: {
                    $each: {
                        answer: { required: requiredIf(function() {
                                return !this.isVideo;
                            }) },
                        $trackBy: 'id'
                    }
                }
            };
        } else {
            return {
                stepForm: {
                    spec: { required: requiredIf(function() {
                            return !this.isVideo;
                        }) },
                    achiev:{ required: requiredIf(function() {
                            return !this.isVideo;
                        }) },
                    goal: { required: requiredIf(function() {
                            return !this.isVideo;
                        }) }
                }
            };
        }
	},
	created() {
		if (this.letter) {
			this.stepForm = this.letter;
		}

		this.localUser = this.user;

		if (this.activeData) {
			const parse = JSON.parse(JSON.stringify(this.activeData));

			this.localUser = parse.user;
			this.stepForm.spec = parse.form.spec;
			this.stepForm.achiev = parse.form.achiev;
			this.stepForm.goal = parse.form.goal;
		}
        this.getVacancy(this.$route.params.id)
            .then(result => {
                this.vacancy = result
            })
	},
	mounted() {
		if (this.payload && this.payload.recordVideo) {
			this.stepForm.video.value = this.payload.recordVideo;
		}
	},
	methods: {
		...mapActions('user', {
			getStudyPlaceUser: 'getStudyPlace'
		}),
        ...mapActions('tb', [
            'getVacancy'
        ]),
		prevStep(error) {
			!this.isSending &&
				this.$emit('prev-step', {
					step: 3,
					...error,
					form: this.stepForm
				});
		},
		selectVideo(event) {
			const file = event.target.files[0];

			if (file.size > this.stepForm.video.limit) {
				this.stepForm.video.error = 'Файл превышает допустимый размер';
			} else if (
				!file.type ||
				!this.stepForm.video.format.some(x =>
					this.isFormatVideoValid(file, x)
				)
			) {
				this.stepForm.video.error =
					'Файл не соответствует нужному формату';
			} else {
				this.stepForm.video.error = '';
				this.stepForm.video.value = file;
			}
		},
		removeVideo() {
			this.stepForm.video.value = undefined;
			this.stepForm.video.el = undefined;
		},
		isFormatVideoValid(video, format) {
			if (
				format === 'mov' &&
				(video.type.includes(format) ||
					video.type.includes('quicktime'))
			)
				return true;
			else if (format === 'mp4' && video.type.includes(format))
				return true;

			return false;
		},
		recordVideo() {
			this.$store.commit('modal/setActiveData', {
				user: this.localUser,
				form: this.stepForm
			});
			this.modalOpen('recordVideo');
		},
		async send() {
			this.isCustomQuestions
				? this.$v.questions.$touch()
				: this.$v.stepForm.$touch();
			if (this.$v.$invalid) {
				this.$scrollTo(document.querySelector('#sendForm'));
			} else if (!this.isSending) {
				this.isSending = true;
				if (
                    this.$metrika &&
					(window.location.hostname ===
						'stage.lift.tbproject.ru' ||
					window.location.hostname === 'lift-bf.ru')
				) {
                    this.$metrika.reachGoal('fast_response_vacancy', {
                        URL: document.location.href
                    })
				}
				try {
					!this.isLogged && (await this.regInUser());

					const user = await this.saveUser();
					const USER = this.$store.state.user.user;
					this.getStudyPlaceUser().then(res => {
						this.studyPlaces = res;
					});
					this.studyPlaces.forEach(item => {
						delete item.id;
						delete item.primary_education;
					});

					this.studyPlaces.forEach(item => {
						if (item.institution !== null) {
							if (item.institution.name === '')
								item.institution = null;
						}
					});
					this.$store
						.dispatch('tb/createResume', {
							user: localStorage.getItem('userID'),
							last_name: this.localUser.last_name,
							first_name: this.localUser.first_name,
							middle_name: this.localUser.middle_name,
							birthday: this.localUser.birthday,
							phone: this.localUser.phone,
							email: this.localUser.email,
							preferred_position: this.$store.state.modal.payload.name,
                            type_of_employment: this.$store.state.modal.payload.type_employment,
							[this.isLogged && USER
								? 'candidate_photo_url'
								: 'photo']:
								USER && USER.image_user.length
									? USER.image_user[0].image
									: ''
						})
						.then(response => {
							this.newResumeID = response.id;
							let answersOnCustomQuestions = '';
							let answersOnStaticQuestions = `${this.stepForm.spec}\n${this.stepForm.achiev}\n${this.stepForm.goal}`;
							if (this.questions && this.questions.length) {
                                this.questions.map(el => {
                                    answersOnCustomQuestions += `${el.answer}\n`;
                                });
                            }
							this.sendProposal({
								userId: user.id,
								vacancyId: this.$route.params.id,
								resumeId: this.newResumeID,
								coverLetter: this.isCustomQuestions
									? answersOnCustomQuestions
									: answersOnStaticQuestions,
								video: this.stepForm.video.value
							});
						})
						.then(() =>
							UserEd.addStudyPlaceResume({
								data: this.studyPlaces,
								id: this.newResumeID
							})
						);
					this.$store.dispatch('user/getAllResponses');

					this.modalClose('vacancySuccess');
				} catch (error) {
					this.isSending = false;
					if (error.field === 'phone' || error.field === 'email')
						this.prevStep({ error });
				} finally {
					this.isSending = false;
				}
			}
		},
		saveUser() {
			const [day, month, year] = this.localUser.birthday.split('.');

			return this.$store.dispatch('user/change', {
				last_name: this.localUser.last_name,
				first_name: this.localUser.first_name,
				middle_name: this.localUser.middle_name,
				email: this.localUser.email,
				phone: this.localUser.phone,
				city: {
					name: this.localUser.city
				},
				birthday: `${year}-${month}-${day}`,
				employment_type: this.localUser.employment_type,
				study: {
					education: this.localUser.study.edu,
					specialization: {
						name: this.localUser.study.spec
					},
					education_facility: {
						name: this.localUser.study.edu_fac
					},
					graduation_year: this.localUser.study.grad_year
				}
			});
		},
		sendProposal({ userId, vacancyId, resumeId, coverLetter, video }) {
			return new Promise((resolve, reject) => {
				let formData = new FormData();

				formData.append('user', userId);
				formData.append('vacancy', vacancyId);
				formData.append('summary_new', resumeId);
				formData.append('cover_letter', coverLetter);

				if (video) {
					formData.append('video_file', video, 'response.mp4');
				}

				const proposal = Tb.startVacancyXHR(formData);

				proposal.onload = () => {
					if (
						proposal.readyState === 4 &&
						(proposal.status === 201 || proposal.status === 200)
					) {
						resolve();
					} else {
						reject();
					}
				};

				proposal.onprogress = event => {
					if (event.lengthComputable) {
						this.progressUpload = event.loaded / event.total;
					}
				};
			});
		}
	}
};
</script>

<style lang="less">
textarea.field + .label {
	top: unset;
	bottom: 65%;
}
.field:focus + .label {
	@media @mobile-end {
		bottom: 100%;
		color: #999;
		font-size: 12px;
	}
}
.label span {
	word-break: break-all;
}
textarea.field:not(:placeholder-shown) + .label {
	top: unset;
	bottom: 100%;
}
</style>
