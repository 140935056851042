<template>
	<section
		v-if="audienceMain"
		class="section section-audience"
	>
		<div class="content content--md">
			<!-- У блока .audience есть 8 модификаторов меняющих цвет фона: audience--black, audience--orange, audience--blue, audience--pink, audience--peach, audience--yellow, audience--lightblue, audience--lightgreen -->
			<div
				class="audience"
				:class="ColorClass"
			>
				<div class="audience__header">
					<h2 class="title-section">
						{{ audienceMain.why_to_participate_title }}
					</h2>
					<!-- Есть чёрная и белая версия картинки: pic__audience--black.png и pic__audience--white.png -->
					<img
						:src="audienceMain.why_to_participate_image"
						alt="Картинка"
						class="audience__pic"
					>
				</div>
				<div
					v-if="audienceInfo"
					class="audience__content"
				>
					<div
						v-for="(item, index) in audienceInfo"
						:key="index"
						class="audience-item"
					>
						<h3 class="audience-item__title">
							{{ item.title }}
						</h3>
						<div
							class="audience-item__text"
							v-html="item.description"
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Audience',
    props: {
        audienceMain: {
            type: Object,
            default: null
        },
        audienceInfo: {
            type: Array,
            default: null
        }
    },
    computed: {
        ColorClass() {
            if (this.audienceMain.why_to_participate_color === '#000000') return 'audience--black'
            if (this.audienceMain.why_to_participate_color === '#D4F2FF') return 'audience--lightgreen'
            if (this.audienceMain.why_to_participate_color === '#EF722E') return 'audience--orange'
            if (this.audienceMain.why_to_participate_color === '#004494') return 'audience--blue'
            if (this.audienceMain.why_to_participate_color === '#F8C8FF') return 'audience--pink'
            if (this.audienceMain.why_to_participate_color === '#FFD5D6') return 'audience--peach'
            if (this.audienceMain.why_to_participate_color === '#FFF8D5') return 'audience--yellow'
            if (this.audienceMain.why_to_participate_color === '#D5F2FF') return 'audience--lightblue'
            return ''
        }
    }
}
</script>
