<template>
	<div
		class="select-button"
		@click="clickDisplay"
	>
		<label class="field-label onboarding-label ">
			<input
				v-model="input"
				type="text"
				class="select-value field"
				:class="{ 'autosearch': autosearch }"
				:readonly="!autosearch"
				:placeholder="placeholder"
				@input="inputDisplay"
			>
			<!-- && onboarding ? '' : placeholder -->
			<div
				v-if="label"
				class="label"
				:style="labelStyles"
				@click="clickDisplay"
			>{{ label }}</div>
			<div
				v-if="error && !onboarding"
				class="help-block form-error"
			>{{ error }}</div>
		</label>
		<div
			v-if="error && onboarding"
			class="error-field"
		>
			{{ error }}
		</div>
		<div
			v-if="!async"
			class="select-arrow"
			:style="styleOptions"
			@click.stop="clickArrow"
		/>
	</div>
</template>

<script>
export default {
    props: {
        onboarding: {
            type:Boolean,
            default:false,
        },
        autosearch: {
            type: Boolean,
            default: false
        },
        dynamic: {
            type: Boolean,
            default: false
        },
        async: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Select value'
        },
        label: {
            type: String,
            default: 'Education'
        },
        value: {
            type: [String, Number],
            default: undefined
        }
    },
    data: () => ({
        input: ''
    }),
    computed: {
        labelStyles() {
            if (this.autosearch) return {
                cursor: 'text'
            }
            return {
                cursor: 'pointer'
            }
        },
        styleOptions() {
            return this.onboarding ? this.$options.filters.bgrImageCover('/img/icons/icon__triangle-down--gray.svg') :this.$options.filters.bgrImageCover('/img/icons/icon-arrow-select.svg');
        }
    },
    watch: {
        value(newV) {
            this.input = newV
        }
    },
    created() {
        this.input = this.value
    },
    methods: {
        inputDisplay() {
            this.$emit('inputDisplay', this.input)
        },
        clickDisplay() {
            this.$emit('clickDisplay')
        },
        clickArrow() {
            this.$emit('clickArrow')
        },
        clickButton() {
            this.$emit('clickButton')
        }
    }
}
</script>
