<template>
	<div class="steps__list">
		<div class="step">
			<div class="step__heading">
				<h2 class="step__title">
					Тестирование
				</h2>
				<router-link
					to="/tests"
					target="_blank"
					class="more-link"
				>
					Подробнее
					<img
						:src="'/img/icons/icon__arrow-right--orange.svg' | prodSrcImage"
						alt="Иконка"
					>
				</router-link>
			</div>
			<div class="step__content">
				<div class="step__text">
					<p><b>Экспресс-диагностика навыков и рекомендации по развитию</b></p>
					<p>Тесты на уровень развития soft skills, готовность к работе и компетенции будущего</p>
				</div>
				<div class="step__duration">
					7 минут
				</div>
			</div>
		</div>
		<div class="step">
			<div class="step__heading">
				<h2 class="step__title">
					Обучение по карьерным трекам
				</h2>
			</div>
			<div class="step__content">
				<div class="step__text">
					<p><b>Онлайн-курсы об отраслях и технологиях, профессиях и компетенциях</b> от представителей ведущих работодателей страны</p>
				</div>
				<div class="step__duration">
					по 2 академических часа на курс
				</div>
			</div>
		</div>
		<div
			id="internship"
			ref="internship"
			class="step"
		>
			<div class="step__heading">
				<h2 class="step__title">
					Стипендия
				</h2>
				<router-link
					to="/stipendia"
					target="_blank"
					class="more-link"
				>
					Подробнее
					<img
						:src="'/img/icons/icon__arrow-right--orange.svg' | prodSrcImage"
						alt="Иконка"
					>
				</router-link>
			</div>
			<div class="step__content">
				<div class="step__text">
					<p>Лучшие участники, прошедшие весь путь на «Лифте», могут <b>получить стипендию</b></p>
				</div>
				<div class="step__duration">
					5 месяцев
				</div>
			</div>
		</div>
		<div class="step">
			<div class="step__heading">
				<h2 class="step__title">
					Наставничество, практики и стажировки
				</h2>
				<router-link
					to="/internship"
					target="_blank"
					class="more-link"
				>
					Подробнее
					<img
						:src="'/img/icons/icon__arrow-right--orange.svg' | prodSrcImage"
						alt="Иконка"
					>
				</router-link>
			</div>
			<div class="step__content">
				<div class="step__text">
					<p>Выбрав профессию мечты, ты можешь пройти <b>стажировку или практику в одной из ведущих компаний страны</b>, чтобы на практике увидеть, как выглядит твоя будущая работа, какие задачи стоят перед компанией и как их решают настоящие специалисты. <b>С помощью опытного наставника ты станешь одним из них</b></p>
				</div>
				<div class="step__duration">
					2-3 месяца
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'EducationSteps'
};
</script>

<style scoped>

</style>
