<template>
	<section class="section section-banner">
		<div class="content">
			<Breadcrumb :page-name="'Cтипендиальная программа'" />
			<div class="banner banner--stipend">
				<img
					:src="'/img/pages/event/pic__stipend-2--x2.png' | prodSrcImage"
					alt="Твоя стипендия"
					class="banner__pic"
				>
				<img
					:src="'/img/racoon/pic__racoon-14--x2.png' | prodSrcImage"
					alt="Енот"
					class="banner__racoon"
				>
				<img
					:src="'/img/pages/event/design__stipend-1.svg' | prodSrcImage"
					alt="Картинка"
					class="banner__design"
				>
				<div class="banner__content">
					<h2 class="title-mono">
						Программа
					</h2>
					<h1 class="title-section">
						Стипендиальная программа «Система»
					</h1>
					<div class="description">
						Поддерживаем мотивированных школьников и студентов в исследовательской, научной и инновационной деятельности
					</div>
					<div
						v-if="!isLogged"
						class="banner-stipend__button"
					>
						<button
							class="button"
							@click="modalOpen('regIn')"
						>
							Зарегистрироваться
						</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import { mapGetters } from 'vuex';

export default {
    name: 'Banner',
    components: {
        Breadcrumb
    },
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    }
};
</script>
