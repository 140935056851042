<template>
	<div v-if="eventSampleSTORE && btnVariations">
		<div v-if="isActive">
			<div v-if="!isRegistered && ($route.params.slug !== 'ocean' && $route.params.slug !== 'phystech')">
				<button
					class="button"
					:style="'background-color:' + btnColor"
					@click="registerToEvent"
				>
					Зарегистрироваться
				</button>
			</div>
			<ContestRegisterButton
				v-if="isContest && isRegistered"
				:btn-variations="btnVariations"
				:is-apply-contest="isApplyContest"
				:actual-tests="actualTests"
				:btn-color="btnColor"
				:contest-id="eventSampleSTORE.contest_id"
				@apply="applyToContest"
			/>
			<div v-if="(!isContest && isRegistered) || ($route.params.slug === 'ocean' || $route.params.slug === 'phystech')">
				<div v-if="!btnLink">
					<div
						v-if="btnName && !btnLink"
						class="banner__button"
					>
						<button
							v-if="!isSended"
							class="button"
							:style="'background-color:' + btnColor"
							@click="registerToEvent"
						>
							{{ buttonName }}
						</button>
						<div
							v-else
							class="button orange create-resume-save lg button-preloader"
						>
							Заявка отправляется
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="btnName && btnLink">
					<button
						class="button"
						@click="() => {startEvent(btnLink)}"
					>
						{{ btnName }}
					</button>
				</div>
			</div>
		</div>
		<div v-else-if="!isLogged">
			<button
				class="button"
				:style="'background-color:' + btnColor"
				@click="registerToPlatform"
			>
				Зарегистрироваться
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import OneContestAPI from '@/api/contest';
import ContestRegisterButton from './ConstructorButtons/ContestRegisterButton.vue';
import User from '@/api/user';

export default {
    name: 'ConstructorButton',
	components: {
		ContestRegisterButton,
	},
    props: {
        btnName: {
            type: String,
            default: null
        },
        btnColor: {
            type: String,
            default: null
        },
        btnLink: {
            type: String,
            default: null
        },
        actualTests: {
            type: Array,
            default: null
        },
        isApplyContest: {
            type: Boolean,
            efault: false
        },
        isDisableProfileCheck: {
            type: Boolean,
            default: false
        },
        isActive: {
            type: Boolean,
            efault: false
        },
        btnVariations: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        isSended: false,
        userRole: '',
    }),
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            user: 'userInfo'
        }),
		...mapGetters('smpl', {
            eventSampleSTORE: 'getEventSample'
        }),
		isRegistered() {
			return this.eventSampleSTORE.is_current_user_already_registered;
		},
		isContest() {
			return this.eventSampleSTORE.type === 'contest';
		},
        buttonName() {
            if (this.isLogged && !this.isRegistered) return this.btnName
            if (this.isRegistered && (this.$route.params.slug !== 'ocean' && this.$route.params.slug !== 'phystech')) return 'Вы уже зарегистрированы на мероприятие!'
            if (this.isLogged && (this.$route.params.slug === 'ocean' || this.$route.params.slug === 'phystech')) return 'Перейти к заявке'
            return 'Зарегистрироваться'
        }
    },
    methods: {
        registerToPlatform() {
            if (this.$route.fullPath === '/events/uchitel' || this.$route.fullPath === '/events/shkolnik' || this.$route.fullPath === '/events/st-st') {
                this.modalOpen('regIn', 'rolesLandings');
            } else {
                this.modalOpen('regIn', 'eventConstructor');
            }
        },

        registerToEvent() {
            if (this.$route.params.slug === 'ocean') this.redirectToExternalService('ocean');
            if (this.$route.params.slug === 'phystech') this.redirectToExternalService('phystech');

            if (!this.isLogged) {
                this.registerToPlatform();
			    return;
            }

            if (!this.isRegistered) {
				const sendData = {
					first_name: this.user.first_name,
					email: this.user.email,
					external_service: {slug: this.$route.params.slug},
					user_role: this.user.user_role
				}
				this.sendRegisterToEvent({ data: {...sendData}, slug: this.$route.params.slug });
            }
        },
        redirectToExternalService(serviceName) {
            if (!this.isLogged) this.modalOpen('regIn', 'test')
            else if (serviceName === 'ocean') window.location.href = 'https://okean.moyastrana.ru/'
            else if (serviceName === 'phystech') window.location.href = 'https://liftbudushhego.moyastrana.ru'
        },
        applyToContest() {
            if (!this.isLogged) this.modalOpen('regIn', 'test')
            else {
                User.detail(this.user.id).then(res => {
                    localStorage.setItem('jwtToken', res.data.jwt_token);
                })

                if (!this.isDisableProfileCheck) {
                    User.isUserComplete(this.user.id).then(() => {
                        this.openContestModal();
                    }).catch(() => {
                        this.modalOpen('is-user-complete', this.user.id)
                    })
                } else {
                    this.openContestModal();
                }
            }
        },

		async sendRegisterToEvent(data) {
			try {
				await OneContestAPI.registerToEvent(data);

				if (this.$route.params.slug === 'ocean')  {
					window.location.href = 'https://okean.moyastrana.ru/';
				}

				if (this.$route.params.slug === 'phystech')  {
					window.location.href = 'https://liftbudushhego.moyastrana.ru';
				}

				this.modalOpen('eventSuccess');
			} catch (error) {
				this.modalOpen('eventFail', error.response.data[0]);
			} finally {
				this.isSended = false;
			}
		},

		startEvent(link) {
			window.location.href = link;
		},

        openContestModal() {
            this.modalOpen('apply-contest', {
                contest: this.eventSampleSTORE.contest_id,
                stage: this.eventSampleSTORE.stage_id,
                status: 'edit',
            })
        },
    }
}
</script>
