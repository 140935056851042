<template>
	<section
		v-if="directions"
		class="section section-directions"
	>
		<div class="content">
			<div class="directions">
				<div
					v-for="(item, index) in directions"
					:key="index"
					class="direction"
				>
					<div class="direction__pic-wrap">
						<img
							:src="item.image"
							alt="Иконка"
							class="direction__pic"
						>
					</div>
					<h3 class="title-element">
						{{ item.title }}
					</h3>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Directions',
    props: {
        directions: {
            type: Array,
            default: null
        }
    }
}
</script>
