<template>
	<div class="modal-content">
		<nav class="main-menu-modal">
			<ul>
				<li>
					<a
						href="/courses"
						@click.prevent="linkTo('courses')"
					><span>Обучение</span></a>
				</li>
				<li>
					<a
						href="/learning-esg"
						@click.prevent="linkTo('learning-esg')"
					><span>Устойчивое развитие</span></a>
				</li>
				<li>
					<a
						href="/career"
						@click.prevent="linkTo('career')"
					><span>Кем быть</span></a>
				</li>
				<li>
					<a
						href="/vacancies"
						@click.prevent="linkTo('vacancies')"
					><span>Вакансии</span></a>
				</li>
				<!-- <li>
					<a
						href="/internship"
						@click.prevent="linkTo('internship')"
					><span>Стажировки</span></a>
				</li> -->
				<li v-if="isRecruiter">
					<a
						href="/search-users"
						@click.prevent="linkTo('search-users')"
					><span>Поиск пользователей</span></a>
				</li>
				<li v-if="projects && projects.length">
					<a
						href="/projects"
						@click.prevent="linkTo('projects')"
					><span>Мероприятия</span></a>
				</li>
				<li>
					<a
						href="/tests"
						@click.prevent="linkTo('tests')"
					><span>Тесты</span></a>
				</li>
				<!--				<li>-->
				<!--					<a-->
				<!--						href="/contest/mama-ya-stazhor"-->
				<!--						@click.prevent="linkTo('contest/mama-ya-stazhor')"-->
				<!--					><span>Конкурс</span></a>-->
				<!--				</li>-->
				<li>
					<a
						href="/bez-otkaza"
						@click.prevent="linkTo('bez-otkaza')"
					><span>Лифт без отказа</span></a>
				</li>
				<li>
					<a
						href="/contacts"
						@click.prevent="linkTo('contacts')"
					><span>О нас</span></a>
				</li>
				<li>
					<a
						href="https://blog.lift-bf.ru/"
						target="_blank"
					><span>Блог</span></a>
				</li>
			</ul>
		</nav>
		<div
			v-if="!isLogged"
			class="button-group"
		>
			<button
				v-if="!isLogged"
				id="btnLogin_1"
				class="button-hollow full"
				@click.prevent="loginToLift"
			>
				Войти
			</button>
			<button
				v-if="!isLogged"
				class="button orange full"
				@click.prevent="signInToLift"
			>
				Регистрация
			</button>
		</div>
		<div v-if="isLogged">
			<hr>
			<nav class="main-menu-modal sm">
				<ul>
					<li v-if="!isRecruiter">
						<a
							:href="`/profile/${userID}`"
							@click.prevent="linkTo(`profile/${userID}`)"
						>
							<span>Профиль</span>
						</a>
					</li>
					<template v-if="isRecruiter">
						<li>
							<a
								:href="'/profile'"
								@click.prevent="linkTo('profile')"
							>
								<span>Профиль</span>
							</a>
						</li>
						<li>
							<a
								:href="'/profile?companies=true'"
								@click.prevent="linkTo('profile?companies=true')"
							>
								<span>Компании</span>
							</a>
						</li>
						<li>
							<a
								:href="'/profile?vacancies=true'"
								@click.prevent="linkTo('profile?vacancies=true')"
							>
								<span>Вакансии</span>
							</a>
						</li>
						<li>
							<a
								:href="'/profile?responses=true'"
								@click.prevent="linkTo('profile?responses=true')"
							>
								<span>Отклики</span>
							</a>
						</li>
						<li>
							<a
								:href="'/profile?favorites=true'"
								@click.prevent="linkTo('profile?favorites=true')"
							>
								<span>Избранные кандидаты</span>
							</a>
						</li>
						<li>
							<a
								:href="'/profile?access=true'"
								@click.prevent="linkTo('profile?access=true')"
							>
								<span>Доступ к данным</span>
							</a>
						</li>
					</template>
					<template v-else>
						<li>
							<a
								:href="`/profile/${userID}?courses=true`"
								@click.prevent="linkTo(`profile/${userID}?courses=true`)"
							>
								<span>Курсы</span>
							</a>
						</li>
						<li>
							<a
								:href="`/profile/${userID}?programs=true`"
								@click.prevent="linkTo(`profile/${userID}?programs=true`)"
							>
								<span>Программы</span>
							</a>
						</li>
						<li>
							<a
								:href="`/profile/${userID}?certificates=true`"
								@click.prevent="linkTo(`profile/${userID}?certificates=true`)"
							>
								<span>Сертификаты</span>
							</a>
						</li>
						<li>
							<a
								:href="`/profile/${userID}?tests=true`"
								@click.prevent="linkTo(`profile/${userID}?tests=true`)"
							>
								<span>Результаты тестов</span>
							</a>
						</li>
						<li>
							<a
								:href="`/profile/${userID}?resumes=true`"
								@click.prevent="linkTo(`profile/${userID}?resumes=true`)"
							>
								<span>Резюме</span>
							</a>
						</li>
						<li>
							<a
								:href="`/profile/${userID}?responses=true`"
								@click.prevent="linkTo(`profile/${userID}?responses=true`)"
							>
								<span>Отклики</span>
							</a>
						</li>
						<li>
							<a
								:href="`/profile/${userID}?favoritesVacancies=true`"
								@click.prevent="linkTo(`profile/${userID}?favoritesVacancies=true`)"
							>
								<span>Избранные вакансии</span>
							</a>
						</li>
					</template>
					<li>
						<a
							href="/logout"
							@click.prevent.stop="exitProfile"
						>
							<span>Выйти</span>
						</a>
					</li>
				</ul>
			</nav>
		</div>

		<ul class="footer-menu-modal">
			<li>
				<a
					class="info"
					href="/privacy-policy"
					@click.prevent="linkTo('privacy-policy')"
				>Политика конфиденциальности</a>
			</li>
			<li>
				<a
					class="info"
					href="/agreement"
					@click.prevent="linkTo('agreement')"
				>Пользовательское соглашение</a>
			</li>
		</ul>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {

    computed: {
        ...mapGetters('user', {
            userID: 'userID',
            isLogged: 'isLoggedIn',
            isRecruiter: 'isRecruiter',
            pageName: 'pageName'
        }),
        ...mapGetters('tb', {
            projects: 'getSpecialProjects'
        })
    },
    created() {
        this.getSpecialProjects()
    },
    methods: {
        ...mapActions('user', [
            'logout',
            'changePageName'
        ]),
        ...mapActions('tb', [
            'getAllCourses',
            'getAllSections',
            'getSpecialProjects'
        ]),
        linkTo(url = '') {
            if (url === `profile/${this.userID}`) this.changePageName('Профиль')
            if (url === 'profile') this.changePageName('Профиль')
            if (url === 'profile?companies=true') this.changePageName('Компании')
            if (url === `profile/${this.userID}?courses=true`) this.changePageName('Курсы')
            if (url === `profile/${this.userID}?programs=true`) this.changePageName('Программы')
            if (url === 'profile?vacancies=true') this.changePageName('Вакансии')
            if (url === `profile/${this.userID}?resumes=true`) this.changePageName('Резюме')
            if (url === `profile/${this.userID}?certificates=true`) this.changePageName('Сертификаты')
            if (url === `profile/${this.userID}?tests=true`) this.changePageName('Тесты')
            if (url === `profile/${this.userID}?responses=true`) this.changePageName('Отклики')
            if (url === 'profile?responses=true') this.changePageName('Отклики')
            if (url === 'profile?favorites=true') this.changePageName('Избранные кандидаты')
            if (url === 'profile?access=true') this.changePageName('Доступ к данным')
            if (url === `profile/${this.userID}?favoritesVacancies=true`) this.changePageName('Избранные вакансии')
            if (this.$route.fullPath !== '/' + url) this.$router.push('/' + url)
            this.modalClose()
        },
        loginToLift() {
            this.modalOpen('signIn')
        },
        signInToLift() {
            this.modalOpen('regIn')
        },
        exitProfile() {
            this.logout()
                .then(() => {
                    this.isAvatarClick = false;
                    this.modalClose()
                    this.getAllCourses()
                    this.getAllSections()

                    if (this.$route.path !== '/') this.$router.push('/').catch(() => {});
                })
        },
    }
}
</script>
