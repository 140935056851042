import { HTTP, getToken } from './default'

export default {
    getAllContests() {
        return HTTP.get('/competition/')
    },
    getContest(slug) {
        return HTTP.get(`/competition/${slug}/`)
    },
    getVideo(id) {
        return HTTP({
            method: 'GET',
            url: `/competition/videos/${id}/`
        })
    },
    getAllVideos(params) {
        return HTTP({
            method: 'GET',
            url: '/competition/videos/',
            params
        })
    },
    sendContest({ data, inputFile }) {
        return HTTP({
            method: 'POST',
            url: '/competition/videos/',
            headers: {
                'Authorization': `Token ${getToken()}`,
                'Content-Type': 'multipart/form-data'
            },
            data,
            onUploadProgress: event => {
                var event = new CustomEvent('uploadprogress', {
                    detail: {
                        loaded: event.loaded,
                        total: event.total
                    }
                })

                inputFile.dispatchEvent(event)
            }
        })
    },
    sendContestNew({data}) {
        return HTTP({
            method: 'POST',
            url: '/competition/request-competition/',
            headers: {
                'Authorization': `Token ${getToken()}`,
                'Content-Type': 'application/json'
            },
            data,
        })
    },
    getVideoFans(id) {
        return HTTP.get(`/competition/videos/${id}/fans/`)
    },
    getAllFans() {
        return HTTP.get('/competition/videos/all_fans/')
    },
    sentVoice(id) {
        return HTTP({
            method: 'POST',
            url: `/competition/videos/${id}/like/`,
            headers: {
                'Authorization': `Token ${getToken()}`,
            }
        })
    },
    cancelVoice(id) {
        return HTTP({
            method: 'POST',
            url: `/competition/videos/${id}/unlike/`,
            headers: {
                'Authorization': `Token ${getToken()}`,
            }
        })
    },
    // Ambassadors
    partnerProposal(data) {
        return HTTP({
            method: 'POST',
            url: '/ambassador/partner/proposal/',
            data
        })
    },
    studentProposal(data) {
        return HTTP({
            method: 'POST',
            url: '/ambassador/student/proposal/',
            data
        })
    },
    getAllMentors({ ordering = '' }) {
        return HTTP({
            method: 'GET',
            url: '/ambassador/mentors/',
            params: {
                ordering
            }
        })
    },
    schoolProposal(data) {
        return HTTP({
            method: 'POST',
            url: '/ambassador/student/school/2021/proposal/',
            data
        })
    },
    partnerForSchoolProposal(data) {
        return HTTP({
            method: 'POST',
            url: '/ambassador/partner/proposal/2021/',
            data
        })
    },
    studentNewProposal(data) {
        return HTTP({
            method: 'POST',
            url: '/ambassador/student/university/2021/proposal/',
            data
        })
    },
    studentProposal2022(student_type, data) {
        return HTTP({
            method: 'POST',
            url: `/ambassador/proposal/2022/${student_type}/`,
            data
        })
    },
    studentProposal2022Fourth(student_type, data) {
        return HTTP({
            method: 'POST',
            url: `/ambassador/proposal/2022/${student_type}/fourth_stream/`,
            data
        })
    },
    //Internship
    getCatalogueInternShip(params) {
        return HTTP({
            url: '/internship/catalogue/',
            method: 'GET',
            params
        })
    },
    getInternshipCities(params) {
        return HTTP({
            url: '/internship/catalogue/cities/',
            method: 'GET',
            params
        })
    },
    getInternshipDirections(params) {
        return HTTP({
            url: '/internship/catalogue/directions/',
            method: 'GET',
            params
        })
    },
    getInternshipCompanies(params) {
        return HTTP({
            url: '/internship/catalogue/name-company/',
            method: 'GET',
            params
        })
    },
    internProposal(data) {
        return HTTP({
            method: 'POST',
            url: '/internship/partner/proposal/',
            data
        })
    },
    //EVENT SAMPLE
    getEventSample(slug) {
        if (getToken()) {
            return HTTP({
                url: `/event/${slug}/`,
                method: 'GET',
                headers: {
                    'Authorization': `Token ${getToken()}`,
                }
            })
        } else {
            return HTTP({
                url: `/event/${slug}/`,
                method: 'GET'
            })
        }
    },
    registerToEvent({data, slug}) {
        return HTTP({
            method: 'PUT',
            url: `/event/register/${slug}/`,
            data
        })
    },
    registerToEventMentorsApplication({data, slug}) {
        return HTTP({
            method: 'PUT',
            url: `/event/register-mentor/${slug}/`,
            data
        })
    },
    signUpEsg(data) {
        return HTTP({
            method: 'POST',
            url: '/program-esg/register-user/',
            headers: {
                'Authorization': `Token ${getToken()}`,
            },
            data
        })
    },
    getInfEsg() {
        return HTTP({
            method: 'GET',
            url: '/program-esg/',
        })
    },
    getIsRegisteredEsg(userId) {
        return HTTP({
            method: 'GET',
            url: `/program-esg/checking_user/${userId}/`,
        })
    }
}
