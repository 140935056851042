<template>
	<div
		v-if="sortedCourses.length"
		class="training__grid"
	>
		<div
			v-for="(item, index) in sortedCourses"
			:key="index"
			class="training__block"
			:class="{
				'training__block--md': item.sectionCourses.length > 5 && item.sectionCourses.length < 11,
				'training__block--lg': item.sectionCourses.length > 10 && item.sectionCourses.length < 16,
				'training__block--xl': item.sectionCourses.length > 15 && item.sectionCourses.length < 21,
			}"
		>
			<div class="training__inner">
				<div class="training__title">
					{{ item.sectionName }}
				</div>
				<div class="training__list">
					<router-link
						v-for="course in item.sectionCourses"
						:key="course.id"
						target="_blank"
						class="training-item"
						:to="`/courses/${course.slug}-${course.id}`"
					>
						<h3 class="training-item__title">
							{{ course.name }}
						</h3>
						<div class="training-item__duration">
							{{ course.duration | durationFilter }}
						</div>
						<div class="training-item__members">
							<img
								:src="'/img/icons/icon__user--gray.svg' | prodSrcImage"
								alt="Участники"
							>
							{{ course.students_enrolled }}
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'SortedCourses',
    props: {
        role: {
            type: String,
            default: null
        }
    },
    data: () => ({
        sections: [],
        courses: []
    }),
    computed: {
        sortedCourses () {
            let temp = this.courses
            let newtemp= []
            temp.filter(item => item.sectionCourses.length !== 0)
                .sort( (a, b) => b.sectionCourses.length - a.sectionCourses.length)
            temp.forEach((i) => {
                if (i.sectionCourses.length > 15) newtemp.push(i)
            })
            temp.forEach((i) => {
                if (i.sectionCourses.length > 10 && i.sectionCourses.length < 16) newtemp.push(i)
            })
            temp.forEach((i) => {
                if (i.sectionCourses.length > 5 && i.sectionCourses.length < 11) newtemp.push(i)
            })
            temp.forEach((i) => {
                if (i.sectionCourses.length < 6) newtemp.push(i)
            })
            return newtemp
        },
    },
    async created () {
        await this.getCourses()
    },
    methods: {
        ...mapActions('tb', [
            'getAllCourses',
            'getAllSections'
        ]),
        getCourses () {
            this.getAllSections()
                .then(res => {
                    this.sections = res
                })
                .then (() => {
                    this.sections.forEach(section => {
                        this.getAllCourses({
                            section: section.id,
                            visible_to: this.role,
                            type: 'Курс'
                        }).then(res => {
                            if (res.results.length) {
                                let sectionCourses = res.results
                                let newObj = {
                                    sectionName: this.sections.find(item => item.id === section.id).name,
                                    sectionCourses: sectionCourses.length > 20 ? sectionCourses.slice(0, 20) : sectionCourses
                                }
                                this.courses.push(newObj)
                            }
                        })
                    })
                })
        },
    }
};
</script>

<style scoped>

</style>
