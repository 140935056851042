<template>
	<div
		v-if="isOpen"
		ref="dropdown"
		class="select-dropdown"
		@scroll="scrollToBottom"
	>
		<template v-if="multi">
			<label
				v-for="(option, index) in listOptions"
				:key="index"
				class="checkbox"
			>
				<input
					type="checkbox"
					:checked="selected.includes(option)"
					@change="changeMultiOption($event, option)"
				>
				<span>{{ option }}</span>
			</label>
		</template>
		<template v-else-if="comb">
			<label
				v-for="(option, index) in listOptions"
				:key="index"
				class="checkbox"
			>
				<input
					type="checkbox"
					:checked="selected.includes(option)"
					@change="changeMultiOption($event, option)"
				>
				<span>{{ option }}</span>
			</label>
			<div
				class="select-option"
				:class="{ active: selected === single }"
				@click="$emit('selectOption', single)"
			>
				{{ single }}
			</div>
		</template>
		<template v-else>
			<div
				v-for="(option, index) in listOptions"
				:key="index"
				class="select-option"
				:class="{ active: selected === option }"
				@click="$emit('selectOption', option)"
			>
				{{ option }}
			</div>
		</template>
	</div>
</template>

<script>

function debounce(f, ms) {
    let isCooldown = false;

    return function () {
        if (isCooldown) return;

        f.apply(this, arguments);

        isCooldown = true;

        setTimeout(() => isCooldown = false, ms);
    };
}

export default {
    props: {
        multi: {
            type: Boolean,
            default: false
        },
        comb: {
            type: Boolean,
            default: false
        },
        single: {
            type: [String, Number],
            default: undefined
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        onPage: {
            type: Number,
            default: 10
        },
        async: {
            type: Boolean,
            default: false
        },
        autosearch: {
            type: Boolean,
            default: false
        },
        selected: {
            type: [String, Number],
            default: undefined
        },
        options: {
            required: true,
            type: Set,
            default: () => new Set()
        }
    },
    data: () => ({
        page: 0
    }),
    computed: {
        bufferData() {
            let bufferResult = []

            if (this.async || this.autosearch) {
                for (let i = 0; i < this.options.size / this.onPage; i++) {
                    bufferResult.push(Array.from(this.options.values()).slice(i * this.onPage, (i + 1) * this.onPage))
                }
            } else {
                bufferResult = Array.from(this.options.values())
            }

            return bufferResult
        },
        listOptions() {
            if (this.async) {
                let result = []

                for (let page = 0; page <= this.page; page++) {
                    if (this.bufferData[page] && this.bufferData[page].length) {
                        result = result.concat(this.bufferData[page])
                    }
                }

                return result
            } else {
                return this.bufferData
            }
        },
        pages() {
            return this.bufferData.length
        }
    },
    methods: {
        scrollToBottom() {
            if (this.async && this.page <= this.pages - 1) {
                let height = this.$refs.dropdown.getBoundingClientRect().height,
                    scrollHeight = this.$refs.dropdown.scrollHeight,
                    scrollTop = this.$refs.dropdown.scrollTop;

                if (scrollHeight === height + scrollTop) {
                    debounce(() => this.page++, 1000)()
                    this.$emit('scrollToBottom')
                }
            }
        },
        changeMultiOption(event, option) {
            if (event.target.checked) {
                this.$emit('selectOption', option)
            } else {
                this.$emit('deselectOption', option)
            }
        }
    }
}
</script>
