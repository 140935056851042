var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-wrap"},[_c('label',{class:{
			'field-label': true,
			'field-label--is-white': _vm.isWhite
		}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.localValue),expression:"localValue",modifiers:{"trim":true}},{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],class:{
				'field-input': true,
				'field-input--is-capitalize': _vm.isCapitalize
			},attrs:{"min":_vm.min,"max":_vm.max,"type":_vm.type,"placeholder":_vm.placeholder},domProps:{"value":(_vm.localValue)},on:{"blur":[function($event){return _vm.$emit('blurInput')},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.localValue=$event.target.value.trim()}}}),(!_vm.notLabel)?_c('label',{class:{
				'label-input': true,
				'label-input--pos-top': _vm.localValue ,
				'label-input--is-sidebar': _vm.isSidebar && _vm.localValue
			}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }