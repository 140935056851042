<template>
	<div class="modal-content">
		<div class="contest-task">
			<div class="modal-title sm">
				Расскажите о своей экспертности
			</div>
			<div
				v-if="nominations.length > 0"
				class="contest-task__field"
			>
				<h4 class="contest-task__subtitle">
					Выберите интересующие вас номинации:
				</h4>
				<div class="checkboxes">
					<div
						v-for="nomination in nominations"
						:key="nomination.id"
						class="field-simple"
					>
						<label class="checkbox-filter">
							<input
								v-model="selectedNominations"
								type="checkbox"
								:value="nomination.id"
							>
							<span>{{ nomination.name }}</span>
						</label>
					</div>
				</div>
				<div
					v-if="selectedNominations.length === 0 && isLoading"
					class="field-simple__error"
				>
					Выберите номинации
				</div>
			</div>
			<div
				v-else
				class="contest-task__field"
			>
				<h4 class="contest-task__subtitle">
					Нет доступных номинаций
				</h4>
			</div>
			<div
				v-if="nominations.length > 0"
				class="button contest-task__button"
				@click="chooseNominations"
			>
				Отправить
			</div>
		</div>
	</div>
</template>

<script>
import Cnt from '@/api/contests';
import { mapGetters } from 'vuex';

export default {
    data: () => ({
        nominations: [],
        selectedNominations: [],
        isError: false,
        isLoading: false
    }),
    computed: {
        userInfo() {
            return this.$store.state.modal.payload.expertInfo;
        },
        contestID() {
            return this.$store.state.modal.payload.contestID;
        },
        ...mapGetters('cnst', {
            userGroup: 'getUserGroup',
        }),
    },
    async created() {
        await this.getContest()
    },
    methods: {
        getContest() {
            Cnt.getContestInfo(this.contestID).then(response => {
                response.data.expert_groups.forEach((expert_group) => {
                    response.data.nominations.forEach((item) => {
                        let userRole = '';
                        this.userGroup.forEach((group) => {
                            if (group.label === localStorage.getItem('userRole')) userRole = group
                        })
                        if (expert_group === userRole.value) {
                            this.nominations.push(item);
                        }
                    })
                })
            });
        },
        chooseNominations () {
            this.isLoading = true;
            if (this.selectedNominations.length === 0) {
                this.isError = true
            } else {
                this.isLoading = false
                this.modalOpen('add-sp-wp', {
                    expertInfo: this.userInfo,
                    contestID: this.contestID,
                    nomination_ids: this.selectedNominations
                })
            }
        }
    }
}
</script>
