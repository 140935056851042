<template>
	<div class="contest">
		<div class="contest-cover">
			<div class="contest-cover-pic">
				<div
					class="image-cover-wrap"
					:style="'/img/landing/ambassador/landing-cover-1.png' | bgrImageCover"
				/>
			</div>
			<div class="contest-cover-content">
				<div class="content">
					<div class="grid">
						<div class="box">
							<Breadcrumb :page-name="'Студенты — старшеклассникам'" />
							<h3 class="page-title">
								Программа <br>«Студент-Старшекласснику»!
							</h3>
							<div class="text">
								<p>
									Объявляем набор студентов на второй этап бесплатной образовательной <br>
									программы «Студент-Старшекласснику». Пройди интенсивное онлайн обучение, прокачай свои навыки проектной работы, стань наставником у школьников!
								</p>
							</div>
							<button
								class="button"
								@click="modalOpen('ambsdr-3', true)"
							>
								Подать заявку
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<section class="section-simple wow">
			<div class="content">
				<div class="main-button-group">
					<div class="grid">
						<div class="box">
							<button
								class="main-button blue"
								@click="$scrollTo($refs.anchorAbout)"
							>
								<span class="main-button-text">Описание программы</span>
								<span class="main-button-icon">
									<img
										:src="'/img/icons/icon-program-list-white.svg' | prodSrcImage"
										alt="about program"
									>
								</span>
							</button>
						</div>
						<div class="box">
							<button
								class="main-button gray"
								@click="$scrollTo($refs.anchorHow)"
							>
								<span class="main-button-text">Как будет проходить?</span>
								<span class="main-button-icon">
									<img
										:src="'/img/icons/icon-question-white.svg' | prodSrcImage"
										alt=""
									>
								</span>
							</button>
						</div>
						<div class="box">
							<button
								class="main-button orange"
								@click="modalOpen('ambsdr-3', true)"
							>
								<span class="main-button-text">Подать <br> заявку</span>
								<span class="main-button-icon">
									<img
										:src="'/img/icons/icon-landing-users-2.svg' | prodSrcImage"
										alt=""
									>
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section-simple light wow">
			<div class="content">
				<h3 class="block-title">
					Начало второго этапа
				</h3>
				<div class="text md dark">
					<p>Набор на первый этап программы закрыт. Не успел присоединиться к нам весной? Увидимся на осеннем потоке, стартующем в сентябре! Заполняй анкету и жди письма о прохождении отбора!</p>
				</div>
			</div>
		</section>

		<section
			class="section-simple"
		>
			<div class="content">
				<h3 class="block-title">
					Этот проект для тебя, если ты
				</h3>
				<div class="text md dark">
					<ul>
						<li>Хочешь прокачать лидерские навыки, умение работать в команде и другие soft skills. Наши эксперты дадут знания, которые ты тут же сможешь использовать для помощи старшеклассникам.</li>
						<li>Умеешь находить общий язык с людьми и горишь желанием помогать другим.</li>
						<li>Открыт для всего нового и не боишься ответственности.</li>
						<li>Готов изучать множество полезной информации, чтобы попасть в проф тусовку экспертов и стать крутым наставником.</li>
					</ul>
				</div>
			</div>
		</section>

		<section
			ref="anchorAbout"
			class="section-simple light"
		>
			<div class="content">
				<h3 class="block-title">
					Зачем участвовать в программе?
				</h3>
				<br>
				<h3 class="block-title sm">
					Обучение
				</h3>
				<div class="nomination-wrap">
					<div class="nomination-item top">
						<img
							:src="'/img/landing/ambassador/feature-styled-8.png' | prodSrcImage"
							alt=""
							class="nomination-pic"
						>
						<div class="nomination-text text dark sm">
							<p>Опыт наставничества — это крутейшая прокачка навыков переговоров, публичных выступлений, проведения мозговых штурмов и работы в команде.</p>
							<p>А полученные знания о том, как управлять проектами и запускать их с нуля, помогут затем сделать карьеру во многих сферах.</p>
						</div>
					</div>
				</div>
				<h3 class="block-title sm">
					Старт карьеры
				</h3>
				<div class="nomination-wrap">
					<div class="nomination-item top">
						<img
							:src="'/img/landing/ambassador/program-4.png' | prodSrcImage"
							alt=""
							class="nomination-pic"
						>
						<div class="nomination-text text dark sm">
							<p>Все участники программы получат возможность пройти стажировку в таких компаниях, как МТС, Озон, Медси и многих других.</p>
							<p>Уже на первом этапе программы победители получат возможность бесплатно пройти консультацию по построению собственной карьеры, сделать классное резюме и подготовиться к собеседованию.</p>
						</div>
					</div>
				</div>
				<h3 class="block-title sm">
					Интересные мероприятия
				</h3>
				<div class="nomination-wrap">
					<div class="nomination-item top">
						<img
							:src="'/img/landing/ambassador/program-3.png' | prodSrcImage"
							alt=""
							class="nomination-pic"
						>
						<div class="nomination-text text dark sm">
							<p>Лучшие участники примут участие в крутых активностях. В прошлом году это были походы в театр и на концерты. А еще — участие в Международном авиационно-космическом салоне МАКС-2021 в качестве интеллектуальных волонтеров с оплатой проживания.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section
			class="section-simple"
		>
			<div class="content">
				<h3 class="block-title">
					Какие знания ты приобретёшь:
				</h3>
				<br>
				<h3 class="block-title sm">
					По наставничеству
				</h3>
				<div class="nomination-wrap">
					<div class="nomination-item top">
						<img
							:src="'/img/landing/ambassador/program-1.png' | prodSrcImage"
							alt=""
							class="nomination-pic"
						>
						<div class="nomination-text text dark sm">
							<ul>
								<li>Что такое проект и как его сделать от начала и до защиты</li>
								<li>Как грамотно спланировать работу над масштабной задачей и сделать ее в срок</li>
								<li>Какая роль у наставника в разработке проекта</li>
							</ul>
						</div>
					</div>
				</div>
				<h3 class="block-title sm">
					По управлению командой
				</h3>
				<div class="nomination-wrap">
					<div class="nomination-item top">
						<img
							:src="'/img/landing/ambassador/feature-styled-4.png' | prodSrcImage"
							alt=""
							class="nomination-pic"
						>
						<div class="nomination-text text dark sm">
							<ul>
								<li>Как ставить задачи, мотивировать команду к их выполнению и правильно давать обратную связь</li>
								<li>Как бороться с возражениями и находить выход из любых ситуаций</li>
								<li>Как контролировать работу команды не перегибая палку</li>
								<li>Уметь делегировать и распределять задачи внутри коллектива</li>
							</ul>
						</div>
					</div>
				</div>
				<h3 class="block-title sm">
					По коммуникации
				</h3>
				<div class="nomination-wrap">
					<div class="nomination-item top">
						<img
							:src="'/img/landing/ambassador/feature-styled-3.png' | prodSrcImage"
							alt=""
							class="nomination-pic"
						>
						<div class="nomination-text text dark sm">
							<ul>
								<li>Все про выступления перед большой аудиторией</li>
								<li>Секреты крутых презентаций</li>
								<li>Как проводить мозговые штурмы и переговоры</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section
			class="section-simple image"
			style="visibility: visible; animation-name: fadeIn;"
		>
			<div
				class="section-simple-bg image-cover-wrap"
				:style="'/img/landing/ambassador/landing-cover-3.jpg' | bgrImageCover"
			/>
			<div class="content">
				<div class="center">
					<div class="text">
						<p>Стань наставником для школьников в проектной работе и получи ценные призы, приглашения на оффлайн мероприятия и почетный статус Амбассадора «Лифта в будущее».</p>
					</div>
				</div>
			</div>
		</section>

		<section
			ref="anchorHow"
			class="section-simple wow fadeIn"
			data-wow-offset="50"
		>
			<div class="content">
				<h3 class="block-title">
					Как это будет проходить?
				</h3>
				<div class="stages-block">
					<div class="stages-line" />
					<div class="stages">
						<div class="stage">
							<div class="stage-date">
								<b>1 этап: Апрель-май</b>
							</div>
							<div class="stage-text">
								<p>Вместе с другими студентами ты будешь учиться основам проектной деятельности в онлайн-формате.</p>
								<p>Это будут видеолекции и вебинары, после прохождения которых ты и твоя команда сможете создать и защитить собственный крутой проект.</p>
							</div>
						</div>
						<div class="stage" />
						<div class="stage">
							<div class="stage-date">
								<b>2 этап: Сентябрь-декабрь</b>
							</div>
							<div class="stage-text">
								<p>Ты станешь наставником для школьников, чтобы в качестве руководителя помочь им придумать и реализовать идеи по актуальным для современного бизнеса темам.</p>
								<p>Не упусти свой шанс активно, полезно и круто провести ближайшие несколько месяцев!</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section
			class="section-simple light"
		>
			<div class="content">
				<div class="contest-form-block">
					<h4 class="block-title">
						Школам
					</h4>
					<div class="text dark sm">
						<p>Если вы представитель школы и хотите присоединиться к проекту, оставьте заявку с помощью формы ниже:</p>
					</div>
					<br>
					<form
						class="form"
						@submit.prevent="sendPartnerForSchool"
					>
						<div class="grid">
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.forSchool.full_name.$model"
											type="text"
											class="field"
											placeholder="ФИО"
											style="text-transform: capitalize"
										>
										<div class="label">ФИО</div>
										<span
											v-if="$v.forSchool.full_name.$error"
											class="help-block form-error"
										>
											{{
												!$v.forSchool.full_name.required ? 'Это обязательное поле' : $v.forSchool.full_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректное ФИО'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.forSchool.email.$model"
											type="email"
											class="field"
											placeholder="Email"
										>
										<div class="label">Email</div>
										<span
											v-if="$v.forSchool.email.$error"
											class="help-block form-error"
										>
											{{
												!$v.forSchool.email.required ? 'Это обязательное поле' : !$v.forSchool.email.email ? 'Введите корректный e-mail' : 'Ошибка поля'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="$v.forSchool.phone.$model"
											v-mask="'+7(###)###-##-##'"
											type="text"
											class="field"
											placeholder="Телефон"
										>
										<div class="label">Телефон</div>
										<span
											v-if="$v.forSchool.phone.$error"
											class="help-block form-error"
										>
											{{
												!$v.forSchool.phone.required ? 'Это обязательное поле' : !$v.forSchool.phone.Phone ? 'Введите корректный номер телефона' : 'Ошибка поля'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="forSchool.city"
											type="text"
											class="field"
											placeholder="Город"
										>
										<div class="label">Город</div>
										<span
											v-if="$v.forSchool.city.$error"
											class="help-block form-error"
										>Это обязательное поле
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.forSchool.school.$model"
											type="text"
											class="field"
											placeholder="Школа"
										>
										<div class="label">Школа</div>
										<span
											v-if="$v.forSchool.school.$error"
											class="help-block form-error"
										>Это обязательное поле</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.forSchool.class_list.$model"
											type="text"
											class="field"
											placeholder="Список классов"
										>
										<div class="label">Список классов</div>
										<span
											v-if="$v.forSchool.class_list.$error"
											class="help-block form-error"
										>Это обязательное поле</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.forSchool.pupils.$model"
											type="text"
											class="field"
											placeholder="Число школьников"
										>
										<div class="label">Число школьников</div>
										<span
											v-if="$v.forSchool.pupils.$error"
											class="help-block form-error"
										>Это обязательное поле</span>
									</label>
								</div>
							</div>
						</div>
						<div class="field-wrap sm">
							<label class="checkbox-filter">
								<input
									v-model="$v.forSchool.isPersonal.$model"
									type="checkbox"
									@change="$v.forSchool.isPersonal.$touch()"
								>
								<span class="info">Соглашаюсь с условиями обработки <a
									href="/privacy-policy"
									target="_blank"
								>персональных данных</a></span>
								<div
									v-if="$v.forSchool.isPersonal.$error"
									class="help-block form-error"
								>Необходимо
									подтверждение
								</div>
							</label>
						</div>
						<div class="field-wrap">
							<label class="checkbox-filter">
								<input
									v-model="$v.forSchool.isAgree.$model"
									type="checkbox"
									@change="$v.forSchool.isAgree.$touch()"
								>
								<span class="info">Соглашаюсь с условиями <a
									href="/agreement"
									target="_blank"
								>пользовательского соглашения</a></span>
								<div
									v-if="$v.forSchool.isAgree.$error"
									class="help-block form-error"
								>Необходимо
									подтверждение
								</div>
							</label>
						</div>
						<button
							v-if="!isSended"
							id="btnAmbsdr_4"
							class="button orange lg btnRegistration"
						>
							Отправить
						</button>
						<div
							v-else
							class="button orange lg button-preloader"
						>
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>

		<section
			class="section-simple wow"
		>
			<div class="content">
				<div class="contest-form-block">
					<h4 class="block-title">
						Школьникам
					</h4>
					<div class="text dark sm">
						<p>Если вы учащийся и хотите присоединиться к проекту, оставьте заявку с помощью формы ниже:</p>
					</div>
					<br>
					<form
						class="form"
						@submit.prevent="sendPartner"
					>
						<div class="grid">
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.partner.full_name.$model"
											type="text"
											class="field"
											placeholder="ФИО"
											style="text-transform: capitalize"
										>
										<div class="label">ФИО</div>
										<span
											v-if="$v.partner.full_name.$error"
											class="help-block form-error"
										>
											{{
												!$v.partner.full_name.required ? 'Это обязательное поле' : $v.partner.full_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректное ФИО'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.partner.email.$model"
											type="email"
											class="field"
											placeholder="Email"
										>
										<div class="label">Email</div>
										<span
											v-if="$v.partner.email.$error"
											class="help-block form-error"
										>
											{{
												!$v.partner.email.required ? 'Это обязательное поле' : !$v.partner.email.email ? 'Введите корректный e-mail' : 'Ошибка поля'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="$v.partner.phone.$model"
											v-mask="'+7(###)###-##-##'"
											type="text"
											class="field"
											placeholder="Телефон"
										>
										<div class="label">Телефон</div>
										<span
											v-if="$v.partner.phone.$error"
											class="help-block form-error"
										>
											{{
												!$v.partner.phone.required ? 'Это обязательное поле' : !$v.partner.phone.Phone ? 'Введите корректный номер телефона' : 'Ошибка поля'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="partner.city"
											type="text"
											class="field"
											placeholder="Город"
										>
										<div class="label">Город</div>
										<span
											v-if="$v.partner.city.$error"
											class="help-block form-error"
										>Это обязательное поле
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.partner.school.$model"
											type="text"
											class="field"
											placeholder="Школа"
										>
										<div class="label">Школа</div>
										<span
											v-if="$v.partner.school.$error"
											class="help-block form-error"
										>Это обязательное поле</span>
									</label>
								</div>
							</div>
						</div>
						<div class="field-wrap sm">
							<label class="checkbox-filter">
								<input
									v-model="$v.partner.isPersonal.$model"
									type="checkbox"
									@change="$v.partner.isPersonal.$touch()"
								>
								<span class="info">Соглашаюсь с условиями обработки <a
									href="/privacy-policy"
									target="_blank"
								>персональных данных</a></span>
								<div
									v-if="$v.partner.isPersonal.$error"
									class="help-block form-error"
								>Необходимо
									подтверждение
								</div>
							</label>
						</div>
						<div class="field-wrap">
							<label class="checkbox-filter">
								<input
									v-model="$v.partner.isAgree.$model"
									type="checkbox"
									@change="$v.partner.isAgree.$touch()"
								>
								<span class="info">Соглашаюсь с условиями <a
									href="/agreement"
									target="_blank"
								>пользовательского соглашения</a></span>
								<div
									v-if="$v.partner.isAgree.$error"
									class="help-block form-error"
								>Необходимо
									подтверждение
								</div>
							</label>
						</div>
						<button
							v-if="!isSended"
							id="btnAmbsdrSend"
							class="button orange lg btnRegistration"
						>
							Отправить
						</button>
						<div
							v-else
							class="button orange lg button-preloader"
						>
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>

		<section
			class="section-simple light"
		>
			<div class="content">
				<h3 class="block-title">
					Контакты
				</h3>
				<div class="text dark sm">
					<p>Разъяснения и консультации по вопросам участия в амбассадорской программе можно получить у организаторов программы.</p>
				</div>
				<br>
				<div class="contacts-simple">
					<div class="contact-simple">
						<div class="info">
							Организатор:
						</div>
						Екатерина Лежнева
					</div>
					<div class="contact-simple">
						<div class="info">
							Контактый телефон:
						</div>
						<a href="tel:+7(495)7374419">+7 (495) 737-44-19</a>
					</div>
					<div class="contact-simple">
						<div class="info">
							Электронная почта:
						</div>
						<a href="mailto:info@mail.lift-bf.ru">info@mail.lift-bf.ru</a>
					</div>
					<div class="contact-simple">
						<div class="info">
							Время работы:
						</div>
						с 11.00 до 18.00 по московскому времени
					</div>
				</div>
			</div>
		</section>

		<section class="section-simple">
			<div class="content">
				<h3 class="block-title">
					Какие компании входят в АФК «Система»?
				</h3>
				<VueSlickCarousel
					class="partners-slider"
					:lazy-load="'progressive'"
					v-bind="partnerSliderSettings"
				>
					<div
						v-for="(slide, index) in partnerSlider"
						:key="index"
						class="slide"
					>
						<div class="partner-title">
							{{ slide.name }}
						</div>
						<div class="partner">
							<img
								:src="slide.img | prodSrcImage"
								alt=""
								class="pic"
							>
						</div>
					</div>
				</VueSlickCarousel>
			</div>
		</section>
	</div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Cnt from '@/api/contest';
import {email, required, sameAs} from 'vuelidate/lib/validators';
import {alphaSpace, Phone} from '@/assets/scripts/custom_validators';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'AmbassadorsForStudents',
    components: {
        Breadcrumb
    },
    data: () => ({
        partner: {
            full_name: '',
            city: '',
            phone: '',
            email: '',
            school: '',
            isPersonal: false,
            isAgree: false,
        },
        forSchool: {
            full_name: '',
            city: '',
            phone: '',
            email: '',
            school: '',
            pupils: '',
            class_list: '',
            isPersonal: false,
            isAgree: false,
        },
        partnerSlider: [
            {
                name: 'Инвестиции',
                img: '/img/contest-partners/Sistema_AFK_horiz_rus.jpg'
            },
            {
                name: 'Благотворительность',
                img: '/img/contest-partners/Bf.Sistema_LOGO_horizontal.png'
            },
            {
                name: 'Телекоммуникации',
                img: '/img/contest-partners/лого-МТС-01-v2.jpg'
            },
            {
                name: 'Производство',
                img: '/img/contest-partners/лого-Сегежа.png'
            },
            {
                name: 'Медицина',
                img: '/img/contest-partners/Logo-Medsi_Wfon_2013-01-v2.jpg'
            },
            {
                name: 'Электроэнергетика',
                img: '/img/contest-partners/besk.jpg'
            },
            {
                name: 'Сельское хозяйство',
                img: '/img/contest-partners/step.png'
            },
            {
                name: 'Электронная торговля',
                img: '/img/contest-partners/logo_Ozon_new.png'
            },
            {
                name: 'Гостиничный бизнес',
                img: '/img/contest-partners/cosmos-hotel-group.png'
            },
            {
                name: 'Финансы',
                img: '/img/contest-partners/МТС-банк-v2.jpg'
            },
            {
                name: 'Микроэлектроника',
                img: '/img/contest-partners/Element_logo_рус-v2.jpg'
            },
            {
                name: 'Биотехнологии',
                img: '/img/contest-partners/sistema-bioteh.png'
            },
            {
                name: 'Пищевая продукция',
                img: '/img/contest-partners/ARHYZ_VITA_logo.jpg'
            },
            {
                name: 'Народные промыслы',
                img: '/img/contest-partners/лого-Гжель-v2.jpg'
            },
            {
                name: 'Недвижимость',
                img: '/img/contest-partners/Бизнес-недвижимость_portal.jpg'
            },
            {
                name: 'Легкая промышленность',
                img: '/img/contest-partners/vologodski-tekstilni-kombinat.jpg'
            },
            {
                name: 'Финансы',
                img: '/img/contest-partners/sistema-capital.png'
            },
            {
                name: 'Интеллектуальные системы',
                img: '/img/contest-partners/ситроникс.png'
            },
            {
                name: 'Недвижимость',
                img: '/img/contest-partners/gruppa-etalon.png'
            },
            {
                name: 'Ритейл',
                img: '/img/contest-partners/koncept-grup.png'
            },
            {
                name: 'Фармацевтика',
                img: '/img/contest-partners/binnofarm-grupp.png'
            },
            {
                name: 'Финансы',
                img: '/img/contest-partners/ewub.jpg'
            }
        ],
        faqGroup: [],
        isCityClear: false,
        isForSchoolCityClear: false,
        isSended: false
    }),
    validations: {
        partner: {
            full_name: {required, alphaSpace},
            email: {required, email},
            phone: {required, Phone},
            city: {required},
            school: {required},
            isPersonal: {
                sameAs: sameAs(() => true)
            },
            isAgree: {
                sameAs: sameAs(() => true)
            }
        },
        forSchool: {
            full_name: {required, alphaSpace},
            email: {required, email},
            phone: {required, Phone},
            city: {required},
            school: {required},
            pupils: {required},
            class_list: {required},
            isPersonal: {
                sameAs: sameAs(() => true)
            },
            isAgree: {
                sameAs: sameAs(() => true)
            }
        }
    },
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            user: 'userInfo',
        }),
        partnerSliderSettings() {
            return {
                ...this.carouselSettings,
                'slides-to-show': 5,
                'slides-to-scroll': 5
            }
        }
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
    },
    methods: {
        ...mapActions('user', {
            getStudyPlace: 'getStudyPlace',
            loginEvent: 'loginEvent'
        }),
        selectCity(val) {
            this.partner.city = val
        },
        selectCityForSchool(val) {
            this.forSchool.city = val
        },
        sendPartner() {
            if (!this.isLogged) {
                this.$v.partner.$touch()
                if (this.$v.partner.$invalid) {
                    console.log('Partner form is error')
                } else if (!this.isSended) {
                    this.isSended = true
                    let [last_name, first_name, middle_name] = this.partner.full_name.split(' ')
                    this.loginEvent({data: {
                            first_name: first_name && first_name.length > 0 ? first_name : ' ',
                            email: this.partner.email,
                            study: {
                                education: ''
                            }
                        }, slug: 'nastavnik'})
                        .then(() => Cnt.studentProposal2022Fourth('student', {
                            first_name: first_name && first_name.length > 0 ? first_name : ' ',
                            middle_name,
                            last_name,
                            ...this.partner
                        }))
                        .then(() => {
                            this.modalOpen('ambsdrSuccess')
                            this.$v.partner.$reset()
                            this.isCityClear = true
                            this.partner = {
                                full_name: '',
                                city: '',
                                phone: '',
                                email: '',
                                school: ''
                            }
                            this.isPersonal = false
                            this.isAgree = false
                            this.isSended = false
                        })
                        .catch(error => {
                            this.isSended = false
                            if (this.isPersonal === true) this.modalClose('ambsdr-2-Fail', 'Вы уже отправили заявку')
                            else this.modalClose('ambsdr-2-Fail', error.response.data.error_text)
                        })
                }
            } else {
                this.$v.partner.$touch()
                if (this.$v.partner.$invalid) {
                    console.log('Partner form is error')
                } else if (!this.isSended) {
                    this.isSended = true
                    let [last_name, first_name, middle_name] = this.partner.full_name.split(' ')
                    this.getStudyPlace()
                        .then(res => {
                            this.studyPlaces = res
                            this.studyPlaces.forEach(item => {
                                if (item.primary_education) {
                                    this.EL.study.education = item.name
                                }
                            })
                        })
                        .then(() => {
                            Cnt.registerToEvent({data: {
                                    first_name: first_name && first_name.length > 0 ? first_name : ' ',
                                    email: this.user.email
                                }, slug: 'nastavnik'})
                        })
                    Cnt.studentProposal2022Fourth('student', {
                        first_name: first_name && first_name.length > 0 ? first_name : ' ',
                        middle_name,
                        last_name,
                        ...this.partner
                    })
                        .then(() => {
                            this.modalOpen('ambsdrSuccess')
                            this.$v.partner.$reset()
                            this.isCityClear = true
                            this.partner = {
                                full_name: '',
                                city: '',
                                phone: '',
                                email: '',
                                school: ''
                            }
                            this.isPersonal = false
                            this.isAgree = false
                            this.isSended = false
                        })
                        .catch(error => {
                            this.isSended = false
                            if (this.isPersonal === true) this.modalClose('ambsdr-2-Fail', 'Вы уже отправили заявку')
                            else this.modalClose('ambsdr-2-Fail', error.response.data.error_text)
                        })
                }
            }
        },
        sendPartnerForSchool() {
            this.$v.forSchool.$touch()
            if (this.$v.forSchool.$invalid) {
                console.log('Partner form is error')
            } else if (!this.isSended) {
                this.isSended = true
                let [last_name, first_name, middle_name] = this.forSchool.full_name.split(' ')

                Cnt.studentProposal2022Fourth('partner', {
                    first_name: first_name && first_name.length > 0 ? first_name : ' ',
                    middle_name,
                    last_name,
                    ...this.forSchool
                })
                    .then(() => {
                        this.modalOpen('ambsdrSuccess')
                        this.$v.forSchool.$reset()
                        this.isForSchoolCityClear = true
                        this.forSchool = {
                            full_name: '',
                            city: '',
                            phone: '',
                            email: '',
                            school: '',
                            pupils: '',
                            class_list: ''
                        }
                        this.isPersonal = false
                        this.isAgree = false
                        this.isSended = false
                    })
                    .catch(error => this.modalOpen('ambsdr-2-Fail', error.response.data.error_text))
            }
        }
    }
}
</script>

<style lang="less">
@import "../../assets/styles/pages/ambassadors";
</style>
