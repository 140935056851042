<template>
	<section
		v-wow
		class="section-simple mission wow fadeInUp"
		data-wow-delay="0.2s"
	>
		<div class="content">
			<div class="mission__content">
				<div class="mission__pretitle">
					Наша миссия
				</div>
				<h2
					v-wow
					class="title-section wow fadeInUp"
					data-wow-delay="0.1s"
				>
					<span>Открываем</span> дорогу в будущее для талантливой и мотивированной молодежи
				</h2>
				<h2
					v-wow
					class="title-section wow fadeInUp"
					data-wow-delay="0.2s"
				>
					<span>Помогаем</span> стать активными участниками социальной трансформации
				</h2>
				<h2
					v-wow
					class="title-section wow fadeInUp"
					data-wow-delay="0.3s"
				>
					<span>Обеспечиваем</span> равные возможности для людей с разными стартовыми условиями
				</h2>
				<div class="mission__subtitle-wrap">
					<div class="mission__subtitle">
						Основатель проекта <br>Владимир Евтушенков
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'ExpertMission'
};
</script>

<style scoped>

</style>
