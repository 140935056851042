import {HTTP, getToken} from './default'

export default {
    getWorkPlace(id) {
        return HTTP({
            url: `/user/users/${id}/job/`,
            method: 'GET',
        })
    },
    addWorkPlace({ data, id }) {
        return HTTP({
            url: `/user/users/${id}/job/`,
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data
        })
    },
    changeWorkPlace({ place, user_id, job_id }) {
        return HTTP({
            url: `/user/users/${user_id}/job/${job_id}/`,
            method: 'PUT',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data: place
        })
    },
    removeWorkPlace({ user_id, job_id }) {
        return HTTP({
            url: `/user/users/${user_id}/job/${job_id}/`,
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
    getWorkPlaceResume(id) {
       if (getToken()) {
           return HTTP({
               url: `/teachbase/summary/${id}/job/`,
               method: 'GET',
               headers: {
                   'Authorization': `Token ${getToken()}`
               }
           })
       } else {
           return HTTP({
               url: `/teachbase/summary/${id}/job/`,
               method: 'GET'
           })
       }
    },
    addWorkPlaceResume({ data, id }) {
        return HTTP({
            url: `/teachbase/summary/${id}/job/`,
            method: 'POST',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data
        })
    },
    changeWorkPlaceResume({ place, resume_id, job_id }) {
        return HTTP({
            url: `/teachbase/summary/${resume_id}/job/${job_id}/`,
            method: 'PUT',
            headers: {
                'Authorization': `Token ${getToken()}`
            },
            data: place
        })
    },
    removeWorkPlaceResume({ resume_id, job_id }) {
        return HTTP({
            url: `/teachbase/summary/${resume_id}/job/${job_id}/`,
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${getToken()}`
            }
        })
    },
}
