<template>
	<p class="auth-error">
		{{ text ? text : 'Ошибка. Попробуйте ещё раз' }}
	</p>
</template>

<script>
export default {
	name: 'BaseErrorMessageForm',
	props: {
		text: {
			type: String,
			default: '',
		}
	}
}
</script>


<style scoped>
	.auth-error {
		color: red;
		font-size: 13px;
		margin-bottom: 9px;
		padding: 5px 0;
		border: 1px solid #ffeded;
		text-align: center;
		background-color: #ffeded;
	}
</style>