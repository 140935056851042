<template>
	<div>
		<div class="step__index title-mono">
			{{ step.priority }} этап
		</div>
		<div class="step__period">
			{{ step.months }}
		</div>
		<div class="step__text">
			{{ step.description }}
		</div>
		<transition
			name="fade"
		>
			<div
				v-if="isShowedMore"
				class="step__description"
			>
				{{ step.description_2 }}
			</div>
		</transition>
		<div
			v-if="step.description_2"
			class="step__toggle-wrap"
		>
			<div
				class="step__toggle"
				:class="{'step__toggle--active': isShowedMore}"
				@click="showMore"
			>
				{{ !isShowedMore? 'Подробнее': 'Свернуть' }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'StepsInfo',
    props: {
        step: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        isShowedMore: false
    }),
    methods: {
        showMore() {
            this.isShowedMore = !this.isShowedMore
        }
    }
}
</script>
