<template>
	<section
		v-if="aboutInfo"
		class="section section-about"
	>
		<div class="content content--md">
			<div class="about">
				<div class="about__content">
					<h2
						class="title-section"
						v-html="aboutInfo.event_description_title"
					/>
					<div
						class="description"
						v-html="aboutInfo.event_description_text"
					/>
					<ConstructorButton
						:btn-name="aboutInfo.event_description_button_caption"
						:btn-link="aboutInfo.event_description_button_link"
						:btn-color="aboutInfo.event_description_button_color"
						:actual-tests="actualTests"
						:is-apply-contest="isApplyContest"
						:btn-variations="btnVariations"
						:is-active="isActiveBtn"
					/>
				</div>
				<div class="about__pic-wrap">
					<img
						:src="aboutInfo.event_description_image"
						alt="Енот"
						class="about__pic"
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ConstructorButton from '@/components/ConstructorComponents/ConstructorButton';

export default {
    name: 'About',
    components: {
        ConstructorButton
    },
    props: {
        aboutInfo: {
            type: Object,
            default: null
        },
        actualTests: {
            type: Array,
            default: null
        },
        isApplyContest: {
            type: Boolean,
            default: false
        },
        isActiveBtn: {
            type: Boolean,
            default: false
        },
        btnVariations: {
            type: Object,
            default: null
        }
    }
}
</script>
