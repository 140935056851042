import OneContestAPI from '@/api/contest';

const state = {
    eventSample: null
}

const actions = {
    async getEventSample({ commit }, slug) {
        try {
            const response = await OneContestAPI.getEventSample(slug);
            commit('setEventSample', response.data);
            return response.data;
        } catch (error) {
            console.log(error);
        }       
    }
}

const mutations = {
    setEventSample(state, eventSample) {
        state.eventSample = eventSample
    }
}

const getters = {
    getEventSample: state => state.eventSample
}



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
