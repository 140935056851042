<template>
	<div class="content">
		<Breadcrumb :page-name="'Тесты'" />
		<div class="tests">
			<h2 class="title-section">
				Тесты
			</h2>
			<p class="tests__text">
				Пройдите тесты и узнайте все свои сильные и слабые стороны. А в качестве бонуса мы приготовили для вас в конце рекомендации экспертов по саморазвитию.
			</p>
			<div
				v-if="!tests"
				class="preloader"
				style="margin-top: 50px;"
			/>

			<div
				v-else
				class="tests__list"
			>
				<div>
					<router-link
						:to="routeMindFormat"
						class="test"
					>
						<img
							:src="'/img/test/test_mindformat1.png' | prodSrcImage"
							alt="Фото"
							class="test__pic"
						>
						<div class="test__content">
							<div class="test__icon" />
							<div class="test__title">
								Тест для оценки потенциала и особенностей мышления
							</div>
						</div>
					</router-link>
				</div>
				<TestCard
					v-for="item in tests"
					:key="item.id"
					:test="item"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import {mapActions, mapGetters} from 'vuex';
import TestCard from '@/components/tests/TestCard';

export default {
    name: 'Tests',
    components: {
        Breadcrumb,
        TestCard
    },
    computed: {
        ...mapGetters('tst', {
            tests: 'getAllTests'
        }),
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    },
    created() {
        this.getAllTests()
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
        if (this.$route.query.modal === 'reg-form-drugoye-delo' && !this.isLogged) this.modalOpen('regIn')
    },
    methods: {
        ...mapActions('tst', [
            'getAllTests'
        ])
    }
}
</script>

<style lang="less">
@import "../assets/styles/pages/tests";
</style>
