<template>
	<div class="modal-content">
		<div
			v-if="isLoading"
			class="preloader sm"
			style="margin: 50px auto"
		/>
		<template v-else>
			<div class="modal-title-wrap">
				<h3 class="modal-title sm">
					Отклик на вакансию
				</h3>
			</div>
			<div
				v-if="isShow"
				class="modal-subtitle-wrap"
			>
				<p class="modal-subtitle">
					Ваш профиль не заполнен. Когда откликаетесь на вакансию,
					работодатель видит ваш профиль, часто среди многих откликов других соискателей.
					Кандидаты с полностью заполненным профилем более заметны. Мы рекомендуем заполнить информацию о себе.
				</p>
			</div>
			<form
				v-if="resumes.length"
				class="form"
				novalidate
			>
				<div class="field-wrap">
					<label class="field-label">
						<c-select
							:options="prepareResumes"
							:name="'Выберите резюме для отправки'"
							:is-error="isResumeError"
							@selected="selectResume"
						/>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<textarea
							v-model.trim="request.comment"
							rows="4"
							class="field"
							placeholder="Дополнительная информация о пользователе"
						/>
						<div class="label">Напишите сопроводительное письмо</div>
					</label>
				</div>
				<button
					v-if="!isSended"
					id="btnSendResume"
					class="button orange lg"
					@click.stop.prevent="sendProposal"
				>
					Отправить
				</button>
				<div
					v-else
					class="button orange lg button-preloader"
				>
					<div class="button-preloader-icons">
						<div class="button-preloader-icon" />
						<div class="button-preloader-icon" />
						<div class="button-preloader-icon" />
					</div>
				</div>
			</form>
			<template v-else>
				<div style="text-align: center">
					<h4 class="modal-subtitle">
						Для отклика на вакансию добавьте резюме в личном кабинете
					</h4>
					<a
						class="button-hollow"
						@click.prevent="createResume"
					>Создать резюме</a>
				</div>
			</template>
		</template>
	</div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {
    data: () => ({
        isLoading: true,
        isSended: false,
        selectedResume: null,
        isResumeError: false,
        request: {
            summary: 0,
            comment: ''
        }
    }),
    computed: {
        ...mapGetters('user', {
            user: 'userID',
            token: 'userToken',
            userID: 'userID',
            isVerification: 'userIsVerification'
        }),
        ...mapGetters('tb', {
            vacancy: 'getVacancy',
            resumes: 'getAllResumeForResponse'
        }),
        isShow() {
            return !!this.token && !this.isVerification
        },
        prepareResumes() {
            return this.resumes && this.resumes.map(item => item.name || false).filter(x => !!x) || undefined
        },
        payload() {
            return this.$store.getters['modal/getPayload']
        }
    },
    created() {
        this.getStudyPlace()
        if (this.resumes) {
            this.isLoading = false
        } else {
            this.getAllResumes().finally(() => this.isLoading = false)
        }
    },
    methods: {
        ...mapActions('tb', {
            send: 'startVacancy',
            getAllResumes: 'getAllResumes'
        }),
        ...mapActions('user', {
            getStudyPlace: 'getStudyPlace'
        }),
        sendProposal() {
            if (this.resumes) {
                if (!this.selectedResume) {
                    this.isResumeError = true
                    return
                } else {
                    if(this.$metrika && (window.location.hostname === 'stage.lift.tbproject.ru' || window.location.hostname === 'lift-bf.ru')) {
                        this.$metrika.reachGoal('vacancy_response')
                    }
                    let formData = new FormData()
                    formData.append('user', this.user)
                    formData.append('vacancy', this.payload.id)
                    formData.append('summary_new', this.resumes.find(item => item.name === this.selectedResume).id)

                    this.request.comment && formData.append('cover_letter', this.request.comment);
                    this.isResumeError = false
                    this.isSended = true
                    this.send(formData)
                        .then(() => {
                            this.modalClose('vacancySuccess')
                            this.isSended = false
                        })
                }
            } else {
                this.modalClose()
                this.$router.push(`/profile/${this.userID}?resume=true&action=create`)
            }
        },
        selectResume(val) {
            this.selectedResume = val
            this.isResumeError = false
        },
        createResume() {
            this.modalClose()
            this.$router.push(`/profile/${this.userID}?resumes=true`)
        }
    }
}
</script>
