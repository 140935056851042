<template>
	<div>
		<Preloader
			v-if="isExpertLoading"
			:is-loading="isExpertLoading"
			@isExpertLoaded="isExpertLoading = !isExpertLoading"
		/>
		<div v-else>
			<div class="navigation-role navigation-role--full">
				<router-link
					target="_blank"
					to="/school"
					class="navigation-role__choose"
				>
					Школьнику
				</router-link>
				<router-link
					target="_blank"
					to="/student"
					class="navigation-role__choose"
				>
					Студенту
				</router-link>
			</div>

			<div class="sections">
				<ExpertSlider />

				<Partners />

				<ExpertStats />

				<div class="content">
					<!-- <AllRolesBanner /> -->
					<!-- <EventBanner /> -->
					<BannersGrid />
				</div>

				<ExpertMission />

				<section
					v-wow
					class="section-simple audience wow fadeInUp"
					data-wow-delay="0.2s"
				>
					<div class="content">
						<h2 class="section-title">
							Мы учим адаптироваться и <span>делать правильный выбор</span> в постоянно меняющейся ситуации
						</h2>
						<div class="section-text">
							Помогаем строить карьеру в реалиях современного мира. Одной профессии на всю жизнь больше не существует. Поэтому мы даем навыки, которые помогают развивать и перестраивать карьеру в любой момент жизни.
						</div>
						<div class="audience__list">
							<router-link
								to="/school"
								target="_blank"
								class="card-audience"
							>
								<img
									:src="'/img/landing/role/pic__audience-1--x2.jpg' | prodSrcImage"
									alt="Фото"
									class="card-audience__pic"
								>
								<div class="card-audience__content">
									<div class="card-audience__icon" />
									<div class="card-audience__title">
										Школьнику
									</div>
									<div class="card-audience__text">
										Систему мотивации, при которой через обучение и практику можно прийти к стажировке, стипендии и своей первой работе.
									</div>
								</div>
							</router-link>
							<router-link
								to="/student"
								target="_blank"
								class="card-audience"
							>
								<img
									:src="'/img/landing/role/pic__audience-2--x2.jpg' | prodSrcImage"
									alt="Фото"
									class="card-audience__pic"
								>
								<div class="card-audience__content">
									<div class="card-audience__icon" />
									<div class="card-audience__title">
										Студенту
									</div>
									<div class="card-audience__text">
										Раскрываем мир технологических профессий и рассказываем о перспективах работы в реальном секторе экономики. Лучшие студенты получат стипендию
									</div>
								</div>
							</router-link>
							<div
								class="card-audience"
								@click="$scrollTo($refs.courses)"
							>
								<img
									:src="'/img/landing/role/pic__audience-3--x2.jpg' | prodSrcImage"
									alt="Фото"
									class="card-audience__pic"
								>
								<div class="card-audience__content">
									<div class="card-audience__title">
										Специалисту
									</div>
									<div class="card-audience__text">
										Лучшие в России курсы по устойчивому развитию и сверхкомпетенциям: системному мышлению, предвидению и управлению изменениями, этике и ценностям
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<ActualSlider />

				<section
					ref="courses"
					v-wow
					class="section-simple training wow fadeInUp"
					data-wow-delay="0.2s"
				>
					<div class="content">
						<h2 class="section-title">
							Курсы для специалистов
						</h2>
						<SortedCourses :role="'Specialist'" />
					</div>
				</section>

				<Subscribe :role="'Specialist'" />

				<Vacancies />
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Preloader from '@/components/events/roles/Preloader';
import ExpertSlider from '@/components/events/roles/ExpertSlider';
import Partners from '@/components/events/roles/Partners';
import ExpertStats from '@/components/events/roles/ExpertStats';
// import AllRolesBanner from '@/components/events/roles/AllRolesBanner';
import ExpertMission from '@/components/events/roles/ExpertMission';
import ActualSlider from '@/components/events/roles/ActualSlider';
import SortedCourses from '@/components/events/roles/SortedCourses';
import Subscribe from '@/components/events/roles/Subscribe';
import Vacancies from '@/components/events/roles/Vacancies';
// import EventBanner from '@/components/events/roles/EventBanner.vue';
import BannersGrid from '@/components/events/roles/BannersGrid.vue';

export default {
    name: 'Expert',
    components: {
        // EventBanner,
				BannersGrid,
        Preloader,
        ExpertSlider,
        Partners,
        ExpertStats,
        // AllRolesBanner,
        ExpertMission,
        ActualSlider,
        SortedCourses,
        Subscribe,
        Vacancies
    },
    data: () => ({
        isExpertLoading: true
    }),
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    },
    created() {
        if (this.$route.path === '/oauth/mobile') {
            let code = this.$route.query.code;
            let return_url = this.$route.query.return_url;
            this.loginMobile({
                code: code,
                pathName: return_url
            }).then(result => {
                window.location.href = result.path;
            });
        }
    },
    mounted() {
        window.scrollTo(0,0)
        if(this.isLogged) this.$router.push('/platform')
    },
    methods: {
        ...mapActions('user', ['loginMobile'])
    }
}
</script>

<style lang="less">
.page-role .major-slider.active-button .slick-dots li.slick-active button:after{
    width: 100%;
    animation: none;
}
.page-role .actual-slider.active-actual-button .slick-dots li.slick-active button:after{
    width: 100%;
    animation: none;
}
.card-audience {
    cursor: pointer;
}
</style>
