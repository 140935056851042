import Cnt from '@/api/contest';

const state = {
	isSignUp: false,
  informations: {},
	isRegistered:false,
};

const actions = {
	signUpEvent({ commit },courses) {
		return new Promise(resolve => {
			Cnt.signUpEsg(courses)
				.then(response => {
					return response.data
				})
				.then(result => {
					commit('setIsSignUp', result);
					resolve(result);
				})
		});
	},
	getInf({ commit }) {
		return new Promise(resolve => {
			Cnt.getInfEsg()
				.then(response => response.data)
				.then(result => {
					commit('setInf', result);
					resolve(result);
				});
		});
	},
	getIsRegistered({ commit },id) {
		return new Promise(resolve => {
			Cnt.getIsRegisteredEsg(id)
				.then(response => response.data)
				.then(result => {
					commit('setIsRegistered', result.isRegistered);
					resolve(result);
				});
		});
	}
};

const mutations = {
	setIsSignUp(state, isSignUp) {
		state.isSignUp = isSignUp;
	},
  setInf(state,inf) { 
    state.informations = inf
  },
  setIsRegistered(state,isRegistered) {
    state.isRegistered = isRegistered
  }
};

const getters = {
	getIsSignUp: state => state.isSignUp,
  getInf: state => state.informations,
  getIsRegistered: state => state.isRegistered,
};

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
};
