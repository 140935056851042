<template>
	<section
		v-if="slider"
		v-wow
		class="section-simple actual wow fadeInUp"
		data-wow-delay="0.2s"
	>
		<div class="content">
			<h2 class="section-title">
				Актуально прямо сейчас
			</h2>
			<div
				class="actual__slider-wrap"
				@click="pauseActualSlider"
			>
				<VueSlickCarousel
					ref="actualCarousel"
					class="actual-slider"
					v-bind="actualSliderSettings"
					:class="{'active-actual-button': isStoppedActual}"
					@swipe="pauseActualSlider"
				>
					<div
						v-for="slide in slider"
						:key="slide.id"
					>
						<div v-if="slide.show_on_specialist_landing">
							<div
								v-if="slide.video"
								class="actual-slider__slide"
								:style="slide.image_slider[0].image | bgrDynamicImageCover"
							>
								<div class="actual-slider__video">
									<video
										autoplay="autoplay"
										muted="muted"
										loop="loop"
									>
										<source
											:src="slide.video.file"
											type="video/mp4"
										>
									</video>
								</div>
								<div class="actual-slider__content">
									<h2
										class="title-section"
										v-html="slide.name"
									/>
									<div
										class="actual-slider__text"
										v-html="slide.description"
									/>
									<a
										v-if="
											slide.button_url &&
												slide.open_button_url_in_another_window
										"
										:href="slide.button_url"
										target="_blank"
										class="button"
										@click.stop=""
									>{{ slide.button_name }}</a>
									<a
										v-if="
											slide.button_url &&
												!slide.open_button_url_in_another_window
										"
										:href="slide.button_url"
										class="button"
										@click.stop=""
									>{{ slide.button_name }}</a>
									<button
										v-if="
											slide.button === 'Регистрация' &&
												!isLogged
										"
										class="button"
										@click.prevent="modalOpen('regIn')"
									>
										Регистрация
									</button>
								</div>
							</div>
							<div
								v-else
								class="actual-slider__slide"
								:style="slide.image_slider[0].image | bgrDynamicImageCover"
							>
								<div class="actual-slider__content">
									<h2
										class="title-section"
										v-html="slide.name"
									/>
									<div
										class="actual-slider__text"
										v-html="slide.description"
									/>
									<a
										v-if="
											slide.button_url &&
												slide.open_button_url_in_another_window
										"
										:href="slide.button_url"
										target="_blank"
										class="button"
										@click.stop=""
									>{{ slide.button_name }}</a>
									<a
										v-if="
											slide.button_url &&
												!slide.open_button_url_in_another_window
										"
										:href="slide.button_url"
										class="button"
										@click.stop=""
									>{{ slide.button_name }}</a>
									<button
										v-if="
											slide.button === 'Регистрация' &&
												!isLogged
										"
										class="button"
										@click.prevent="modalOpen('regIn')"
									>
										Регистрация
									</button>
								</div>
							</div>
						</div>
					</div>
				</VueSlickCarousel>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ActualSlider',
    data: () => ({
        isStoppedActual: false,
        actualSliderSettings: {
            arrows: true,
            fade: true,
            focusOnSelect: true,
            waitForAnimate: false,
            infinite: true,
            easing: 'swing',
            dots: true,
            autoplay: true,
            pauseOnFocus: false,
            pauseOnHover: false,
            pauseOnDotsHover: false,
            speed: 500,
            autoplaySpeed: 3600,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        arrows: false
                    }
                }
            ]
        }
    }),
    computed: {
        ...mapGetters('cmn', {
            slider: 'getMainSlider'
        }),
        ...mapGetters('user', {
            isLogged: 'isLoggedIn'
        })
    },
    async created () {
        await this.getMainSlider()
    },
    methods: {
        ...mapActions('cmn', ['getMainSlider']),
        pauseActualSlider() {
            this.$refs.actualCarousel.pause()
            this.isStoppedActual = true
        }
    }
};
</script>
