<template>
	<section
		v-if="prizesMain"
		class="section section-prizes"
	>
		<div class="content">
			<!-- У блока .prizes есть 8 модификаторов меняющих цвет фона: prizes--black, prizes--orange, prizes--blue, prizes--pink, prizes--peach, prizes--yellow, prizes--lightblue, prizes--lightgreen -->
			<div
				class="prizes"
				:class="ColorClass"
			>
				<h2 class="title-section">
					{{ prizesMain.prizes_block_title }}
				</h2>
				<div
					v-if="prizesInfo"
					class="prizes__list"
				>
					<div
						v-for="(item, index) in sortedPrizes"
						:key="index"
						class="prize"
					>
						<div class="prize__pic-wrap">
							<img
								:src="item.image"
								alt="Картинка"
								class="prize__pic"
							>
						</div>
						<div class="title-element">
							{{ item.title }}
						</div>
						<div
							class="prize__text"
							v-html="item.description"
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'Prizes',
    props: {
        prizesMain: {
            type: Object,
            default: null
        },
        prizesInfo: {
            type: Array,
            default: null
        }
    },
    computed: {
        ColorClass() {
            if (this.prizesMain.prizes_block_color === '#000000') return 'prizes--black'
            if (this.prizesMain.prizes_block_color === '#D4F2FF') return 'prizes--lightgreen'
            if (this.prizesMain.prizes_block_color === '#EF722E') return 'prizes--orange'
            if (this.prizesMain.prizes_block_color === '#004494') return 'prizes--blue'
            if (this.prizesMain.prizes_block_color === '#F8C8FF') return 'prizes--pink'
            if (this.prizesMain.prizes_block_color === '#FFD5D6') return 'prizes--peach'
            if (this.prizesMain.prizes_block_color === '#FFF8D5') return 'prizes--yellow'
            if (this.prizesMain.prizes_block_color === '#D5F2FF') return 'prizes--lightblue'
            return ''
        },
        sortedPrizes () {
            return this.prizesInfo &&  this.prizesInfo.filter(item => !!item.priority).sort((a, b) => a.priority - b.priority)
        }
    }
}
</script>

<style lang="less">
@media (max-width: 767px) {
    .page-event .prize {
        display: block !important;
        text-align: center !important;
        margin: 0 auto !important;
    }
    .page-event .prize .title-element {
        white-space: normal;
    }
}
</style>
