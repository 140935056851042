<template>
	<div class="modal-content">
		<template v-if="payload">
			<div class="modal-title-wrap">
				<div class="modal-title sm">
					Стать наставником
				</div>
			</div>
			<form
				class="form"
				novalidate
				@submit.prevent="send"
			>
				<div class="text dark sm">
					Персональная информация:
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model.trim="$v.form.full_name.$model"
							type="text"
							class="field"
							placeholder="ФИО"
							style="text-transform: capitalize"
						>
						<div class="label">ФИО</div>
						<span
							v-if="$v.form.full_name.$error"
							class="help-block form-error"
						>
							{{
								!$v.form.full_name.required ? 'Это обязательное поле' : $v.form.full_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректное ФИО'
							}}
						</span>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model.trim="$v.form.email.$model"
							type="email"
							class="field"
							placeholder="Email"
						>
						<div class="label">Email</div>
						<span
							v-if="$v.form.email.$error"
							class="help-block form-error"
						>
							{{ !$v.form.email.required ? 'Это обязательное поле' : !$v.form.email.email ? 'Введите корректный e-mail' : 'Ошибка поля' }}
						</span>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model="$v.form.phone.$model"
							v-mask="'+7(###)###-##-##'"
							type="text"
							class="field"
							placeholder="Телефон"
						>
						<div class="label">Телефон</div>
						<span
							v-if="$v.form.phone.$error"
							class="help-block form-error"
						>
							{{ !$v.form.phone.required ? 'Это обязательное поле' : !$v.form.phone.Phone ? 'Введите корректный номер телефона' : 'Ошибка поля' }}
						</span>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model="form.city"
							type="text"
							class="field"
							placeholder="Город"
						>
						<div class="label">Город</div>
						<span
							v-if="$v.form.city.$error"
							class="help-block form-error"
						>Это обязательное поле
						</span>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<c-select
							:name="'Вуз/ссуз'"
							autosearch-education
							:default-data="form.education_facility"
							:is-error="$v.form.education_facility.$error"
							@selected="selectEduFac"
						/>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model.trim="$v.form.study_year.$model"
							type="text"
							class="field"
							placeholder="Год обучения (курс)"
						>
						<div class="label">Год обучения (курс)</div>
						<span
							v-if="$v.form.study_year.$error"
							class="help-block form-error"
						>Это обязательное поле</span>
					</label>
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<input
							v-model.trim="$v.form.faculty.$model"
							type="text"
							class="field"
							placeholder="Факультет"
						>
						<div class="label">Факультет</div>
						<span
							v-if="$v.form.faculty.$error"
							class="help-block form-error"
						>Это обязательное поле</span>
					</label>
				</div>
				<div class="text dark sm">
					Области профессиональных интересов:
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<textarea
							v-model.trim="$v.form.professional_interests.$model"
							rows="4"
							class="field-default"
							placeholder="Эксперта из какой отрасли и профессии ты хотел бы себе в наставники?"
						/>
						<span
							v-if="$v.form.professional_interests.$error"
							class="help-block form-error"
						>Это обязательное поле</span>
					</label>
				</div>
				<div class="text dark sm">
					Почему ты хочешь стать наставником:
				</div>
				<div class="field-wrap">
					<label class="field-label">
						<textarea
							v-model.trim="$v.form.motivation_letter.$model"
							rows="10"
							class="field-default"
							placeholder="Мотивационное письмо"
						/>
						<span
							v-if="$v.form.motivation_letter.$error"
							class="help-block form-error"
						>Это обязательное поле</span>
					</label>
				</div>
				<div class="field-wrap sm">
					<label class="checkbox">
						<input
							v-model="$v.isPersonal.$model"
							type="checkbox"
							@change="$v.isPersonal.$touch()"
						>
						<span>Соглашаюсь с условиями обработки <a
							href="/privacy-policy"
							target="_blank"
						>персональных данных</a></span>
						<div
							v-if="$v.isPersonal.$error"
							class="help-block form-error"
						>Необходимо подтверждение</div>
					</label>
				</div>
				<div class="field-wrap">
					<label class="checkbox">
						<input
							v-model="$v.isAgree.$model"
							type="checkbox"
							@change="$v.isAgree.$touch()"
						>
						<span>Соглашаюсь с условиями <a
							href="/agreement"
							target="_blank"
						>пользовательского соглашения</a></span>
						<div
							v-if="$v.isAgree.$error"
							class="help-block form-error"
						>Необходимо подтверждение</div>
					</label>
				</div>
				<button
					v-if="!isSended"
					id="btnAmbsdrStd"
					class="button orange lg btnRegistration"
				>
					Отправить
				</button>
				<div
					v-else
					class="button orange lg button-preloader"
				>
					<div class="button-preloader-icons">
						<div class="button-preloader-icon" />
						<div class="button-preloader-icon" />
						<div class="button-preloader-icon" />
					</div>
				</div>
			</form>
		</template>
		<div
			v-else
			class="center"
		>
			<br>
			<div class="modal-title-wrap">
				<h4 class="modal-subtitle">
					Регистрация закрыта
				</h4>
			</div>
			<button
				class="button-hollow"
				@click="modalClose"
			>
				Закрыть
			</button>
		</div>
	</div>
</template>

<script>

import Cnt from '@/api/contest'
import {required, email, sameAs} from 'vuelidate/lib/validators'
import {alphaSpace, Phone} from '@/assets/scripts/custom_validators'
import {mapActions, mapGetters} from 'vuex';

export default {
    data: () => ({
        form: {
            full_name: '',
            email: '',
            phone: '',
            city: '',
            education_facility: '',
            study_year: '',
            faculty: '',
            professional_interests: '',
            motivation_letter: ''
        },
        isPersonal: false,
        isAgree: false,
        isSended: false
    }),
    validations: {
        form: {
            full_name: {required, alphaSpace},
            email: {required, email},
            phone: {required, Phone},
            city: {required},
            education_facility: {required},
            study_year: {required},
            faculty: {required},
            professional_interests: {required},
            motivation_letter: {required}
        },
        isPersonal: {
            sameAs: sameAs(() => true)
        },
        isAgree: {
            sameAs: sameAs(() => true)
        }
    },
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            user: 'userInfo',
        }),
        activeData() {
            return this.$store.state.modal.activeData
        },
        payload() {
            return this.$store.state.modal.payload
        }
    },
    created() {
        if (this.activeData) {
            const parse = JSON.parse(JSON.stringify(this.activeData))
            this.form = parse.form
            this.isPersonal = parse.isPersonal
            this.isAgree = parse.isAgree
        }
    },
    methods: {
        ...mapActions('user', {
            getStudyPlace: 'getStudyPlace',
            loginEvent: 'loginEvent'
        }),
        selectCity(val) {
            this.form.city = val
        },
        selectEduFac(val) {
            this.form.education_facility = val
        },
        send() {
            if (!this.isLogged) {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    console.log('Student form is error')
                } else if (!this.isSended) {
                    this.isSended = true
                    if (this.$metrika) this.$metrika.reachGoal('studentfeed')
                    let [last_name, first_name, middle_name] = this.form.full_name.split(' ')
                    this.loginEvent({data: {
                            first_name: first_name && first_name.length > 0 ? first_name : ' ',
                            email: this.form.email,
                            study: {
                                education: ''
                            }
                        }, slug: 'nastavnik'})
                        .then(() => Cnt.studentProposal2022Fourth('college', {
                            first_name: first_name && first_name.length > 0 ? first_name : ' ',
                            middle_name,
                            last_name,
                            ...this.form
                        }))
                        .then(() => {
                            this.modalOpen('ambsdrSuccess')
                            this.$v.form.$reset()
                            this.isCityClear = true
                            this.form = {
                                full_name: '',
                                email: '',
                                phone: '',
                                city: '',
                                education_facility: '',
                                study_year: '',
                                faculty: '',
                                professional_interests: '',
                                motivation_letter: ''
                            }
                            this.isPersonal = false
                            this.isAgree = false
                            this.isSended = false
                            this.$store.commit('modal/resetActiveData')
                            this.modalClose('ambsdrSuccess')
                        })
                        .catch(error => {
                            this.$store.commit('modal/setActiveData', {
                                form: this.form,
                                isPersonal: this.isPersonal,
                                isAgree: this.isAgree
                            })
                            this.isSended = false
                            if (this.isPersonal === true) this.modalClose('ambsdr-2-Fail', 'Вы уже отправили заявку')
                            else this.modalClose('ambsdr-2-Fail', error.response.data.error_text)
                        })
                    }
                } else {
                    this.$v.$touch()
                    if (this.$v.$invalid) {
                        console.log('Student form is error')
                    } else if (!this.isSended) {
                        this.isSended = true
                        if (this.$metrika) this.$metrika.reachGoal('studentfeed')
                        let [last_name, first_name, middle_name] = this.form.full_name.split(' ')
                        this.getStudyPlace()
                            .then(res => {
                                this.studyPlaces = res
                                this.studyPlaces.forEach(item => {
                                    if (item.primary_education) {
                                        this.EL.study.education = item.name
                                    }
                                })
                            })
                            .then(() => Cnt.registerToEvent({data: {
                                    first_name: this.form.full_name,
                                    email: this.user.email
                                }, slug: 'nastavnik'}))
                        Cnt.studentProposal2022Fourth( 'college',{
                            first_name: first_name && first_name.length > 0 ? first_name : ' ',
                            middle_name,
                            last_name,
                            ...this.form
                        })
                            .then(() => {
                                this.modalOpen('ambsdrSuccess')
                                this.$v.form.$reset()
                                this.isCityClear = true
                                this.form =  {
                                    full_name: '',
                                    email: '',
                                    phone: '',
                                    city: '',
                                    education_facility: '',
                                    study_year: '',
                                    faculty: '',
                                    professional_interests: '',
                                    motivation_letter: ''
                                }
                                this.isPersonal = false
                                this.isAgree = false
                                this.isSended = false
                                this.$store.commit('modal/resetActiveData')
                                this.modalClose('ambsdrSuccess')
                            })
                            .catch(error => {
                                this.$store.commit('modal/setActiveData', {
                                    form: this.form,
                                    isPersonal: this.isPersonal,
                                    isAgree: this.isAgree
                                })
                                this.isSended = false
                                if (this.isPersonal === true) this.modalClose('ambsdr-2-Fail', 'Вы уже отправили заявку')
                                else this.modalClose('ambsdr-2-Fail', error.response.data.error_text)
                            })
                }
            }
        }
    }
}
</script>

<style>
.checkbox input:checked+span:before {
    background: #ef722e;
    border-color: #ef722e;
}
.checkbox input:checked+span:after {
    background: url(../../assets/img/icons/icon-checkbox-filter-check.png) center center no-repeat;
}
</style>
