<template>
	<div class="modal-content">
		<div class="center">
			<div class="modal-title-wrap">
				<div class="modal-title sm">
					Авторизация
				</div>
				<h4 class="modal-subtitle">
					{{ subtitle }}
				</h4>
			</div>
			<div class="buttons-list">
				<button
					class="button-hollow"
					@click="modalClose('signIn')"
				>
					Вход
				</button>
				<button
					class="button blue"
					@click="modalClose('regIn')"
				>
					Регистрация
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    computed: {
        ...mapGetters('modal', {
            payload: 'getPayload'
        }),
        subtitle() {
            if (this.payload === 'part') return 'Принимать участие могут только авторизованные пользователи. Войдите в свой аккаунт или зарегистрируйтесь.'
            return 'Голосовать могут только авторизованные пользователи. Войдите в свой аккаунт или зарегистрируйтесь.'
        }
    }
}
</script>
