<template>
	<div class="modal-content">
		<div class="certificate-modal">
			<img
				:src="'/img/pages/onboarding/pic__offer-2.png' | prodSrcImage"
				alt="Иконка"
				class="certificate-modal__pic"
			>
			<div class="title-element">
				Для получения сертификата «Инноватора профориентации» необходимо провести <span>2 занятия по методике</span> «Лифт в будущее» и оставить обратную связь
			</div>
			<div class="certificate-modal__text">
				Сертификат появится в личном кабинете, вы получите уведомление по электронной почте
			</div>
			<div
				class="button"
				@click="modalClose"
			>
				Отлично
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'Teacher'
}
</script>

<style scoped>

</style>
