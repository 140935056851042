import Tb from '@/api/teachbase'

const state = {
    company: null,
    vacancies: null,
    cities: null,
    activities: null,
    specialisation: null,
    typeEmployment: null,
    companies: null
}

const actions = {
    getCompany({ commit }, id) {
        return new Promise(resolve => {
            Tb.getCompany(id)
                .then(response => response.data)
                .then(result => {
                    commit('setCompany', result)
                    resolve(result)
                })
        })
    },
    getCompanies({ commit }) {
        return new Promise(resolve => {
            Tb.getCompanies()
                .then(response => response.data.results)
                .then(result => {
                    commit('setCompanies', result)
                    resolve(result)
                })
        })
    },
    getVacancies({ commit }, filter) {
        return new Promise((resolve) => {
            !filter ? commit('setVacancies', undefined) : false;
            Tb.getVacanciesCompany(filter)
                .then(response => response.data)
                .then(payload => {
                    commit('setVacancies', payload.results)
                    resolve(payload)
                })
        })
    },
    getCities({ commit }, id) {
        return new Promise(resolve => {
            Tb.getCompanyCities(id)
                .then(response => response.data)
                .then(result => {
                    commit('setCities', result)
                    resolve(result)
                })
        })
    },
    getActivities({ commit }, id) {
        return new Promise(resolve => {
            Tb.getCompanyActivities(id)
                .then(response => response.data)
                .then(result => {
                    commit('setActivities', result)
                    resolve(result)
                })
        })
    },
    getSpecialisation({ commit }, id) {
        return new Promise(resolve => {
            Tb.getCompanySpecialisation(id)
                .then(response => response.data)
                .then(result => {
                    commit('setSpecialisation', result)
                    resolve(result)
                })
        })
    },
    getTypeEmployment({ commit }, id) {
        return new Promise(resolve => {
            Tb.getCompanyTypeEmployment(id)
                .then(response => response.data)
                .then(result => {
                    commit('setTypeEmployment', result)
                    resolve(result)
                })
        })
    }
}

const mutations = {
    setCompany(state, company) {
        state.company = company
    },
    setCompanies(state, companies) {
        state.companies = companies
    },
    setVacancies(state, vacancies) {
        state.vacancies = vacancies
    },
    setCities(state, cities) {
        state.cities = cities
    },
    setActivities(state, activities) {
        state.activities = activities
    },
    setSpecialisation(state, specialisation) {
        state.specialisation = specialisation
    },
    setTypeEmployment(state, typeEmployment) {
        state.typeEmployment = typeEmployment
    }
}

const getters = {
    getCompany: state => state.company,
    getCompanies: state => state.companies,
    getVacancies: state => state.vacancies,
    getCities: state => state.cities,
    getActivities: state => state.activities,
    getSpecialisation: state => state.specialisation,
    getTypeEmployment: state => state.typeEmployment
}



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
