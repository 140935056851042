<template>
	<div class="sections">
		<section class="intro">
			<div class="content">
				<div class="panel-testing">
					<!-- <div
						v-if="$route.params.id === 'mindformat'"
						class="block-title"
					>
						Первый этап участия в стипендиальной программе
					</div> -->
					<div
						v-if="$route.params.id !== 'mindformat'"
						class="block-title"
					>
						Тебе будет представлен ряд ситуаций, с которыми ты можешь столкнуться в своей жизни. Внимательно прочитай описание и выбери вариант ответа, близкий к тому, как ты обычно поступаешь.
					</div>
					<div
						v-if="$route.params.id === 'mindformat'"
						class="intro__text"
					>
						<p style="margin-bottom: 20px">
							После прохождения теста вы узнаете:
						</p>
						<ul>
							<li>Уровень гармонии, уверенности и тревоги</li>
							<li>Ваш формат мышления</li>
							<li>Все выявленные софт-скиллы</li>
							<li>Свои уникальные способности</li>
							<li>Слабые стороны</li>
							<li>Мотивацию</li>
							<li>Особенности поведения в семье, быту и отношениях</li>
							<li>Примеры известных людей вашего формата мышления</li>
							<li>Социальные стремления</li>
						</ul>
					</div>
					<div
						v-else
						class="intro__text"
					>
						<ul>
							<li>На каждый вопрос у тебя будет неограниченное количество времени</li>
							<li>Среднее время прохождения теста - 25 минут</li>
						</ul>
					</div>
					<div
						class="panel-testing__footer"
					>
						<div v-if="$route.params.id !== 'mindformat'">
							<div
								v-if="isLogged && !isPassed"
								class="intro__subtitle"
							>
								В процессе выполнения теста <span>нельзя вернуться к предыдущему вопросу и изменить ответ</span>
							</div>
							<div
								v-else-if="isLogged && isPassed"
								class="intro__subtitle"
							>
								Вы уже проходили этот тест
							</div>
							<div
								v-else
								class="intro__subtitle"
							>
								В процессе выполнения теста <span>нельзя вернуться к предыдущему вопросу и изменить ответ</span>
								<div class="intro__subtitle intro__regin">
									Пожалуйста, авторизуйтесь, чтобы пройти тестирование
								</div>
							</div>
						</div>
						<div v-else>
							<div class="intro__subtitle">
								Всего 110 вопросов с вариантами ответов
							</div>
							<div class="intro__subtitle">
								Необходимо ответить на все вопросы из теста
							</div>
							<div class="intro__subtitle">
								Время прохождения теста не ограничено
							</div>
							<div class="intro__subtitle">
								Вы можете вернуться к предыдущему вопросу
							</div>
						</div>
						<button
							v-if="isLogged && !isPassed"
							class="button orange"
							@click="goToTest"
						>
							{{ btnName }}
							<img
								:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
						<router-link
							v-else-if="isLogged && isPassed"
							:to="`/tests/${this.$route.params.id}?task=true`"
							class="button orange"
						>
							Пройти заново
							<img
								:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
								alt="Иконка"
								class="button__icon-right"
							>
						</router-link>
						<button
							v-else
							class="button orange"
							@click="modalOpen('regIn', 'testing')"
						>
							Авторизоваться
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Tests from '@/api/tests';

export default {
    name: 'StartPage',
    data: () => ({
        btnStatus: '',
        url: ''
    }),
    computed: {
    ...mapGetters('user', {
            isLogged: 'isLoggedIn',
        }),
    ...mapGetters('tst', {
            isPassed: 'isPassed'
        }),
        btnName() {
            if (this.$route.params.id === 'mindformat') {
                if (this.btnStatus === 'not_signed' || this.btnStatus === 'not_started') return 'Начать'
                if (this.btnStatus === 'started') return 'Продолжить'
                if (this.btnStatus === 'finished') return 'Результаты'
            }
            return 'Начать'
        }
    },
    created() {
        this.getTestData()
        if (this.$route.fullPath === '/tests/mindformat#error') this.modalOpen('stdFail', 'Произошла ошибка, попробуйте зайти еще раз')
    },
    methods: {
        ...mapActions('tst', [
            'getTest'
        ]),
        getTestData() {
            if (this.$route.params.id === 'mindformat') this.getTestMindFormat()
            else this.getTest(this.$route.params.id)
        },
        getTestMindFormat() {
            Tests.getMindFormat().then(response => {
                this.btnStatus = response.data.status
                if(response.data.status !== 'not_signed') this.url = response.data.url
            })
        },
        goToTest () {
            if (this.$route.params.id !== 'mindformat') this.$router.push(`/tests/${this.$route.params.id}?task=true`)
            else {
                if (this.btnStatus === 'not_signed') {
                    Tests.signUpMindFormat().then(response => {
                        if (response.status === 200) {
                            Tests.getMindFormat().then(response => {
                                window.location.href = response.data.url
                            })
                        }
                    })
                }
                else window.location.href = this.url
            }
        }
    }
}
</script>

<style lang="less" scoped>
.intro__subtitle.intro__regin {
    margin-top: 20px;
    font-size: 14px;
}
</style>
