<template>
	<div>
		<div
			v-if="isNoChoose"
			class="banner__button"
		>
			<button
				v-if="btnVariations.isUserRole"
				class="button"
				@click="
					btnVariations.isParticipant = true;
					btnVariations.isExpert = false;
				"
			>
				Стать участником
			</button>
			<button
				v-if="btnVariations.isExpertRole"
				class="button"
				@click="passOnboarding"
			>
				Стать экспертом
			</button>
		</div>

		<div v-if="isParticipant">
			<div
				v-if="!isApplyContest"
				class="banner__button"
			>
				<a
					v-if="!btnVariations.isMindformatTest"
					class="button"
					:style="'background-color:' + btnColor"
					href="/tests/mindformat/"
					target="_blank"
				>
					Пройти тест для оценки потенциала и особенностей мышления
				</a>
				<router-link
					v-else-if="actualTests && actualTests.length > 0"
					class="button"
					:style="'background-color:' + btnColor"
					:to="`/tests/${actualTests[0].id}`"
					target="_blank"
				>
					Пройти {{ actualTests[0].name.toLowerCase() }}
				</router-link>
			</div>
			<div
				v-else
				class="banner__button"
			>
				<button
					class="button"
					:style="'background-color:' + btnColor"
					@click="$emit('apply')"
				>
					Прохождение конкурса
				</button>
			</div>
		</div>
		<div
			v-if="isShowOnboarding"
			class="banner__button"
		>
			<button
				class="button"
				@click="modalOpen('about-expert', contestId)"
			>
				Пройти онбординг
			</button>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ContestRegisterButton',

	props: {
		btnVariations: {
			type: Object,
			default: null
		},
    isApplyContest: {
      type: Boolean,
      efault: false
    },
    actualTests: {
        type: Array,
        default: null
    },
    btnColor: {
        type: String,
        default: null
    },
    contestId: {
      type: Number,
      default: null,
    }
	},

	computed: {
		isNoChoose() {
			return !this.btnVariations.isParticipant && !this.btnVariations.isExpert;
		},
        isParticipant() {
          return this.btnVariations.isParticipant && !this.btnVariations.isExpert;
        },
        isShowOnboarding() {
          return this.btnVariations.isExpert && !this.btnVariations.isParticipant && !this.btnVariations.isParticipantPass;
        },
	},

    methods: {
		passOnboarding() {
			if (!this.btnVariations.isExpertPass) {
				this.btnVariations.isExpert = true;
				this.btnVariations.isParticipant = false;
			} else {
				this.modalOpen(
					'stdFail',
					'Ваша заявка, чтобы стать экспертом подана'
				);
			}
		}
	}
};
</script>
